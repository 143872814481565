<template>
  <div class="HCmyCP">
    <div class="back text-center">
      <van-icon
        name="arrow-left"
        size="20"
        color="white"
        class="arrow"
        @click="backbtn()"
      />
      <span class="f-17 white">我的藏品</span>
    </div>
    <van-loading v-if="loding" />
    <div v-else>
      <div class="HCmyCP_cet" v-if="collectionList.length != 0">
        <div class="cp_list">
          <div
            class="list"
            :class="[glFG == i ? 'gl' : '']"
            @click="select(i)"
            v-for="(item, i) in collectionList"
            :key="i"
          >
            <img :src="item.image" alt="" />
            <div class="list_btm">
              <div class="f-13">{{ item.title.split("）")[0] }}）</div>
              <div class="f-11">
                <span>{{ item.title.split("）")[1] }} ·</span>
                <span> {{ item.number }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="listbtnFG">
          <div class="HCmyCP_btn" v-if="btnFlg" @click="confirm()">
            确认选中
          </div>
          <div class="HCmyCP_btn2" v-else>确认选中</div>
        </div>
      </div>
      <div class="nocet" v-else>
        <div class="text">暂无藏品</div>
        <img src="../../assets/img/no-more.png" alt="" />
      </div>
    </div>

    <van-overlay :show="cancel">
      <div class="wrapper">
        <div class="cancelConf">
          <div class="title">是否取消选择本藏品</div>
          <div class="btn_box">
            <div class="no f-15" @click="cancel = false">再想想</div>
            <div class="yes f-15" @click="back()">确认取消</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="hasLogin"
    ></login>
  </div>
</template>
<script>
import { checknft } from "../../api/benCao.js";
import { mapMutations, mapState } from "vuex";
import Login from "../../components/Login";

export default {
  name: "HCmyCP",
  components: {
    Login,
  },
  data() {
    return {
      loding: true,
      attribute: "",
      listbtnFG: true,
      isShowLogin: false,
      CpCet: false,
      btnFlg: false,
      cancel: false,
      glFG: -1,
      collectionList: [],
    };
  },
  created() {
    this.attribute = this.$route.params.attribute;
    this.getlist();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      quality: (state) => state.quality,
    }),
  },
  mounted() {
    this.brightness();
  },
  methods: {
    ...mapMutations(["setfire", "setgold", "setwood", "setwater", "setSoil"]),

    brightness() {
      if (this.attribute == "fire") {
        this.glFG = this.quality.fire.index;
      } else if (this.attribute == "gold") {
        this.glFG = this.quality.gold.index;
      } else if (this.attribute == "wood") {
        this.glFG = this.quality.wood.index;
      } else if (this.attribute == "water") {
        this.glFG = this.quality.water.index;
      } else if (this.attribute == "Soil") {
        this.glFG = this.quality.Soil.index;
      }
      // if (this.glFG == "" || this.glFG == undefined) {
      if (this.glFG < 0 || this.glFG == undefined) {
        this.btnFlg = false;
      } else {
        this.btnFlg = true;
      }
    },
    async getlist() {
      this.collectionList = [];
      try {
        const res = await checknft({});
        this.loding = false;
        if (this.attribute == "fire") {
          // 火
          res.data.forEach((v, i) => {
            if (v.five == "火") this.collectionList.push(v);
          });
        } else if (this.attribute == "gold") {
          //金
          res.data.forEach((v, i) => {
            if (v.five == "金") {
              this.collectionList.push(v);
            }
          });
        } else if (this.attribute == "wood") {
          //木
          res.data.forEach((v, i) => {
            if (v.five == "木") {
              this.collectionList.push(v);
            }
          });
        } else if (this.attribute == "water") {
          //水
          res.data.forEach((v, i) => {
            if (v.five == "水") {
              this.collectionList.push(v);
            }
          });
        } else if (this.attribute == "Soil") {
          //土
          res.data.forEach((v, i) => {
            if (v.five == "土") {
              this.collectionList.push(v);
            }
          });
        } else {
          this.collectionList = res.data;
          this.listbtnFG = false;
        }
      } catch (e) {
        console.log(e);
        if (e.code == 201) {
          // this.isShowLogin = true;
          this.loding = false;
          this.$toast("请先登录账号");
        }
      }
    },
    confirm() {
      if (this.attribute == "fire") {
        this.setfire({
          ...this.collectionList[this.glFG],
          index: this.glFG,
        });
      } else if (this.attribute == "gold") {
        this.setgold({
          ...this.collectionList[this.glFG],
          index: this.glFG,
        });
      } else if (this.attribute == "wood") {
        this.setwood({
          ...this.collectionList[this.glFG],
          index: this.glFG,
        });
      } else if (this.attribute == "water") {
        this.setwater({
          ...this.collectionList[this.glFG],
          index: this.glFG,
        });
      } else if (this.attribute == "Soil") {
        this.setSoil({
          ...this.collectionList[this.glFG],
          index: this.glFG,
        });
      }
      this.back();
    },
    select(i) {
      if (this.listbtnFG) {
        this.glFG = i;
        if (this.glFG != -1) {
          this.btnFlg = true;
        }
      }
    },
    hasLogin() {
      this.isShowLogin = false;
      this.getlist();
    },
    backbtn() {
      if (this.attribute == "fire") {
        if (this.quality.fire.index > -1) {
          this.cancel = true;
        } else {
          this.back();
        }
      } else if (this.attribute == "gold") {
        if (this.quality.gold.index > -1) {
          this.cancel = true;
        } else {
          this.back();
        }
      } else if (this.attribute == "wood") {
        if (this.quality.wood.index > -1) {
          this.cancel = true;
        } else {
          this.back();
        }
      } else if (this.attribute == "water") {
        if (this.quality.water.index > -1) {
          this.cancel = true;
        } else {
          this.back();
        }
      } else if (this.attribute == "Soil") {
        if (this.quality.Soil.index > -1) {
          this.cancel = true;
        } else {
          this.back();
        }
      } else {
        this.back();
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.HCmyCP {
  min-height: 100vh;
  background: #000;
  position: relative;
  padding-bottom: 30px;
  .back {
    position: relative;
    width: 100%;
    top: 0;
    height: 48px;
    line-height: 44px;
    background-color: black;
    .arrow {
      width: 27px;
      height: 27px;
      background: #4d4d4d;
      border-radius: 50%;
      text-align: center;
      line-height: 27px;
      position: absolute;
      top: 22px;
      left: 25px;
      transform: translateY(-50%);
    }
  }
  .van-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .HCmyCP_cet {
    margin-top: 50px;
    .cp_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 17px;
      .list {
        border: 1px solid rgba(0, 0, 0, 0);
        margin-bottom: 15px;
        width: 49%;
        img {
          height: 166px;
          width: 100%;
        }
        .list_btm {
          padding: 7px 13px;
          background: #161616;
          border-radius: 0 0 3px 3px;
          color: #fff;
        }
      }
      .gl {
        border: 1px solid #f7dfb6;
        box-shadow: 0px 0px 9px 0px #f1bc7e;
        border-radius: 3px;
      }
    }
    .HCmyCP_btn {
      width: 215px;
      height: 40px;
      background: linear-gradient(90deg, #e7c99c, #feefd2);
      border-radius: 20px;
      margin: 30px auto 0;
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .HCmyCP_btn2 {
      width: 215px;
      height: 40px;
      background: #626262;
      border-radius: 20px;
      margin: 30px auto 0;
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nocet {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .text {
      font-size: 18px;
      font-weight: 400;
      color: #7f7f7f;
      margin-bottom: 55px;
      text-align: center;
    }
    img {
      height: 106px;
      width: 106px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .cancelConf {
      width: 281px;
      height: 170px;
      background: #1c1c1c;
      border-radius: 10px;
      position: relative;
      padding: 40px 20px 0;
      .title {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
      .btn_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        .no {
          width: 113px;
          height: 37px;
          background: #ffffff;
          border-radius: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .yes {
          width: 114px;
          height: 38px;
          background: #000000;
          border: 1px solid #000000;
          border-radius: 19px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
