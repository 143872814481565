<template>
  <van-overlay :show="show" z-index="99">
    <div class="wrapper login" @click.stop>
      <div class="block">
        <div class="close">
          <van-icon name="cross" size="15" color="#69686D" @click="close" />
        </div>
        <h1 class="text-center f-22" style="color: #8391cd">登陆轻松账号</h1>
        <p class="text-center f-12">未注册的手机号自动创建轻松账号</p>
        <van-form @submit.native.prevent="onSubmit" :show-error="false">
          <van-field
            v-model="tel"
            type="number"
            name="用手机号"
            label="手机号"
            @input="getLoginCheck()"
            placeholder="请填写手机号"
            @blur="tempSaveTel"
            maxlength="11"
            style="margin-bottom: 25px"
            :rules="[
              { required: true, message: '请填写手机号' },
              { pattern, message: '请输入正确的手机号' },
            ]"
          />
          <van-field
            v-model="code"
            name="验证码"
            label="验证码"
            placeholder="请填写验证码"
            @blur="tempSaveCode"
            style="margin-bottom: 25px"
            maxlength="4"
            :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <template #button>
              <span style="color: black" v-show="!isShowTime" @click="getCode"
                >发送验证码</span
              >
              <span style="color: black" v-show="isShowTime">{{ time }}s</span>
            </template>
          </van-field>
          <van-field
            v-show="!register"
            v-model="invited_code"
            type="text"
            name="用手机号"
            label="邀请码"
            placeholder="请填写邀请码"
            @blur="tempSaveTel"
            maxlength="6"
            style="margin-bottom: 25px"
          />

          <van-field
            name="radio"
            label="单选框"
            style="background: none; padding-right: 0; padding-left: 0"
          >
            <template #input>
              <van-radio-group
                v-model="radio"
                direction="horizontal"
                checked-color="black"
              >
                <van-radio name="1">
                  <span class="f-10"
                    >已查看并同意
                    <span
                      @click="toPage('protocol')"
                      style="text-decoration: underline"
                      >《轻松小镇用户协议》</span
                    >、
                    <span
                      @click="toPage('privacy')"
                      style="text-decoration: underline"
                      >《轻松小镇游戏许可及服务协议》</span
                    >、
                    <span
                      @click="toPage('goodsProtocol')"
                      style="text-decoration: underline"
                      >《虚拟商品购买服务协议》</span
                    >
                  </span>
                </van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div style="color: red" class="f-12" v-show="isShowError">
            请查看并同意《轻松小镇用户协议》与《轻松小镇隐私协议》
          </div>
          <div style="margin: 16px" class="text-center">
            <van-button
              class="login-btn f-17"
              native-type="submit"
              :loading="isLoading"
            >
              <span class="f-17 bold-500">登录</span>
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
    <clicaptcha
      v-if="isShowCheck"
      @success="success"
      @fail="isShowCheck = false"
      :tel="tel"
    ></clicaptcha>
  </van-overlay>
</template>

<script>
import { loginApi, share, userApi, loginCheck } from "../api/user";
import { mapMutations } from "vuex";
import Clicaptcha from "./Clicaptcha";
import { getString } from "../../tool";
import { getGlobal } from "../api/thirty";

export default {
  name: "Login",
  components: { Clicaptcha },
  props: {
    show: Boolean,
  },
  data() {
    return {
      tel: sessionStorage.getItem("mobile") || "",
      code: sessionStorage.getItem("code") || "",
      invited_code: "",
      time: 60,
      isShowTime: false,
      register: true,
      timer: "",
      isLoading: false,
      radio: "",
      isShowError: false,
      pattern: /^1\d{10}$/,
      isShowCheck: false,
    };
  },
  async mounted() {
    this.invited_code = localStorage.getItem("invited_code") || "";
    console.log(this.invited_code);
    await this.getLoginCheck();
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetToken: "setToken",
    }),
    toPage(name) {
      this.$router.push({ name });
    },
    close() {
      this.$emit("close");
    },
    showCheck() {
      this.isShowCheck = true;
    },
    async onSubmit(values) {
      console.log(777777, this.GetArgsFromHref(window.location.href,"invited_ids"));

      if (!this.radio) {
        this.isShowError = true;
        return;
      }
      try {
        this.isLoading = true;
        let user_id = this.invited_code || localStorage.getItem("invited_id");
        getString();
        const res = await loginApi({
          mobile: this.tel,
          sms_code: this.code,
          user_id: user_id,
          act_id: localStorage.getItem("act_id"),
        });
        // debugger;
        this.vxSetToken(res.data.acctoken);
        this.getUser();
        this.getShareConfig();
        this.$emit("success");
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        // this.$toast.fail(e.data)
        this.$toast(e.data);
      }
    },
    success() {
      this.isShowCheck = false;
      this.showCountdown();
      this.$toast("验证码已发送，请注意查收！");
    },
    showCountdown() {
      this.isShowTime = true;
      this.timer = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.time = 60;
          this.isShowTime = false;
        }
      }, 1000);
    },
    async getCode() {
      if (!this.tel) {
        return this.$toast("请填写手机号");
      }
      this.showCheck();
      // try {
      //   const res = await codeApi({
      //     mobile: this.tel
      //   })
      //   console.log(res)
      //   this.$toast(res.data.info)
      //   this.showCountdown()
      // } catch (e) {
      //   console.log(e)
      //   this.$toast.fail(e.data)
      // }
    },
    async getUser() {
      try {
        const res = await userApi({});
        this.vxSetUser({
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    async getLoginCheck() {
      if (this.tel.length == 11) {
        try {
          const res = await loginCheck({
            mobile: this.tel,
          });
          this.register = res.data.register;
        } catch (e) {}
      }
    },
    tempSaveTel() {
      sessionStorage.setItem("mobile", this.tel);
    },
    tempSaveCode() {
      sessionStorage.setItem("code", this.code);
    },
    //获取链接值
    GetArgsFromHref(sHref, sArgName) {
      var args = sHref.split("?");
      var retval = "";

      if (args[0] == sHref) {
        /*参数为空*/
        return retval; /*无需做任何处理*/
      }
      var str = args[1];
      args = str.split("&");
      for (var i = 0; i < args.length; i++) {
        str = args[i];
        var arg = str.split("=");
        if (arg.length <= 1) continue;
        if (arg[0] == sArgName) retval = arg[1];
      }
      return retval;
    },
    async getShareConfig() {
      try {
        const res = await share({});
        if (!localStorage.getItem("invited_id")) {
          localStorage.setItem("invited_id", res.data.user_id);
        }
        localStorage.setItem("invited_ids", this.GetArgsFromHref(window.location.href,"invited_ids"));
        localStorage.setItem("user_id", res.data.user_id);
        localStorage.setItem("activity_id", res.data.reserve_id);
        localStorage.setItem("count", res.data.count);
        const origin = window.location.origin;
        const hash = window.location.hash;
        window.location.href = `${origin}/?invited_id=${localStorage.getItem(
          "user_id"
        )}&activity_id=${localStorage.getItem("activity_id")}${hash}`;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    background: url("../assets/img/login-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    width: 80%;
    background-color: white;
    padding: 31px;
    border-radius: 10px;
    background-position-x: 48%;

    .close {
      position: absolute;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: #ececec;
      right: 10px;
      top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .login-btn {
      background: #000000;
      border-radius: 25px;
      color: white;
      padding: 10px 57px;
      display: inline-block;
    }

    /deep/ .van-cell {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 22px;
      font-size: 16px;
      color: black;

      .van-field__label {
        display: none;
      }
    }
  }
}
</style>
