<template>
  <div class="p-30 protocol">
    <nav-bar title="典当规则" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
      @click-left="onClickLeft" />
    <div style="text-align: center;  font-size: 17px; font-weight: 700;">
      轻松小镇元气当铺介绍
    </div>
    <br/>
    <br/>
    <div>
      元气当铺——小镇居民的典当集市
    </div>
    <br/>元气当铺简介
    <br/>古有铜板，镇有元气，小镇珍宝，士农工商皆取之为其道也。茶坊酒肆，得之则富昌，失之则贫弱。市井居民，得之则锦绣华服，失之则箪食瓢饮。十里长街，金翠满目，宝物竞驻，元气当铺——轻松小镇首个虚实兑换场景，乃商贾蜂拥之地。市列珠玑，户盈罗绮，集四海之珍奇。赶集之时，家家户户皆可倾囊元气当百货，珠环翠绕典豪奢，拥有元气的居民将成为小镇钟鸣鼎食之家。在当铺的街头巷尾，各类好物将乘着元气自由穿梭，奔赴天南海北，使居民富甲一方、小镇繁盛昌明。
    <br/>元气当铺典当规则
    <br/><br/>1、典当使用方法
    <br/>持有元气的居民需要先登录轻松小镇账号，然后在元气当铺中选择需要兑换的商品，确认后即可使用元气兑换。在购物车结算页面选择“使用元气支付”，确认消耗的元气数量，输入支付密码，即可减免相应付款金额。需要注意的是，不同商品所需的元气数量可能不同，同时有的商品可能还需要居民支付一定的现金差价做抵扣。
    <br/><br/>2、典当结算方式
    <br/>在元气当铺中，居民可以使用元气来抵扣部分或全部商品价格。具体抵扣规则根据不同商品而定，有的商品可以全额用元气抵扣，有的则只能使用一定数量的元气进行抵扣，有的则需要现金抵扣元气数额不足部分。同时，不同商品可以享受元气抵扣比例动态波动。目前，元气当铺设置两种典当结算方式，采取何种结算方式并不固定，具体以物品及所属活动规则为准。
    <br/><br/>（1）元气典当
    <br/>当铺物品都标有兑换时所需消耗的元气数值，通过消耗一定元气数量可兑换当铺物品。
    <br/><br/>（2）元气+现金组合典当
    <br/>当元气不足以支付时，可选择元气+现金组合典当。请注意，元气当铺初衷是为所有参与元气采集玩法的小镇居民提供福利，所有账号使用组合支付时，元气余额须大于该典当物应支付的元气数量的80%。如账户内元气数量少于该数量，则不可在填写订单页面使用元气支付。
    <br/>此外，元气当铺中所有物品组合支付时，元气消耗及现金分配比例非固定值，非统一值，具体以页面实际展示的可消耗元气数量以及典当比例为准。


    <br/><br/>3、典当注意事项
    <br/>（1）除非另有证明，储存在元气当铺服务器上的数据是用户使用元气当铺服务的唯一有效证据；
    <br/>（2）支付时暂不支持用户自定义使用的元气数量；
    <br/>（3）用户所采集的元气不得提现，不得为他人付款；
    <br/>（4）元气商城所有商品均为官方正品，典当交易一经确认，概不支持退换退货，请仔细确认后支付；
    <br/>（5）交易成功后，我们将尽快为您发货。除定制外，商品发货时间最迟不超过45个工作日，若需查询物流信息，请联系官方客服人员，感谢您支持与理解；
    <br/>（6）为保证用户的资产安全，元气支付功能仅限实名认证用户使用，未完成实名认证的用户请在实名认证页面完成实名后使用该功能；
    <br/>（7）用户在典当过程中，如果出现违规行为(如作弊领取、恶意套现、刷信誉等行为)，轻松小镇有权取消用户典当物，撤销违规交易，收回元气权益，如给轻松小镇造成损失，平台保留向违规用户追索赔偿的权利；
    <br/>（8）元气仅可在轻松小镇平台使用，如用户帐号暂停使用，则轻松小镇将取消该用户帐号内元气相关使用权益；
    <br/>（9）元气当铺不支持退换货。任何以非出于个人消费使用的目的恶意拍下商品，恶意占用库存等行为，并在拍下后恶意申请退款、恶意申请售后等行为，元气当铺一概不予退款；
    <br/>（10）典当存在重大误解或显失公平的订单，如：商品上架错误、商品分类错误、产品参数错误、标价错误、库存错误、结算数据错误以及其它无法控制或合理预见的情形，元气当铺将发布声明，告知居民由于典当错误，合同构成重大误解。“错误订单”商品将不予发货，已经付款的请申请全额退款，并给与适当补偿。
    <br/>（11）建议居民使用轻松小镇官方渠道下载APP或登录元气当铺，避免使用来路不明的软件或链接。同时，居民需要保护好自己的账号密码和手机验证码，不要随意泄露给他人。在使用元气兑换商品时，居民需要认真阅读《元气当铺用户服务协议》，并仔细核对商品信息和元气数量，避免失误操作损失元气。
    <br/><br/>
    <br/><br/>元气当铺安全提示
    <br/>为营造元气当铺公平、公开、公正的典当环境，保护所有用户的合法合规权益，我们将对存在以下行为的用户采取包括但不限于暂停发货、取消订单、拦截已发货的订单、限制账户部分或全部权限、封禁账号等措施：
    <br/>（1）冒用他人的联系电话、收货地址进行提交订单的；
    <br/>（2）收货人称商品非本人订购、未委托他人订购且未接受他人为自己订购；
    <br/>（3）经举报或元气当铺有合理理由认为账号从事二次销售行为的；
    <br/>（4）将自有账户内的权益、元气信息转卖、转让予他人；
    <br/>（5）在元气当铺恶意批量刷取权益等行为并在元气当铺使用；
    <br/>（6）利用技术手段或其他方式在套取元气、商品、优惠券或者其他利益；
    <br/> （7）自行或委托他人通过机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序或技术手段参与元气典当活动；
    <br/>（8）任何轻松小镇用户（包括未成年人用户）向轻松小镇提供错误、不完整、不实信息等造成不能通过实名认证或遭受任何其他损失；
    <br/>（9）由于用户将元气当铺账户密码告知他人或未保管好自己的密码或与他人共享轻松小镇账户或任何其他非轻松小镇的过错，导致用户的个人资料泄露；
    <br/>（10）同一用户重复参与只限典当一次的商品，“同一用户”指使用同一账号、同一手机号、同一设备、同一身份证、同一支付账号、或同一收货地址等的用户，其中任意一项或数项存在相同、相似、非真实有效、通过特定标记形成关联，或元气当铺有合理理由认为存在关联的，都视为同一用户；
    <br/>（11）任何由于黑客攻击、计算机病毒侵入或发作、电信部门技术调整导致之影响、因政府管制而造成的暂时性关闭、由于第三方原因(包括不可抗力）及其他非因轻松小镇过错而造成的实名认证信息、订单交易信息泄露、丢失、被盗用或被篡改等；
    <br/>（12）扰乱正常交易秩序的行为，包括但不限于：I使用不同的买家账号大量购买商品；II超出正常交易习惯，频繁拒绝支付尾款、拒收商品、拒绝接受服务或提出退换货等售后要求的；III组织或参与虚假交易的；IV购买大量商品且用不合理理由要挟、恐吓、敲诈元气当铺及轻松小镇，影响正常交易的行为。
    <br/>（13）元气当铺认定的其他不正当行为、舞弊行为和恶意行为。

    <br/><br/>轻松小镇有权根据国家法律法规、监管政策的变化或风险防范的需要对元气当铺的相关服务作出调整或更新，我们会以公告等通知方式就与元气当铺相关服务的更新向用户进行提示。除通知中另有说明外，更新的内容将于更新公告公布的生效之日起生效。若用户不接受变更后的服务内容或功能，或不同意更新后的相关协议内容，用户需立即停止使用相关服务。若用户继续使用轻松小镇提供的相关元气当铺服务的，即视为用户已同意我们作出的变更，并自愿接受变更后的协议内容约束。

  </div>
</template>

<script>
import { NavBar } from "vant";

export default {
  name: 'Protocol',
  components: { NavBar },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped lang="scss">
/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.protocol {
  position: relative;
  padding-top: 50px;
}
</style>
