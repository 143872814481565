<template>
  <div class="page-box">
    <div v-if="!payType">
      <nav-bar title="确认支付" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
        @click-left="onClickLeft" />
      <div class="order">
        <div class="orderNumber">￥<span>{{ money }}</span></div>
        <div class="orderFont">确认支付</div>
        <div class="orderTime">
          支付剩余时间
          <span>{{ timeText }}</span>
        </div>
      </div>
      <div class="selectPay">
        <!-- <div class="selectType">
          <div class="selectLeft">
            <div class="img">
              <van-icon name="wechat-pay" size="14" color="#07c160"></van-icon>
            </div>
            <div class="text">微信支付</div>
          </div>
          <div class="selectRight">
            <checkbox v-model="checked"></checkbox>
          </div>
        </div> -->
        <div class="selectType">
          <div class="selectLeft">
            <div class="img">
              <van-icon name="alipay" size="16" color="#0090ff"/>
            </div>
            <div class="text">支付宝支付</div>
          </div>
          <div class="selectRight">
            <checkbox v-model="checked"></checkbox>
          </div>
        </div>
      </div>
      <!-- <div class="btm" @click="onPay()">
        <div class="btn">立即支付</div>
      </div>
      {{baseUrl + 'index/shop_order/pay_zhifubao'}} -->

      <template>
        <form :action="baseUrl + 'index/shop_order/pay_zhifubao'" autocomplete="off" method="post">
          <input name="acctoken" v-model="acctoken" type="text" style="height: 1px; overflow: hidden; opacity: 0" />
          <input name="url" v-model="url" type="text" style="height: 1px; overflow: hidden; opacity: 0" />
          <input name="order" v-model="order" type="text" style="height: 1px; overflow: hidden; opacity: 0" />
          <div class="submitBtn">
            <input type="submit" id="submit4" name="submit_button" value="立即支付" class="white f-21 btn" />
          </div>
        </form>
      </template>
    </div>
    <div v-else>
      <div class="payTopSuc">
        <div class="img">
          <img :src="require('../../assets/img/yqMall/paySuccess.png')" alt="" />
        </div>
        <div class="text">支付成功</div>
      </div>
      <div class="order">
        <div class="orderNumber">￥<span>{{ money }}</span></div>
      </div>
      <div class="btm">
        <div class="btn" style="width: 35%" @click="goToBack()">完成</div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Checkbox } from "vant";
import { pay_zhifubao } from "../../api/yqMallApi";
const baseUrl = require("../../../site");

export default {
  name: "detail",
  components: { NavBar, Checkbox },
  data() {
    return {
      baseUrl: 'http://8.142.162.201/',
      checked: true,
      payType: false, //支付状态
      money: '',
      order: '', //支付订单
      acctoken: localStorage.getItem("token"),
      url: //支付回调路径
        window.location.origin +
        window.location.pathname +
        window.location.search +
        "#/yqMallOrder",
        createTime:'',//下单时间
        timer: '',//支付倒计时
        timeText: ''//支付时间展示
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  computed: {},
  created() { },
  mounted() {
    this.order = this.$route.query.order
    this.money = this.$route.query.money;
    this.createTime = this.$route.query.time;
    this.onTime();
    
  },
  methods: {
    onTime(){
      this.timer = setInterval(() => {
        this.timeText = this.countDown(this.createTime);
      }, 1000);
    },
    async onPay() {
      this.payZfbShow = true
      // this.payType = true
      // try {
      //   const res = await pay_zhifubao({
      //     url: this.url,
      //     order: this.order,
      //     acctoken: acctoken
      //   });
      // } catch (e) {
      //   console.log(e);
      // }
    },
    goTo(name) {
      this.$router.push({ name });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    goToBack() {
      this.$router.go(-2)
    },
    //倒计时
    countDown(endTime) {
      var nowtime = new Date(); //获取当前时间
      var lefttime = (Number(endTime) + 1800) * 1000 - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
        console.log(lefttime)
      if (leftm <= 0 && lefth <= 0 && lefts <= 0) {
        clearInterval(this.timer);
        return `00:00:00`;
      }
      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      return `${timeAdd0(lefth)}:${timeAdd0(leftm)}:${timeAdd0(lefts)}`;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.van-nav-bar .van-icon {
  color: #000000;
}

/deep/.van-checkbox__icon--checked .van-icon {
  background-color: #000000 !important;
  border-color: #000000 !important;
}
.submitBtn{

  position: absolute;
  bottom: 26px;
  width: 100%;

}

.btn {
  background: #000;
  border-radius: 23px;
  text-align: center;
  padding: 0px 5px;
  height: 40px;
  font-size: 15px;
  display: block;
  width: 80%;
  margin: auto;
}

.payTopSuc {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 27px 0;

  .img {
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    font-size: 15px;
    margin-left: 5px;
  }
}

.page-box {
  min-height: 100vh;
  background: #fff;
}

.order {
  margin: 60px 0;

  .orderNumber {
    font-size: 19px;
    margin-bottom: 16px;
    text-align: center;

    span {
      font-size: 36px;
      margin-left: 2px;
      font-weight: 700;
    }
  }

  .orderFont {
    margin-bottom: 5px;
    font-size: 13px;
    color: #808080;
    text-align: center;
  }

  .orderTime {
    margin-bottom: 10px;
    font-size: 13px;
    color: #808080;
    text-align: center;

    span {
      color: #000000;
      margin-left: 5px;
    }
  }
}

.selectPay {
  padding: 0 10px;

  .selectType {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selectLeft {
      display: flex;
      align-items: center;

      .img {
        margin-top: 3px;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-size: 13px;
      }
    }

    .selectRight {}
  }
}

.btm {
  width: 100%;
  position: fixed;
  bottom: 30px;
  margin: auto;

  .btn {
    width: 90%;
    margin: 0 auto 10px;
    background: #000;
    border-radius: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 10px 0;
  }
}
</style>
