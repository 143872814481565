<template>
  <div class="synthesis">
    <!-- <div class="back text-center">
      <van-icon
        name="arrow-left"
        size="25"
        color="white"
        class="arrow"
        @click="back"
      />
    </div> -->
    <div class="header">
      <div class="title">本草合成</div>
      <div class="title2">集齐五种属性本草纲目合成特殊版本草</div>
    </div>
    <div class="synthesis_cet">
      <div class="synthesis_box" :style="'background-image: url(' + Qimg + 'synthesis.gif)' ">
        <div class="choicebox" ref="ids" :class="rotate ? 'rotate1' : ''">
          <!-- 火 -->
          <div class="fire icon">
            <img
              :class="fire ? 'sxactive' : ''"
              src="../../assets/img/BcSynthesis/fire.png"
              alt=""
            />
          </div>
          <div class="fire1 icon" @click="toPage('HCmyCP', 'fire')">
            <img
              :class="fire ? 'sxactive2' : ''"
              src="../../assets/img/BcSynthesis/fire1.png"
              alt=""
            />
          </div>
          <!-- 金 -->
          <div class="gold icon">
            <img
              :class="gold ? 'sxactive' : ''"
              src="../../assets/img/BcSynthesis/gold.png"
              alt=""
            />
          </div>
          <div class="gold1 icon" @click="toPage('HCmyCP', 'gold')">
            <img
              :class="gold ? 'sxactive2' : ''"
              src="../../assets/img/BcSynthesis/gold1.png"
              alt=""
            />
          </div>
          <!-- 木 -->
          <div class="wood icon">
            <img
              :class="wood ? 'sxactive' : ''"
              src="../../assets/img/BcSynthesis/wood.png"
              alt=""
            />
          </div>
          <div class="wood1 icon" @click="toPage('HCmyCP', 'wood')">
            <img
              :class="wood ? 'sxactive2' : ''"
              src="../../assets/img/BcSynthesis/wood1.png"
              alt=""
            />
          </div>
          <!-- 水 -->
          <div class="water icon">
            <img
              :class="water ? 'sxactive' : ''"
              src="../../assets/img/BcSynthesis/water.png"
              alt=""
            />
          </div>
          <div class="water1 icon" @click="toPage('HCmyCP', 'water')">
            <img
              :class="water ? 'sxactive2' : ''"
              src="../../assets/img/BcSynthesis/water1.png"
              alt=""
            />
          </div>
          <!-- 土 -->
          <div class="Soil icon">
            <img
              :class="Soil ? 'sxactive' : ''"
              src="../../assets/img/BcSynthesis/Soil.png"
              alt=""
            />
          </div>
          <div class="Soil1 icon" @click="toPage('HCmyCP', 'Soil')">
            <img
              :class="Soil ? 'sxactive2' : ''"
              src="../../assets/img/BcSynthesis/Soil1.png"
              alt=""
            />
          </div>
        </div>
        <div class="bombox">
          <!-- <div
            v-if="boom1"
            :class="boom4 ? 'small' : ''"
            class="boom1 boom"
          ></div> -->
          <div :class="boom4 ? 'small1' : ''" class="boom1 boom"></div>
          <div :class="boom5 ? 'small1' : ''" class="boom1 boom"></div>
          <div :class="boom6 ? 'small1' : ''" class="boom1 boom"></div>
          <div :class="boom7 ? 'small1' : ''" class="boom1 boom"></div>
        </div>
        <div class="overBombox">
          <div :class="overBom4 ? 'big' : ''" class="overBom"></div>
          <div :class="overBom5 ? 'big' : ''" class="overBom"></div>
        </div>
        <div
          v-if="(!fire || !gold || !wood || !water || !Soil) && btnFG"
          class="hc_btn"
          @click="tips()"
        ></div>
        <div
          v-if="fire && gold && wood && water && Soil && btnFG"
          class="hc_btn"
          @click="synsis()"
        >
          <img src="../../assets/img/BcSynthesis/hc_btn.png" alt="" />
        </div>
        <div
          v-if="fire && gold && wood && water && Soil && !btnFG"
          class="hc_btn"
        >
          <img src="../../assets/img/BcSynthesis/hc_btn.png" alt="" />
        </div>
      </div>

      <div class="tips" v-show="startbtn">点击五行属性选择对应藏品</div>
      <div class="consume" v-show="startbtn">单次合成消耗：99元石</div>
      <div class="consume text-center" v-show="startbtn">
        特殊藏品可在轻松小镇APP内「我的藏品」查看
      </div>
      <div class="look_cp" v-show="startbtn" @click="toPage('HCmyCP')">
        查看我的藏品
      </div>
    </div>
    <audio ref="audioTip" style="display: none">
      <source src="../../assets/img/BcSynthesis/boom.mp3" />
    </audio>
    <van-overlay :show="sucssSysis">
      <div class="wrapper">
        <div class="sucssSysis" v-if="AftersynthesisData != ''">
          <div class="hed text-center m-b-20">合成成功，恭喜获得</div>
          <div class="hed2 text-center" v-if="AftersynthesisData.nft_name">
            {{ AftersynthesisData.nft_name.split("版")[0] }}版
          </div>
          <div
            class="hed2 text-center m-b-30"
            v-if="AftersynthesisData.nft_name"
          >
            {{ AftersynthesisData.nft_name.split("版")[1] }}#{{
              AftersynthesisData.token_id
            }}
          </div>
          <div class="text-center">
            <img class="img" :src="AftersynthesisData.nft_img_url" alt="" />
          </div>
          <div class="cdBtn" @click="onClearInterval(1)">收下藏品</div>
          <div class="hed3 text-center">
            特殊藏品可在轻松小镇APP内「我的藏品」查看
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="confirmPoP">
      <div class="wrapper">
        <div class="confirmPoP">
          <div class="confirmPoP_title">是否确认消耗以下藏品<br />进行合成</div>
          <div class="collection_list">
            <div class="collection" v-for="item in quality" :key="item.id">
              <img :src="item.image" alt="" />
              <div class="collection_right">
                <div class="title">
                  <span>{{ item.title }}</span>
                  <!-- <span>{{ item.shard_id }}</span> -->
                </div>
                <div class="coll_Id font-over200">
                  <span style="margin-right: 5px">藏品编号:</span
                  >{{ item.number }}
                </div>
              </div>
            </div>
          </div>
          <div class="confirmPoP_btn" @click="confirmFn()">
            <div class="confirmPoP_text">确认合成</div>
            <div class="confirmPoP_text2">99元石/次</div>
          </div>
          <div class="warning">
            基础版藏品合成后将被销毁<br />合成后将不可撤销，请谨慎选择
          </div>
          <van-icon
            name="close"
            class="close"
            size="30"
            @click="confirmPoP = false"
          />
        </div>
      </div>
    </van-overlay>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="hasLogin"
    ></login>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { synthesisNft } from "../../api/benCao.js";
import Login from "../../components/Login";

export default {
  name: "BcSynthesis",
  components: {
    Login,
  },

  data() {
    return {
      btnFG: true,
      AftersynthesisData: {}, //合成后数据
      startbtn: true, //开始动画后隐藏底部按钮
      isShowLogin: false,
      fire: false,
      gold: false,
      wood: false,
      water: false,
      Soil: false,
      confirmPoP: false,
      rotate: false, //判断旋转
      boom1: false, //爆炸1
      boom2: false, //爆炸2
      boom3: false, //爆炸3
      boom4: false, //爆炸1
      boom5: false, //爆炸2
      boom6: false, //爆炸3
      boom7: false,
      overBom4: false,
      overBom5: false,
      boomTime: 5,
      s: "",
      r: "",
      is: 5,
      deg: 0,
      sucssSysis: false, //收到藏品弹窗
      cartoonTime: "", //定时器
      collectionList: [
        {
          img: require("../../assets/img/BcSynthesis/b-c-banner.png"),
          name1: "本草纲目（明·金陵版）",
          name2: "金·龙 #9999",
          ID: "121985050405000910",
        },
        {
          img: require("../../assets/img/BcSynthesis/b-c-banner.png"),
          name1: "本草纲目（明·金陵版）",
          name2: "金·龙 #9999",
          ID: "121985050405000911",
        },
        {
          img: require("../../assets/img/BcSynthesis/b-c-banner.png"),
          name1: "本草纲目（明·金陵版）",
          name2: "金·龙 #9999",
          ID: "121985050405000912",
        },
        {
          img: require("../../assets/img/BcSynthesis/b-c-banner.png"),
          name1: "本草纲目（明·金陵版）",
          name2: "金·龙 #9999",
          ID: "121985050405000930",
        },
        {
          img: require("../../assets/img/BcSynthesis/b-c-banner.png"),
          name1: "本草纲目（明·金陵版）",
          name2: "金·龙 #9999",
          ID: "121985050405000990",
        },
      ],
    };
  },
  created() {
    if (!localStorage.getItem("token")) {
      // this.isShowLogin = true;
      this.$toast("请先登录账号");
    } else {
      this.getIndexData();
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      quality: (state) => state.quality,
    }),
  },

  mounted() {
    if (this.$refs.audioTip) {
    }
    // this.$refs.audioTip.load();
  },
  methods: {
    ...mapMutations(["setfire", "setgold", "setwood", "setwater", "setSoil"]),
    toPage(name, attribute) {
      this.$router.push({ name, params: { attribute: attribute } });
    },
    tips() {
      this.$toast("请选择对应属性,进行藏品合成");
    },
    synsis() {
      this.confirmPoP = true;
    },
    async confirmFn() {
      this.confirmPoP = false;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 1000,
      });
      let time = 10;
      let nft = [
        this.quality.fire.id,
        this.quality.gold.id,
        this.quality.wood.id,
        this.quality.water.id,
        this.quality.Soil.id,
      ];
      try {
        const res = await synthesisNft({
          nft: nft,
        });
        if (res.code == 200) {
          this.AftersynthesisData = res.data.data;
          //  控制合成效果
          this.cartoonTime = setInterval(() => {
            if (time > 0) {
              time -= 0.5;
              if (time == 9.5) {
                this.rotateFn();
                this.btnFG = false;
                this.startbtn = false;
              } else if (time == 6) {
                this.boom1 = true;
                this.boom4 = true;
                this.$refs.audioTip.play();
              } else if (time == 5.5) {
                this.boom2 = true;
                this.boom3 = true;
                this.boom5 = true;
              } else if (time == 5) {
                this.boom6 = true;
              } else if (time == 4.5) {
                this.boom7 = true;
                this.overBom1 = true;
              } else if (time == 4) {
                this.boom4 = false;
                this.boom5 = false;
                this.boom6 = false;
                this.boom7 = false;
                this.overBom4 = true;
                this.sucssSysis = true;
              } else if (time == 3.5) {
                this.overBom5 = true;
              } else if (time == 2.5) {
                this.overBom4 = false;
                this.overBom5 = false;
                this.startbtn = true;
                this.btnFG = true;
                // this.rotate = false;
              }
              // if (time == 1.5) {
              //   clearInterval(cartoonTime);
              //   this.getIndexData();
              // }
            }
          }, 500);
          console.log(this.cartoonTime);
          // 成功之后清空vuex数据
          this.setfire({});
          this.setgold({});
          this.setwood({});
          this.setwater({});
          this.setSoil({});
        }
      } catch (e) {
        console.log(e);
        this.$toast(e.data);
      }
    },
    hasLogin() {
      this.isShowLogin = false;
    },
    // 获取vue内数据
    getIndexData() {
      if (this.quality.fire.id) {
        this.fire = true;
      } else {
        this.fire = false;
      }
      if (this.quality.gold.id) {
        this.gold = true;
      } else {
        this.gold = false;
      }
      if (this.quality.wood.id) {
        this.wood = true;
      } else {
        this.wood = false;
      }
      if (this.quality.water.id) {
        this.water = true;
      } else {
        this.water = false;
      }
      if (this.quality.Soil.id) {
        this.Soil = true;
      } else {
        this.Soil = false;
      }
    },
    // 页面返回
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },

    // 控制旋转方法
    rotateFn() {
      this.s = setInterval(() => {
        this.is -= 1;
        this.fun1();
      }, 1000);
    },
    // 点击收下藏品后清除定时器
    onClearInterval(type) {
      for (var i = 1; i <= 8000; i++) {
        clearInterval(i);
        var obj = this.$refs.ids;
        obj.style.transform = "rotate(" + 0 + "deg)";
      }
      if (type == 1) {
        this.sucssSysis = false;
        this.getIndexData();
      }
    },
    // 也是控制转圈函数
    fun1() {
      if (this.is == 1) {
        clearInterval(this.s);
        setTimeout(() => {
          this.onClearInterval();
        }, 4000);

        return false;
      }
      this.r = setInterval(() => {
        this.fun();
      }, this.is);
    },
    // 还是控制转圈函数
    fun() {
      var obj = this.$refs.ids;
      this.deg += 1.5;
      // this.deg += 2;
      var d = this.deg + "deg";
      obj.style.transform = "rotate(" + d + ")";
      obj.style.transition = "all 0.3s";
    },
  },
};
</script>
<style lang="scss" scoped>
.synthesis {
  min-height: 100vh;
  background: url("../../assets/img/BcSynthesis/synthesis-bg.png") no-repeat;
  background-size: 100% 100%;
  .back {
    position: relative;
    width: 100%;
    top: 0;
    height: 48px;
    line-height: 44px;
    background-color: black;
    .arrow {
      position: absolute;
      top: 22px;
      transform: translateY(-50%);
      left: 25px;
    }
  }
  .header {
    background-color: black;
    padding: 25px 0;
    .title {
      color: #fff;
      font-size: 25px;
      text-align: center;
      margin-bottom: 15px;
    }
    .title2 {
      font-size: 15px;
      font-family: SourceHanSansSC-Normal;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }
  }
  .synthesis_cet {
    padding-bottom: 60px;
    .synthesis_box {
      height: 380px;
      width: 100%;
      background-size: 100% 100%;
      position: relative;
      .rotate1 {
        animation: rotate 3s linear infinite;
        // animation: rotate 0.5s linear infinite;
      }
      @keyframes rotate {
        0% {
          -webkit-transform: rotate(0deg);
        }
        25% {
          -webkit-transform: rotate(90deg);
        }
        50% {
          -webkit-transform: rotate(180deg);
        }
        75% {
          -webkit-transform: rotate(270deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      .choicebox {
        height: 380px;
        width: 100%;
        background: url("../../assets/img/BcSynthesis/choicebox.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        top: 0;
        left: 0;
        .icon {
          position: absolute;
          img {
            height: 45px;
            width: 45px;
          }
        }
        .sxactive {
          opacity: 1 !important;
        }
        .sxactive2 {
          opacity: 0 !important;
        }

        .fire1 {
          top: 90px;
          left: 112px;
          img {
            width: 35px;
            height: 35px;
            transform: 2s;
          }
        }
        .gold1 {
          top: 90px;
          right: 112px;
          img {
            width: 35px;
            height: 35px;
            transform: 2s;
          }
        }
        .wood1 {
          top: 205px;
          left: 78px;
          img {
            width: 30px;
            height: 30px;
            transform: 2s;
          }
        }
        .water1 {
          top: 197px;
          right: 71px;
          img {
            width: 45px;
            height: 45px;
            transform: 2s;
          }
        }
        .Soil1 {
          top: 272px;
          right: 172px;
          img {
            width: 30px;
            height: 30px;
            transform: 2s;
          }
        }
        .fire {
          top: 85px;
          left: 107px;
          img {
            opacity: 0;
            transition: 1.5s;
          }
        }
        .gold {
          top: 85px;
          right: 107px;
          img {
            opacity: 0;
            transition: 1.5s;
          }
        }
        .wood {
          top: 198px;
          left: 71px;
          img {
            opacity: 0;
            transition: 1.5s;
          }
        }
        .water {
          top: 198px;
          right: 71px;
          img {
            opacity: 0;
            transition: 1.5s;
          }
        }
        .Soil {
          top: 265px;
          right: 165px;
          img {
            opacity: 0;
            transition: 1.5s;
          }
        }
      }

      .bombox {
        .boom {
          height: 100vh;
          width: 100vw;
          position: fixed;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          left: 0;
          top: 0;
          z-index: 998;
          background-image: url("../../assets/img/BcSynthesis/boom1.png");
        }

        .small {
          transform: scale(1) !important;
          transition: 1s !important;
        }
        .small1 {
          transform: scale(1) !important;
          transition: 1s !important;
          z-index: 999 !important;
          opacity: 0.5 !important;
        }
        .boom1 {
          opacity: 0;
          z-index: -2;
          transform: scale(5.5);
        }
        .boom2 {
          transform: scale(3.5);
        }
        .boom3 {
          transform: scale(3.5);
        }
      }
      .overBombox {
        .big {
          transform: scale(2) !important;
          z-index: 999 !important;
          opacity: 0 !important;
          transition: 1.5s;
          // transform: scale(2) !important;
          // transition: 1s;
        }
        .overBom {
          height: 100vh;
          width: 100vw;
          position: fixed;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          left: 0;
          top: 0;
          z-index: -2;
          background-image: url("../../assets/img/BcSynthesis/boom2.png");
        }
      }
      .hc_btn {
        position: absolute;
        top: 190px;
        left: 50%;
        width: 60px;
        height: 70px;
        transform: translate(-50%, -50%);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .tips {
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-family: SourceHanSansSC-Light;
      font-weight: 300;
      color: #ffffff;
      margin-top: 20px;
    }
    .consume {
      color: #fff;
      text-align: center;
      font-size: 13px;
      font-family: SourceHanSansSC-Light;
      font-weight: 300;
      color: #ffffff;
      margin-top: 12px;
    }
    .look_cp {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 25px auto 0;
      width: 243px;
      height: 43px;
      background: linear-gradient(90deg, #e7c99c, #feefd2);
      border-radius: 22px;
      font-size: 17px;
      font-family: SourceHanSansSC-Regular;
      font-weight: 400;
      color: #0d0d0d;
    }
  }
  .van-overlay {
    background-color: rgba($color: #000000, $alpha: 0.99);
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .confirmPoP {
      width: 333px;
      background: #101010;
      border-radius: 10px;
      padding: 30px 25px 25px;
      position: relative;
      z-index: 997;
      .confirmPoP_title {
        text-align: center;
        color: #fff;
        font-size: 17px;
        font-family: SourceHanSansCN-Medium;
        font-weight: 500;
        color: #ffffff;
        line-height: 1.5em;
        margin-bottom: 30px;
      }
      .collection_list {
        .collection {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          img {
            height: 60px;
            width: 60px;
            margin-right: 15px;
          }
          .collection_right {
            .title {
              font-size: 12px;
              color: #fff;
            }
            .coll_Id {
              font-size: 9px;
              color: #808080;
              margin-top: 8px;
            }
          }
        }
      }
      .confirmPoP_btn {
        width: 247px;
        height: 40px;
        background: linear-gradient(90deg, #e7c99c, #feefd2);
        border-radius: 20px;
        margin: 20px auto 0;
        position: relative;
        font-family: SourceHanSansCN-Regular;

        .confirmPoP_text {
          font-size: 17px;
          font-weight: 400;
          color: #000000;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .confirmPoP_text2 {
          font-size: 10px;
          font-weight: 400;
          color: #9d8054;
          position: absolute;
          top: 57%;
          left: 75%;
          transform: translate(-50%, -50%);
        }
      }
      .warning {
        font-size: 12px;
        font-family: SourceHanSansCN-Light;
        font-weight: 300;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
        line-height: 1.5em;
      }
      .close {
        position: absolute;
        color: #fff;
        left: 50%;
        bottom: -50px;
        transform: translate(-50%, -50%);
      }
    }
    .sucssSysis {
      .hed {
        font-size: 23px;
        font-weight: 400;
        color: #ffffff;
      }
      .hed2 {
        font-size: 27px;
        font-weight: bold;
        color: #ffffff;
      }
      .img {
        width: 300px;
        height: 300px;
        box-shadow: 0px 0px 9px 0px #f1bc7e;
      }
      .cdBtn {
        width: 215px;
        height: 40px;
        background: linear-gradient(90deg, #e7c99c, #feefd2);
        border-radius: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #000000;
        text-align: center;
        line-height: 40px;
        margin: 20px auto 25px;
      }
      .hed3 {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
      }
    }
  }
}
</style>
