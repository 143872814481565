import { render, staticRenderFns } from "./PasswordCxbc2.vue?vue&type=template&id=393f22ca&scoped=true&"
import script from "./PasswordCxbc2.vue?vue&type=script&lang=js&"
export * from "./PasswordCxbc2.vue?vue&type=script&lang=js&"
import style0 from "./PasswordCxbc2.vue?vue&type=style&index=0&id=393f22ca&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "393f22ca",
  null
  
)

export default component.exports