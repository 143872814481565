
<template>
  <div class="page-box">
    <nav-bar title="我的订单" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
      @click-left="onClickLeft" @click-right="onGoto('yqMallKf')">
      <template #right>
        <img style="width: 18px; height: 18px" :src="require('../../assets/img/yqMall/kef.png')" alt="" />
      </template>
    </nav-bar>
    <tabs v-model="active" :sticky="true" :offset-top="38.6" @change="onTabs" animated>
      <tab title="全部">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh(null)">
          <div class="pullListItem" v-if="orderList.length">
            <div class="orderList" v-for="(item, index) in orderList" :key="index">
              <div class="orderTop">
                <div class="orderLeftPayTime" @click="copyOrder(item.order)">
                  <span>
                    订单号：{{ item.order }}
                  </span>
                  <img style="width: 16px; height: 16px" :src="require('../../assets/img/yqMall/copy.jpg')" alt="" />
                </div>
                <div class="orderRightType textError" v-if="item.type == '0' || item.type == '1'">
                  待支付：{{ countDown(item.create_time) }}分钟
                </div>
                <div class="orderRightType" v-if="item.type == '2'">
                  待发货
                </div>
                <div class="orderRightType" v-if="item.type == '3'">
                  已发货
                </div>
                <div class="orderRightType" v-if="item.type == '4'">
                  待收货
                </div>
                <div class="orderRightType textSuccess" v-if="item.type == '5'">
                  已完成
                </div>
                <div class="orderRightType" v-if="item.type == '-1'">
                  已取消
                </div>
              </div>
              <div class="commodity" v-if="!item.is_car">
                <div class="commodityImg">
                  <img :src="item.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ item.name }}</div>
                  <div class="commoditySelect">{{ item.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                      <span v-if="item.price_rmb != '0.00' && item.price_score != '0'
                        ">
                        +
                      </span>
                      <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
              <div class="commodity" v-for="(s, n) in item.car_info" :key="n" v-else>
                <div class="commodityImg">
                  <img :src="s.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ s.name }}</div>
                  <div class="commoditySelect">{{ s.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="s.price_rmb != '0.00'">￥{{ s.price_rmb }}</span>
                      <span v-if="s.price_rmb != '0.00' && s.price_score != '0'">
                        +
                      </span>
                      <span class="yq" v-if="s.price_score != '0'">{{ s.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ s.num }}</div>
                  </div>
                </div>
              </div>
              <div v-if="item.type == '0' || item.type == '1'">
                <div class="orderMoney">
                  共一件商品 需付：
                  <div class="money" v-if="!item.type">
                    <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                    <span v-if="item.price_rmb != '0.00' && item.price_score != '0'">
                      +
                    </span>
                    <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                      <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                    </span>
                  </div>
                  <div v-else-if="item.type == 1">
                    <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                  </div>
                </div>
                <div class="btm">
                  <div>
                    <div style="text-align: left; font-size: 12px;">
                      下单时间:{{ item.create_time | dateFormat() }}
                    </div>
                  </div>
                  <div class="disFlex_center">
                    <div class="btnOne" @click="onCancellationOrder(item, null)">取消订单</div>
                    <div class="btnTwo" @click="onType(item, '')">立即付款</div>
                  </div>
                </div>
              </div>
              <div v-else-if="item.type == '4'">
                <div class="btm">
                  <div>
                    <div style="text-align: left; font-size: 12px;">
                      下单时间:{{ item.create_time | dateFormat() }}
                    </div>
                  </div>
                  <div class="btnTwo" @click="onReceipt(item, null)">确认收货</div>
                </div>
              </div>
              <div v-else>
                <div class="btm">
                  <div>
                    <div style="text-align: left; font-size: 12px;">
                      下单时间:{{ item.create_time | dateFormat() }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noList" v-else>暂无订单数据，快去购买吧~</div>
        </van-pull-refresh>
      </tab>
      <tab title="待付款">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh('0')">
          <div class="pullListItem" v-if="orderList.length">
            <div class="orderList" v-for="(item, index) in orderList" :key="index">
              <div class="orderTop">
                <div class="orderLeftPayTime" @click="copyOrder(item.order)">

                  <span>
                    订单号：{{ item.order }}
                  </span>
                  <img style="width: 16px; height: 16px" :src="require('../../assets/img/yqMall/copy.jpg')" alt="" />
                </div>
                <div class="orderRightType textError">
                  待支付：{{ countDown(item.create_time) }}分钟
                </div>
              </div>
              <div class="commodity" v-if="!item.is_car">
                <div class="commodityImg">
                  <img :src="item.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ item.name }}</div>
                  <div class="commoditySelect">{{ item.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                      <span v-if="item.price_rmb != '0.00' && item.price_score != '0'
                        ">
                        +
                      </span>
                      <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
              <div class="commodity" v-for="(s, n) in item.car_info" :key="n" v-else>
                <div class="commodityImg">
                  <img :src="s.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ s.name }}</div>
                  <div class="commoditySelect">{{ s.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="s.price_rmb != '0.00'">￥{{ s.price_rmb }}</span>
                      <span v-if="s.price_rmb != '0.00' && s.price_score != '0'">
                        +
                      </span>
                      <span class="yq" v-if="s.price_score != '0'">{{ s.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ s.num }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="orderMoney">
                  共一件商品 需付：
                  <div class="money" v-if="!item.type">
                    <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                    <span v-if="item.price_rmb != '0.00' && item.price_score != '0'">
                      +
                    </span>
                    <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                      <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                    </span>
                  </div>
                  <div v-else-if="item.type == 1">
                    <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                  </div>
                </div>
                <div class="btm">
                  <div>
                    <div style="text-align: left; font-size: 12px;">
                      下单时间:{{ item.create_time | dateFormat() }}
                    </div>
                  </div>
                  <div class="disFlex_center">
                    <div class="btnOne" @click="onCancellationOrder(item, '0')">取消订单</div>
                    <div class="btnTwo" @click="onType(item, '')">立即付款</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noList" v-else>暂无订单数据，快去购买吧~</div>
        </van-pull-refresh>
      </tab>
      <tab title="待发货">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh('2')">
          <div class="pullListItem" v-if="orderList.length">
            <div class="orderList" v-for="(item, index) in orderList" :key="index">
              <div class="orderTop">
                <div class="orderLeftPayTime" @click="copyOrder(item.order)">
                  <span>
                    订单号：{{ item.order }}
                  </span>
                  <img style="width: 16px; height: 16px" :src="require('../../assets/img/yqMall/copy.jpg')" alt="" />
                </div>
                <div class="orderRightType">
                  待发货
                </div>
              </div>
              <div class="commodity" v-if="!item.is_car">
                <div class="commodityImg">
                  <img :src="item.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ item.name }}</div>
                  <div class="commoditySelect">{{ item.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                      <span v-if="item.price_rmb != '0.00' && item.price_score != '0'
                        ">
                        +
                      </span>
                      <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
              <div class="commodity" v-for="(s, n) in item.car_info" :key="n" v-else>
                <div class="commodityImg">
                  <img :src="s.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ s.name }}</div>
                  <div class="commoditySelect">{{ s.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="s.price_rmb != '0.00'">￥{{ s.price_rmb }}</span>
                      <span v-if="s.price_rmb != '0.00' && s.price_score != '0'">
                        +
                      </span>
                      <span class="yq" v-if="s.price_score != '0'">{{ s.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ s.num }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div style="text-align: left; font-size: 12px; margin-top: 10px;">
                  下单时间:{{ item.create_time | dateFormat() }}
                </div>
              </div>
            </div>
          </div>
          <div class="noList" v-else>暂无订单数据，快去购买吧~</div>
        </van-pull-refresh>
      </tab>
      <tab title="待收货">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh('4')">
          <div class="pullListItem" v-if="orderList.length">
            <div class="orderList" v-for="(item, index) in orderList" :key="index">
              <div class="orderTop">
                <div class="orderLeftPayTime" @click="copyOrder(item.order)">
                  <span>
                    订单号：{{ item.order }}
                  </span>
                  <img style="width: 16px; height: 16px" :src="require('../../assets/img/yqMall/copy.jpg')" alt="" />
                </div>
                <div class="orderRightType">
                  待收货
                </div>
              </div>
              <div class="commodity" v-if="!item.is_car">
                <div class="commodityImg">
                  <img :src="item.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ item.name }}</div>
                  <div class="commoditySelect">{{ item.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                      <span v-if="item.price_rmb != '0.00' && item.price_score != '0'
                        ">
                        +
                      </span>
                      <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
              <div class="commodity" v-for="(s, n) in item.car_info" :key="n" v-else>
                <div class="commodityImg">
                  <img :src="s.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ s.name }}</div>
                  <div class="commoditySelect">{{ s.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="s.price_rmb != '0.00'">￥{{ s.price_rmb }}</span>
                      <span v-if="s.price_rmb != '0.00' && s.price_score != '0'">
                        +
                      </span>
                      <span class="yq" v-if="s.price_score != '0'">{{ s.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ s.num }}</div>
                  </div>
                </div>
              </div>
              <div>
                <div class="btm">
                  <div>
                    <div style="text-align: left; font-size: 12px;">
                      下单时间:{{ item.create_time | dateFormat() }}
                    </div>
                  </div>
                  <div class="btnTwo" @click="onReceipt(item, 4)">确认收货</div>
                </div>
              </div>
            </div>
          </div>
          <div class="noList" v-else>暂无订单数据，快去购买吧~</div>
        </van-pull-refresh>
      </tab>
      <tab title="已完成">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh('5')">
          <div class="pullListItem" v-if="orderList.length">
            <div class="orderList" v-for="(item, index) in orderList" :key="index">
              <div class="orderTop">
                <div class="orderLeftPayTime" @click="copyOrder(item.order)">
                  <span>
                    订单号：{{ item.order }}
                  </span>
                  <img style="width: 16px; height: 16px" :src="require('../../assets/img/yqMall/copy.jpg')" alt="" />
                </div>
                <div class="orderRightType textSuccess">
                  已完成
                </div>
              </div>
              <div class="commodity" v-if="!item.is_car">
                <div class="commodityImg">
                  <img :src="item.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ item.name }}</div>
                  <div class="commoditySelect">{{ item.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="item.price_rmb != '0.00'">￥{{ item.price_rmb }}</span>
                      <span v-if="item.price_rmb != '0.00' && item.price_score != '0'
                        ">
                        +
                      </span>
                      <span class="yq" v-if="item.price_score != '0'">{{ item.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ item.num }}</div>
                  </div>
                </div>
              </div>
              <div class="commodity" v-for="(s, n) in item.car_info" :key="n" v-else>
                <div class="commodityImg">
                  <img :src="s.image" alt="" />
                </div>
                <div class="commodityType">
                  <div class="commodityName">{{ s.name }}</div>
                  <div class="commoditySelect">{{ s.value | fontFilter() }}</div>
                  <div class="commodityMoney">
                    <div class="money">
                      <span v-if="s.price_rmb != '0.00'">￥{{ s.price_rmb }}</span>
                      <span v-if="s.price_rmb != '0.00' && s.price_score != '0'">
                        +
                      </span>
                      <span class="yq" v-if="s.price_score != '0'">{{ s.price_score }}
                        <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                      </span>
                    </div>
                    <div class="numer">x{{ s.num }}</div>
                  </div>
                </div>
              </div>

              <div>
                <div style="text-align: left; font-size: 12px; margin-top: 10px;">
                  下单时间:{{ item.create_time | dateFormat() }}
                </div>
              </div>
            </div>
          </div>
          <div class="noList" v-else>暂无订单数据，快去购买吧~</div>
        </van-pull-refresh>
      </tab>
    </tabs>
    <YqPay :money="money" :order="order" :price="price" :time="times" :isWxPay="isWxPay" @close="onPopupClose($event)"
      v-if="showType" />

  </div>
</template>
<script>

import { order_list, cancellation, confirm } from "../../api/yqMallApi";
import { NavBar, Tabs, Tab } from "vant";
import YqPay from "./components/yqPay";
import { type } from "os";
export default {
  components: { NavBar, Tabs, Tab, YqPay },
  data() {
    return {
      listTabs: [
        {
          name: '全部',
          type: null,
        },
        {
          name: '待付款',
          type: 0,
        },
        {
          name: '待发货',
          type: 2,
        },
        {
          name: '待收货',
          type: 4,
        },
        {
          name: '已完成',
          type: 5,
        }
      ],
      active: 0,
      orderList: [],
      isLoading: false,
      money: '', //需要支付的钱
      order: '', //需要支付的订单号
      times: '',//支付倒计时
      showType: false,
      isWxPay: false, //是否微信支付
      price: '',//微信支付金额
      type: null, //当前选中状态
      page: 1,
      orderId: ''//若有orderid 则表明支付宝支付成功返回order页面
    };
  },
  filters: {
    //时间格式转换
    dateFormat(value) {
      if (!value) return "";
      let time = new Date(value * 1000);

      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      return `${timeAdd0(y)}-${timeAdd0(m)}-${timeAdd0(d)} ${timeAdd0(
        h
      )}:${timeAdd0(mm)}:${timeAdd0(s)}`;
      // if (type == 'min') {
      // 	return `${timeAdd0(m)}min`;
      // }
    },
    fontFilter(value) {
      return value.replace(",", " ")
    }
  },
  // created activated
  created() { },
  // 监听变量
  watch: {},
  mounted() {
    if (this.$route.query.order_id) {
      this.orderId = this.$route.query.order_id;
    }
    this.onOrderList();
    this.handleScroll();
  },
  destroyed() { },
  methods: {
    //复制订单
    copyOrder(e) {
      var input = document.createElement("input"); // 创建input对象
      input.value = e; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$toast('复制成功');
    },
    //上划加载
    handleScroll() {
      let _this = this;
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (Math.ceil(scrollTop + windowHeight) == scrollHeight) {
          _this.page++
          _this.onOrderList(_this.type)
        }
      };
    },
    // tabs 0全部 1 待付款 2 待收货 3 已完成
    onTabs(e) {
      switch (e) {
        case 0:
          this.type = null
          break;
        case 1:
          this.type = '0'
          break;
        case 2:
          this.type = '2'
          break;
        case 3:
          this.type = '4'
          break;
        case 4:
          this.type = '5'
          break;
        default:
          break;
      }
      console.log(122121, this.type)
      this.onRefresh(this.type)
    },
    //关闭支付
    onPopupClose(e) {
      setTimeout(() => {
        this.showType = e;
      }, 300)
    },
    //不同状态按钮 0待支付 1元气支付完成 待微信支付 2订单完成（待发货）3已发货 4待收货 5已完成，负1 取消订单
    onType(item, type) {
      console.log(12, item)
      switch (item.type) {
        case 0:
          if (item.price_rmb != '0.00') {
            this.price = item.price_rmb
            this.isWxPay = true
          }
          this.order = item.order
          this.money = item.price_score
          this.showType = true
          this.times = item.create_time
          break;
        case 1:
          this.onGoto(`yqMallPayOrder?money=${item.price_rmb}&order=${item.order}&time=${item.create_time}`)
          break;
        case 2:
          this.onReceipt(item, type);
          break;
        case -1:
          this.onCancellationOrder(item, type);
          break;
      }

    },
    //取消订单
    async onCancellationOrder(item, type) {
      try {
        const res = await cancellation({
          order: item.order
        });
        this.$toast('取消订单成功');
        this.onRefresh(type);
      } catch (e) {
        this.$toast('取消订单失败');
      }
    },
    //确认收获
    async onReceipt(item, type) {
      try {
        const res = await confirm({
          order: item.order
        });
        this.$toast('确认收货成功');
        this.onRefresh(type);
      } catch (e) {
        this.$toast('确认收货失败');
      }
    },
    //下拉加载
    onRefresh(type) {
      this.page = 1
      this.orderList = []
      this.isLoading = true;
      this.onOrderList(type);
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
    //商品详情
    async onOrderList(type = null) {
      try {
        const res = await order_list({
          type: type,
          page: this.page
        });
        let data = res.data.data.data
        for (let i = 0; i < data.length; i++) {
          this.orderList.push(data[i]);
        }

      } catch (e) {
        console.log(e);
      }
    },
    // function 调用uniapp的 方法
    onClickLeft() {
      let name = 'yqMallIndex'
      if (this.orderId) {
        this.$router.push({ path: name });
      } else {
        this.$router.go(-1);
      }
    },
    onGoto(name) {
      this.$router.push({ path: name });
    },
    //倒计时
    countDown(endTime) {
      var nowtime = new Date(); //获取当前时间
      var lefttime = (endTime + 1800) * 1000 - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      if (leftm <= 0) {
        return `00`;
      }
      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      return `${timeAdd0(leftm)}`;
    },
  },
};
</script>
<style  lang="scss" scoped>
/deep/.van-pull-refresh {
  height: 100%;
}

/deep/.van-loading {
  text-align: center;
}

/deep/.van-pull-refresh__head {
  display: flex;
  justify-content: center;
}

/deep/.van-tabs__line {
  background-color: #000000;
  width: 20px;
  bottom: 20px;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.pullListItem {
  min-height: 100vh;
}

.disFlex_center {
  display: flex;
  align-items: center;
}

.orderRightType {
  font-size: 13px;
}

.textError {
  color: #f00;
}

.textSuccess {
  color: #00bf5b;
}

.noList {
  text-align: center;
  margin-top: 40px;
  min-height: 100vh;
}

.page-box {
  min-height: 100vh;
  background: #ececec;
}

.orderList {
  width: 95%;
  margin: 10px auto;
  border-radius: 10px;
  padding: 10px;
  background: #ffffff;

  .btm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btnOne {
      padding: 5px 8px;
      color: #000000;
      border-radius: 30px;
      border: 1px solid #000000;
      background: #fff;
    }

    .btnTwo {
      padding: 5px 10px;
      color: #fff;
      border-radius: 30px;
      border: 1px solid #000000;
      background: #000000;
      margin-left: 10px;
    }
  }

  .orderMoney {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .money {
      display: flex;
      align-items: center;
    }

    .yq {
      display: flex;
      align-items: center;

      .moneyYq {
        width: 16px;
        height: 16px;
      }
    }
  }

  .orderTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .orderLeftPayTime {
      display: flex;
      align-items: center;
      width: 70%;

      span {
        font-size: 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      img {
        margin-left: 10px;
      }
    }
  }

  .commodity {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .commodityImg {
      border-radius: 5px;
      overflow: hidden;
      margin-right: 5px;
      width: 85px;
      height: 85px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .commodityType {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      width: 70%;
      line-height: 32px;

      .commodityName {
        color: #000000;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        font-weight: 700;
      }

      .commoditySelect {
        font-size: 13px;
        color: #737373;
        width: 100%;
      }

      .commodityMoney {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .money {
          font-size: 14px;
          display: flex;
          align-items: center;

          .yq {
            display: flex;
            align-items: center;

            .moneyYq {
              margin-top: 3px;
              width: 22px;
              height: 22px;
              margin-left: 1px;
            }
          }
        }

        .numer {
          font-size: 14px;
        }
      }
    }
  }
}
</style>