<template>
  <div class="thirtyRank">
    <div class="top">
      <!-- 头部 -->
      <div class="top_navigation">
        <div class="top_time">
          <div class="wd m-b-10">距离活动结束</div>
          <div class="time">{{ showTime }}</div>
        </div>
        <div class="right_text">
          <div class="text">合成升级</div>
          <div class="text">非遗珍稀</div>
          <div class="text">实体赋能</div>
        </div>
        <img
          class="top_rules"
          @click="toPage('Thirtyrule')"
          src="../../assets/img/thirty/rules.png"
          alt=""
        />
      </div>
    </div>
    <div class="bottom">
      <div class="title">
        <div>排名</div>
        <div>用户</div>
        <div>幸运值</div>
      </div>
      <div class="rank_list">
        <van-loading class="loding" v-if="loding" />
        <div v-else v-for="(item, i) in rank" :key="i" class="list">
          <div class="rank">{{ item.ranking }}</div>
          <div class="name">
            <img
              class="user_img"
              :src="item.avatar == '0' ? noimg : item.avatar"
              alt=""
            />
            <div class="username">
              {{ item.username }}
            </div>
          </div>
          <div class="growth">{{ item.growing }}</div>
        </div>
      </div>
      <div class="rank_btm">
        <div class="rank_btm_hd">
          <div class="left">
            <span v-if="ranking > 1">
              距上一名还差：{{ difference }}幸运值
            </span>
          </div>
          <div class="right" @click="toPage('ThirtyShare')">继续邀请好友 ></div>
        </div>
        <div class="btn">
          <div class="rank">{{ ranking }}</div>
          <div class="name">
            <img
              class="user_img"
              :src="user.avatar == '' ? noimg : user.avatar"
              alt=""
            />
            <div class="username">{{ user.nickname }}</div>
          </div>
          <div class="growth">{{ growing }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { leaderboard } from "../../api/thirty.js";
import { mapMutations, mapState } from "vuex";
import { getWxConfig } from "../../../tool";

export default {
  name: "ThirtyRank",
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  components: {},
  data() {
    return {
      showTime: "0d : 0h : 0m",
      timer: null,
      loding: true,
      difference: "",
      ranking: "",
      growing: "",
      noimg: require("../../assets/img/user.png"),
      rank: [],
    };
  },

  mounted() {
    this.list();
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    getWxConfig(
      "可穿戴非遗文化藏品艾草胸针火热预约中",
      "点击链接参与活动有机会免费获得"
    );
  },
  watch: {},
  methods: {
    async list() {
      try {
        const res = await leaderboard({});
        console.log(res);
        this.rank = res.data.leaderboard;
        this.ranking = res.data.ranking;
        this.difference = res.data.difference;
        this.growing = res.data.growing;
        this.loding = false;
        const now = new Date().getTime();
        let overtime = res.data.data.time2 * 1000;
        if (now < overtime) {
          this.timer = setInterval(() => {
            const time = this.countTime(overtime);
            if (overtime) {
              this.showTime = time;
            } else {
              this.showTime = "0d:0h:0m";
              location.reload();
            }
          }, 1000);
        }
      } catch (e) {
        console.log(e);
        this.$toast(e.data);
        this.loding = false;
      }
    },
    toPage(name) {
      this.$router.push({
        name,
      });
    },
    countTime(endTime) {
      const now = new Date().getTime();
      const duration = endTime - now;
      if (duration <= 0) {
        return false;
      }
      const day = Math.floor(duration / (1000 * 60 * 60 * 24));
      const hour = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const min = Math.floor((duration / (1000 * 60)) % 60);
      const s = Math.floor((duration / 1000) % 60);
      // return `${day ? day + "d" : ""}:${hour < 10 ? "0" + hour : hour}:${
      //   min < 10 ? "0" + min : min
      // }:${s < 10 ? "0" + s : s}`;
      return `${day < 1 ? "00d" : day + "d"} : ${
        hour < 10 ? "0" + hour + "h" : hour + "h"
      } : ${min < 10 ? "0" + min + "m" : min + "m"}`;
    },
  },
};
</script>
<style lang="scss">
.thirtyRank {
  .top {
    height: 455px;
    width: 100%;
    background-image: url("../../assets/img/thirty/thirtyrankbg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .top_navigation {
      min-height: 500px;
      position: relative;

      .img_one_Box {
        padding: 30px 20px;
        .img {
          height: 30px;
          width: 100px;
        }
      }
      .top_time {
        position: absolute;
        left: 20px;
        top: 334px;
        .wd {
          font-size: 15px;
          font-weight: 500;
          font-style: italic;
          color: #000000;
        }
        .time {
          font-size: 15px;
          font-weight: 500;
          font-style: italic;
          color: #000000;
        }
      }
      .right_text {
        position: absolute;
        top: 280px;
        right: 38px;
        .text {
          font-size: 15px;
          font-weight: 500;
          font-weight: SourceHanSansSC-Regular;
          font-style: italic;
          color: #000000;
          margin-bottom: 10px;
        }
      }
      .top_rules {
        width: 30px;
        height: 85px;
        position: absolute;
        right: 0;
        top: 180px;
      }
    }
  }
  .bottom {
    width: 375px;
    height: 356px;
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    top: -10px;
    padding: 20px 0px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 55px;
    }
    .rank_list {
      padding: 0 20px 105px;
      height: 400px;
      overflow-y: scroll;
      .loding {
        text-align: center;
        margin-top: 100px;
      }
      .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px 10px 40px;
        border-bottom: 1px solid #dbdbdb;
        .rank {
          font-size: 17px;
          font-weight: bold;
          color: #55f0af;
        }
        .name {
          font-weight: 500;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: left;
          margin-left: 50px;
          width: 120px;

          img {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .username {
            max-width: 85px;
            font-size: 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            // flex: 1;
            // text-align: center;
          }
        }
        .growth {
          width: 50px;
          text-align: center;
        }
      }
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
    }
    .rank_btm {
      width: 100%;
      padding: 0 25px 30px;
      position: fixed;
      bottom: 0px;
      left: 0;
      background-color: #ffffff;
      .rank_btm_hd {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        .left {
          font-size: 11px;
          font-weight: 400;
          color: #808080;
        }
        .right {
          font-size: 11px;
          font-weight: bold;
          color: #000000;
        }
      }
      .btn {
        width: 100%;
        height: 50px;
        background: linear-gradient(90deg, #84f5de, #56e8f2, #4cc5f8);
        border-radius: 5px;
        padding: 0 40px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .rank {
          font-size: 13px;
          font-weight: 500;
          color: #ffffff;
        }
        .name {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          .user_img {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .username {
            max-width: 80px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .growth {
        }
      }
    }
  }
}
</style>
