<template>
  <div class="share">
    <van-overlay
      z-index="100"
      :show="show"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }"
    >
      <div class="heads">
        <div class="backs">
          <img
            class="bckgsImg"
            src="../../../assets/img/sleepActivity/close.png"
            alt=""
          />
        </div>
      </div>
      <van-loading
        type="circular"
        size="50"
        color="#FFFFFF"
        v-if="loadingIns"
        class="loding"
        vertical
      >
        正在生成海报
      </van-loading>
      <div class="tips">长按图片转发或保存</div>
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="shareHtml" id="poster" ref="poster">
            <div class="bgkss">
              <img
                class="bgksInt"
                src="../../../assets/img/sleepActivity/poster.png"
                alt=""
              />
              <div class="shareUser">
                <div class="sharePosterBgk">
                  <img
                    src="../../../assets/img/sleepActivity/posterFont.png"
                    alt=""
                  />
                </div>
                <div class="shares">
                  <div class="shareLeft">
                    <div class="avatar">
                      <img :src="avatar" alt="" />
                    </div>
                    <!-- <img
                      class="zzz"
                      src="../../../assets/img/sleepActivity/zzz.png"
                      alt=""
                    /> -->
                  </div>
                  <div class="qrs shareRight">
                    <vue-qr
                      :text="url"
                      class="qr-code"
                      :margin="0"
                      :logoScale="0.3"
                      :size="45"
                    ></vue-qr>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="img" class="poster_img">
        <img
          @touchstart="getTouchStart(img)"
          @touchend="getTouchEnd"
          :src="img"
          alt=""
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import store from "../store";
import vueQr from "vue-qr";
import html2canvas from "html2canvas";
import { mapMutations, mapState } from "vuex";


export default {
  props: ["show", "collectionUrl"],

  components: {
    vueQr,
  },
  data() {
    return {
      img: "",
      url: "",
      loadingIns: true,
      type: 2,
      longClick: 0,
      avatar: "", //头像
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  async mounted() {
    document.addEventListener("UniAppJSBridgeReady", () => {
      this.getTouchStart();
    });
    this.loadingIns = true;
    this.checkImg(this.user.avatar);
    this.createPoster();
  },

  methods: {
    checkImg(imgurl) {
      return new Promise(function (resolve, reject) {
        var imgObj = new Image();
        imgObj.src = imgurl;
        imgObj.onload = function (res) {
          resolve(res);
        };
        imgObj.onerror = function (err) {
          reject(err);
        };
      })
        .then(() => {
          this.avatar = this.user.avatar
        })
        .catch(() => {
          this.avatar = require("../../../assets/img/user.png");
        });
    },
    getTouchStart(img) {
      this.timer = setTimeout(() => {
        uni.postMessage({
          data: {
            action: img,
            invitationCode: "",
          },
        });
      }, 1000);
    },
    getTouchEnd() {
      clearTimeout(this.timer);
    },
    back() {
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    createPoster() {
      this.url = `${window.location.origin}/#/sleepActivityIndex?invited_ids=${this.user.id}`;
      setTimeout(() => {
        const dom = document.getElementById("poster");
        console.log(111, dom.offsetWidth);
        console.log(11221, dom.offsetHeight);
        html2canvas(dom, {
          width: dom.offsetWidth, //设置canvas尺寸与所截图尺寸相同，防止白边
          height: dom.offsetHeight, //防止白边
          backgroundColor: "#000000", // null 表示设置背景为透明色
          useCORS: true, // 是否尝试使用CORS从服务器加载图像
          allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
          taintTest: true, // 是否在渲染前测试图片
          scale: 4, // dpr比列
          useCORS: true,
          scrollY: 0, // 截屏时页面滚动，造成截屏图片不全或空白
        })
          .then((canvas) => {
            const img = canvas.toDataURL("image/png");
            this.img = img;
            this.loadingIns = false;
          })
          .catch((e) => {
            console.log(e);
            // this.$toast.fail("??????");
          });
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heads {
  .backs {
    width: 27px;
    height: 27px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    position: fixed;
    bottom: 7%;
    left: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .bckgsImg {
      width: 100%;
      height: 100%;
    }
  }
}

.share {
  .block {
    position: relative;
  }
  .shareHtml {
    position: absolute;
    top: -10000000px;
    // top: 0px;
    left: 50%;
    // width: 300px;
    // height: 500px;
    transform: translate(-50%, -50%);

    .hedimg {
      width: 295px;
      height: 459px;
      // background: pink;
      border-radius: 5px 5px 0px 0px;
    }
    .bgkss {
      width: 252px;
      height: 555px;
      margin: auto;
      position: relative;
      .bgksInt {
        position: absolute;
        width: 100%;
        z-index: -1;
        height: 100%;
        top: 0;
      }
      .shareUser {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 15px;
        display: flex;
        align-content: center;
        .sharePosterBgk {
          width: 230px !important;
          height: 60px;
          margin: auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .shares {
          position: absolute;
          display: flex;
          width: 100%;
          .shareLeft {
            position: absolute;
            left: 23px;
            top: 15px;
            .avatar {
              width: 33px;
              height: 34px;
              border-radius: 50%;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .zzz {
              width: 22px;
              height: 15px;
              position: absolute;
              top: -20px;
              right: -18px;
            }
          }
          .shareRight {
            position: absolute;
            right: 10px;
            top: 7px;
          }
          .qrs {
            width: 44px;
            height: 48px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;

            .qr-code {
              width: 90%;
              height: 90%;
              background: #ffffff;
            }
          }
        }
      }
    }
  }

  .tips {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    top: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .poster_img {
    height: 100vh;
    width: 100%;
    img {
      width: 60%;
      max-height: 467px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0px 0px 30px #0935af;
    }
  }
}
</style>
