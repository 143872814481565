<template>
  <div class="cxbcRule">
    <div class="cxbcRule_title">
      <div class="left"></div>
      <div class="right">
        <div class="til1">活动规则</div>
        <div class="til2">RULES</div>
      </div>
    </div>
    <div class="rule_all_title m-b-15">
      <img src="../../assets/img/cxbc/rule_yq.png" alt="" />
      <div class="rule_all_title_text">发行总量 : 10000</div>
    </div>
    <div class="rule_hx m-b-10"></div>
    <div class="rule_all_title m-b-10">
      <img src="../../assets/img/cxbc/rule_yq.png" alt="" />
      <div class="rule_all_title_text">发行内容 :</div>
    </div>
    <!-- <div class="cet_bt m-b-10">种普通款</div> -->
    <div class="cet_cet m-b-10 justify">
      该藏品是「李时珍数字艺术俱乐部Lishizhen.Art」发行的第一款数字藏品，该数字藏品致敬了“医圣”李时珍画像，进行了创新性的二次创作，融合了伟大医学家李时珍的16本著作、中草药及李时珍不同时期的代表服饰相关元素以及现代化潮酷元素，用数字化的方式致敬李时珍及传播中国中医传统文化。该藏品使用多种不同部件通过算法自动生成，采用721标准铸造，每一款李时珍头像均为独一无二的非同质化数字藏品。<br />
    </div>
    <div class="cet_cet m-b-10 justify">
      李时珍头像数字藏品将以盲盒的形式发布，共分为3种稀有程度：R级、SR级、SSR级。<br />
      SSR级传说款：李时珍头像数字藏品中含手拿《本草纲目》书籍元素的藏品为SSR级传说款；<br />SR级珍稀款：李时珍头像数字藏品中含手拿艾草、古代服装元素的藏品为SR级珍稀款；<br />
      R级普通款：其余李时珍头像数字藏品为R级普通款；
    </div>
    <div class="rule_hx m-b-10"></div>

    <div class="rule_all_title m-b-10">
      <img src="../../assets/img/cxbc/rule_yq.png" alt="" />
      <div class="rule_all_title_text">发行节奏 :</div>
    </div>
    <div class="cet_cet m-b-20 justify">
      李时珍头像数字藏品共分4期。每期藏品所含稀缺藏品数量将会按照总比例平均分配。
    </div>
    <!-- <div class="cet_bt m-b-10">公开发售</div> -->
    <div class="cet_cet justify">
      •
      4月27日开启赋能优先发布：发行数量为1000个。其中姜小竹持有用户、《本草纲目》合成终极版持有用户（限量300名）、第二期超级体验官、艾草种子持有数超过20颗的用户（名单快照时间为4月8日15:00前），以上4种身份用户每种身份本轮可获得1个。
    </div>
    <!-- <div class="cet_bt m-b-10">优先购</div> -->
    <div class="cet_cet justify">
      •
      5月4日开启集草优先发布：发行数量为2000个。其中参与草药的用户可合成百草图鉴，百草图鉴将会直接获得本轮优先发布资格。
    </div>
    <div class="cet_cet m-b-10 justify">
      •
      5月11日开启抽签发布：发行数量5000个，本轮将会针对所有已经预约李时珍头像数字藏品的用户开启抽签通道，流程和规则会在藏品发布前进行通知。
    </div>
    <!-- <div class="cet_bt m-b-10">正式发售</div> -->
    <div class="cet_cet m-b-10 justify">
      • 最后一期藏品公开发布时间、规则待定，发行数量为1500个。
    </div>
    <div class="cet_cet m-b-10 justify">
      •
      官方预留：其中500个李时珍头像数字藏品将会被官方保留，用于赠与重要合作伙伴以及奖励给在用户共创过程中的重要贡献者。
    </div>
    <!-- <div class="cet_cet m-b-10">
      本批发售面向所有参与藏品预约的用户。所有参与预约的用户可在2022 3.16
      15:00后登陆APP凭手速参与抢购4000个本草纲目金陵版数字藏品盲盒，先到先得，售完即止。
    </div>
    <div class="cet_bt m-b-10">种子空投</div>
    <div class="cet_cet m-b-10">
      其中1500个「本草纲目·金陵版」数字藏品将与轻松小镇已开始发售的「创世艾草种子」绑定发行。持有种子用户满足条件用户将通知获得一个藏品盲盒空投（空投规则后续公众号会公示），需要登录APP领取获得。用户开启盲盒即有概率获得本次藏品。
    </div>
    <div class="cet_bt m-b-10">硬件盲盒</div>
    <div class="cet_cet m-b-10">
      其中2000个「本草纲目·金陵版」数字藏品将与后期轻松小镇硬件发行绑定，用户购买硬件将附赠藏品盲盒，开启硬件盲盒可获得本次藏品。
    </div>
    <div class="cet_bt m-b-10">官方预留</div>
    <div class="cet_cet m-b-30">
      其中500个「本草纲目·金陵版」数字藏品由官方预先保留（用于未来小镇赠送重要合作伙伴及重要贡献者，其中200个赠予本次版权方中国中医科学院）。
    </div> -->
    <div class="rule_hx m-b-10"></div>
    <div class="remarks_box m-b-10">
      <div class="m-b-10">注:</div>
      <div class="justify">
        1、本次藏品为弘扬中国传统文化与纪念李时珍而进行创作，希望借此推动我国传统中医文化复兴及数字化创新传播与发展，平台坚决抵制将藏品用于炒作等非正当用途。
      </div>
      <div class="justify">
        2、藏品合成：本次发售的李时珍头像数字藏品将会在未来和国际版数字头像在合法合规的情况下实现玩法联动，合成玩法将会在后续的推文中公布。
      </div>
      <div class="justify">
        3、本次藏品使用超快链发行，并在上线后开启亲友转增功能，以下为转增玩法相关说明。
      </div>
      <div class="justify">
        •
        小镇用户将在该数字藏品正式发布后开放转赠功能，用户在转赠的时候需要消耗转赠符。
      </div>
      <div class="justify">
        •
        转赠符为轻松小镇的消耗型道具，需要使用艾草种子或元气兑换，本阶段1枚种子即可兑换1张转赠符，兑换后种子由官方回收。为防止恶意炒作，转赠符会限量逐步释放，种子或元气兑换转赠符的比例将随总体种子发行数量、转赠情况动态调整。
      </div>
      <div class="justify">
        • 转赠间隔时间：
        所有用户默认在获得并持有某藏品45天后方可使用转赠符进行转赠。
      </div>
      <div class="justify">
        种子持有量超过15枚的用户，在首次获得并持有藏品7天后，可使用转赠符进行转赠。
      </div>
      <div class="justify">
        种子持有量超过10枚的用户，在首次获得并持有藏品15天后，可使用转赠符进行转赠。
      </div>
      <div class="justify">
        持有本草纲目藏品的用户，在首次获得并持有藏品30天后，可使转赠符进行转赠。
      </div>
      <div class="m-b-15 justify">
        单个藏品首次转赠后，后续持有用户转赠该藏品同样需要持有超过45天后方可转赠。
      </div>
      <div class="m-b-10 justify">
        注释：首次获得并持有某藏品指该用户通过公开预约或空投方式首次获得该藏品，若藏品为他人赠送则不视为首次获得并持有，需持有45天后方可转赠，默认持有超过45天后即可拥有转赠权限（转赠需要消耗转赠符）。
      </div>
    </div>
    <div class="rule_hx m-b-10"></div>

    <div class="btm_box">
      <div class="rule_all_title m-b-10">
        <img src="../../assets/img/cxbc/rule_yq.png" alt="" />
        <div class="rule_all_title_text">创意玩法 :</div>
      </div>
      <div class="cet_cet m-b-10 justify">
        李时珍头像数字藏品是「李时珍数字艺术俱乐部Lishizhen.Art」发行的第一款数字藏品，在创作层面，致敬了“医圣”李时珍画像，进行了创新性的二次创作的创新玩法数字藏品，技术层面基于721标准算法铸造生成，每一款李时珍头像均为非同质化数字藏品，在运营治理层面，我们希望打造基于Web3用户共创俱乐部的玩法，让每一个用户都能参与到后期的共创、运营、治理中来，拥有该藏品后，除了收藏外，我们鼓励持有用户在藏品基础上进行二次创作、周边衍生创作，希望通过DAO共创方式让传统中医文化及东方元素的数字艺术发展壮大，走向世界。
      </div>
      <!-- <div class="cet_cet m-b-10">
        1、编号：每一个藏品将1-10000以此拥有不同的编号。
      </div>
      <div class="cet_cet m-b-10">
        2、五行：金木水火土5种不同属性藏品随机发放。
      </div>
      <div class="cet_cet m-b-10">
        3、生肖：藏品将根据用户提交的生辰信息生成用户专属的生肖并体现在藏品中。有任何疑问
        欢迎第一时间来小镇咨询
      </div> -->
    </div>
    <div class="footer">
      <div class="text-center m-b-25">
        <img
          src="../../assets/img/kefu.png"
          alt=""
          width="100%"
          height="100%"
          class="group"
        />
      </div>
      <div class="tips">如有疑问 欢迎第一时间来小镇咨询</div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.cxbcRule {
  background: #000;
  padding: 30px 20px;
  .rule_all_title {
    display: flex;
    align-items: center;
    img {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
    .rule_all_title_text {
      font-size: 14px;
      color: #fff;
      font-weight: bold;
    }
  }
  .cxbcRule_title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .left {
      width: 2px;
      height: 30px;
      background: url("../../assets/img/cxbc/rule-top.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
    }
    .right {
      color: #fff;
      line-height: 1em;
      .til1 {
        font-weight: 700;
        font-size: 16px;
      }
      .til2 {
        font-size: 12px;
      }
    }
  }
  .rule_hx {
    height: 3px;
    width: 100%;
    background: url("../../assets/img/cxbc/transverse.png") no-repeat;
    background-size: 100% 100%;
  }
  .cet_bt {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
  .cet_cet {
    color: #fff;
    font-size: 12px;
    line-height: 1.5em;
  }
  .remarks_box {
    width: 100%;
    min-height: 200px;
    // background: #2e2c38;
    border-radius: 9px;
    padding: 0;
    color: #fff;
    div {
      font-size: 10px;
    }
  }
  .btm_box {
    padding: 0;
  }
  .footer {
    text-align: center;
    .group {
      width: 118px;
      height: 118px;
      background: #ffffff;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.45);
      border-radius: 7px;
      display: inline-block;
      padding: 7px;
      margin-top: 30px;
    }
    .tips {
      height: 25px;
      width: 250px;
      margin: 0 auto;
      text-align: center;
      line-height: 25px;
      border-radius: 15px;
      font-size: 12px;
      background: linear-gradient(81deg, #e8c99a 0%, #f6e0be 97%);
    }
  }
}
</style>
