<template>
  <div class="inviteRank">
    <div class="back_box">
      <div class="back" @click="back()">
        <img
          :src="require('../../assets/img/back.png')"
          width="60%"
          height="50%"
        />
      </div>
    </div>
    <div class="inviteRank_header">
      <div class="noSelected" @click="change(1)">
        <div class="text" :class="changeType == 1 ? 'text1' : ''">已认证</div>
        <div class="border" :class="changeType == 1 ? 'border1' : ''"></div>
      </div>
      <div class="noSelected" @click="change(0)">
        <div class="text" :class="changeType == 0 ? 'text1' : ''">未认证</div>
        <div class="border" :class="changeType == 0 ? 'border1' : ''"></div>
      </div>
    </div>
    <div class="grey"></div>

    <!-- <div class="inviteList" v-show="changeType == 0"> -->
    <div class="inviteList">
      <van-loading type="spinner" v-if="loding" class="loding" />
      <van-list
        :finished="finished"
        offset="0"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        v-else
      >
        <div class="list" v-for="(item, i) in inviteData" :key="i">
          <div class="left">
            <div class="name">
              <div class="noyuyue" v-if="!item.huodong">未预约</div>
              <div class="yuyueBtn" v-else>已预约</div>
              <div class="username">{{ item.username }}</div>
            </div>
            <div class="time">{{ (item.create_time * 1000) | dateFormat }}</div>
          </div>
          <div class="right_number">
            {{ item.mobile }}
          </div>
        </div>
        <div class="lodingtext">{{ lodingtext }}</div>
      </van-list>
    </div>
  </div>
</template>
<script>
import { invite_log } from "../../api/thirty.js";

export default {
  name: "inviteRank",
  components: {},
  data() {
    return {
      flag: false,
      loding: true,
      page: 1,
      changeType: 1,
      inviteData: [],
      // inviteData1: [],
      noData: false,
      lodingtext: "加载更多",
      huodong: "",
      total: 0,
      finished: false, //上拉加载完毕
    };
  },
  mounted() {
    this.getList();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollBottom);
  },
  created() {},

  methods: {
    async getList() {
      this.flag = false;
      try {
        const res = await invite_log({
          certification: this.changeType,
          page: this.page,
        });
        this.inviteData = this.inviteData.concat(res.data.data);
        console.log(this.inviteData);
        this.total = res.data.total;
        this.flag = true;
        if (this.inviteData.length == res.data.total) {
          this.lodingtext = "没有更多记录,快去邀请好友参与吧";
        }
        this.loding = false;
      } catch (e) {
        this.loding = false;
      }
    },
    onLoad() {
      console.log(".....");
      let _this = this;
      if (_this.inviteData && _this.flag) {
        _this.page++;
        if (_this.inviteData.length < _this.total) {
          _this.getList();
        }
      }
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/Thirty" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
    change(i) {
      this.inviteData = [];
      this.changeType = i;
      this.page = 1;
      this.lodingtext = "加载更多";
      this.loding = true;
      setTimeout(() => {
        this.getList();
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.inviteRank {
  height: calc(100vh - 46px);

  .back_box {
    .back {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      position: absolute;
      top: 15px;
      left: 10px;
    }
  }

  .inviteRank_header {
    height: 55px;
    padding: 0 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .noSelected {
      display: flex;
      flex-direction: column;
      align-items: center;
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        opacity: 0.7;
      }
      .text1 {
        opacity: 1;
      }
      .border {
        width: 13px;
        height: 3px;
      }
      .border1 {
        width: 13px;
        height: 3px;
        background: #000000;
        border-radius: 5px;
      }
    }
  }
  .grey {
    width: 100%;
    height: 7px;
    background: #f2f4f7;
  }
  .inviteList {
    padding: 0 20px 50px;

    .list {
      padding: 20px 0 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba($color: #242328, $alpha: 0.1);
      .left {
        width: 210px;
        .name {
          display: flex;
          align-items: center;
          margin-bottom: 3px;
          .username {
            font-size: 13px;
            font-weight: bold;
            color: #000000;
            max-width: 75%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            margin-left: 5px;
          }
          .yuyueBtn {
            width: 45px;
            height: 17px;
            background: linear-gradient(225deg, #0bb7ff 0%, #00d0c1 100%);
            border-radius: 8px 8px 8px 0px;
            font-size: 9px;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            line-height: 17px;
          }
          .noyuyue {
            width: 45px;
            height: 17px;
            background: linear-gradient(225deg, #9db5bf 0%, #abc6d1 100%);
            border-radius: 8px 8px 8px 0px;
            font-size: 9px;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            line-height: 17px;
          }
        }
        .time {
          font-size: 9px;
          font-weight: 400;
          color: #000000;
          opacity: 0.7;
        }
      }

      .right_number {
        font-size: 13px;
        font-weight: 500;
        color: #000000;
      }
    }
    .lodingtext {
      color: #000000;
      opacity: 0.7;
      font-size: 12px;
      text-align: center;
      margin: 30px 0 0 0;
    }
    .loding {
      text-align: center;
      margin: 30px 0 0 0;
    }
  }
}
</style>
