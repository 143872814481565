<template>
  <div class="page-box">
    <div>
      <div class="bgk_Btm">
        <div class="bgk bgk1">
          <img
            class="img"
            src="../../assets/img/lszSolicitation/solictations/1.jpg"
            alt=""
          />
        </div>
        <!-- <div class="bgk bgk2">
          <img class="img" src="../../assets/img/lszSolicitation/2.jpg" alt="" />
        </div> -->
        <div class="bgk bgk3">
          <!-- <img
            class="img"
            src="../../assets/img/lszSolicitation/solictations/4.jpg"
            alt=""
          /> -->
        </div>
        <div
          class="bgk bgkIns"
          :style="configure.is_join ? 'height: 1030px;' : 'height: 880px;'"
        ></div>
      </div>
      <div class="bgk_Top">
        <div class="bgk bgks bgk1">
          <img
            class="img"
            src="../../assets/img/lszSolicitation/title1.png"
            alt=""
          />
        </div>
        <div class="bgk bgks bgk2">
          <img
            class="img"
            src="../../assets/img/lszSolicitation/solictations/title11.png"
            alt=""
          />
        </div>

        <div class="bgk bgks bgk3">
          <div class="hdgz" @click="gotoPage('SolicitationHdgz')"></div>
        </div>
        <div class="bgk bgks bgk4">
          <div>
            中国（北京）国际服务贸易交易会（即服贸会，前京交会），是全球唯一一个国家级、国际性、综合型的服务贸易平台，由商务部和北京市人民政府共同举办，世贸组织、联合国贸发会议、经合组织等国际组织共同支持，是全球唯一涵盖服务贸易12大领域的综合型服务贸易交易会。
            <P></P>
            今年中国（北京）国际服务贸易交易会将于2022年8月31日～9月5日在北京举办。轻松小镇作为坚持推广和传播中国中医文化的第三数字健康空间，是中医数藏行业唯一一家被邀请参与的平台。小镇将在服贸会健康卫生服务专题数字中医板块进行数字艺术藏品上墙和轮播展览。我们邀请所有李时珍数字艺术俱乐部的成员参与到这场全球盛会中来，现正式向所有俱乐部成员征集1029个李时珍头像数字艺术藏品在服贸会上进行展览，让用户持有的版权藏品大放异彩，也让世界各地的参观者欣赏中医数字艺术之美！
          </div>
        </div>
        <div v-if="configure.is_join" class="bgk rankingList">
          <div class="rankingListsTop">
            <div class="textsIn">
              助力排名前 <span class="tips3">30</span> 将直接获得授权资格
            </div>
            <div class="textsIn" @click="gotoPage('solicitationRankingLista')">
              查看完整榜单 >
            </div>
          </div>
          <div class="rankingListsBottom">
            <div class="bottoms">
              <div class="ranking">{{ userRanking.ranking }}</div>
              <div class="userRanking">
                <div class="avatars"><img :src="user.avatar" alt="" /></div>
                <div class="users">
                  <div class="userName">{{ user.nickname }}</div>
                  <div class="boostValue">
                    助力值
                    <span>{{ userRanking.growing }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="invitation">
              <div class="invitations" @click="gotoPage('invitationRecorda')">
                查看邀请记录
              </div>
            </div>
          </div>
        </div>
        <div class="bgk bgk5">
          <img
            class="hhpng"
            src="../../assets/img/lszSolicitation/solictations/hc.png"
            alt=""
          />
          <div class="bgk bgk2s">
            <!-- <img
              class="img"
              src="../../assets/img/lszSolicitation/solictations/3.jpg"
              alt=""
            /> -->
          </div>
          <div class="tipsFont">
            <div class="tipsTop">
              <div class="tipsIcon">
                <img
                  class="img"
                  src="../../assets/img/lszSolicitation/icon1.png"
                  alt=""
                />
              </div>
              <div class="tipsTitle">参与收益：</div>
            </div>
            <div class="tipsBottom">
              本次被征用的1029个头像藏品分为2个类别，999个头像藏品以视频轮播的方式展示，30个头像藏品以图片上墙的方式在展会区域展示。
              <p></p>
              其中999个以视频轮播方式展示的用户将会获得「数字中医宣传大使专属纪念勋章」数字藏品一枚。
              <p></p>
              30个以藏品上墙方式展示的用户将会获得「数字中医宣传大使专属纪念勋章」一枚+现场其他品牌参展商的优先合作权益（如有）+下一期版权征集的优先租赁机会。
              <p></p>
              所有参与活动的用户都可获得转赠符+2，艾草胸针+1的空投福利（空投将在征集活动结束后为大家发放，「数字中医宣传大使专属纪念勋章」数字藏品奖励不叠加）
            </div>
          </div>
          <div class="tipsFont">
            <div class="tipsTop">
              <div class="tipsIcon">
                <img
                  class="img"
                  src="../../assets/img/lszSolicitation/icon2.png"
                  alt=""
                />
              </div>
              <div class="tipsTitle">参与方式：</div>
            </div>
            <div class="tipsBottom">
              所有持有李时珍头像数字艺术藏品的用户均可进入轻松小镇App，在【中国（北京）国际服务贸易交易会用户藏品展示征集活动】页面报名，填写自己所持有的藏品的信息并提交藏品。
              <p></p>
              用户参与活动后可以分享自己的邀请助力海报邀请好友帮忙助力投票。活动期间，每位好友每天可以参与投票1次（温馨提示：只有通过扫描个人分享海报并且完成投票才能增加助力票数，且参与用户不能为自己助力）。
              <p></p>
              所有参与助力的用户中将随机抽取500名用户（完成实名认证）获得「数字中医宣传大使专属纪念勋章」一枚。
            </div>
          </div>
          <div class="tipsFont">
            <div class="tipsTop">
              <div class="tipsIcon">
                <img
                  class="img"
                  src="../../assets/img/lszSolicitation/icon3.png"
                  alt=""
                />
              </div>
              <div class="tipsTitle">入选机制：</div>
            </div>
            <div class="tipsBottom">
              在活动期间获得好友助力投票越多，入选的几率越高。助力值排名前30名用户将直接获得本次服贸活动藏品上墙展示资格。助力值排名第31名——第1029名用户将直接获得本次服贸活动藏品以视频轮播的方式展示的资格。每位用户限提供一个李时珍头像数字艺术藏品。中奖名单将会在活动结束以后在轻松小镇App进行公布。
              <span class="tips2"
                >（注：如发现用脚本/外挂等机器干预刷票的行为将直接取消该用户活动资格。）</span
              >
            </div>
          </div>
          <div class="tipsFont tipsTime">
            <div>活动时间：8月19日19:00—8月21日12:00</div>
            <div>活动结果公布时间：8月22日12:00</div>
            <div>版权使用时间：2022年8月22日12:00-2022年9月6日00:00</div>
          </div>
        </div>
        <div class="explain">
          <div class="explains">
            <div class="explainTitle">特别说明:</div>
            <div class="explainFont">
              1、本次入选的李时珍头像数字艺术藏品版权使用周期为15天，在版权使用周期内，成功被征用的1029个李时珍头像数字艺术藏品使用权归被授权品牌方轻松小镇所有。该1029位用户须与轻松小镇签订授权协议。
            </div>
            <div class="explainFont">
              2、本次入选的李时珍头像数字艺术藏品将直接冻结15天（用户不能进行交易、不能转移，不得出现账号注销、倒卖的情况，如果出现直接倒卖账号行为，平台会追溯该用户法律责任）。
            </div>
            <div class="explainFont">
              3、在活动结束后好友投票数如有并列，将根据用户藏品持有数量（除艾草胸针以外的所有藏品总数）进行二次排名，藏品持有数量越多的用户排名越靠前。
            </div>
            <div class="explainFont">
              4、本活动最终解释权归轻松小镇和李时珍数字艺术俱乐部所有。
            </div>
          </div>
        </div>
        <div
          class="bgk bgk6"
          style="opacity: 0.9"
          :style="endActivity ? 'margin: 10px 0 80px' : 'margin: 10px 0 120px'"
        >
          <img
            class="img"
            src="../../assets/img/lszSolicitation/solictations/bottom11.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="btns">
      <div class="btnsIns loadingIns" v-if="loadings">
        <div>
          <van-loading
            type="circular"
            size="30"
            color="#ffffff"
            v-if="loadings"
            class="loding"
          />
        </div>
      </div>
      <div v-else>
        <div class="btnsTime" v-if="!endActivity">
          {{ endTime }}
        </div>
        <div
          class="btnsIns"
          v-else-if="endActivity && configure.is_join && !announceActivity"
        >
          <div class="btnsT btnsInT">
            <div>
              {{ announceTime }}
            </div>
          </div>
        </div>
        <div
          class="btnsIns"
          @click="onPublishData()"
          v-else-if="endActivity && configure.is_join && announceActivity"
        >
          <div class="btnsT btnsInT">
            <div>查看结果</div>
          </div>
        </div>
        <div class="btnsIns" v-else>
          <div class="btnsT btnsInT" @click="onLogins()">
            <div>活动结束</div>
          </div>
        </div>
        <div v-if="!endActivity">
          <div class="btnsIns" v-if="!configure.is_join">
            <div
              class="btnsT btnsInT"
              @click="gotoPage('SolicitationCollection')"
            >
              <div>参与征集</div>
            </div>
          </div>
          <div class="btnsIns" v-else>
            <div class="bubbles">
              <div>好友每天可以助力1次</div>
            </div>
            <div class="btnsS" @click="gotoPage('SolicitationCollection')">
              <div>重新选择藏品</div>
            </div>
            <div class="btnsT btnsIn" @click="onShare()">
              <div>邀请好友参与助力</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Share
      ref="Share"
      v-if="showShare"
      @close="onColse()"
      :show="showShare"
    ></Share>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="loginSuccess"
    ></login>
    <TipsPopup
      ref="TipsPopup"
      :ranking="userRanking.ranking"
      @onSuccess="onSuccessIn()"
    ></TipsPopup>
  </div>
</template>
<script>
import { userApi, signInApi, configApi } from "../../api/user";
import { enter, configure, ranking, winning } from "../../api/solicitation";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapMutations, mapState } from "vuex";
import Login from "../../components/Login";
import Share from "./components/share";
import axios from "axios";
import { getWxConfig } from "../../../tool";
import TipsPopup from "./components/TipsPopup";

const baseUrl = require("../../../site");

export default {
  name: "thirty",
  components: {
    SwiperSlide,
    Swiper,
    Login,
    Share,
    TipsPopup,
  },
  data() {
    return {
      showShare: false, //分享海报展示
      isShowLogin: false, //判断是否登录
      configure: {}, //用户是否参与
      userRanking: {}, //用户当前排行榜
      timer: "", //活动结束定时器
      announceTimer: "", //公布结果定时器
      endTime: "", //活动结束倒计时
      endActivity: false, //活动是否结束
      loadings: true,
      announceTime: "", //公布倒计时
      announceActivity: false, //是否公布结果
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#05060a");
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      invited_code: (state) => state.invited_code,
      invited_ids: (state) => state.invited_ids,
    }),
  },
  mounted() {
    // document.addEventListener("UniAppJSBridgeReady", () => {
    //   console.log("yyyyy");
    //   // this.getTouchStart();
    // });
    //没参与活动进入

    // this.$refs.TipsPopup.onSwitch(2, "open");

    this.onLogoin();
  },
  destroyed() {
    clearInterval(this.timer);
  },

  created() {
    // this.browserOpenApp();
    // window.location.href = "relax-town:///pages/user/realName";
    // this.getIndex();
    // getWxConfig(
    //   "可穿戴非遗文化藏品艾草胸针火热预约中",
    //   "点击链接参与活动有机会免费获得"
    // );
    // toApp("relax-town:///pages/user/realName");
  },
  watch: {},
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    async onLogoin() {
      this.loadings = true;
      await this.onConfigure();
      if (this.isLogin) {
        if (!this.configure.is_enter) {
          await this.onParticipate();
        }
        await this.getUser();
        await this.onRanking();
      }
    },
    //用户当前排名
    async onRanking() {
      try {
        const res = await ranking({
          acctoken: this.isLogin,
        });
        this.userRanking = res.data;
      } catch (e) {
        console.log(e);
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    //是否报名
    async onConfigure() {
      let i = 0;
      try {
        const res = await configure({
          acctoken: this.isLogin,
        });
        this.timer = setInterval(() => {
          const time = this.countDown(Number(res.data.end_time), 1);
          if (!time) {
            clearInterval(this.timer);
            if (this.configure.is_join) {
              this.loadings = true;
              this.onPublish();
            } else {
              this.endTime = "活动已结束";
              this.endActivity = true;
              this.loadings = false;
            }
            // announceTime
          } else {
            this.endTime = time;
            if (!i) {
              i += 1;
              this.loadings = false;
            }
          }
        }, 1000);
        this.configure = res.data;
      } catch (e) {
        this.onError(e);
      }
    },
    //是否开始公布结果
    onPublish() {
      this.endActivity = true;
      this.announceActivity = false;
      this.announceTimer = setInterval(() => {
        const time = this.countDown(Number(1661140800), 2);
        // const time = this.countDown(Number(1660904502), 2);
        if (!time) {
          clearInterval(this.announceTimer);
          // announceTime
          this.announceActivity = true;
        } else {
          this.announceTime = time;
        }
      }, 1000);
      setTimeout(() => {
        this.loadings = false;
      }, 1500);
    },
    //用户是否中奖
    async onPublishData() {
      try {
        const res = await winning({
          acctoken: this.isLogin,
        });
        if (res.data.code == 200) {
          this.$refs.TipsPopup.onSwitch(2, "open");
        } else if (res.data.code == 222 && !res.data.receive) {
          this.$refs.TipsPopup.onSwitch(3, "open");
        } else {
          this.$refs.TipsPopup.onSwitch(5, "open");
        }
      } catch (e) {
        this.onError(e);
      }
    },
    //参加活动
    async onParticipate() {
      try {
        const res = await enter({
          invite: this.invited_code
            ? this.invited_code
            : this.invited_ids
            ? this.invited_ids
            : "",
          acctoken: this.isLogin,
        });
        this.onConfigure();
      } catch (e) {
        this.onError(e);
      }
    },
    //登录成功
    loginSuccess() {
      this.isShowLogin = false;
      this.showGetMedal();
    },
    //没登陆先登录
    onLogins() {
      if (!this.isLogin) {
        this.isShowLogin = true;
        return false;
      }
    },
    showGetMedal() {
      this.onLogoin();
    },
    onColse() {
      this.showShare = false;
    },
    onShare() {
      this.showShare = true;
    },
    /**
     * @param {*} name 跳转页面路由的name
     */
    gotoPage(name) {
      if (!this.isLogin && name == "SolicitationCollection") {
        this.$toast("您还未登录，请先登录账号");
        setTimeout(() => {
          this.isShowLogin = true;
        }, 2000);
        return false;
      }
      this.$router.push({
        name,
      });
    },
    //获取用户信息
    async getUser() {
      try {
        const res = await userApi({});
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        this.onError(e);
      }
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
      this.loadings = false;
    },
    /**
     * 倒计时
     * @param {Number} endTime 结束时间
     * @param {Number} type 状态值
     */
    countDown(endTime, type) {
      var nowtime = new Date(); //获取当前时间
      var lefttime = endTime * 1000 - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数

      if (leftd <= 0 && lefth <= 0 && leftm <= 0 && lefts <= 0) {
        return false;
      }

      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      if (type == 1) {
        return `距征集结束还剩 ${leftd}d  ${timeAdd0(lefth)}:${timeAdd0(
          leftm
        )}:${timeAdd0(lefts)}`;
      } else {
        return `结果将在 ${timeAdd0(lefth)}:${timeAdd0(leftm)}:${timeAdd0(
          lefts
        )} 后公布`;
      }
      // return `${leftd}天${lefth}时${leftm}分${lefts}秒`;
    },
  },
};
</script>
<style scoped lang="scss">
.page-box{
  background: #00090b;
  min-height: 100vh;
}
.bgk_Btm {
  position: absolute;

  .bgk {
    margin-bottom: -10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bgkIns {
    background: #00090b;
    width: 100%;
  }

  .bgk1 {
  }

  .bgk2 {
  }

  .bgk3 {
    top: 788px;
    position: relative;
  }

  .bgk5 {
    margin-bottom: 80px;
    position: relative;
  }
}

.bgk_Top {
  position: absolute;
  z-index: 2;
  text-align: center;

  .rankingList {
    margin-bottom: 10px;
    width: 90%;
    margin: auto;

    .rankingListsTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .textsIn {
        color: #ffffff;
        font-size: 12px;

        .tips3 {
          color: #07b295 !important;
        }
      }
    }

    .rankingListsBottom {
      background: #013c4b;
      border: 1px solid #007b9a;
      border-radius: 10px;
      padding: 20px 22px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .bottoms {
        display: flex;
        align-items: center;

        .ranking {
          font-weight: 700;
          font-size: 20px;
          margin-right: 20px;
          color: #ffffff;
        }

        .userRanking {
          display: flex;
          align-items: center;

          .avatars {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid #ffffff;
            margin-right: 10px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .users {
            color: #ffffff;

            .userName {
              font-weight: 700;
              font-size: 14px;
              margin-bottom: 5px;
              width: 90px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .boostValue {
              font-size: 11px;

              span {
                font-weight: 700;
              }
            }
          }
        }
      }

      .invitation {
        background: rgba(0, 0, 0, 0.5);

        border-radius: 31px;
        padding: 10px;

        .invitations {
          color: #ffffff;
          font-size: 11px;
        }
      }
    }
  }

  .bgks {
    display: flex;
    justify-content: center;
  }

  .bgk {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .bgk1 {
    padding: 30px 0 20px;

    img {
      width: 300px;
      height: 30px;
    }
  }

  .bgk2 {
    padding: 0px 16px 50px;
  }

  .bgk3 {
    justify-content: flex-end !important;
    position: relative;
    overflow: hidden;

    .hdgz {
      background-image: url("../../assets/img/lszSolicitation/hdgzIcon.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 22px;
      height: 74px;
      margin-right: -2px;
    }
  }

  .bgk4 {
    background-image: url("../../assets/img/lszSolicitation/solictations/jz2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 330px;
    padding: 30px 0 20px;
    display: flex;
    // align-items: center;
    justify-content: center;
    margin-top: 100px;

    div {
      padding: 0 44px;
      font-size: 11px;
      color: #fff;
      line-height: 1.5em;
      margin-bottom: 15px;

      .bgkFonts {
        font-weight: 700;
      }
    }
  }

  .bgk5 {
    position: relative;
    .hhpng {
      position: absolute;
      height: 99px;
      width: 45.6px;
      right: 0;
      top: 130px;
    }
    .bgk2s {
      position: absolute;
      z-index: -1;
      top: -100px;
    }

    .tipsTime {
      color: #fff;
      line-height: 20px;

      div {
        font-size: 11px !important;
      }
    }

    .tipsFont {
      margin-bottom: 20px;
      color: #fff;
      padding: 0 47px 0 41px;

      .tipsTop {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .tipsIcon {
          margin-right: 10px;

          img {
            width: 11px;
            height: 11px;
          }
        }

        .tipsTitle {
          color: #fff;
          font-size: 12px;
          font-weight: 700;
        }
      }

      .tipsBottom {
        color: #fbfaff;
        font-size: 11px;
        line-height: 1.5em;

        .tips1 {
          color: #f00 !important;
        }

        .tips2 {
          color: #808080 !important;
        }
      }
    }
  }

  .explain {
    width: 327px;
    min-height: 360px;
    margin: 50px auto 0;
    background-image: url("../../assets/img/lszSolicitation/solictations/tipsBgk.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .explains {
      padding: 20px 20px 30px 20px;
      color: #ffffff;
      line-height: 20px;

      .explainTitle {
        font-size: 12px !important;
        font-weight: 700;
        margin: 10px 0;
      }

      .explainFont {
        margin-bottom: 20px;
        font-size: 11px;
        line-height: 1.5em;
      }
    }
  }
}

.btns {
  position: fixed;
  width: 100%;
  z-index: 3;
  padding: 10px 0 20px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  .btnsTime {
    text-align: center;
    font-size: 13px;
    color: #fff;
    margin: 10px 0 20px;
  }

  .loadingIns {
    justify-content: center;
  }

  .btnsIns {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 12px;

    .bubbles {
      position: absolute;
      background-image: url("../../assets/img/lszSolicitation/bubble.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-size: 11px;
      color: #22464b;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 38px;
      top: -28px;
      width: 145px;
      height: 30px;
      animation: whirling 6s linear;
      animation-fill-mode: forwards;

      div {
        margin-bottom: 7px;
        color: #22464b;
        font-size: 11px;
      }
    }

    .btnsS {
      border-radius: 50px;
      background: #2a2e30;
      border: 1px solid #2a2e30;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36%;

      div {
        padding: 10px 0;
        color: #fff;
      }
    }

    .btnsInT {
      width: 80%;
    }

    .btnsIn {
      width: 54%;
    }

    .btnsT {
      border-radius: 50px;
      // background: #22464b;
      background: url("../../assets/img/lszSolicitation/solictations/2.png")
        no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        padding: 10px 0;
        color: #fff;
      }
    }
  }
}

@keyframes whirling {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
