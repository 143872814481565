<template>
  <van-overlay
    :show="isShow"
    z-index="10"
    style="background-color: rgba(0, 0, 0, 0.8)"
    @click="close"
  >
    <div class="wrapper" @click.stop>
      <div class="block">
        <van-icon
          name="close"
          style="opacity: 0.8"
          @click="close"
          class="white close"
          size="24"
        />
        <div class="f-26 bold white text-center m-b-25">加入轻松社群</div>
        <div class="wrap black text-center f-12 m-b-25">
          步骤1：复制下方口令
        </div>
        <div class="btn text-center black f-13">
          口令：/QStown {{ user.code }}
          <span class="f-11 copy" ref="copy" @click="copy">点击复制</span>
        </div>
        <div class="wrap black text-center f-12 m-t-25">
          步骤2：加入官方社群复制口令完成社群认证
        </div>
        <div class="text-center m-t-25">
          <span class="code">
            <vue-qr :text="src" :margin="5" :size="105"></vue-qr>
          </span>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from "vuex";
import { configApi } from "../api/user";
import Clipboard from "clipboard";
import vueQr from "vue-qr";

export default {
  name: "SheGroupDlg",
  props: ["isShow"],
  components: { vueQr },
  data() {
    return {
      src: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.getCode();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    copy() {
      let copy = this.$refs.copy;
      const clipBoard = new Clipboard(copy, {
        text: () => {
          return `/QStown ${this.user.code}`;
        },
      });
      clipBoard.on("success", (e) => {
        console.log(e);
        this.$toast("复制成功");
      });
      clipBoard.on("error", (err) => {
        console.log(err);
        this.$toast.fail("复制失败");
      });
    },
    async getCode() {
      this.$toast.loading({
        forbidClick: true,
        duration: 0,
      });
      try {
        const res = await configApi({});
        this.src = res.data.wx_qun;
        this.$emit("qr", this.src);
        this.$toast.clear();
      } catch (e) {
        this.$toast.clear();
        this.$toast(e.data);
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 88%;
    background-color: #fff;
    position: relative;
    background: url("../assets/img/b-shequ-dlg-bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 40px 30px;
    .close {
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
    .wrap {
      padding: 8px;
      background-image: radial-gradient(
        circle,
        white,
        white,
        rgba(255, 255, 255, 0)
      );
    }
    .btn {
      width: 223px;
      padding: 10px 5px;
      background: rgba(255, 255, 255, 0.7);
      border: 1px solid #ffffff;
      border-radius: 22px;
      margin: auto;
    }
    .code {
      display: inline-block;
      width: 110px;
      height: 110px;
      border-radius: 3px;
      padding: 3px;
    }
  }
}
</style>
