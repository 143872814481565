import http from './http.js'

export const reserve = (data) => {
  return http.post('index/Medal/reserve', data)
}

// 首页
export const info = (data) => {
  return http.post('/index/Medal/info/', data)
}
// 首页
export const leaderboard = (data) => {
  return http.post('index/Medal/leaderboard', data)
}
// 首页
export const lot = (data) => {
  return http.post('index/medal/lot', data)
}

// 邀请好友
export const invite_log = (data) => {
  return http.post('index/Medal/invite_log', data)
}

// 获取合成需要数量
export const synthesisConfig = (data) => {
  return http.post('/index/Medal/synthesisConfig', data)
}

// 获取合成需要数量
export const synthesisList = (data) => {
  return http.post('/index/Medal/synthesisList', data)
}

// 获取合成需要数量
export const synthesis = (data) => {
  return http.post('/index/Medal/synthesis', data)
}
// 合成记录
export const synthesisLog = (data) => {
  return http.post('/index/Medal/synthesisLog', data)
}

// 获取活动详情
export const isGetActivity = (data) => {
  return http.post('activity/', data)
}
// 预约活动
export const subscribe = (data) => {
  return http.post('/act/activity/subscribe', data)
}
// 抽奖
export const goodLuck = (data) => {
  return http.post('/act/activity/good_luck', data)
}

// 预约人数列表
export const subscribeLog = (data) => {
  return http.get('/act/activity/subscribe_log', {
    params: data
  })
}
// 我的奖品
export const myPropLog = (data) => {
  return http.post('/act/activity/sacrifice_log', data)
}
// 邀请记录
export const myInviteLog = (data) => {
  return http.post('/act/activity/my_invite_log', data)
}
// 获取全局配置
export const getGlobal = (data) => {
  return http.post('/act/config/global', data)
}
// 活动页埋点
export const userCreate = (data) => {
  return http.post('/act/statistics/create', data)
}
