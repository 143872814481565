<template>
  <div>
    <div class="head">
      <div class="back">
        <van-icon name="arrow-left" class="white" size="18" @click="back"/>
      </div>
      <div class="head-title">
        <div>活动规则</div>
      </div>
    </div>
    <div class="texts">
一、时间
<p></p>
活动时间：8月2日20:00—8月7日20:00
<p></p>
活动结果公布时间：8月7日晚20:00
<p></p>
版权使用时间：2022年8月8日00:00-2023年2月4日00:00
<p></p>
 
二、参与方式
<p></p>

所有持有李时珍头像数字艺术藏品的用户均可在轻松小镇App活动页面点击参与征集参加本次活动，并且填写自己所持有的藏品的征集信息。用户参与征集以后可以分享自己的邀请助力海报邀请好友帮忙助力投票，活动期间，每人每天可以投票1次（温馨提示：只有通过扫描个人分享海报并且完成投票才能增加助力票数，且参与用户不能为自己助力）。
<p></p>
 
三、入选机制
<p></p>

获得更多好友投票的用户入选的几率越高，助力值排名前20名用户将直接获得本次版权使用资格，其余用户随机抽取10位获得本次版权使用资格。所有授权李时珍头像数字艺术藏品的形象的用户将会获得由倍轻松及轻松小镇提供的授权收益。
<p></p>
 
四、特别说明
<p></p>

1、本次征集的李时珍头像数字艺术藏品版权使用周期为180天，在版权使用周期内，成功被征用的30个李时珍头像数字艺术藏品使用权归授权品牌方（倍轻松X轻松小镇）所有，该30位用户须与轻松小镇签订授权协议。
<p></p>
 
2、本次征集的李时珍头像数字艺术藏品版权使用周期为180天，租赁给平台的藏品将直接冻结180天（用户不能进行交易、不能转移，不得出现账号注销、倒卖的情况，如果出现直接倒卖账号行为，平台会追溯该用户身份法律责任）。
<p></p>
 
3、实物奖品将在李时珍数字艺术IP联名款姜小竹礼品盒出厂后及元气系统上线后统一发放，具体时间待平台通知；
<p></p>
 
4、在活动结束后好友投票数如有并列，将根据并列用户藏品持有数量（除艾草胸针外的所有藏品总数）进行二次排名，藏品持有数量越高的人排名越靠前。
<p></p>
 
本活动最终解释权归轻松小镇和李时珍数字艺术俱乐部所有。
 <p></p>
    </div>
  </div>
</template>
<script>
  export default {
    name: "lszSolicitationHdgz",
    created() {
    },
    beforeCreate () {
      document.querySelector('body').setAttribute('style', 'background-color:#000000')
    },
    methods: {
      back() {
        this.$router.back();
      },
    },
  };
</script>
<style></style>
<style scoped lang="scss">
  .texts{
    color: #878689;
    line-height: 20px;
    padding: 20px;
    div{
      font-size: 12px!important;
    }
  }
  .head {
    background-size: 100% 100%;
    position: relative;
    padding: 28px;
    display: flex;
    align-items: center;
    background: #000000;
    .back {
      width: 27px;
      height: 27px;
      background: rgba(0, 0, 0, 0.48);
      border-radius: 50%;
      position: fixed;
      left: 13px;
      top: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    .head-title {
      color: #ffffff;
      text-align: center;
      font-size: 15px !important;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
