<template>
  <div class="p-30 protocol">
    <nav-bar title="会员购平台用户服务协议" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
      @click-left="onClickLeft" />
    <div class="f-17 bold text-center">会员购平台用户服务协议</div>
    <div>
      更新日期：
      <br/>生效日期：
      <br/>欢迎您与元气当铺（下称“元气当铺”或“我们”）共同签署本《元气当铺平台用户服务协议》（下称“本协议”）并使用元气当铺平台服务！
      <br/>本协议为《元气当铺服务协议》修订版本，自本协议发布之日起，元气当铺平台各处所称“元气当铺服务协议”均指本协议。
      <br/>请您在使用元气当铺服务前阅读并充分理解本协议，特别是与您的权益（可能）存在重大关系的条款（包括免除元气当铺责任的条款、限制您权利的条款、争议解决条款等），会员购已采用字体加粗的方式来特别提醒您，请您留意重点查阅。
      <br/>元气当铺可能会根据国家法律法规、政策调整，或业务整体规划等，对元气当铺及/或本协议进行修改或变更。我们将以适当的方式（包括但不限于页面提示、弹窗、站内消息、网站公告等）提醒您，以便您及时了解本协议的最新版本。若您不同意前述修改或变更，请您立即停止使用元气当铺服务。您继续使用元气当铺服务即视为您接受并同意本协议所有条款，包括但不限于前述修改及变更。
      <br/>请您在勾选同意本协议前谨慎阅读并理解相关内容，如您勾选同意即视为您自此发生的交易均受该协议约束，包括但不限于前述修改及变更。
      <br/>您确认您具备完全民事权利能力和完全民事行为能力，有能力同意并遵守本协议，并对您本协议项下的全部行为独立承担法律责任。若您不具备前述与您行为相适应的民事行为能力，则应获得法定监护人的知情同意。如您尚未成年，请在法定监护人的陪同下阅读和判断是否同意本协议。
      <br/>特别提示：由于您使用的软件版本、设备、操作系统等不同以及第三方原因可能导致您实际可使用的具体服务有差别，由此可能给您带来的不便，您表示理解，并不会因此向会员购提出任何主张或追究元气当铺及其关联公司任何责任。
      <br/>一、协议范围 
      <br/>1.1 签约主体
      <br/>本协议由您与元气当铺共同缔结，本协议对您与会员购均具有合同效力。
      <br/>元气当铺是指经营会员购平台的各法律主体，您可随时查看元气当铺平台网站公示的证照信息以确定与您履约的元气当铺主体。本协议项下，元气当铺主体还有可能因为提供新的服务而新增，如您使用新增的元气当铺服务的，视为您同意新增的元气当铺主体与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。
      <br/>1.2 完整协议
      <br/>本协议基于《轻松小镇用户使用协议》《轻松小镇隐私政策》以及轻松小镇的相关协议规范（统称为“轻松小镇服务条款”）制定；同时，元气当铺不时发布的关于元气当铺的相关协议、规则、公告、说明等各类规则也是本协议的一部分；您在使用元气当铺服务的同时应遵守本协议、轻松小镇服务条款及前述相关协议、规则等所有条款。
      <br/>二、会员购平台服务及规范
      <br/>2.1服务概况
      <br/>您有权在元气当铺平台上享受商品及/或服务的浏览与收藏、购买与评价、交易争议处理、信息交流如问答及分享等服务。元气当铺提供的服务内容众多，具体您可登录元气当铺平台浏览。
      <br/>2.2 商品及/或服务的浏览与收藏
      <br/>在您浏览我们网站或客户端的过程中，会员购为您提供了信息分类、关键词检索、筛选等功能，以更好地匹配您的需求。
      <br/>商品价格、数量、介绍、是否有货等商品信息由元气当铺维护，并随时都有可能发生变动，任何变动元气当铺不作特别通知，请您至商品页面自行查看。元气当铺会尽最大努力保证您所浏览的商品信息的准确性；但由于商品种类繁多、商品信息的数量较大以及互联网技术因素等客观原因，商品信息页面显示可能会有一定的滞后性或差错，您对此表示知悉并理解。
      <br/>2.3 商品及/或服务的购买
      <br/>当您在元气当铺平台购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。您提交订单即表示对订单中所确认的订购商品、收货信息等内容的准确性负责。如果因为您填写的收货人姓名、联系电话、收货地址等信息错误，导致延期配送、不能配送或商品交付给非您本意的收货人的，由此造成的损失需由您自行承担；因此造成任何损失或增加费用的，应由您完全独自承担。
      <br/>当您购买目前或将来提供的特殊品类商品（包括但不限于预售类商品、盲盒、票务等）时，本协议未涉及的或该特殊品类商品展示页中另有规定的（以下称“特殊条款”），从其规定。如果本协议与特殊条款有不一致之处，以特殊条款为准。
      <br/>您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、扰乱元气当铺平台正常交易秩序的行为。基于维护元气当铺平台交易秩序及交易安全的需要，元气当铺发现上述情形时可主动执行关闭相关交易订单等操作。
      <br/>2.4商品及/或服务订单的生效
      <br/>元气当铺上我司提供商品和价格等商品信息仅仅作为要约邀请。您下单时须写明订单信息内容，系统生成的订单信息是计算机信息系统根据您填写的内容和操作自动生成的数据，作为您向我司发出的合同要约。您付款后，即视为您与我司之间的合同成立。如果您在一份订单里订购了多种商品，但仅就部分商品支付价款，则我司和您之间仅就该部分商品成立合同关系。您未能在指定时间完成付款的，元气当铺有权协助我司取消订单。
      <br/>如因系统故障或元气当铺的过失导致显示信息明显不合理的（包括但不限于商品价格明显偏低等情形），请勿进行下一步操作并立即通知元气当铺进行修改，如您明知显示信息明显不合理仍然提交订单的，元气当铺有权在法律允许的最大限度内取消错误订单并及时通知您。
      <br/>2.5商品及/或服务的支付优惠
      <br/>2.5.1您可以在个人账户中查看优惠券种类、数量、有效期限及使用状态等信息。请您在有效期到期前使用。优惠券、满减规则等的使用限制以页面展示为准。
      <br/>2.5.2若订单使用了优惠券，当一笔订单中包含多个符合使用优惠券条件的商品时，元气当铺将按商品售价占订单总金额的比例计算单个商品优惠金额，抵扣到每个符合条件的商品金额中，若订单部分退款，将退还该退货商品的实付金额。
      <br/>2.5.3当使用优惠券的订单发生退货时，在所有商品退款完成后系统会自动返还相应的优惠券，且仅支持返还有效期内的优惠券；若订单部分退款，优惠券将不予返还。
      <br/>2.6商品及/或服务的交付
      <br/>用户付款并填写真实的收货人姓名、有效联系电话、收货地址是发货的前提。
      <br/>2.6.1您知悉并理解，现货商品的发货时间为参考时间，该参考时间可能根据库存状况、送货时间、送货地点等客观因素存在误差，具体发货时间以会员购实际发出时间为准。
      <br/>2.6.2您同样知悉并理解，预售类商品页列出的“预计到货时间”“发货时间”亦为参考时间，可能因受制作周期、物流周期、质检返工等诸多因素影响存在误差，导致预售类商品的实际发货时间可能延迟，您对上述情形表示同意及理解，具体发货时间以商品实际发货时间为准。
      <br/>2.6.3商品的可配送区域为中国大陆地区（除特殊偏远地区），收件地址在此之外的区域请与客服人员核实可配送后再下单。
      <br/>2.6.4运费视配送地址不同可能不同，具体以订单支付页面为准。
      <br/>2.6.5在签收商品时，请您本人或您指定的收件人在不拆封商品包装的情况下，或因检查商品的必要对商品进行拆封查验且不影响商品完好的情况下，在快递人员前当面验货，确认无误后再签收。若您或上述收件人委托他人签收商品或承运人已按您或收件人指示将商品置于指定地点的，视为本人签收。
      <br/>2.7商品及/或服务的购买评价
      <br/>您有权在元气当铺提供的评价系统中对与您达成交易的商品进行评价。您应当理解，您在元气当铺的评价信息是公开的。您的所有评价行为应遵守本协议及元气当铺服务条款，评价内容应当客观真实，不应包含任何污言秽语、色情低俗、广告信息及法律法规与轻松小镇服务条款列明之其他禁止性信息；您不应以不正当方式利用评价权利对元气当铺销售商或其他用户实施威胁、敲诈勒索。元气当铺可对您实施上述行为所产生的评价信息进行删除或屏蔽。
      <br/>2.8 交易争议处理
      <br/>2.8.1如您依据元气当铺平台规则使用元气当铺平台的争议调解服务，则表示您认可并愿意履行元气当铺根据其所了解到的争议事实并依据元气当铺平台规则所作出的调解决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。在元气当铺平台调解决定作出前，您可选择上述途径解决争议以中止元气当铺平台的争议调处服务。如您对调解决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调解决定。
      <br/>2.9信息交流
      <br/>为满足用户间分享购物心得等信息交流需求，您可通过元气当铺提供的问答、晒单分享、论坛、群组及其他信息发布功能公开发布信息及与其他用户互动，信息的形式包括文字、图片、视频、直播内容等，您须确保您所发布的所有信息均符合相关法律法规及本协议的要求。
      <br/>三、用户信息的保护及授权
      <br/>为了更好的为您提供服务，元气当铺会记录您在选购商品过程中在线填写的所有信息。若有需要可提供给相关服务提供方，我们向您承诺，我们会以最大努力保障您的个人信息安全，严格要求相关服务提供方对您的个人信息保密，不得对外泄露或做其他任何用途。同时，我们将根据《轻松小镇隐私政策》保护您的个人信息。
      <br/> 如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。
      <br/>四、其他
      <br/>4.1除非另有证明，储存在元气当铺服务器上的数据是您使用元气当铺服务的唯一有效证据。
      <br/>4.2若您存在以下行为，一经发现，我们有权采取包括但不限于暂停发货、取消订单、拦截已发货的订单、限制账户部分或全部权限、封禁账号等措施：
      <br/>4.2.1将自有账户内的优惠、促销信息转卖、转让予他人；
      <br/>4.2.2通过元气当铺及其合作商的合法活动页面之外的第三方交易渠道获得优惠券并在元气当铺进行使用；
      <br/>4.2.3在元气当铺恶意批量刷取优惠券等并在元气当铺使用；
      <br/>4.2.4利用技术手段或其他方式在会员购套取商品、优惠券、元气、运费或者其他利益；
      <br/>4.2.5同一用户重复参与促销活动，“同一用户”指使用同一账号、同一手机号、同一设备、同一身份证、同一支付账号、或同一收货地址等的用户，其中任意一项或数项存在相同、相似、非真实有效、通过特定标记形成关联，或元气当铺有合理理由认为存在关联的，都视为同一用户；
      <br/>4.2.6自行或委托他人通过机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序或技术手段参与促销活动；
      <br/>4.2.7以非出于个人消费使用的恶意目的拍下商品，并在拍下后恶意申请退款、恶意申请售后等，除本协议另有约定外，元气当铺不予退款；
      <br/>4.2.8经举报或元气当铺有合理理由认为账号从事二次销售行为的；
      <br/>4.2.9冒用他人的联系电话、收货地址进行提交订单的；
      <br/>4.2.10收货人称商品非本人订购、未委托他人订购且未接受他人为自己订购；
      <br/>4.2.11扰乱正常交易秩序的行为，包括但不限于：
      <br/>（1）短时间内大量购买低价或促销商品；
      <br/>（2）使用不同的买家账号大量购买商品；
      <br/>（3）超出正常交易习惯，频繁拒绝支付尾款、拒收商品、拒绝接受服务或频繁提出退换货等售后要求的；
      <br/>（4）采取欺诈手段退换货的，如售后退货掉包等；
      <br/>（5）组织或参与虚假交易的；
      <br/>（6）购买大量商品且用不合理理由要挟、恐吓、敲诈元气当铺及我司，影响正常交易的行为。
      <br/>4.2.12元气当铺认定的其他不正当行为、舞弊行为和恶意行为。
      <br/>4.2.13为确保您合法权益，对于平台识别出的我司可能存在危及用户合法权益的情形，您同意平台有权取消相关商品订单。 您确认并同意，前述措施将同样适用于您的关联账号。
      <br/>五、责任限制
      <br/>5.1您知悉并同意，会员购不因下述任一情况而可能导致的任何损害赔偿承担责任，包括但不限于财产、收益、数据资料等方面的损失或其它无形损失：
      <br/>（1）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致系统或服务不能正常运作；
      <br/>（2）由于黑客攻击、电信部门技术调整或故障、系统维护等原因而造成的系统服务中断或者延迟；
      <br/>（3）由于政府命令、法律法规的变更、司法机关及行政机关的命令、裁定等原因而导致的系统服务中断、终止或延迟；
      <br/>（4）由于您将账号、密码告知他人或未保管好自己的密码或与他人共享账号或任何其他非会员购的过错，导致您的个人资料泄露；
      <br/>（5）由于与元气当铺链接或合作的其它网站所造成的银行账户信息、身份信息泄露及由此而导致的任何法律争议和后果；
      <br/>（6）您（包括未成年人用户）向元气当铺提供错误、不完整、不实信息等，造成不能使用元气或遭受任何其他损失；
      <br/>（7）如因系统维护或升级的需要而需暂停服务时，我们将尽可能事先进行通知。对于服务的中断或终止而给您造成的任何损失，我们无须对您或任何第三方承担任何责任。
      <br/>5.2您理解元气当铺对您的任何请求采取行动均需要合理时间，且元气当铺应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除元气当铺存在法定过错外，会员购不承担责任。
      <br/>5.3元气当铺仅向您提供会员购平台服务，您了解元气当铺平台上的信息系销售商或用户自行发布，且可能存在风险和瑕疵。元气当铺将通过依法建立相关检查监控制度尽可能保障您在元气当铺平台上的合法权益及良好体验。同时，鉴于元气当铺平台具备存在海量信息及信息网络环境下信息与实物相分离的特点，元气当铺无法逐一审查商品及/或服务的信息，无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。
      <br/>5.4您理解并同意，在争议调处服务中，元气当铺平台工作人员并非专业调解人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意或重大过失外，调处方对争议调处决定免责。
      <br/>六、通知
      <br/>6.1为更好地为您提供服务，您同意接受我们向您发送信息，包括但不限于在元气当铺发布公告，向您发送电子邮件，向您提供的联系电话发送手机短信、拨打电话，向您的轻松小镇账号发送站内消息以及系统消息，向您提供的地址邮寄书面通知等。如果元气当铺能够证明以上述形式的信息已经发送给您或者已在相关页面公布，将视为您已收到相关信息。以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
      <br/>6.2为更好地向您提供服务，元气当铺可能通过站内消息、系统消息、手机短信、电话等方式向您发送订单信息、促销活动信息等。如您同意本协议即视为您已默示同意接受此项服务。
      <br/>七、法律适用与管辖
      <br/>7.1.元气当铺不行使、未能及时行使或者未充分行使本协议或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响元气当铺在将来行使该权利。
      <br/>7.2本协议的订立、效力、解释、履行、修改和终止以及争议的解决适用中华人民共和国法律。如就本协议内容或其执行发生任何争议，应尽量通过友好协商的方式加以解决。如一方向另一方发出要求协商解决的书面通知后30天内争议仍未解决，则争议双方均一致同意提交深圳仲裁委员会按照其仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。
      <br/>附件一：元气当铺自营售后服务政策
      <br/>【总则】
      <br/>一、由于元气当铺商品的特殊性，所以目前元气当铺的所有商品不接受退换货，如给您带来不便，请您谅解
      <br/>二、您承诺，仅为个人出于非商业目的使用元气当铺服务,仅为个人合理自用购买元气当铺所有商品，包括但不限于预售商品、数字藏品商品等，购买后不会将该等商品进行二次销售。
      <br/> 三、关于商品缺货的处理
      <br/> 由于市场变化、供应商生产计划变化、系统问题、不可抗力等因素影响，商品可能出现缺货情形。在此情形下，元气当铺将尽快通知您并提供解决方案（包括但不限于取消订单、换货、调货等方式）。通知方式包括但不限于在元气当铺发布公告、发送邮件、向您提供的联系电话发送手机短信、向您的轻松小镇账号发送消息或系统通知等。请您在未收到商品之前留意通知。您也可以随时通过客服与我们联系，由此给您带来的不便敬请谅解。
      <br/> 若商品缺货，在首次通知您之日起的第7个工作日，元气当铺有权取消订单，退还您购买该商品时实际支付金额，并以优惠券的方式给您适当补偿，由此给您带来的不便敬请谅解。缺货商品是否补货不再另行通知，如您对此商品感兴趣，请您随时关注。
    </div>
  </div>
</template>

<script>
import { NavBar } from "vant";

export default {
  name: 'Protocol',
  components: { NavBar },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  }
}
</script>

<style scoped lang="scss">
/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.protocol {
  position: relative;
  padding-top: 50px;
}
</style>
