// 睡眠活动
import http from './http'


//早睡活动详情
export const info = (data) => {
  return http.post('/index/Early_Sleep/info', data)
}

//加入早睡详情
export const reserve = (data) => {
  return http.post('/index/Early_Sleep/reserve', data)
}

//排行榜
export const leaderboard = (data) => {
  return http.post('/index/Early_Sleep/leaderboard', data)
}

//中奖消息弹框
export const tips = (data) => {
  return http.post('/index/Early_Sleep/tips', data)
}

//邀请记录
export const invitation_log = (data) => {
  return http.post('/index/Early_Sleep/invitation_log', data)
}