
<template>
  <div>
    <van-popup
      v-model="showTypes"
      position="bottom"
      :style="{ height: !payType ? '85%' : '75%' }"
      :round="true"
      :safe-area-inset-bottom="true"
      :close-on-click-overlay="false"
    >
      <div v-if="!payType">
        <div class="payTop">
          <div class="close" @click="onClose()">
            <!-- <img src="../../../assets/img/yqMall/yq.png" alt="" /> -->
            <van-icon name="cross" size="17"/>
          </div>
          <div class="payText">请输入元气密码</div>
        </div>
        <div class="payCont">
          <div class="contName">支付给元气当铺</div>
          <div class="money">
            {{money}}
            <img
              class="moneyYq"
              src="../../../assets/img/yqMall/yq.png"
              alt=""
            />
          </div>
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :gutter="10"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          v-model="value"
          :show="showKeyboard"
          :hide-on-click-outside="false"
          @blur="showKeyboard = false"
        />
      </div>

      <div v-else>
        <div class="payLoading">
          <div class="contName">支付给元气当铺</div>
          <div class="money">
            {{money}}
            <img
              class="moneyYq"
              src="../../../assets/img/yqMall/yq.png"
              alt=""
            />
          </div>
        </div>
        <div class="payType">
          <img class="payImg" v-if="yqPayType" src="../../../assets/img/yqMall/yqPlay2.png" alt="" />
          <img class="payImg" v-else src="../../../assets/img/yqMall/yqPlay1.png" alt="" />
          <div v-if="!yqPayType">
            <div class="payFont payFontLoading">元气支付中</div>
            <div class="pay">
              <div class="dot1"></div>
              <div class="dot2"></div>
              <div class="dot3"></div>
            </div>
          </div>
          <div v-else>
            <div class="payFont payFontSuccess">支付成功</div>
            <div class="btm">
              <div class="btn" v-if="isWxPay"  @click="replaceGoTo(`yqMallPayOrder`)">下一步支付</div>
              <div class="btn" v-else @click="replaceGoTo('yqMallOrder')">完成支付</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import store from "../store";
import { pay_score } from "../../../api/yqMallApi";
import { Popup } from "vant";
import md5 from "js-md5";

export default {
  components: { Popup },
  props: {
    showType: { // （v-model）指定当前是否选中
      type: Boolean,
      default: true,
    },
    money: { // 金额展示
      type: [Number, String],
      default: null
    },
    order: { // 订单号
      type: [Number, String],
      default: null
    },
    isWxPay: { //是否需要微信支付
      type: Boolean,
      default: false,
    },
    price: { // 人民币金额展示
      type: [Number, String],
      default: null
    },
    time: { // 支付倒计时
      type: [Number, String],
      default: null
    },
  },
  watch: {
    value(value) {
      if (value.length === 6) {
        this.yqPay();
      }
    },
  },
  components: {},
  data() {
    return {
      value: "",
      showKeyboard: true,
      payType: false,
      yqPayType: false, //元气支付状态
      showTypes: this.showType
    };
  },

  computed: {},
  async mounted() {},
  methods: {
    //元气关闭
    onClose(){
      this.showTypes = false
      this.$emit('close', 'closePopup')
    },
    //元气支付
    async yqPay(){
      try {
        let code = md5(`qsxz_${this.value}`);
        console.log(412, this.order ,this.value)
        let res = await pay_score({
          code: code,
          order: this.order
        })
        this.payType = true;
        setTimeout(() => {
          this.yqPayType = true;
        }, 3000);
      } catch (error) {
          this.$toast(error.message || error.data);
          this.value = ''
      }
    },
    goTo(name) {
      this.$router.push({ path:name });
    },
    //页面栈不存入
    replaceGoTo(name){
      this.showTypes = false
      let url;
      if(name == 'yqMallPayOrder'){
        url = `yqMallPayOrder?money=${this.price}&order=${this.order}&time=${this.time}`
        this.$emit('close', 'yqMallPayOrder')
      } else {
        url = name
        this.$emit('close', 'closePopup')
      }
      this.$router.replace({ path:url });
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-password-input__security li {
  background-color: #f2f2f2;
}
/deep/.van-key__wrapper:nth-child(10){
  opacity: 0!important;
}
.payLoading {
  margin: 30px 0;
  .contName {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .money {
    display: flex;
    align-items: center;
    font-size: 30px;
    justify-content: center;
    font-weight: 700;

    img {
      width: 30px;
      height: 30px;
      margin-left: 5px;
    }
  }
}
.payType {
  text-align: center;
  .payImg {
    margin-bottom: 10px;
    width: 50px;
    height: 55px;
  }
  .btm {
    .btn {
      padding: 15px 20px;
      font-size: 16px;
      color: #ffffff;
      border-radius: 40px;
      background: #000000;
      width: 44%;
      text-align: center;
      margin: 7rem auto 0;
    }
  }
  .payFont {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .payFontLoading {
    color: #bfbfbf;
  }
  .payFontSuccess {
    color: #7bccfc;
  }
  .pay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.payTop {
  position: relative;
  margin: 20px 0 30px;
  .close {
    position: absolute;
    left: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .payText {
    text-align: center;
    font-size: 14px;
    color: #000;
    font-weight: 700;
  }
}
.payCont {
  margin-bottom: 30px;
  .contName {
    font-size: 15px;
    margin-bottom: 10px;
    text-align: center;
  }
  .money {
    display: flex;
    align-items: center;
    font-size: 32px;
    justify-content: center;
    font-weight: 700;
    img {
      width: 40px;
      height: 40px;
      margin-top: 5px;
    }
  }
}
.dot1,
.dot2,
.dot3 {
  background: #fff;
  width: 6px;
  height: 6px;
  border-color: black;
  border-radius: 50%;
  margin: 10px;
}

.dot1 {
  animation: jump 1.6s -0.32s linear infinite;
  background: #e5e5e5;
}
.dot2 {
  animation: jump 1.6s -0.16s linear infinite;
  background: #b3b3b3;
}
.dot3 {
  animation: jump 1.6s linear infinite;
  background: #808080;
}

@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
</style>
