<template>
  <div class="page-box">
    <!-- <nav-bar :title="detail.title" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
      @click-left="onClickLeft" /> -->
    <van-swipe class="my-swipe_1" indicator-color="white">
      <van-swipe-item v-for="(item, index) in detail.banner" :key="index">
        <img class="swipeBan_1" :src="item" alt="" />
      </van-swipe-item>
    </van-swipe>
    <div class="detailCont">
      <div class="contName">
        {{ detail.title }}
      </div>
      <div class="explain">{{ detail.introduce }}</div>
      <div class="money" v-if="simulatedDATA.difference[0]">
        <span v-if="simulatedDATA.difference[0].price[0].price != '0.00'">￥{{ simulatedDATA.difference[0].price[0].price
        }}</span>
        <span class="addIcon"
          v-if="simulatedDATA.difference[0].price[0].price != '0.00' && simulatedDATA.difference[0].price[0].score != '0'">
          + </span>
        <span class="moneyScore" v-if="simulatedDATA.difference[0].price[0].score != '0'">{{
          simulatedDATA.difference[0].price[0].score }}
          <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
        </span>
        <!-- <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" /> -->
      </div>
    </div>
    <div class="select">
      <div class="select_Stck">
        <div class="select_Name">库存：</div>
        <div class="select_Number">{{ skuSelectData.stock }}</div>
      </div>
      <div class="select_Stck spaceBetween" @click="show = true">
        <div class="select_Left">
          <div class="select_Name">已选：</div>
          <div class="select_Number">{{ skuSelectData.value }}</div>
        </div>
        <div class="select_Reft">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div class="richText" v-html="detaText(detail.text)"></div>
    <!-- <div class="btm_Btn">
      <div class="shoppinIcon" @click="goTo('yqMallShopping')">
        <van-icon size="26" name="shopping-cart-o" />
      </div>
      <div class="btn">
        <div class="cart btnClass" @click="onBtnType('1')">加入购物车</div>
        <div class="creOrder btnClass" @click="onBtnType('2')">提交订单</div>
      </div>
    </div> -->
    <van-popup v-model="show" round :safe-area-inset-bottom="true" position="bottom">
      <div class="van-sku-header van-hairline--bottom">
        <div class="van-image van-sku-header__img-wrap">
          <img :src="skuSelectData.image" class="van-image__img" style="object-fit: cover" />
        </div>
        <div class="van-sku-header__goods-info">
          <div class="sku_goods_name">
            <span class="">{{ skuSelectData.name }}</span>
          </div>
          <div class="sku_goods_price">
            <!-- <span class="sku_price_symbol">￥</span
            > -->
            <div class="sku_price_num" v-if="JSON.stringify(priceData) != '{}'">
              <span v-if="priceData.price != '0.00'">￥{{ priceData.price }}</span>
              <span v-if="priceData.price != '0.00' && priceData.score != '0'">+</span>
              <span v-if="priceData.score != '0'">{{ priceData.score }}
                <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
              </span>
            </div>
          </div>
          <div class="van-sku-header-item">
            <span class="van-sku__stock">剩余
              <span class="van-sku__stock-num">{{ skuSelectData.stock }}</span>
              件</span>
          </div>
          <div class="sku_header_item">
            已选
            {{ skuSelectData.value }}
          </div>
        </div>
      </div>
      <div class="van-sku-body" style="max-height: 467px">
        <div class="van-sku-group-container">
          <div class="van-sku-row van-hairline--bottom" v-for="(ProductItem, n) in simulatedDATA.specifications" :key="n">
            <div class="van-sku-row__title">{{ ProductItem.title }}</div>
            <span @click="specificationBtn(oItem.name, n, $event, index)" :class="[
              subIndex[n] == index ? 'van-sku-row__item--active' : '',
              oItem.isShow ? '' : 'disabled',
              'van-sku-row__item',
            ]" v-for="(oItem, index) in ProductItem.options" :key="index">
              <div class="van-sku-row__item-name">{{ oItem.name }}</div>
            </span>
          </div>
        </div>
        <div class="van-sku-stepper-stock">
          <div class="van-sku__stepper-title">购买数量</div>
          <div class="van-stepper">
            <van-stepper :max="skuSelectData.stock" v-model="value" />
          </div>
        </div>
        <div class="van-sku-group-container" v-if="skuSelectData.price">
          <div class="van-sku-row van-hairline--bottom" v-if="skuSelectData.price.length">
            <div class="van-sku-row__title">支付方式</div>
            <span v-for="(i, n) in skuSelectData.price" :key="n">
              <div :class="[
                i.isShow ? 'van-sku-row__item--active' : '',
                'van-sku-row__item',
              ]" @click="priceType(i, n)">
                <div class="van-sku-row__item-name">
                  <span v-if="i.price != '0.00'">￥{{ i.price }}</span>
                  <span v-if="i.price != '0.00' && i.score != '0'"> + </span>
                  <span v-if="i.score != '0'">{{ i.score }}
                    <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                  </span>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div class="van-sku-messages"></div>
      </div>
      <!-- <div class="van-sku-actions">
        <button @click="onPay()" class="van-button van-button--danger van-button--large">
          <div class="van-button__content">
            <span class="van-button__text">{{
              buttonType == 1 ? "加入购物车" : "立即购买"
            }}</span>
          </div>
        </button>
      </div> -->
    </van-popup>
  </div>
</template>

<script>
import { shop_spu, get_sku, join_cart } from "../../api/yqMallApi";
import { NavBar } from "vant";

export default {
  name: "detail",
  components: { NavBar },
  data() {
    return {
      detail: {}, //商品详情
      show: false,
      value: 1,
      skuSelectData: {}, //选择的sku
      simulatedDATA: {
        //后台返回的数据 多规格
        difference: [],
        specifications: [],
      },
      priceText: '',//价格展示
      selectArr: [], //存放被选中的值
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      skuData: {}, //sku接口数据
      id: "",
      buttonType: 1, //1 购物车  2 购买
      priceData: {}, //sku选择支付方式数据
    };
  },
  filters: {
    
  },
  mounted() {
    console.log(777, this.$route.query)
    if(this.$route.query.token){
      localStorage.setItem('token', this.$route.query.token)
    }
    this.id = this.$route.query.id;
    this.goodsDetail();
    this.goodsSku();
  },
  computed: {},
  created() { },
  methods: {
    detaText(value) {
      if(value){
        return value.replace(/<(\/)?p>/g, '\n');
      } else {
        return value
      }
    },
    async onPay() {
      if (!Object.keys(this.skuSelectData).length) {
        this.$toast.fail("请选择属性");
        return false;
      }
      if (JSON.stringify(this.priceData) == "{}") {
        this.$toast.fail("请选择支付类型");
        return false;
      }
      if (!this.skuSelectData.stock) {
        this.$toast("商品已售无，请选择其他类型商品~");
        return false;
      }
      if (this.buttonType == 1) {
        try {
          const res = await join_cart({
            spu_id: this.skuSelectData.spu_id,
            sku_id: this.skuSelectData.id,
            num: this.value,
            price_id: this.priceData.id
          });
          this.$toast("加入购物车成功");
          this.show = false;
        } catch (e) {
          this.$toast.fail("加入购物车失败");
          console.log(e);
        }
      } else {
        let data = {
          ...this.skuSelectData,
          num: this.value,
          spuData: this.detail,
          priceData: this.priceData,
        },
          name = "yqMallConfirmOrder";
        this.$router.push({
          name,
          query: { data: JSON.stringify(data), payType: "common", pageName: 'details' },
        });
      }
    },
    //按钮状态
    onBtnType(e) {
      this.buttonType = e;
      this.show = true;
    },
    //商品详情
    async goodsDetail() {
      try {
        const res = await shop_spu({
          spu_id: this.id,
        });
        this.detail = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    goTo(name) {
      this.$router.push({ name });
    },
    onClickLeft() {
      // this.$router.go(-1);
      this.$router.back()
    },

    //商品sku详情
    async goodsSku() {
      try {
        const res = await get_sku({
          spu_id: this.id,
        });
        this.skuData = res.data.data;
        this.simulatedDATA.specifications = this.skuData.options;
        this.simulatedDATA.difference = this.skuData.sku;
        // this.priceText = `￥${this.skuData.sku[0].price[0].price} + ${this.skuData.sku[0].price[0].score}`
        this.onPriceAddIsShow(this.skuData.sku[0]);
        for (let i = 0; i < this.simulatedDATA.difference.length; i++) {
          this.simulatedDATA.difference[i].value =
            this.simulatedDATA.difference[i].value.join();
        }
        var self = this;
        for (var i in self.simulatedDATA.difference) {
          self.shopItemInfo[self.simulatedDATA.difference[i].value] =
            self.simulatedDATA.difference[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
        }
        self.checkItem();
      } catch (e) {
        console.log(e);
      }
    },
    //支付方式选择
    priceType(item, index) {
      this.priceData = item;
      for (let i = 0; i < this.skuSelectData.price.length; i++) {
        this.skuSelectData.price[i].isShow = false;
      }
      this.skuSelectData.price[index].isShow = true;
      this.$forceUpdate();
    },
    specificationBtn: function (item, n, event, index) {
      var self = this;
      if (self.selectArr[n] != item) {
        self.selectArr[n] = item;
        self.subIndex[n] = index;
      } else {
        self.selectArr[n] = "";
        self.subIndex[n] = -1; //去掉选中的颜色
      }
      self.priceData = {}
      self.checkItem();
    },
    checkItem: function () {
      var self = this;
      var option = self.simulatedDATA.specifications;
      var result = []; //定义数组储存被选中的值
      for (var i in option) {
        result[i] = self.selectArr[i] ? self.selectArr[i] : "";
      }
      for (var i in option) {
        var last = result[i]; //把选中的值存放到字符串last去
        for (var k in option[i].options) {
          result[i] = option[i].options[k].name; //赋值，存在直接覆盖，不存在往里面添加name值

          option[i].options[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      if (this.shopItemInfo[result]) {
        this.onPriceAddIsShow(this.shopItemInfo[result]);
      }
      self.$forceUpdate(); //重绘
    },
    isMay: function (result) {
      for (var i in result) {
        if (result[i] == "") {
          return true; //如果数组里有为空的值，那直接返回true
        }
      }
      return this.shopItemInfo[result].stock == 0 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
    },
    onPriceAddIsShow(d) {
      let data = d;
      for (let i = 0; i < data.price.length; i++) {
        data.price[i].isShow = false;
      }
      this.skuSelectData = data;
    },
  },
};
</script>

<style lang="scss" space-around>
.richText{
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-top: -4px;
  }
}

</style>

<style scoped lang="scss">
/deep/ .van-sku-row__item-name .moneyYq {
  width: 15px;
  height: 15px;
}

/deep/ .van-swipe__indicator--active {
  border-radius: 10px;
  width: 15px;
}

/deep/.van-sku-actions .van-button--danger {
  background: #000;
  border-radius: 50px !important;
  padding: 21px 0;
}

/deep/.van-sku-actions .van-button {
  font-size: 18px !important;
}

/deep/.van-sku-row__item {
  background: #cdcdcd;
  color: #555555;
  padding: 0px 15px;
  border-radius: 23px;
}

/deep/ .van-sku-row__item--active {
  background: #535353;
  color: #fff;
  padding: 0px 15px;
  border-radius: 23px;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
}

/deep/.van-sku-header__img-wrap {
  width: 73px !important;
  height: 73px !important;
}
/deep/.van-sku-header-item{
  margin-top: 0px!important;
}
.sku_header_item{
  font-size: 10px;
  color: #808080;
}
.van-sku-row__item-name {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
}

.disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border-color: #ebeef5;
}

.sku_goods_name {
  span {
    font-size: 17px;
    
font-weight: bold;
  }
}

.van-sku-stepper-stock {
  display: flex;
  justify-content: space-between;
}

.sku_goods_price {
  font-size: 15px;

  .sku_price_num {
    display: flex !important;
      align-items: center;
    span {
      display: flex !important;
      align-items: center;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.page-box {
  background: #f7f7f7 !important;
}

.my-swipe_1 {
  .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    height: 375px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.detailCont {
  background: #fff;
  padding: 20px;
  margin-bottom: 6px;

  .contName {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 14px;
  }

  .explain {
    color: #808080;
    font-size: 14px;
    margin-bottom: 13px;
  }

  .money {
    display: flex;
    font-size: 19px;
    align-items: center;

    .addIcon {
      margin: 0px 5px;
    }

    .moneyScore {
      display: flex;
      align-items: center;
    }

    .moneyYq {
      width: 36px;
      height: 36px;
    }
  }
}

.select {
  background: #ffffff;
  padding: 21px 17px;
  margin-bottom: 10px;

  .select_Stck {
    display: flex;
    align-items: center;

    .select_Name {
      font-size: 14px;
      color: #808080;
    }

    .select_Number {
      font-size: 14px;
      color: #000000;
    }
  }

  .spaceBetween {
    justify-content: space-between;
  }

  .select_Left {
    display: flex;
    align-items: center;
  }

  .select_Stck:first-child {
    margin-bottom: 15px;
  }
}

.richText {
  // height: 200px;
  width: 100%;
  // margin-bottom: 70px;
}

.btm_Btn {
  display: flex;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  align-items: center;
  padding: 15px 13px;

  .shoppinIcon {
    margin-right: 5px;
  }

  .btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;

    .btnClass {
      width: 45%;
      border-radius: 50px;
      padding: 14px 0;
      font-size: 13px;
      text-align: center;
    }

    .cart {
      background: #ffffff;
      border: 1px solid #000000;
      color: #000;
      font-weight: 700;
    }

    .creOrder {
      background: #000000;
      border: 1px solid #000000;
      color: #fff;
      font-weight: 700;

    }
  }
}</style>
