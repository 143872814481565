<template>
  <div class="share">
    <van-overlay
      z-index="100"
      :show="show"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }"
    >
      <div class="heads">
        <div class="backs">
          <van-icon name="arrow-left" class="white" size="18" @click="back" />
        </div>
      </div>
      <van-loading
        type="circular"
        size="50"
        color="#FFFFFF"
        v-if="loadingIns"
        class="loding"
        vertical
      >
        海报生成中...
      </van-loading>
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="shareHtml" id="poster" ref="poster">
            <!-- <div class="bgks" v-if="type == 1">
              <div class="shareUser">
                <div class="userImg">
                  <img :src="user.avatar" alt="">
                </div>
                <div class="userName">
                  <div>李时珍数字艺术俱乐部 持有者</div>
                  <div class="name">{{user.nickname}}</div>
                </div>
              </div>
              <div class="sharePartner">
                <div class="partner">
                  我正在申请「轻松小镇 x 倍轻松」
                </div>
                <div class="partnerText">品牌合作</div>
              </div>
              <div class="textFonts">邀请您为我助力</div>
              <div class="collectionImg">
                <img :src="configure.nft_image" alt="">
              </div>
              <div class="qrCode">
                <div class="codeFont">
                  <div>扫描右边二维码</div>
                  <div>登陆后即可为我助力！</div>
                </div>
                <div class="qrs">
                  <vue-qr :text="url" class="qr-code" :margin="0" :logoScale="0.2" :size="50"></vue-qr>
                </div>
              </div>
            </div> -->
            <div class="bgkss">
              <img
                class="bgksInt"
                src="../../../assets/img/lszSolicitation/solictations/sharbG.jpg"
                alt=""
              />
              <div class="shareUser">
                <div class="userImg">
                  <img :src="user.avatar" alt="" />
                </div>
                <div class="userName">
                  <div class="name">{{ user.nickname }}</div>
                  <div>李时珍数字艺术俱乐部</div>
                  <div>#{{ configure.token_id }}藏品持有者</div>
                  <!-- 「{{ configure.ssr_type }}」 -->
                </div>
              </div>
              <div class="sharePartner">
                <div class="partner">
                  我正在申请<br />「数字中医宣传大使」x「李时珍头像版权IP共创活动」<br />「中国国际服务贸易交易会」藏品上墙活动
                </div>
                <!-- <div class="partner">版权使用权合作</div> -->
              </div>
              <div class="collectionImg">
                <img :src="configure.nft_image" alt="" />
              </div>
              <div class="collectionCont">邀请你为我助力，成功参与即可获奖</div>
              <div class="qrCode">
                <div class="codeFont">
                  <!-- <div>扫描右边二维码</div>
                  <div>登陆后即可为我助力！</div> -->
                </div>
                <div class="qrs">
                  <vue-qr
                    :text="url"
                    class="qr-code"
                    :margin="0"
                    :logoScale="0.2"
                    :size="50"
                  ></vue-qr>
                  <!-- <img src="" alt=""> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="img" class="poster_img">
        <img
          @touchstart="getTouchStart(img)"
          @touchend="getTouchEnd"
          :src="img"
          alt=""
        />
      </div>
      <div class="tips">长按图片转发或保存</div>
    </van-overlay>
  </div>
</template>

<script>
// import store from "../store";
import { configure } from "../../../api/solicitation";
import vueQr from "vue-qr";
import html2canvas from "html2canvas";
import { mapMutations, mapState } from "vuex";

export default {
  props: ["show", "collectionUrl"],

  components: {
    vueQr,
  },
  data() {
    return {
      img: "",
      url: "",
      configure: {},
      loadingIns: true,
      type: 2,
      longClick: 0,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  async mounted() {
    document.addEventListener("UniAppJSBridgeReady", () => {
      this.getTouchStart();
    });
    this.loadingIns = true;
    await this.onConfigure();
    this.createPoster();
  },
  methods: {
    getTouchStart(img) {
      this.timer = setTimeout(() => {
        uni.postMessage({
          data: {
            action: img,
            invitationCode: "",
          },
        });
      }, 1000);
    },
    getTouchEnd() {
      clearTimeout(this.timer);
    },
    back() {
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    //获取授权藏品图片
    async onConfigure() {
      try {
        const res = await configure({
          acctoken: this.isLogin,
        });
        this.configure = res.data;
        console.log(4444, this.configure);
      } catch (e) {
        this.onError(e);
      }
    },
    createPoster() {
      this.url = `${window.location.origin}/#/SolicitationHelpPage?invited_id=${this.user.id}`;
      setTimeout(() => {
        const dom = document.getElementById("poster");
        html2canvas(dom, {
          width: dom.offsetWidth, //设置canvas尺寸与所截图尺寸相同，防止白边
          height: dom.offsetHeight - 12, //防止白边
          backgroundColor: null, // null 表示设置背景为透明色
          useCORS: true, // 是否尝试使用CORS从服务器加载图像
          allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
          taintTest: true, // 是否在渲染前测试图片
          scale: 4, // dpr比列
          useCORS: true,
          scrollY: 0, // 截屏时页面滚动，造成截屏图片不全或空白
        })
          .then((canvas) => {
            const img = canvas.toDataURL("image/png");
            this.img = img;
            this.loadingIns = false;
          })
          .catch((e) => {
            console.log(e);
            // this.$toast.fail("??????");
          });
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heads {
  .backs {
    width: 27px;
    height: 27px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    position: fixed;
    left: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.share {
  .block {
    position: relative;
  }

  .shareHtml {
    position: absolute;
    top: -10000000px;
    // top: 0;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);

    .hedimg {
      width: 295px;
      height: 459px;
      // background: pink;
      border-radius: 5px 5px 0px 0px;
    }

    .bgkss {
      .bgksInt {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
      }

      .shareUser {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 70px;

        .userImg {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          color: #ffffff;
          font-size: 14px;

          .name {
            color: #ffffff;
            font-weight: 700;
            font-size: 15px;
          }
        }
      }

      .sharePartner {
        color: #ffffff;
        width: 93%;
        margin: auto;
        text-align: center;
        line-height: 20px;
        font-weight: 700;
        .partner {
          font-size: 11px;
          text-align: center;
        }
      }

      .collectionImg {
        width: 180px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px auto;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .collectionCont {
        margin: 10px auto 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 11px;
        text-align: center;
        // img{
        //   width: 26px;
        //   height: 15px;
        // }
      }

      .qrCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 20px 10px;

        .codeFont {
          color: #979999;

          div {
            font-size: 13px;
          }
        }

        .qrs {
          width: 60px;
          height: 60px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          .qr-code {
            width: 90%;
            height: 90%;
            background: #ffffff;
          }
        }
      }
    }

    .bgks {
      background: #000;
      border-radius: 10px;

      .shareUser {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;

        .userImg {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          color: #979999;
          font-size: 14px;

          .name {
            color: #ffffff;
            font-weight: 700;
            font-size: 15px;
          }
        }
      }

      .sharePartner {
        color: #ffffff;
        font-weight: 700;
        line-height: 25px;
        padding: 10px;

        div {
          font-size: 16px;
        }
      }

      .textFonts {
        color: #979999;
        font-size: 11px;
        padding: 10px;
      }

      .collectionImg {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .qrCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .codeFont {
          color: #979999;

          div {
            font-size: 13px;
          }
        }

        .qrs {
          width: 60px;
          height: 60px;
          border-radius: 10px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          .qr-code {
            width: 90%;
            height: 90%;
          }
        }
      }
    }
  }

  .tips {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .poster_img {
    height: 100vh;
    width: 100%;
    img {
      width: 310px;
      height: 560px;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
