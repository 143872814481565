<template>
  <div>
    <div class="head">
      <div class="back">
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div>
      <div class="head-title">
        <div>活动规则</div>
      </div>
    </div>
    <div class="texts">
      一、时间
      <p></p>
      活动时间：8月19日19:00—8月21日12:00
      <p></p>
      活动结果公布时间：8月22日12:00
      <p></p>
      版权使用时间：2022年8月22日12:00-2022年9月6日00:00
      <p></p>

      二、参与方式
      <p></p>

      所有持有李时珍头像数字艺术藏品的用户均可进入轻松小镇App，在【中国（北京）国际服务贸易交易会用户藏品展示征集活动】页面报名，填写自己所持有的藏品的信息并提交藏品。
      <p></p>
      用户参与活动后可以分享自己的邀请助力海报邀请好友帮忙助力投票。活动期间，每位好友每天可以参与投票1次（温馨提示：只有通过扫描个人分享海报并且完成投票才能增加助力票数，且参与用户不能为自己助力）。
      <p></p>
      所有参与助力的用户中将随机抽取500名用户（完成实名认证）获得「数字中医宣传大使专属纪念勋章」一枚。
      <p></p>

      三、入选机制
      <p></p>

      在活动期间获得好友助力投票越多，入选的几率越高。助力值排名前30名用户将直接获得本次服贸活动藏品上墙展示资格。助力值排名第31名——第1029名用户将直接获得本次服贸活动藏品以视频轮播的方式展示的资格。每位用户限提供一个李时珍头像数字艺术藏品。中奖名单将会在活动结束以后在轻松小镇App进行公布。（注：如发现用脚本/外挂等机器干预刷票的行为将直接取消该用户活动资格。）
      <p></p>

      四、特别说明
      <p></p>

      1、本次入选的李时珍头像数字艺术藏品版权使用周期为15天，在版权使用周期内，成功被征用的1029个李时珍头像数字艺术藏品使用权归被授权品牌方轻松小镇所有。该1029位用户须与轻松小镇签订授权协议。
      <p></p>

      2、本次入选的李时珍头像数字艺术藏品将直接冻结15天（用户不能进行交易、不能转移，不得出现账号注销、倒卖的情况，如果出现直接倒卖账号行为，平台会追溯该用户法律责任）。
      <p></p>

      3、在活动结束后好友投票数如有并列，将根据用户藏品持有数量（除艾草胸针以外的所有藏品总数）进行二次排名，藏品持有数量越多的用户排名越靠前。
      <p></p>

      本活动最终解释权归轻松小镇和李时珍数字艺术俱乐部所有。
      <p></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "lszSolicitationHdgz",
  created() {},
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#000000");
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>
<style></style>
<style scoped lang="scss">
.texts {
  color: #878689;
  line-height: 20px;
  padding: 20px;
  div {
    font-size: 12px !important;
  }
}
.head {
  background-size: 100% 100%;
  position: relative;
  padding: 28px;
  display: flex;
  align-items: center;
  background: #000000;
  .back {
    width: 27px;
    height: 27px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    position: fixed;
    left: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .head-title {
    color: #ffffff;
    text-align: center;
    font-size: 15px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
