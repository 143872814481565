<template>
  <div class="ben-rules white">
    <div class="head">
      <div class="back">
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div>
      <div class="head-title">
        <div class="f-23 m-b-10">活动规则</div>
        <div class="f-23">Rules</div>
      </div>
    </div>
    <div class="detail">
      <div class="detail-wrap">
        <div class="detail-wrap-p">
          <div class="circle f-16 m-b-35 hui">
            发行总量：<span class="bold white">10000</span>
          </div>
          <div class="circle f-16 m-b-25 hui">发行内容：</div>
          <div class="f-13 bold m-b-10">
            <img
              :src="require('../assets/img/number2.png')"
              width="12px"
              height="17px"
            />
            种普通款
          </div>
          <div class="f-11 hui hang-jian-ju m-b-15" style="text-align: justify">
            根据中国传统五行「金木水火土」发行<span class="bold white"
              >「本草纲目·金陵版」数字藏品</span
            >，每款2000个，本次共发行10000个，“金木水火土”5个属性平均分配。此外在不同发行方式中，每种发行方式的发行的藏品五行属性也将平均分配。
          </div>
          <div class="f-13 bold m-b-10 white">
            <img
              :src="require('../assets/img/number1.png')"
              width="9px"
              height="16px"
            />
            款终极合成款
          </div>
          <div class="f-11 hui hang-jian-ju m-b-20" style="text-align: justify">
            单个用户集齐「金木水火土」5种属性的「本草纲目·金陵版」数字藏品后，可选择合成熔铸
            <span class="bold white"
              >终极合成款「本草纲目·金陵版」数字藏品</span
            >
            ，熔铸合成后原5种属性藏品将自动销毁。终极合成款「本草纲目·金陵版」数字藏品本次无预先发行，仅可通过用户自行合成熔铸而成。
          </div>
          <div class="circle f-16 m-b-15 hui">发行方式：</div>
          <div class="f-11 hui hang-jian-ju m-b-15" style="text-align: justify">
            终极合成款仅可通过熔铸合成获得，本次仅发行普通款，具体发行方法如下：
          </div>
        </div>
        <van-divider
          dashed
          :style="{ borderColor: 'white', marginBottom: '25px', opacity: 0.5 }"
        />
        <div class="detail-wrap-p">
          <div class="f-13 bold m-b-15">公开发售</div>
          <div class="f-11 hang-jian-ju hui m-b-25" style="text-align: justify">
            其中6000个「本草纲目·金陵版」数字藏品用于公开发售，其中包括优先购买和正式抢购。其中金木水火土属性藏品各1200个。
          </div>
          <div class="f-11 t-bg text-center bold m-b-20">优先购</div>
          <div class="hui f-11 hang-jian-ju m-b-20" style="text-align: justify">
            1.
            299个持有姜小竹的用户、200个体验官、200个特殊用户可直接在2022年3月12日15:00参与提前抢购，每人限购1个。
          </div>
          <div class="hui f-11 hang-jian-ju m-b-25" style="text-align: justify">
            2、其他用户可在预约后参与「集草优先购」玩法获得优先抢购资格（2022年3月11日20:00公布名单），并在优先购时间内优先购买。「集草优先购」玩法中将内置1301个「藏品优先抢购」资格，并在参与活动的用户中随机抽取发放这部分优先抢购资格。用户预约后即可获赠艾草，也可通过邀请好友获得更多艾草，收集艾草越多的用户中签概率越高。本次邀请好友获得艾草数量没有上限。
          </div>
          <div class="f-11 t-bg text-center bold m-b-20">正式发售</div>
          <div class="hui f-11 hang-jian-ju m-b-20" style="text-align: justify">
            没有获得优先购买资格的用户可通过正式发售抢购本次藏品，正式发售数量为4000个。<br />
            本轮发售总量为4000个。本批发售面向所有参与藏品预约的用户。所有参与预约的用户可在2022
            3.16
            15:00后登陆APP凭手速参与抢购4000个本草纲目金陵版数字藏品盲盒，先到先得，售完即止。
          </div>
        </div>
        <van-divider
          dashed
          :style="{ borderColor: 'white', marginBottom: '25px', opacity: 0.5 }"
        />
        <div class="detail-wrap-p">
          <div class="f-13 bold m-b-15">种子空投</div>
          <div class="hui f-11 hang-jian-ju m-b-20" style="text-align: justify">
            其中1500个「本草纲目·金陵版」数字藏品将与轻松小镇已开始发售的「创世艾草种子」绑定发行。持有种子用户满足条件用户将通知获得一个藏品盲盒空投（空投规则后续公众号会公示），需要登录APP领取获得。用户开启盲盒即有概率获得本次藏品。
          </div>
        </div>
        <van-divider
          dashed
          :style="{ borderColor: 'white', marginBottom: '25px', opacity: 0.5 }"
        />
        <div class="detail-wrap-p">
          <div class="f-13 bold m-b-15">硬件盲盒</div>
          <div class="hui f-11 hang-jian-ju m-b-20" style="text-align: justify">
            其中2000个「本草纲目·金陵版」数字藏品将与后期轻松小镇硬件发行绑定，用户购买硬件将附赠藏品盲盒，开启硬件盲盒可获得本次藏品。
          </div>
        </div>
        <van-divider
          dashed
          :style="{ borderColor: 'white', marginBottom: '25px', opacity: 0.5 }"
        />
        <div class="detail-wrap-p">
          <div class="f-13 bold m-b-15">官方预留</div>
          <div class="hui f-11 hang-jian-ju" style="text-align: justify">
            其中500个「本草纲目·金陵版」数字藏品由官方预先保留（用于未来小镇赠送重要合作伙伴及重要贡献者，其中200个赠予本次版权方中国中医科学院）。
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="zhu f-11 hang-jian-ju m-b-30" style="text-align: justify">
        注：<br />
        1、本次藏品为纪念本草纲目与李时珍发行，希望借此推动我国中医药文化在新时代传播与发展，平台坚决抵制将藏品用于炒作等非正当用途。
        <br />
        2、本次藏品使用百度超级链发行，并将在上线后开启亲友转赠功能，以下为转赠玩法相关说明
        <br />
        •
        小镇用户本草纲目金陵版数字藏品在正式发售后将开放转赠功能，用户在转赠的时候需要消耗转赠符。
        <br />
        •
        转赠符为轻松小镇的消耗型道具，需要使用种子或元气兑换，本阶段1枚种子即可兑换1张转赠卡，兑换后种子会直接销毁。为防止恶意炒作，转赠符会限量逐步释放，种子或元气兑换转赠符的比例将随总体种子发行数量、转赠情况动态调整。
        <br />
        • 转赠间隔时间：
        所有用户默认在购买并持有某藏品45天后方可使用种子兑换转赠符。<br />
        种子持有量超过20枚的用户，在首次购买并持有藏品7天后，即可消耗1包种子提前解锁兑换转赠符。<br />
        种子持有量超过10枚的用户，在首次购买并持有藏品15天后，即可消耗1包种子提前解锁兑换转赠符。<br />
        单个藏品首次转赠后，后续持有用户转赠该藏品同样需要持有超过45天后方可转赠。<br />
        注释：首次购买持有某藏品指该用户通过公开发售或空投方式首次获得该藏品，若藏品为他人赠送则不视为首次购买持有，需持有45天后方可转赠，默认持有超过45天后即可拥有转赠权限（转赠需要消耗转赠符）。
        <br />
      </div>
      <div class="detail-wrap-p">
        <div class="circle f-16 m-b-25 hui">创意玩法：</div>
        <div class="hui f-11 hang-jian-ju m-b-20" style="text-align: justify">
          本次藏品发行中，轻松小镇创造性提出「用户共创算法铸造」概念，让藏品稀缺性由用户数据主导完全随机，将获得藏品的用户信息用于藏品铸造过程中，打造真正的「专属纪念藏品」。
        </div>
        <div class="hui f-11 hang-jian-ju m-b-30" style="text-align: justify">
          本次藏品设计中，在算法层次通过以下3个纬度打造「本草纲目·金陵版」数字藏品幸运值与专属性。<br />
          1、编号：每一个藏品将1-10000以此拥有不同的编号。<br />
          2、五行：金木水火土5种不同属性藏品随机发放。<br />
          3、生肖：藏品将根据用户提交的生辰信息生成用户专属的生肖并体现在藏品中。
        </div>
        <div class="text-center m-b-25">
          <img
            :src="require('../assets/img/kefu.png')"
            width="100%"
            height="100%"
            class="group"
          />
        </div>
        <div class="tip text-center f-13">
          有任何疑问 欢迎第一时间来小镇咨询
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWxConfig } from "../../tool";

export default {
  name: "BRules",
  created() {
    getWxConfig(
      "《本草纲目》金陵版数字藏品盲盒预约开启",
      "点击链接预约我国首个中医药文化古籍文物藏品，一起入驻轻松小镇～"
    );
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.ben-rules {
  background-color: black;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .hui {
    color: rgba(255, 255, 255, 0.8);
  }
  .hang-jian-ju {
    line-height: 18px;
  }
  .head {
    height: 377px;
    background: url("../assets/img/b-r-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding: 28px;
    .back {
      width: 27px;
      height: 27px;
      background: rgba(0, 0, 0, 0.48);
      border-radius: 50%;
      position: fixed;
      left: 13px;
      top: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }
    .head-title {
      padding-left: 15px;
      border-left: 2px solid #ffd099;
      margin-top: 140px;
    }
  }
  .detail {
    padding: 17px;
    margin-top: -130px;
    position: relative;
    .detail-wrap {
      background-color: black;
      border-radius: 10px;
      padding: 35px 0px;
      .detail-wrap-p {
        padding-left: 28px;
        padding-right: 28px;
      }
      .circle {
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          background: #ffffff;
          border-radius: 50%;
          position: absolute;
          left: -15px;
          top: calc(50% - 2.5px);
        }
      }
      .t-bg {
        width: 68px;
        border-radius: 2px;
        padding: 3px;
        background: url("../assets/img/b-r-t-bg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .footer {
    margin: -10px 17px 0;
    padding-bottom: 20px;
    background: url("../assets/img/b-r-footer-bg.png") no-repeat;
    background-size: 100% 100%;
    .zhu {
      background: rgba(255, 255, 255, 0.16);
      padding: 15px 13px;
      border-radius: 7px;
    }
    .detail-wrap-p {
      padding-left: 28px;
      padding-right: 28px;
    }
    .circle {
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background: #ffffff;
        border-radius: 50%;
        position: absolute;
        left: -15px;
        top: calc(50% - 2.5px);
      }
    }
    .group {
      width: 118px;
      height: 118px;
      background: #ffffff;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.45);
      border-radius: 7px;
      display: inline-block;
      padding: 7px;
    }
    .tip {
      width: 268px;
      height: 29px;
      line-height: 29px;
      background: rgba(255, 255, 255, 0.23);
      border: 1px solid white;
      background-image: linear-gradient(173deg, #ffffff, #f9f6f7, #ffffff) 1 1;
      border-radius: 15px;
      margin: 0 auto 25px;
    }
  }
}
</style>
