<template>
  <div class="page-box">
    <nav-bar title="确认订单" :fixed="true" :placeholder="true" :safe-area-inset-top="true" left-arrow
      @click-left="onClickLeft" />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="orderPage">
        <div class="address">
          <div class="noAddress" v-if="addressType" @click="goTo('yqMallAddressList')">
            <div class="no_Add">
              <div class="noIcon">+</div>
              <div class="add">添加收货地址</div>
            </div>
          </div>
          <div @click="goTo('yqMallAddressList')" v-else class="sucAddress">
            <div class="addressCont">
              <div class="addIcon">
                <!-- <img src="../../assets/img/yqMall/yq.png" alt="坐标" /> -->
                <van-icon name="location-o" size="20" />
              </div>
              <div class="addessText">
                <div class="user">
                  <div class="name">{{ addressData.name }}</div>
                  <div class="phone">{{ addressData.phone }}</div>
                </div>
                <div class="coordinate">
                  {{ addressData.sheng }} {{ addressData.shi }}
                  {{ addressData.qu }}
                </div>
              </div>
            </div>
            <div class="rightIcon">
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
        <div class="detailOrder">
          <div class="commodity" v-if="payType == 'common'">
            <div class="commodityImg">
              <img :src="payData.image" alt="" />
            </div>
            <div class="commodityType">
              <div class="commodityName">{{ payData.spuData.title }}</div>
              <div class="commoditySelect">
                {{ payData.value }}
              </div>
              <div class="commodityMoney">
                <div class="money">
                  <span class="moneyPrice" v-if="payMoney.money != 0">￥{{ payMoney.money }}</span>
                  <span class="addIcon" v-if="payMoney.money != 0 && payMoney.vitality != 0">
                    +
                  </span>
                  <span class="moneyVitality" v-if="payMoney.vitality != 0">{{ payMoney.vitality }}
                    <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                  </span>
                </div>
                <div class="numer">x{{ payData.num }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="commodity" v-for="(item, index) in goodsList" :key="index">
              <div class="commodityImg">
                <img :src="item.sku_info.image" alt="" />
              </div>
              <div class="commodityType">
                <div class="commodityName">{{ item.spu_info.title }}</div>
                <div class="commoditySelect">{{ item.sku_info.name }}</div>
                <div class="commodityMoney">

                  <div class="money">
                    <span class="moneyPrice" v-if="item.sku_info.price.price != 0">￥{{ item.sku_info.price.price }}</span>
                    <span class="addIcon" v-if="item.sku_info.price.price != 0 &&
                      item.sku_info.price.score != 0
                      ">
                      +
                    </span>
                    <span class="moneyVitality" v-if="item.sku_info.price.score != 0">{{ item.sku_info.price.score }}
                      <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                    </span>
                  </div>
                  <div class="numer">x{{ item.num }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="line"></div>
          <div class="orderCont">
            <div class="orderText">
              <div class="left">配送方式</div>
              <div class="right">快递</div>
            </div>
            <div class="orderText written">
              <div class="left">买家留言</div>
              <div class="right">
                <input type="text" class="liuyanInput" maxlength="99" placeholder="留言" v-model="liuyanText" />
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="orderCont">
            <div class="orderText">
              <div class="left">运费</div>
              <div class="right">￥0</div>
            </div>
            <div class="orderText">
              <div class="left">商品金额</div>
              <div class="right">￥{{ payMoney.money }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="pay">
        <div class="payName">支付方式</div>
        <div class="payType">
          <div class="payList">
            $4300 + 1212
            <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
          </div>
          <div class="payList">
            $4300
            <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
          </div>
          <div class="payList">
            1212
            <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
          </div>
        </div>
      </div> -->
        <div class="agree">
          <div class="agreeName">
            同意<span @click="goTo('yqMallPayProtocol')">《元气商城平台用户服务协议》</span>
          </div>
          <div class="agreeBtn">
            <Switcher :defaultChecked="true" v-model="checked" :disabled="false" />
          </div>
        </div>
      </div>
    </van-pull-refresh>

    <div class="btm_b">
      <!-- <div class="btm_b_text">
        <div class="text_money">
          <span class="span1">合计：</span>
          <span class="span2">
            <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
            {{ payMoney.vitality }}</span>
        </div>
        <div class="text_yq">
          <span class="span1">&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span class="span2">￥{{ payMoney.money }}</span>
        </div>
      </div> -->
      <div class="btm_b_text">
          <div class="text_money">
            <span class="span1">合计:</span>
            <span class="span2">￥{{ payMoney.money }}</span>
            <span class="span2"> + </span>
            <span class="itemScore span2">{{ payMoney.vitality }}
              <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
            </span>
          </div>
        </div>
      <div class="btm_b_btn" @click="onPay()">提交订单</div>
      <!-- goTo('yqMallPayOrder') -->
    </div>
    <YqPay :money="payMoney.vitality" :order="order" :price="price" :time="orderData.create_time" :isWxPay="isWxPay"
      @close="onPopupClose($event)" v-if="showType" />
  </div>
</template>

<script>
import {
  get_top,
  cart_order,
  wxConfig,
  weixin_pay,
  getOpenidApi,
  getWeiUser,
  create_order,
  create_cart_order,
  getAll
} from "../../api/yqMallApi";
import Switcher from "./components/Switcher";
import YqPay from "./components/yqPay";
import { NavBar } from "vant";
import qs from "qs";
import wx from "weixin-js-sdk";
export default {
  name: "detail",
  components: { NavBar, Switcher, YqPay },
  data() {
    return {
      checked: true,
      addressType: true,
      showType: false,
      liuyanText: "", //卖家留言
      addressData: {}, //地址
      payData: {}, //支付详情
      payType: "", //下单类型  common 普通下单  shopping 购物车下单
      goodsIdList: [], //购物车下单列表ID
      goodsList: [], //购物车下单商品列表
      openid: localStorage.getItem("openid"), //微信用户openID
      payMoney: {
        //支付金额
        money: 0,
        vitality: 0,
      },
      priceData: {},
      isLoading: false,
      order: '', //订单号
      isWxPay: false,//是否微信支付
      isYqPay: false,//是否元气支付
      orderData: {} //下单订单数据
    };
  },
  //退出当页面后初始化地址和价格
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('addressData', '')
    this.payMoney.money = 0;
    this.payMoney.vitality = 0;
    next();
  },
  watch: {
    '$route'() {
      // 此处写router变化时，想要初始化或者是执行的方法......
      if (localStorage.getItem('addressData') != '') {
        this.onGetAll();
        this.addressData = JSON.parse(localStorage.getItem('addressData'))
        this.addressType = false
      } else {
        this.onGetTopAddress();
      }
      this.getData();
    }
  },
  computed: {},
  mounted() {
    this.getData();
    this.onGetTopAddress();
  },
  async created() {
    if (!localStorage.getItem("weiCode")) {
      // 静默授权
      // alert(1)
      // this.getAuthorize();
    } else if (localStorage.getItem("weiCode")) {
      // 将code传递给后端
      // alert(2)
      // try {
      //     alert(3)
      //     await this.getUserInfo();
      // } catch (err) {
      //   console.log(err);
      // }
    }
    // this.getList();
  },
  methods: {
    /**
     * 页面首次进入数据获取
     * @param {*} 
     */
    getData() {
      this.payType = this.$route.query.payType;
      if (this.payType) {
        if (this.payType == "common") {
          this.payData = JSON.parse(this.$route.query.data);
          console.log(1111, this.payData)
          //价格计算
          let money = Number(this.payData.priceData.price), //金额
            vitality = Number(this.payData.priceData.score); //元气
          if (money > 0) {
            money = money * this.payData.num;
          }
          if (vitality > 0) {
            vitality = vitality * this.payData.num;
          }
          this.payMoney.money = money;
          this.payMoney.vitality = vitality;
        } else {
          this.goodsIdList = JSON.parse(this.$route.query.data);
          this.onGetGoodsIdList();
        }
        this.getWxConfig();
      }
    },
    //获取地址列表
    async onGetAll() {
      try {
        const res = await getAll({ acctoken: localStorage.getItem("token") });
        let list = res.data.data;
        if (!list.length) {
          this.addressType = true
        }
      } catch (e) {
        //TODO handle the exception
      }
    },
    onPopupClose(e) {
      setTimeout(() => {
        this.showType = false;
      }, 300)
      if (e == 'closePopup') {
        setTimeout(() => {
          this.replaceGoTo('yqMallOrder')
        }, 700)
      }
    },
    //下拉刷新
    onRefresh() {
      if (localStorage.getItem('addressData') == '') {
        this.onGetTopAddress();
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
    async getUserInfo() {
      try {
        const res = await getWeiUser(
          qs.stringify({ code: localStorage.getItem("weiCode") })
        );
        console.log(res);
        this.openid = res.data.openid;
        alert("openId:", this.openid);
        localStorage.setItem("openid", this.openid);
        alert(localStorage.getItem("openid"));
      } catch (err) {
        console.log(err);
        alert("openIdError:", err);
      }
    },
    getAuthorize() {
      const url = encodeURIComponent(window.location.href);
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxaba606fc2a9bf1e5&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=yqMallConfirmOrder#wechat_redirect`;
    },
    //页面栈不存入
    replaceGoTo(name) {
      this.$router.replace({ path: name });
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.isWeiXinBro = true; // 是微信端
      } else {
        this.isWeiXinBro = false;
      }
    },
    async getWxConfig() {
      try {
        let res1, res2;
        res1 = await wxConfig({
          url: window.location.href,
        });

        // res2 = await weixin_pay({
        //   url: window.location.href,
        //   openId: "123",
        // });

        // wx.config({
        //   debug: false, // 开启调试模式
        //   appId: res.data.wx.appId, // 必填，公众号的唯一标识
        //   timestamp: res.data.wx.timestamp, // 必填，生成签名的时间戳
        //   nonceStr: res.data.wx.nonceStr, // 必填，生成签名的随机串
        //   signature: res.data.wx.signature, // 必填，签名
        //   jsApiList: [
        //     'onMenuShareAppMessage', // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容
        //     'onMenuShareTimeline' // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
        //   ]
        // })
      } catch (e) {
        console.log(e);
      }
      // wx.ready(function () {
      //   wx.hideMenuItems({
      //     menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
      //   })
      // })
      // wx.error(function (res) {
      // })
    },

    //获取购物车下单商品详情列表
    async onGetGoodsIdList() {
      try {
        const res = await cart_order({
          cart_id_arr: this.goodsIdList,
        });
        this.goodsList = res.data.data.data;
        //计算价格 总价*数量
        for (let i = 0; i < this.goodsList.length; i++) {
          this.payMoney.money =
            this.payMoney.money +
            this.goodsList[i].sku_info.price.price * this.goodsList[i].num;
          this.payMoney.vitality =
            this.payMoney.vitality +
            this.goodsList[i].sku_info.price.score * this.goodsList[i].num;
        }
      } catch (e) {
        console.log(e);
      }
    },
    //获取默认地址
    async onGetTopAddress() {
      try {
        const res = await get_top({});
        this.addressData = res.data.data;
        this.addressData != null ? this.addressType = false : this.addressType = true;
      } catch (e) {
        console.log(e);
      }
    },
    async onPay() {
      if (!this.checked) {
        this.$toast.fail("请同意协议");
        return false;
      }
      if (this.addressType) {
        this.$toast.fail("请选择地址");
        return false;
      }
      try {
        let data;
        if (this.payType == "common") {
          const res = await create_order({
            spu_id: this.payData.spu_id,
            sku_id: this.payData.id,
            address_id: this.addressData.id,
            num: this.payData.num,
            liuyan: this.liuyanText,
            price_id: this.payData.priceData.id,
          });
          data = res.data.data

        } else {
          const res = await create_cart_order({
            cart_id_arr: this.goodsIdList,
            address_id: this.addressData.id,
            liuyan: this.liuyanText
          });
          data = res.data.data
        }
        this.order = data.order
        this.orderData = data
        //若需要元气支付和微信支付
        if (this.payMoney.vitality && this.payMoney.money) {
          this.isYqPay = true
          this.isWxPay = true
          this.showType = true;
          this.price = this.payMoney.money;
          return false
        }
        //若需要元气支付但不需要微信支付
        if (this.payMoney.vitality && !this.payMoney.money) {
          this.isYqPay = true
          this.isWxPay = false
          this.showType = true;
          return false
        }
        //若不需要元气支付但需要微信支付
        if (!this.payMoney.vitality && this.payMoney.money) {
          this.$router.replace({ path: `yqMallPayOrder?money=${this.payMoney.money}&order=${this.order}&time=${this.orderData.create_time}` });
          return false
        }
        //0元购
        if (!this.payMoney.vitality && !this.payMoney.money) {
          this.$toast('购买成功~');
          setTimeout(() => {
            this.$router.replace({ path: `yqMallOrder` });
          }, 2000)
          return false
        }
      } catch (e) {
        this.$toast(e.data);
        console.log(e);
      }
    },
    goTo(name) {
      this.$router.push({ path: name });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
<style scoped lang="scss">
/deep/.van-pull-refresh {
  min-height: 100vh;
}

/deep/.van-loading {
  text-align: center;
}

/deep/.van-pull-refresh__head {
  display: flex;
  justify-content: center;
}

/deep/.m-switch-wrap .switch-checked {
  background: #000000;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.liuyanInput {
  text-align: right;
  border: none;
  padding: 0;
  margin-right: -1px;
}

.page-box {
  background: #f1f1f1;
  min-height: 100vh;
}

.orderPage {
  padding: 8px;

  .address {
    background: #ffffff;
    border-radius: 10px;
    padding: 9px 12px;
    margin-bottom: 6px;

    .noAddress {
      border: 1px dashed #666;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .no_Add {
        display: flex;
        align-items: center;
        padding: 20px;

        .add {
          font-size: 13px;
          color: #000;
        }
      }
    }

    .sucAddress {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .addressCont {
        display: flex;
        align-items: center;

        .addIcon {
          margin-right: 10px;

          img {
            width: 13px;
            height: 16px;
          }
        }

        .addessText {
          .user {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            color: #000;

            div {
              font-size: 15px;
            }

            .name {
              margin-right: 5px;
            }
          }

          .coordinate {
            font-size: 13px;
            color: #666666;
          }
        }
      }

      .rightIcon {}
    }
  }

  .detailOrder {
    background: #ffffff;
    padding: 20px 15px;
    border-radius: 10px;
    margin-bottom: 6px;

    .commodity {
      display: flex;
      align-items: center;

      .commodityImg {
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
        width: 88px;
        height: 88px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .commodityType {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        width: 70%;
        line-height: 32px;

        .commodityName {
          color: #000000;
          font-weight: 700;
          font-size: 16px;
          line-height: 25px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }

        .commoditySelect {
          font-size: 13px;
          color: #737373;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }

        .commodityMoney {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .money {
            display: flex;
            align-items: center;

            .addIcon {
              margin: 0 5px;
            }

            .moneyVitality {
              display: flex;
              align-items: center;
              font-size: 14px;

              .moneyYq {
                width: 26px;
                height: 26px;
              }
            }
          }

          .numer {
            font-size: 14px;
          }
        }
      }
    }

    .line {
      height: 1px;
      width: 100%;
      background: #b3b3b3;
      margin: 23px 0;
    }

    .orderCont {
      .orderText {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          font-size: 13px;
          color: #4d4d4d;
        }

        .right {
          color: #000000;
          font-size: 13px;
        }
      }

      .orderText:first-child {
        margin-bottom: 17px;
      }

      .written {
        .right {
          color: #cccccc;
        }
      }
    }
  }

  .pay {
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 18px;
    padding: 16px;

    .payName {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .payType {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .payList {
        display: flex;
        align-items: center;
        margin-right: 14px;
        background: #f4f4f4;
        border-radius: 15px;
        padding: 10px 16px;
        color: #000;
        margin-bottom: 5px;

        img {
          width: 15px;
          height: 15px;
        }
      }

      .selectList {
        background: #333333;
        color: #fff;
      }
    }
  }

  .agree {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 140px;

    .agreeName {
      font-size: 13px;

      span {
        font-weight: 700;
      }
    }

    .agreeBtn {}
  }
}

.btm_b {
  padding: 20px 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;

  .btm_b_text {
    .text_money {
        // margin-bottom: 13px;
        display: flex;
        align-items: center;
        width: 170px;
        flex-wrap: wrap;

        span {
          display: flex;
          align-items: center;
        }

        .span1 {
          color: #5EB0F5;
          font-size: 17px;
        }

        .span2 {
          color: #5EB0F5;
          font-size: 15px;
          margin: 0 2px 0;
        }

        .itemScore {
          display: flex;
          align-items: center;
          margin-top: 2px;

          .moneyYq {
            width: 25px;
            height: 25px;
          }
        }
      }

    .text_yq {
      .span1 {
        font-size: 12px;
        color: #808080;
      }

      .span2 {
        color: #82c6ff;
        font-size: 14px;
        margin-left: 35px;
      }
    }
  }

  .btm_b_btn {
    padding: 15px 50px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 40px;
    background: #000000;
  }
}
</style>
