<template>
  <div class="cxbcindx">
    <div class="cxbcindx_hed">
      <img :src="Qimg + 'index_hd_Bg.gif'" style="width: 100%;height: 100%">
    </div>
    <div class="cxbcindx_btm" :style="'background-image: url(' + Qimg + 'index_btm_bg.jpg)'">
      <div class="rule" @click="toPage('cxbcRule')"></div>
      <div class="cxbcindx_btm_title">李时珍数字艺术俱乐部</div>
      <div class="cxbcindx_btm_title2">
        <span style="margin: 0 8px">Lishizhen.Art</span>
      </div>
      <div class="lszHeader">李时珍头像数字藏品盲盒</div>
      <div></div>
      <div class="cxbcindx_btm_title2">
        <img src="../../assets/img/cxbc/xinxin.png" alt="" />
        <span style="margin: 0 8px">限量发布预约开启</span>
        <img src="../../assets/img/cxbc/xinxin.png" alt="" />
      </div>

      <!-- <div class="cxbcindx_btm_Price m-t-20">
        第四轮发售价<span class="f-14">￥</span
        ><span class="f-17" style="margin: 0 2px">399</span>元/个
      </div> -->
      <!-- <div class="cxbcindx_btm_Price">
        第三轮发售价<span class="f-14">￥</span
        ><span class="f-17" style="margin: 0 2px">299</span>元/个
      </div> -->
      <!-- 立即预约-->
      <div>
        <div
          ref="piediv"
          class="cxbcindx_btm_btn"
          v-if="!userjoin && yuyueBtn == 0"
          @click="reserveBtn()"
        >
          <div class="btn_wd">立即预约</div>
          <!-- <div class="btn_time">预约倒计时:{{ showTime2 }}</div> -->
          <div class="btn_time">等待第四期公开发布</div>
        </div>
        <div
          class="cxbcindx_btm_btn"
          @click="toBTNyq('cxbcShare')"
          v-if="userjoin && yuyueBtn == 0"
        >
          <div class="btn_wd">已预约成功 去邀请好友</div>
          <!-- <div class="btn_time">预约倒计时:{{ showTime2 }}</div> -->
          <div class="btn_time">抢购将于5.26 13:00开启</div>
        </div>
        <div
          class="cxbcindx_btm_btn1"
          v-if="!userjoin && yuyueBtn != 0 && yuyueBtn != 5"
        >
          <div class="btn_wd">预约已结束</div>
        </div>
        <!-- 距离提前发售倒计时 -->
        <div class="cxbcindx_btm_btn" v-if="userjoin && yuyueBtn == 1">
          <!-- <div class="btn_wd">已预约</div> -->
          <div class="btn_wd">已中签</div>
          <div class="btn_time">发布倒计时:{{ showTime7 }}</div>
        </div>
        <!-- 距离正式发售倒计时 -->
        <div
          class="cxbcindx_btm_btn"
          v-if="userjoin && yuyueBtn == 3 && toappx"
        >
          <div class="btn_wd">等待发售</div>
          <div class="btn_time">公布链接倒计时:{{ showTime5 }}</div>
        </div>
        <div
          class="cxbcindx_btm_btn"
          v-if="userjoin && yuyueBtn == 3 && !toappx"
          @click="topurchase2()"
        >
          <div class="btn_wd">请前往app进行抢购</div>
          <div class="btn_time">公布链接倒计时:{{ showTime5 }}</div>
        </div>

        <div class="cxbcindx_btm_btn2" v-if="userjoin && yuyueBtn == 12">
          <div class="btn_wd">已售罄</div>
          <div class="btn_time">抢购倒计时:{{ showTime8 }}</div>
        </div>
        <div
          class="cxbcindx_btm_btn"
          v-if="userjoin && yuyueBtn == 7"
          @click="extract()"
        >
          <div class="btn_wd">
            <span v-if="!cjJoin">公开购抽签</span>
            <span v-if="cjJoin">
              <span v-if="cjzhon">恭喜您，已中签！</span>
              <span v-if="!cjzhon">很遗憾，未中签！</span>
            </span>
          </div>
          <div class="btn_time">抽签倒计时:{{ showTime10 }}</div>
        </div>
        <div
          class="cxbcindx_btm_btn"
          v-if="userjoin && yuyueBtn == 2"
          @click="topurchase()"
        >
          <div class="btn_wd">第三期公开抢购</div>
          <div class="btn_time">抢购结束倒计时:{{ showTime8 }}</div>
        </div>
        <div
          class="cxbcindx_btm_btn"
          v-if="userjoin && yuyueBtn == 4 && toappx"
          @click="topurchase1()"
        >
          <div class="btn_wd">正式抢购</div>
          <div class="btn_time">正式抢购结束倒计时:{{ showTime4 }}</div>
        </div>
        <div
          class="cxbcindx_btm_btn"
          v-if="userjoin && yuyueBtn == 4 && !toappx"
          @click="topurchase2()"
        >
          <div class="btn_wd">请前往app进行抢购</div>
          <div class="btn_time">正式抢购结束倒计时:{{ showTime4 }}</div>
        </div>
        <!-- <div class="cxbcindx_btm_btn" v-if="userjoin && yuyueBtn == 3">
          <div class="btn_wd">优先抢购</div>
          <div class="btn_time">优先抢购倒计时:{{ showTime6 }}</div>
        </div> -->
        <div class="cxbcindx_btm_btn1" v-if="yuyueBtn == 5">
          <div class="btn_wd">已结束</div>
        </div>
        <div v-if="cjJoin" style="color: #fff">
          <div v-if="cjzhon">
            <!-- <div class="text-center f-10 m-t-10">恭喜您，已中签！</div> -->
            <div class="text-center f-10 m-t-10">
              请与5月11日下午15:00在此页面直接点击购买
            </div>
          </div>
          <div v-else>
            <!-- <div class="text-center f-10 m-t-10">很遗憾，未中签！</div> -->
            <!-- <div class="text-center f-10 m-t-10">请关注下一轮公开抽签活动</div> -->
          </div>
        </div>
        <div class="text-center f-10 m-t-10" style="color: #fff">
          第四轮公开发售将面向所有预约用户
        </div>
        <div class="text-center f-10" style="color: #fff">
          请提前完成实名认证避免影响获得
        </div>
        <div class="NumberPpe">
          <!-- 预约人数 <span>{{ count }}</span> -->
          <div class="operation_box_popnum m-b-10">
            <img src="../../assets/img/cxbc/cx-right.png" alt="" />
            <span class="operation_box_popnums"
              >参与人数:<span class="f-17 bold m-l-5">{{ count1 }}</span></span
            >
            <img src="../../assets/img/cxbc/cx-left.png" alt="" />
          </div>
        </div>
      </div>

      <div class="text-center">
        <img class="indexMid" src="../../assets/img/cxbc/indexMid.png" alt="" />
      </div>

      <!-- <div class="cxbcindx_btm_title3 m-t-30">
        <div class="wd f-12 bold">IP出品方</div>
        <div class="wd1 f-16">中医数字创新实验室</div>
        <div class="f-10 bold text-center">
          (中国中医科学院中医药信息研究所)
        </div>
      </div>
      <div class="cxbcindx_btm_title3 m-t-30">
        <div class="wd f-12 bold">首席顾问</div>
        <div class="wd1 f-15">王剑教授</div>
        <div class="f-10 bold text-center">
          (湖北中医药大学李时珍研究所所长)
        </div>
      </div>
      <div class="four_ball1">
        <img src="../../assets/img/cxbc/forre.png" alt="" />
      </div> -->
      <div class="four_ball">
        <img src="../../assets/img/cxbc/four_ball.png" alt="" />
      </div>
      <!-- 冲寻百草入口 -->
      <!-- <div class="cxbc_entrance">
        <div class="cxbc_entrance_cet" :style="'background-image: url(' + Qimg + 'entrance_bg.gif)'">

          <div class="btn" @click="goactive()">立即参与</div>
          <div class="time f-12">
            距离活动结束:<span
              class="m-l-5"
              style="font-weight: bold; letter-spacing: 2px"
              >{{ showTime8 }}</span
            >
          </div>
        </div>
      </div> -->
      <!-- <div class="cxbcSynthesis_btm">
        <div class="operation_box">
          <div class="activity">
            <div class="activity_one m-b-20">
              <div class="left">
                <img src="../../assets/img/cxbc/gzh.jpg" alt="" />
                <div>
                  <div class="f-14 bold">进入公众号发送对应口号</div>
                  <div class="f-10">可获得1次抽签次数</div>
                </div>
              </div>
              <div class="right">
                <div class="btn" v-if="thePublic">已完成</div>
                <div class="btn" v-else @click="towc2()">去完成</div>
              </div>
            </div>
            <div class="activity_one m-b-20">
              <div class="left">
                <img src="../../assets/img/cxbc/activity-yq2.png" alt="" />
                <div>
                  <div class="f-14 bold">进入社区发送对应口号</div>
                  <div class="f-10">可获得1次抽签次数</div>
                </div>
              </div>
              <div class="right">
                <div class="btn" v-if="wx_join">已完成</div>
                <div class="btn" v-else @click="towc()">去完成</div>
              </div>
            </div>
            <div class="activity_one">
              <div class="left">
                <img src="../../assets/img/cxbc/activity-yq1.png" alt="" />
                <div>
                  <div class="f-14 bold">邀请好友收集百草</div>
                  <div class="f-10">好友成功预约即可获得1次抽签机会</div>
                  <div class="f-10">每有1个好友成功完成实名认证 +15元石</div>
                </div>
              </div>
              <div class="right">
                <div class="btn" @click="toyq('cxbcShare')">去邀请</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="rhythm_title">
        <img src="../../assets/img/cxbc/left.png" alt="" />
        <div class="text">活动节奏</div>
        <img src="../../assets/img/cxbc/right.png" alt="" />
      </div>
      <div class="cxbc_rhythm">
        <img
          class="rhythmbg"
          src="../../assets/img/cxbc/rhythm_bg1.png"
          alt=""
        />
        <div class="time_box time_box_right">
          <div class="time">04-18 19:00</div>
          <div class="text">开始预约及集百草</div>
        </div>
        <div class="time_box2 time_box_left">
          <div class="time">04-27 15:00</div>
          <div class="text">第一批优先发布</div>
        </div>
        <div class="time_box3 time_box_right">
          <div class="time">05-03 15:00</div>
          <div class="text">集百草活动结束</div>
        </div>
        <div class="time_box4 time_box_left">
          <div class="time">05-04 15:00</div>
          <div class="text">集百草中签优先发布</div>
        </div>
        <div class="time_box5 time_box_right">
          <div class="time">05-11 15:00</div>
          <div class="text">正式发布</div>
        </div>
        <!-- <div class="time_box6 time_box_left">
          <div class="time">2022.03.25</div>
          <div class="text">开启预约集草</div>
        </div> -->
      </div>
      <div class="cxbc_introduce">
        <div class="cet">
          <div class="induce_box">
            <div class="induce_box_hed">创作背景</div>
            <div class="induce_box_text m-b-35">
              2022年2月25日，轻松小镇联合战略合作方倍轻松股份（688793）、IP授权方中国中医科学院中医药信息研究所共同发布了【中国首个中医药古籍IP数字藏品《本草纲目》金陵版】为中医数字化创新插上了一杆旗帜，最终有22.8万人参与了本草纲目数字藏品的预约，此次发布大获成功，受到了官媒的广泛关注，成为了中医数字藏品领域的样板案例，为了更进一步推动中医文化传承、创新发展，在继本草纲目数字藏品之后，拜访了中医领域的各路权威机构和专家学者，受到了广泛的支持，最终推出了与本草纲目数字藏品一脉相承的【李时珍数字艺术俱乐部Lishizhen.Art】头像系列，该系列旨在纪念《本草纲目》作者，明代伟大的医学家李时珍。
            </div>
            <div class="induce_box_hed">藏品介绍</div>
            <div class="induce_box_text m-b-35">
              「李时珍数字艺术俱乐部Lishizhen.Art」希望用数字艺术向全世界讲述中国故事，将东方元素融入数字艺术创作中，对标Bored
              Ape Yacht
              Club，希望打造出首个产业顶级藏品俱乐部，在创作及原画方面，都加入了中国标志性的文化标签，稀缺元素均取材于《本草纲目》记载的中草药，力求每一张头像在展示东方文化魅力的同时，都能经得起时间的考验，该数字藏品致敬了“医圣”李时珍画像，进行了创新性的二次创作，融合了伟大医学家李时珍的16本著作、中草药及李时珍不同时期的代表服饰相关元素以及现代化潮酷元素，用数字化的方式致敬李时珍。<br />
              该数字藏品通过算法自动生成10000款非同质化李时珍头像数字藏品，每一个藏品都独一无二，这也是中医领域首个采用721标准铸造出的数字藏品。
              <br />李时珍（1518年7月3日－1593年），字东璧，晚年自号濒湖山人，湖北蕲州（今湖北省蕲春县蕲州镇）人
              ，明代著名医药学家，中国古代伟大的医学家、药物学家，著有《本草纲目》、《濒湖脉学》等中医药经典。
            </div>

            <div class="induce_box_hed">联合共创</div>
            <div class="induce_box_text m-b-35">
              本次数字藏品由「中国中医科学院中医药信息研究所」监制、湖北中医药大学李时珍研究所所长王剑教授担任顾问、倍轻松股份战略合作，轻松小镇独家发行。「李时珍数字艺术俱乐部Lishizhen.Art」将以Web3的理念进行治理，由多方支持发起，是轻松小镇参与共创的重要数字藏品，未来俱乐部将引入更多实体结合的场景，让李时珍数字艺术俱乐部成为面向全球获得国际认可的NFT数字藏品和顶级IP，此次发行的「李时珍头像数字藏品」为国内版，后期在合法合规且政策支持的情况下，将会与国际知名机构联合共创，推出国际版头像藏品，向全世界传播中国传统文化。
            </div>
            <div class="induce_box_hed">中医数字创新实验室</div>
            <div class="induce_box_text">
              本次【李时珍数字艺术俱乐部Lishizhen.Art】由【中医数字创新实验室】支持出品。为了更进一步的探索中国传统文化与数字化创新的可能性，轻松小镇联合战略合作伙伴倍轻松与【中国中医科学院中医药信息研究所】共同发起了首个中医数字化创新研究领域的「实验室」。数字化技术创新与中医药古籍的碰撞，产生了意想不到的化学反应，本草纲目作为全人类的文化瑰宝和顶级IP，如何进一步通过数字化技术的创新延伸，让其再次焕发新生，是我们一直在思考的问题。此次合作旨在探索数字技术与中医领域的创新、传承与应用，其中数字藏品对于中医文化二次创作及IP开发是实验室研究的重点方向，接下来实验室将会对中医文化传承创新做出持续探索。
            </div>
          </div>
        </div>
      </div>
      <div class="cxbc_know">
        <div class="cxbc_know_title">藏品须知</div>
        <div class="cxbc_know_text">
          数字藏品为虚拟数字藏品，具有唯一性，获得后不可退换。请勿对数字藏品进行炒作、场外交易、欺诈，或以其他非法方式进行使用。
        </div>
        <div class="footer">
          <div class="text-center m-b-25">
            <img
              src="../../assets/img/kefu.png"
              alt=""
              width="100%"
              height="100%"
              class="group"
            />
          </div>
          <div class="footer_text">
            扫码关注「轻松小镇」<br />第一时间了解小镇资讯
          </div>
          <img
            class="footer_Logo"
            src="../../assets/img/b-has-logo.png"
            alt=""
          />
        </div>
      </div>
      <div class="toapp" v-if="!toappx">
        <div class="left">
          <div class="left_Wd">下载轻松小镇App</div>
          <div class="left_Wd2">抢先获取最新小镇信息</div>
        </div>
        <div class="right" @click="toxz()">去下载</div>
      </div>
    </div>
    <!-- 預約成功 -->
    <van-overlay :show="yuyue" z-index="99">
      <div class="wrapper">
        <div class="block" style="padding: 20px">
          <div class="getDrug_title">预约成功</div>
          <div class="text-center getDrug_cent m-t-20">
            获取一次「重寻百草」抽签活动<br />抽签合成百草即可获得优先购机会
          </div>
          <!-- <div class="received" @click="toPage('cxbcSynthesis')">立即参与</div> -->
          <div class="received" @click="yuyue = false">立即参与</div>
          <van-icon
            name="close"
            class="close"
            size="30"
            @click="yuyue = false"
          />
        </div>
      </div>
    </van-overlay>
    <!-- 合成活动已结束 -->
    <van-overlay :show="activityOver" z-index="99">
      <div class="wrapper">
        <div class="block" style="padding: 20px 0 30px">
          <div class="Over">
            <div class="left">
              <div class="f-16 bold m-b-5 text-center">「重寻百草」</div>
              <div class="f-12 text-center m-t-20 m-b-20">
                集5种药草合成藏品优先购资格
              </div>
            </div>
            <div class="btn">活动已结束</div>
            <van-icon
              name="close"
              class="close"
              size="30"
              @click="activityOver = false"
            />
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 去预约 -->
    <van-overlay :show="appoint" z-index="99">
      <div class="wrapper">
        <div class="block" style="padding: 20px">
          <div class="appoint">
            <div class="text-center appoint_text">
              该活动需预约后才能参加<br />是否前往预约?
            </div>
            <div class="btn_bx">
              <div class="cancel" @click="appoint = false">取消</div>
              <div class="confirm" @click="appointGo()">去预约</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 优先购资格 -->
    <van-overlay :show="synSuccess">
      <div class="wrapper">
        <div class="block1">
          <div class="text-center f-18 m-b-5">恭喜您，已中签！</div>
          <!-- <div class="text-center f-18">获得公开抢购资格!</div> -->
          <img src="../../assets/img/cxbc/yxgimg.png" alt="" />
          <div class="sucss_Btn f-20" @click="synSuccess = false">收下资格</div>
          <div class="text-center f-12">
            请与5月11日下午15:00在此页面直接点击购买
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 没有优先购资格 -->
    <van-overlay :show="noSuccess">
      <div class="wrapper">
        <div class="block1">
          <div class="text-center f-18 m-b-5">很遗憾，未中签！</div>
          <img src="../../assets/img/cxbc/yxgimg.png" alt="" />
          <div class="sucss_Btn f-20" @click="noSuccess = false">确定</div>
          <div class="text-center f-12">请关注下一轮公开抽签活动</div>
        </div>
      </div>
    </van-overlay>
    <!-- 未实名 -->
    <van-overlay :show="realName" z-index="999">
      <div class="wrapper">
        <div class="block">
          <div class="realName">
            <div class="realName_title">
              您还未完成实名<br />请完成实名后参与抽签
            </div>
            <div class="to_realName" @click="toxz()">下载app完成实名</div>
            <div class="realName_tips">
              依据相关法律规定<br />获得数字藏品前需完成实名认证
            </div>
            <van-icon
              name="close"
              class="close"
              size="30"
              @click="realName = false"
            />
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- <van-loading class="loding" v-if="true" /> -->
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="hasLogin"
    ></login>
    <she-group-dlg
      :isShow="isShowGroup"
      :qrcode="qrcode"
      @qr="qrcodeFN"
      @close="isShowGroup = false"
    ></she-group-dlg>
    <PasswordCxbc
      :isShow="isShowGroup1"
      @qr="qrcodeFN1"
      @close="isShowGroup1 = false"
    ></PasswordCxbc>
  </div>
</template>
<script>
import { reserve, newLottery } from "../../api/cxbc";
import { mapMutations, mapState } from "vuex";
import SheGroupDlg from "../../components/PasswordCxbc.vue";
import PasswordCxbc from "../../components/PasswordCxbc2.vue";
import { getWxConfig } from "../../../tool";
import { userApi } from "../../api/user";
import Login from "../../components/Login";

import axios from "axios";
const moment = require("moment");
const baseUrl = require("../../../site");
export default {
  name: "cxbc",
  components: {
    Login,
    SheGroupDlg,
    PasswordCxbc,
  },
  data() {
    return {
      loding: false,
      realName: false, //没实名
      cjJoin: false, //参没参加活动
      cjzhon: false, //中没中
      toappx: false,
      purchase: "",
      wx_join: false, //参加活动了吗
      thePublic: false,
      count1: "",
      qrcode: "",
      qrcode2: "",
      synSuccess: false,
      noSuccess: false,
      isShowGroup: false,
      relName: false,
      isShowGroup1: false,
      isShowLogin: false,
      yuyue: false, //预约弹窗
      activityOver: false, //合成活动结束
      activityOverFg: 0,
      yuyueBtn: 0, // 已预约0  提前抢购倒计时1 提前抢购结束2 正式抢购倒计时3 正式抢购结束4  结束5
      userjoin: false, //预约活动了吗
      synthesis: false, //是否为有限抢购
      appoint: false, //没预约弹窗
      count: 0, //参与人数
      time7: "", //合成开始时间
      time8: "", //合成结束时间
      timer8: null,
      timer2: null,
      timer5: null,
      timer4: null,
      timer6: null,
      showTime2: "00:00:00",
      showTime8: "00:00:00", //提前抢购中
      showTime5: "00:00:00", //正式抢购倒计时
      showTime4: "00:00:00", //正式抢购中
      showTime7: "00:00:00", // 提前抢购倒计时
      showTime6: "00:00:00",
      showTime9: "00:00:00",
      showTime10: "00:00:00",
    };
  },
  created() {
    getWxConfig(
      "李时珍头像数字藏品火爆预约中",
      "李时珍数字艺术俱乐部，中国中医科学院中医药信息研究所监制，中医数字创新实验室出品。"
    );
  },
  destroyed() {
    clearInterval(this.timer2);
    clearInterval(this.timer8);
  },
  mounted() {
    // console.log(this.$refs.piediv.getBoundingClientRect().top);
    // window.addEventListener("scroll", this.handleScroll, true);
    this.getIndex();
    this.getUser();
    this.toappx = this.openInWebview();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  methods: {
    async extract() {
      this.$toast.loading({
        message: "请稍等...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      try {
        const res = await newLottery({});
        // this.$toast(e.data);
        console.log(res);
      } catch (e) {
        console.log(e);
        this.$toast.clear();
        if (e.code == 99) {
          this.synSuccess = true;
          this.getIndex();
        } else if (e.code == -99) {
          this.noSuccess = true;
          this.getIndex();
        } else if (e.code == 50) {
          if (!this.toappx) {
            this.realName = true;
          } else {
            this.$toast(e.data);
          }
        } else {
          this.$toast(e.data);
        }
      }
    },
    topurchase() {
      window.location.href = this.purchase;
    },
    topurchase1() {
      console.log(this.user.idCard);
      if (this.user.idCard == "") {
        if (!this.toappx) {
          this.realName = true;
        } else {
          this.$toast("您还未实名,请前往app实名认证");
        }
      } else {
        window.location.href = this.purchase;
      }
    },
    topurchase2() {
      window.location.href = "https://appfile.relaverse.cn/m/download.html";
    },
    openInWebview() {
      /* 返回true或false; */
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // 微信浏览器判断
        return false;
      } else if (ua.match(/QQ/i) == "qq") {
        // QQ浏览器判断
        return false;
      } else if (ua.match(/WeiBo/i) == "weibo") {
        return false;
      } else {
        if (ua.match(/Android/i) != null) {
          return ua.match(/browser/i) == null;
        } else if (ua.match(/iPhone/i) != null) {
          return ua.match(/safari/i) == null;
        } else {
          return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null;
        }
      }
    },
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    toxz() {
      window.location.href = "https://appfile.relaverse.cn/m/download.html";
    },
    towc() {
      if (localStorage.getItem("token")) {
        if (this.userjoin) {
          // this.isShowGroup = true;
          this.$toast("活动已结束");
        } else {
          this.appoint = true;
        }
      } else {
        this.isShowLogin = true;
      }
    },
    towc2() {
      if (localStorage.getItem("token")) {
        if (this.userjoin) {
          // this.isShowGroup1 = true;
          this.$toast("活动已结束");
        } else {
          this.appoint = true;
        }
      } else {
        this.isShowLogin = true;
      }
    },
    toBTNyq(page) {
      if (localStorage.getItem("token")) {
        if (this.userjoin) {
          this.toPage(page);
        } else {
          this.appoint = true;
        }
      } else {
        this.isShowLogin = true;
      }
    },
    toyq(page) {
      if (localStorage.getItem("token")) {
        if (this.userjoin) {
          this.$toast("活动已结束");
        } else {
          this.appoint = true;
        }
      } else {
        this.isShowLogin = true;
      }
    },
    async getUser() {
      try {
        const res = await userApi({});
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        console.log(e);
        // if (e.code === 201) {
        //   this.isShowLogin = true;
        // }
      }
    },
    // 去合成
    goactive() {
      if (this.userjoin == true) {
        if (this.activityOverFg == 0) {
          this.toPage("cxbcSynthesis");
        } else if (this.activityOverFg == 1) {
          this.activityOver = true;
        }
      } else {
        this.appoint = true;
      }
    },

    async reserveBtn() {
      try {
        const res = await reserve({
          from_user: localStorage.getItem("invited_id"),
        });
        console.log(res);
        // this.yuyue = true;
        this.getIndex();
      } catch (e) {
        console.log(e);
        if (e.code == 201) {
          this.$toast(e.data);
          this.isShowLogin = true;
        } else {
          this.$toast(e.data);
        }
      }
    },
    appointGo() {
      this.appoint = false;
      document.body.scrollTop = 400;
      document.documentElement.scrollTop = 400;
      window.pageYOffset = 400;
    },
    // 获取页面数据
    async getIndex() {
      axios
        .post(baseUrl.domain2 + "/index/shizhen/info/", {
          acctoken: localStorage.getItem("token"),
        })
        .then((success) => {
          const res = success.data;
          if (res.code == 200) {
            this.synthesis = res.data.synthesis;
            this.userjoin = res.data.join;
            this.count = res.data.count;
            this.purchase = res.data.url;
            this.wx_join = res.data.wx_join;
            this.qrcode = res.data.wx_qun;
            this.count1 = res.data.lottery_count;
            this.thePublic = res.data.thePublic;
            this.cjJoin = res.data.canjia; //参没参加活动
            this.cjzhon = res.data.zhong; //中没中
            const now = new Date().getTime();
            const time1 = res.data.info.time1 * 1000; // 开始预约时间
            const time2 = res.data.info.time2 * 1000; // 活动结束
            // const time3 = res.data.time3 * 1000; // 公布集草中签时间
            const time4 = res.data.info.time4 * 1000; // 抢购时间
            const time5 = res.data.info.time5 * 1000; // 提前抢购时间
            const time6 = res.data.info.time6 * 1000; // 抢购截止时间
            const time9 = res.data.info.time9 * 1000; // 优先抢购结束时间
            const time7 = res.data.info.time7 * 1000; // 合成开始时间
            const time8 = res.data.info.time8 * 1000; // 合成结束时间
            const time12 = res.data.info.time12 * 1000; // 合成结束时间
            this.time7 = moment(time7).format("MM-DD HH:mm");
            this.time8 = moment(time8).format("MM-DD HH:mm");
            if (1652155200000 < now && now < 1652241600000 && this.userjoin) {
              this.yuyueBtn = 7;
              this.timer2 = setInterval(() => {
                const time = this.countTime(1652241600000);
                // console.log(time);
                if (time) {
                  this.showTime10 = time;
                } else {
                  this.showTime10 = "00:00:00";
                  location.reload();
                }
              }, 1000);
            } else {
              // 合成活动进行中
              if (now < time8) {
                this.timer8 = setInterval(() => {
                  const time = this.countTime(time8);
                  // console.log(time);
                  if (time) {
                    this.showTime8 = time;
                  } else {
                    this.showTime8 = "00:00:00";
                    location.reload();
                  }
                }, 1000);
              } else if (now > time8) {
                this.activityOverFg = 1;
              }
              // 距离预约结束
              if (now < time2) {
                this.yuyueBtn = 0;
                this.timer2 = setInterval(() => {
                  const time = this.countTime(time2);
                  // console.log(time);
                  if (time) {
                    this.showTime2 = time;
                  } else {
                    this.showTime2 = "00:00:00";
                    location.reload();
                  }
                }, 1000);
              } else {
                // 判断是不是白名单
                if (this.synthesis) {
                  if (now < time5) {
                    //没开始提前抢购  显示提前抢购倒计时
                    this.yuyueBtn = 1;
                    this.timer2 = setInterval(() => {
                      const time = this.countTime(time5);
                      if (time) {
                        this.showTime7 = time;
                      } else {
                        this.showTime7 = "00:00:00";
                        location.reload();
                      }
                    }, 1000);
                  } else {
                    //超过提前抢购时间  判断是否超过提前抢购结束时间
                    if (now < time9) {
                      if (now <= time12) {
                        // 提前抢购中
                        this.yuyueBtn = 2;
                        this.timer2 = setInterval(() => {
                          const now2 = new Date().getTime();
                          if (now2 >= time12) {
                            this.yuyueBtn = 12;
                          }
                          const time = this.countTime(time9);
                          // console.log(time);
                          if (time) {
                            this.showTime8 = time;
                          } else {
                            this.showTime8 = "00:00:00";
                            location.reload();
                          }
                        }, 1000);
                      } else {
                        // 已售罄
                        this.yuyueBtn = 12;
                        this.timer2 = setInterval(() => {
                          const time = this.countTime(time9);
                          // console.log(time);
                          if (time) {
                            this.showTime8 = time;
                          } else {
                            this.showTime8 = "00:00:00";
                            location.reload();
                          }
                        }, 1000);
                      }
                    } else {
                      // 提前抢购结束  判断是否在正式抢购时间中
                      if (now < time4) {
                        //还没到正式抢购开始时间   显示正式抢购时间倒计时
                        this.yuyueBtn = 3;
                        this.timer2 = setInterval(() => {
                          const time = this.countTime(time4);
                          if (time) {
                            this.showTime5 = time;
                          } else {
                            this.showTime5 = "00:00:00";
                            location.reload();
                          }
                        }, 1000);
                      } else {
                        if (now < time6) {
                          // 正式抢购中
                          this.yuyueBtn = 4;
                          this.timer2 = setInterval(() => {
                            const time = this.countTime(time6);
                            // console.log(time);
                            if (time) {
                              this.showTime4 = time;
                            } else {
                              this.showTime4 = "00:00:00";
                              location.reload();
                            }
                          }, 1000);
                        } else {
                          // 抢购结束
                          this.yuyueBtn = 5;
                        }
                      }
                    }
                  }
                } else {
                  if (now < time4) {
                    // 距离开始抢购倒计时
                    this.yuyueBtn = 3;
                    this.timer2 = setInterval(() => {
                      const time = this.countTime(time4);
                      // console.log(time);
                      if (time) {
                        this.showTime5 = time;
                      } else {
                        this.showTime5 = "00:00:00";
                        location.reload();
                      }
                    }, 1000);
                  } else {
                    if (now < time6) {
                      // 正式抢购中
                      this.yuyueBtn = 4;
                      this.timer2 = setInterval(() => {
                        const time = this.countTime(time6);
                        // console.log(time);
                        if (time) {
                          this.showTime4 = time;
                        } else {
                          this.showTime4 = "00:00:00";
                          location.reload();
                        }
                      }, 1000);
                    } else {
                      // 抢购结束
                      this.yuyueBtn = 5;
                    }
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    countTime(endTime) {
      const now = new Date().getTime();
      const duration = endTime - now;
      if (duration <= 0) {
        return false;
      }
      const day = Math.floor(duration / (1000 * 60 * 60 * 24));
      const hour = Math.floor((duration / (1000 * 60 * 60)) % 24);
      const min = Math.floor((duration / (1000 * 60)) % 60);
      const s = Math.floor((duration / 1000) % 60);
      return `${day ? day + "天" : ""}${hour < 10 ? "0" + hour : hour}:${
        min < 10 ? "0" + min : min
      }:${s < 10 ? "0" + s : s}`;
    },
    qrcodeFN(v) {
      this.qrcode = v;
    },
    qrcodeFN1(v) {
      this.qrcode2 = v;
    },
    hasLogin() {
      this.isShowLogin = false;
      this.getIndex();
    },
    toPage(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.cxbcindx {
  .cxbcindx_hed {
    height: 540px;
    width: 100%;
  }
  .cxbcindx_btm {
    min-height: 2178px;
    width: 100%;
    background-size: 100% 100%;
    padding: 10px 0 30px 0;
    .rule {
      height: 66px;
      width: 20px;
      position: absolute;
      top: 550px;
      right: 0;
      background: url("../../assets/img/cxbc/rule.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
      // border-radius: 5px 0 0 5px;
      // word-wrap: break-word;
      // background: red;
    }
    .cxbcindx_btm_title {
      color: #fff;
      text-align: center;
      font-size: 24px;
      letter-spacing: 2px;
    }
    .cxbcindx_btm_title2 {
      color: #fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 2.5px;
      margin-top: 5px;
      img {
        height: 20px;
      }
    }
    .lszHeader {
      width: 280px;
      height: 35px;
      border-radius: 30px;
      background: linear-gradient(81deg, #e8c99a 0%, #f6e0be 97%);
      margin: 30px auto 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 2px;
      font-weight: 800;
    }
    .cxbcindx_btm_title3 {
      color: #fff;
      .wd {
        // font-size: 12px;
        // margin-bottom: 3px;
        text-align: center;
      }
      .wd1 {
        // font-size: 12px;
        margin: 5px 0;
        text-align: center;
        opacity: 0.8;
      }
    }
    .cxbcindx_btm_Price {
      font-size: 10px;
      color: #fff;
      text-align: center;
      span {
        // font-size: 17px;
        font-weight: bold;
      }
    }
    .indexMid {
      width: 90%;
      margin: 20px auto;
    }
    .cxbcindx_btm_btn {
      width: 280px;
      height: 55px;
      border-radius: 30px;
      background: linear-gradient(81deg, #e8c99a 0%, #f6e0be 97%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 15px auto 0;
      .btn_wd {
        font-size: 17px;
        margin-bottom: 5px;
      }
      .btn_time {
        font-size: 10px;
      }
    }
    .cxbcindx_btm_btn2 {
      width: 280px;
      height: 55px;
      border-radius: 30px;
      background: #757475;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 15px auto 0;
      .btn_wd {
        font-size: 17px;
        margin-bottom: 5px;
      }
      .btn_time {
        font-size: 10px;
      }
    }
    .cxbcindx_btm_btn1 {
      width: 280px;
      height: 60px;
      border-radius: 30px;
      background: #757475;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 15px auto 0;
      .btn_wd {
        font-size: 18px;
      }
    }
    .NumberPpe {
      text-align: center;
      color: #fff;
      margin: 10px 0 0;
      font-size: 12px;

      img {
        height: 5px;
        width: 65px;
      }
      .operation_box_popnum {
        display: flex;
        align-items: center;
        justify-content: center;
        .operation_box_popnums {
          font-size: 10px;
          margin: 0 5px;
          text-align: center !important;
        }
      }
    }
    .four_ball {
      text-align: center;
      padding: 10px 20px 20px;
      img {
        width: 100%;
      }
    }
    .cxbc_entrance {
      padding: 0 30px;
      .cxbc_entrance_cet {
        margin: 20px auto 0;
        width: 100%;
        min-height: 300px;
        padding-top: 190px;
        background-size: 100% 100%;
        border-radius: 9px;
        .text {
          text-align: center;
          line-height: 1.5em;
          color: #fff;
        }
        .btn {
          height: 40px;
          width: 150px;
          background: #fefefe;
          margin: 20px auto 0;
          font-size: 16px;
          font-weight: bold;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .time {
          text-align: center;
          color: #fff;
          margin-top: 20px;
        }
      }
    }
    .cxbcSynthesis_btm {
      // min-height: 110px;
      width: 100%;
      // background: url("../../assets/img/cxbc/cxbcsy-btm.png") no-repeat;
      // background-size: 100% 100%;
      padding: 0 20px 0px;
      .operation_box {
        width: 100%;
        // min-height: 200px;
        padding: 20px 10px 0px;
        color: #fff;
        .operation_box_title {
          color: #fff;
          font-size: 14px;
          text-align: center;
        }
        .operation_box_popnum {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 5px;
            width: 65px;
          }
          .operation_box_popnums {
            font-size: 12px;
            margin: 0 5px;
            text-align: center !important;
          }
        }
        .activity {
          .activity_one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .left {
              display: flex;
              align-items: center;
              img {
                height: 43px;
                width: 43px;
                border-radius: 50%;
                margin-right: 10px;
              }
            }
            .right {
              .btn {
                width: 80px;
                height: 30px;
                background: linear-gradient(81deg, #e8c99a 0%, #f6e0be 97%);
                border-radius: 20px;
                color: #000;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: 1px;
              }
            }
          }
        }
        .btn_box {
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .received {
            height: 40px;
            width: 120px;
            background: #fffcdb;
            border-radius: 20px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .extract {
            height: 40px;
            width: 120px;
            background: linear-gradient(160deg, #a5d7f0 0%, #209ddc 97%);
            border-radius: 20px;
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .rhythm_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      position: relative;
      top: 20px;
      .text {
        color: #fff;
        font-size: 20px;
        margin: 0 20px;
      }
      img {
        width: 100px;
        height: 20px;
      }
    }
    .cxbc_rhythm {
      width: 100%;
      min-height: 500px;
      // background: url("../../assets/img/cxbc/rhythm_bg1.png") no-repeat;
      // background-size: 100% 100%;
      padding: 90px 30px 0;
      position: relative;
      z-index: 2;
      .rhythmbg {
        width: 90%;
        position: absolute;
        z-index: 11;
        top: 0;
        left: 20px;
        animation: rotate 5s linear infinite;
      }
      @keyframes rotate {
        0% {
          transform: scale(1); /*从0度开始*/
        }
        50% {
          transform: scale(1.05);
        }
        100% {
          transform: scale(1); /*360度结束*/
        }
      }
      .time_box_right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 6px;
        border-right: 1px solid #fff;
        width: 120px;
        height: 30px;
        color: #fff;
        .time {
          font-size: 16px;
          text-align: right;
          line-height: 1em;
          font-weight: bold;
        }
        .text {
          font-size: 8px;
          text-align: right;
        }
      }
      .time_box_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 6px;
        border-left: 1px solid #fff;
        width: 120px;
        height: 30px;
        color: #fff;
        .time {
          font-size: 16px;
          text-align: left;
          line-height: 1em;
          font-weight: bold;
        }
        .text {
          font-size: 8px;
          text-align: left;
        }
      }
      .time_box {
        position: absolute;
        left: 0;
        top: 100px;
        z-index: 13;
      }
      .time_box2 {
        position: absolute;
        right: 0;
        top: 160px;
        z-index: 12;
      }
      .time_box3 {
        position: absolute;
        left: 30px;
        top: 200px;
        z-index: 12;
      }
      .time_box4 {
        position: absolute;
        right: 0px;
        top: 250px;
        z-index: 12;
      }
      .time_box5 {
        position: absolute;
        left: 0;
        top: 300px;
        z-index: 12;
      }
    }
    .cxbc_introduce {
      padding: 0 20px;
      position: relative;
      z-index: 1;
      top: -30px;
      .cet {
        width: 100%;
        min-height: 30px;
        background: #2e2c38;
        border-radius: 9px;

        .induce_box {
          padding: 30px 15px 20px;
          .induce_box_hed {
            height: 32px;
            width: 130px;
            background: linear-gradient(81deg, #e8c99a 0%, #f6e0be 97%);
            // background: url("../../assets/img/cxbc/hed_title.png") no-repeat;
            // background-size: 100% 100%;
            text-align: center;
            // padding: 0 10px;
            line-height: 32px;
            font-size: 12px;
            margin-bottom: 15px;
            font-weight: 700;
            margin: 0 auto 15px;
            border-radius: 4px;
          }
          .induce_box_text {
            line-height: 1.5em;
            font-size: 12px;
            color: #fff;
            letter-spacing: 1px;
            text-align: justify;
          }
        }
      }
    }
    .cxbc_know {
      padding: 0 20px;
      .cxbc_know_title {
        height: 2px;
        width: 314px;
        margin: 0 auto;
        background: url("../../assets/img/cxbc/konwbg.png") no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        text-align: center;
        color: #fff;
        line-height: 2px;
      }
      .cxbc_know_text {
        color: #fff;
        font-size: 10px;
        margin-top: 20px;
      }
    }
    .footer {
      text-align: center;
      .group {
        width: 118px;
        height: 118px;
        background: #ffffff;
        box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.45);
        border-radius: 7px;
        display: inline-block;
        padding: 7px;
        margin-top: 30px;
      }
      .footer_text {
        color: #fff;
        text-align: center;
        line-height: 1.5em;
        letter-spacing: 2px;
      }
      .footer_Logo {
        height: 30px;
        width: 83px;
        margin: 30px auto 0;
      }
    }
  }
  .toapp {
    width: 100%;
    height: 70px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .left {
      .left_Wd {
        font-size: 12px;
        font-weight: 500;
        color: #000;
        line-height: 20px;
        font-weight: bold;
      }
      .left_Wd2 {
        font-size: 10px;
        font-weight: 500;
        color: #000;
        opacity: 0.8;
        font-weight: bold;
        line-height: 20px;
      }
    }
    .right {
      width: 86px;
      height: 32px;
      background: #000;
      border-radius: 16px;
      font-size: 13px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      line-height: 32px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 90%;
      background-color: #e6f3f6;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 35px 30px 40px;
      .getDrug_title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
      .getDrug_cent {
        text-align: center;
        line-height: 2em;
        font-size: 12px;
      }
      .received {
        height: 33px;
        width: 165px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #000;
        border-radius: 20px;
        margin: 20px auto 10px;
      }
      .Over {
        width: 100%;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
        .btn {
          height: 33px;
          width: 165px;
          font-size: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          background: #332c2b;
          border-radius: 20px;
          font-size: 12px;
          margin: 0 auto;
        }
      }
      .appoint {
        width: 100%;
        padding: 0 20px;
        .appoint_text {
          line-height: 2em;
        }
        .btn_bx {
          width: 100%;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .cancel {
            height: 40px;
            width: 120px;
            background: #a1a1a1;
            color: #fff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .confirm {
            height: 40px;
            width: 120px;
            background: #000;
            color: #fff;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .close {
        position: absolute;
        bottom: -60px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
    }
    .realName {
      .realName_title {
        line-height: 1.5em;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
      }
      .close {
        position: absolute;
        bottom: -60px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
      .realName_tips {
        text-align: center;
        font-size: 10px;
        margin-top: 20px;
        line-height: 1.5em;
      }
      .to_realName {
        height: 33px;
        width: 165px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: #000;
        border-radius: 20px;
        margin: 20px auto 0;
      }
    }
    .block1 {
      width: 304px;
      height: 375px;
      background: url("../../assets/img/cxbc/sucss.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 30px;
      text-align: center;
      img {
        width: 160px;
        height: 160px;
        // margin: 20px 0 0 35px;
      }
      .sucss_Btn {
        height: 40px;
        width: 160px;
        background: #000;
        margin: 20px auto;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
      }
    }
  }
  .loding {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 66;
    transform: (-50%, -50%);
    color: #e6f3f6;
  }
}
</style>
