
<template>
  <div class="">
    <form action="/">
      <search v-model="value" show-action placeholder="请输入搜索关键词" @search="onSearch()" @cancel="onCancel" />
    </form>
    <div v-if="search" class="historyList">
      <div class="historyText">热门搜索</div>
      <div class="lists">
        <div class="item" v-for="(item, index) in listHot" :key="index" @click="onPopular(item.name)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="list" v-if="!search && list.length">
      <div class="listItem" v-for="(item, index) in list" :key="index" @click="goTo(`yqMallDetail?id=${item.id}`)">
        <div class="listImg">
          <img :src="item.image" alt="" />
        </div>
        <div class="listCont">
          <div class="listName">{{ item.title }}</div>
          <div class="listPs">{{ item.introduce }}</div>
          <div class="listMnoney">
            <span class="itemMoneyPrice" v-if="item.price.price != '0.00'">￥{{ item.price.price }}</span>
            <span class="addIcon" v-if="item.price.price != '0.00' && item.price.score != '0'">+</span>
            <span class="itemMoneyScore" v-if="item.price.score != '0'">{{ item.price.score }}
              <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="noList" v-if="!search && !list.length">没有搜索到商品</div>
  </div>
</template>
<script>
import { shop_search, searchHot } from "../../api/yqMallApi";
import { Search } from "vant";
export default {
  components: { Search },
  data() {
    return {
      value: "",
      list: [],
      search: true,
      listHot: [], //热门搜索
      page: 1,
    };
  },
  // created activated
  created() { },
  // 监听变量
  watch: {},
  mounted() {
    this.onHotSearch();
    this.handleScroll();
  },
  destroyed() { },
  methods: {
    //上划加载
    handleScroll() {
      let _this = this;
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (Math.ceil(scrollTop + windowHeight) >= scrollHeight) {
          _this.page++
          _this.onSearch();
        }
      };
    },
    /**
     * 热门搜索点击
     * @param {*} e 搜索关键字
     */
    onPopular(e){
      this.value = e;
      this.onPopular();
    },
    //搜索
    async onSearch() {
      try {
        const res = await shop_search({ 
          title: this.value,
          page: this.page
        });
        let list = res.data.data.data;
        for(let i = 0; i < list.length; i++){
          this.list.push(list[i])
        }
        this.search = false;
      } catch (e) {
        console.log(e);
      }
    },
    //热门搜索
    async onHotSearch(e) {
      try {
        const res = await searchHot({});
        this.listHot = res.data.data;
      } catch (e) {
        this.$toast.fail("请稍后再试~");
        console.log(e);
      }
    },
    goTo(name) {
      this.$router.push({ path: name });
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped></style>
<style  scoped lang="scss">
/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.noList {
  text-align: center;
  margin-top: 60px;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px;
  .listItem {
    margin-bottom: 20px;
    width: 48%;
    .listImg {
      border-radius: 5px;
      overflow: hidden;
      margin-bottom: 5px;
      text-align: center;

      width: 100%;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .listCont {
      .listName {
        font-size: 15px;
        overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            width: 100%;
      }

      .listPs {
        font-size: 14px;
        color: #e1e1e1;
        overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            width: 100%;
      }

      .listMnoney {
            display: flex;
            align-items: center;
            margin-top: 5px;
            .itemMoneyPrice{
              text-align: center;
              font-size: 12px!important;
            }
            .addIcon{
              margin: 0 2px;
            }
            .itemMoneyScore{
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
            }

            img {
              width: 20px;
              height: 20px;
            }
          }
    }
  }
}

.historyList {
  padding: 8px 12px;

  .historyText {
    padding: 12px 0;
  }

  .lists {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .item {
      border-radius: 30px;
      padding: 5px 15px;
      background: #ededed;
      margin-right: 10px;
      font-size: 11px;
    }
  }
}
</style>