<template>
  <div>
    <nav-bar left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon @click="goToPage('yqMallSearch')" name="search" size="18" />
      </template>
    </nav-bar>
    <tabs v-model="active" :ellipsis="false" ref="tabs" @change="onChangeTabs" @click="onClickOne" animated>
      <tab :title="item.title" v-for="(item, index) in tabOne" :name="index" :key="index">
        <div class="cateList">
          <div class="leftNav">
            <sidebar v-model="activeKey" @change="onChange(tabTwo[activeKey].id)">
              <sidebar-item v-for="(r, t) in tabTwo" :key="t" :title="r.title">
              </sidebar-item>
            </sidebar>
          </div>
          <div class="listItem">
            <div class="lists" v-if="tabList.length">
              <div class="item" v-for="(o, s) in tabList" :key="s" @click="onGotoQuery(`yqMallDetail?id=${o.id}` )">
                <div class="itemImg">
                  <div class="noStock" v-if="!o.shop_sku.stock">
                    <div class="noText">
                      <div class="noLine"></div>
                      <div class="text">
                        售罄
                      </div>  
                    </div>
                  </div>
                  <img :src="o.image" alt="" />
                </div>
                <div class="itemCont">
                  <div class="itemName">{{ o.title }}</div>
                  <div class="itemText">{{ o.introduce }}</div>
                  <div class="itemMoney" v-if="o.price">
                    <div class="itemMoneyPrice" v-if="o.price.price != '0.00'">￥{{ o.price.price }} +&nbsp;</div>
                    <div class="itemMoneyScore" v-if="o.price.score != '0'"> {{ o.price.score }}
                      <img class="moneyYq" src="../../assets/img/yqMall/yq.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="lists noLists" v-else>暂无商品，快去挑选吧</div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
// import store from "../store";
import { shopTypeOne, shopTypeTwo, shopList } from "../../api/yqMallApi";
import { Tabs, Tab, Sidebar, SidebarItem, NavBar } from "vant";
export default {
  components: { Tabs, Tab, Sidebar, SidebarItem, NavBar },
  data() {
    return {
      activeKey: 0,
      active: 0, //一级分类下标
      tabOne: [11, 22, 33], //一级分类
      tabTwo: [], //二级分类
      tabList: [], //分类商品列表
    };
  },
  computed: {},
  destroyed(){
  },
  mounted() {
      this.active = Number(sessionStorage.getItem('tabsId')); //获取缓存中一级分类下标  从商品详情返回自动选择
      this.activeKey = Number(sessionStorage.getItem('tabsSecondaryId')); //获取缓存中二级分类下标  从商品详情返回自动选择
      this.onShopTypeOne();
  },
  methods: {
    //上划加载
    handleScroll() {
      let _this = this;
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (Math.ceil(scrollTop + windowHeight) == scrollHeight) {
          console.log(1111)
          // _this.page++
          // _this.onGetList();
        }
      };
    },
    //一级切换回调
    onChangeTabs(e){
      sessionStorage.setItem('tabsId', Number(e))
      sessionStorage.getItem('tabsSecondaryId', Number(0))
      this.activeKey = 0
    },
    //一级分类回调
    async onShopTypeOne() {
      try {
        const res = await shopTypeOne({});
        this.tabOne = res.data.data;
        console.log(11, this.tabOne)
        this.onClickOne(this.active);
      } catch (e) {
        console.log(e);
      }
    },
    //二级分类回调
    async onClickOne(e,t) {
      try {
        const res = await shopTypeTwo({
          parent_id: this.tabOne[e].id,
        });
        this.tabTwo = res.data.data;
        this.onChange(this.tabTwo[this.activeKey].id);
        // this.activeKey = 0
      } catch (e) {
        console.log(e);
      }
    },
    //商品列表回调
    async onChange(e) {
      sessionStorage.setItem('tabsSecondaryId', this.activeKey)
      try {
        const res = await shopList({
          type: e,
        });
        this.tabList = res.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    onGotoQuery(name) {
      console.log(name)
      this.$router.push({ path:name });
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    onClickLeft() {
      sessionStorage.setItem('tabsSecondaryId', 0)
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.van-sidebar {
  width: 115px;
}
/deep/.van-sidebar{
  width: 100%;
}
/deep/.van-sidebar-item {
  text-align: center;
  background: #ffffff;
}

/deep/.van-sidebar-item--select::before {
  content: "" !important;
  display: none;
}

/deep/.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background: #f7f8fa;
  font-weight: 500!important;
}

/deep/.van-tabs__line {
  background-color: #000000;
  width: 20px;
  bottom: 20px;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.cateList {
  display: flex;
  width: 100%;
  background: #f7f8fa;

  .leftNav {
    height: calc(100vh - 90px);
    overflow: scroll;
    background: #ffffff;
    width: 31%;
  }

  .listItem {
    height: calc(100vh - 90px);
    overflow: scroll;
    width: 69%;

    .noLists {
      font-size: 13px;
      height: 100%;
      display: flex;
      justify-content: center !important;
      ;
      align-items: center;
    }

    .lists {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 16px;

      .item {
        margin-bottom: 20px;
        width: 48%;
        .itemImg {
          margin-bottom: 10px;
          text-align: center;
          position: relative;
          width: 100px;
            height: 100px;
          .noStock{
            position: absolute;
            z-index: 12;
            color: #000;
            left: 0px;
            bottom: 0;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(221, 221, 221, .6);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            .noText{
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              .noLine{
                width: 50px;
                height: 50px;
                position: absolute;
                border: 1px solid #808080;
                border-radius: 50%;
              }
              .text{
                font-size: 16px;
                font-weight: 700;
              }
            }
          }
          img {
            border-radius: 10px;
            overflow: hidden;
            width: 100%;
            height: 100%;
          }
        }

        .itemCont {
          .itemName {
            font-size: 15px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            width: 100%;
          }

          .itemText {
            text-align: center;
            font-size: 13px;
            color: #969696;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            width: 100%;
          }

          .itemMoney {
            display: flex;
            align-items: center;
            // justify-content: center;
            margin-top: 5px;
            .itemMoneyPrice{
              text-align: center;
              font-size: 11px!important;
            }
            .itemMoneyScore{
              display: flex;
              align-items: center;
              // margin-top: 3px;
              font-size: 11px;

            }

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
