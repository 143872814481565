
import http from './http'

//首页banner
export const bannerList = (data) => {
  return http.post('/index/Shop_Index/banner_list', data)
}

//首页分类banner
export const shopIndexType = (data) => {
  return http.post('/index/Shop_Index/shop_index_type', data)
}

//首页banner下面的banner
export const indexJianbianList = (data) => {
  return http.post('/index/Shop_Index/index_jianbian_list', data)
}

//地址列表
export const getAll = (data) => {
  return http.post('/index.php/index/shop_address/getAll', data)
}

//商品一级分类信息
export const shopTypeOne = (data) => {
  return http.post('/index/shop_Index/shop_type_one', data)
}

//商品二级分类信息
export const shopTypeTwo = (data) => {
  return http.post('/index/Shop_Index/shop_type_two', data)
}

//商品分类列表
export const shopList = (data) => {
  return http.post('/index/Shop_Index/shop_list', data)
}

//SPU商品信息
export const shop_spu = (data) => {
  return http.post('/index/Shop_Index/shop_spu', data)
}

//SKU商品信息
export const get_sku = (data) => {
  return http.post('/index/Shop_Index/get_sku', data)
}

//加入购物车
export const join_cart = (data) => {
  return http.post('/index/Shop_cart/join_cart', data)
}

//购物车列表
export const cart_list = (data) => {
  return http.post('/index/Shop_cart/cart_list', data)
}

//修改购物车SKU
export const upd_sku = (data) => {
  return http.post('/index/shop_cart/upd_sku', data)
}

//删除购物车某一条
export const del_cart = (data) => {
  return http.post('/index/shop_cart/del_cart', data)
}

//搜索
export const shop_search = (data) => {
  return http.post('/index/shop_index/shop_search', data)
}

//热门搜索
export const searchHot = (data) => {
  return http.post('/index/shop_index/hot', data)
}

//查询默认地址
export const get_top = (data) => {
  return http.post('/index/shop_address/get_top', data)
}

//购物车下单查询回显
export const cart_order = (data) => {
  return http.post('/index/shop_cart/cart_order', data)
}

//订单列表
export const order_list = (data) => {
  return http.post('/index/shop_order/list', data)
}

//创建订单
export const create_order = (data) => {
  console.log(55555, data)
  return http.post('/index/shop_order/create_order', data)
}

//购物车创建订单
export const create_cart_order = (data) => {
  console.log(55555, data)
  return http.post('/index/Shop_cart/create_cart_order', data)
}

//微信配置签名
export const wxConfig = (data) => {
  return http.post('index/login/wxs', data)
}


// 获取openID
export const getOpenidApi = (data) => {
  return http.post('/api/v1.Ciftis/getWxOpenid', data)
}

// 获取微信信息
export const getWeiUser = (data) => {
  return http.post('/api/v1.Ciftis/getUserInfoByCode', data)
}

//微信支付
export const weixin_pay = (data) => {
  return http.post('/wechat/api/weixinJs', data)
}

// 支付宝支付
export const pay_zhifubao = (data) => {
  return http.post('/index/shop_order/pay_zhifubao', data)
}

// 元气支付
export const pay_score = (data) => {
  return http.post('/index/shop_order/pay_score', data)
}

// 删除地址
export const delAddress = (data) => {
  return http.post('/index/shop_address/del', data)
}

// 取消订单
export const cancellation = (data) => {
  return http.post('/index/shop_order/cancellation', data)
}

// 确认收获
export const confirm = (data) => {
  return http.post('/index/shop_order/confirm', data)
}