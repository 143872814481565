<template>
  <div class="thirtyfuse">
    <div class="thirtyfuse_top">
      <div class="record" @click="record('CompoRecord')">
        合成记录 <img src="../../assets/img/thirty/record.png" alt="" />
      </div>
      <div class="swp">
        <swiper
          class="swiper-container1"
          @slideChange="slideChange"
          ref="mySwiper"
          :options="option"
        >
          <swiper-slide class="text-center swpbg">
            <img class="swp_img" :src="poster[0].img" alt="" />
            <div class="swp_text1 text-center">{{ poster[0].grade }}</div>
          </swiper-slide>
          <swiper-slide class="text-center swpbg">
            <img class="swp_img" :src="poster[1].img" alt="" />
            <div class="swp_text1 text-center">{{ poster[1].grade }}</div>
          </swiper-slide>
          <swiper-slide class="text-center swpbg">
            <img class="swp_img" :src="poster[2].img" alt="" />
            <div class="swp_text1 text-center">{{ poster[2].grade }}</div>
          </swiper-slide>
          <swiper-slide class="text-center swpbg">
            <img class="swp_img" :src="poster[3].img" alt="" />
            <div class="swp_text1 text-center">{{ poster[3].grade }}</div>
          </swiper-slide>

          <div
            class="swiper-button-next"
            v-show="initialSlide != 3"
            slot="button-next"
          >
            <!-- <van-icon name="arrow" /> -->
          </div>
          <div
            class="swiper-button-prev"
            v-show="initialSlide != 0"
            slot="button-prev"
          >
            <!-- <van-icon name="arrow-left" /> -->
          </div>
        </swiper>
      </div>
    </div>
    <div class="thirtyfuse_btm">
      <div class="title">请选择填充3个{{ material }}</div>
      <div class="fuse_material">
        <!-- 合成需要的材料 -->
        <div
          v-show="materialList.length > 0"
          class="list"
          v-for="(item, i) in materialList"
          :key="i"
        >
          <div class="box">
            <img
              class="closeBtn"
              src="../../assets/img/thirty/closebt.png"
              alt=""
              @click="closeBtn(item)"
            />
            <img class="gradeImg" :src="item.photo" alt="" />
          </div>
          <div class="name">{{ item.nft_name }}</div>
          <div class="number">#{{ item.token_id }}</div>
        </div>
        <!-- 内容不足3个的时候显示添加按钮 -->
        <div
          v-show="materialList.length < 3"
          class="addlist"
          v-for="item in increase"
          :key="item + 999"
          @click="toNext()"
        >
          <div class="box"><van-icon size="30" name="plus" /></div>
        </div>
      </div>
      <div
        class="okbtn f-14 bold m-t-25"
        v-if="materialList.length == 3"
        @click="isShowConfirm = true"
      >
        开始合成
      </div>
      <div class="nobtn f-14 bold m-t-25" v-else>合成藏品材料不足</div>
    </div>
    <!-- 您是否确定合成 -->
    <van-overlay
      :show="isShowConfirm"
      @click="isShowConfirm = false"
      z-index="100"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }"
    >
      <div class="wrapper">
        <div class="Confirm">
          <div class="title f-18 bold text-center">
            您是否确认合成
            <span class="wd">{{ grade }}</span>
          </div>
          <div class="required">
            <div class="text-center required_title f-12 m-b-10">
              用于藏品合成
            </div>
            <div class="required_listBox">
              <div class="list" v-for="(item, i) in materialList" :key="i">
                <div class="img_box">
                  <img :src="item.photo" alt="" />
                </div>
                <div class="f-12 bold-500 text-center m-t-10 m-b-5">
                  {{ item.nft_name }}
                </div>
                <div class="f-12 bold-500 text-center">
                  #{{ item.token_id }}
                </div>
              </div>
            </div>
          </div>
          <div class="f-12 tips">
            合成后用于合成藏品将直接被销毁<br />此操作不可撤销/复原，请谨慎选择
          </div>
          <div class="btn_box m-t-20">
            <div class="no_btn f-12" @click="isShowConfirm = false">
              我再想想
            </div>
            <div class="yes_btn f-12" @click="tosynthesis()">确认合成</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 合成成功 -->
    <van-overlay
      :show="isShowSynthesis"
      @click="isShowSynthesis = false"
      z-index="100"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }"
    >
      <div class="wrapper">
        <div class="Synthesis">
          <div class="Synthesis_bg">
            <div class="title f-16 text-center">合成成功</div>
            <div class="title1">{{ successData.info }}</div>
            <img class="rank_icon" :src="successData.image" alt="" />
          </div>
          <div class="tips">藏品可在「我的藏品」内查看</div>
          <div class="accept f-16 bold">收下藏品</div>
        </div>
      </div>
    </van-overlay>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="hasLogin"
    ></login>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { synthesisConfig, synthesis } from "../../api/thirty.js";
import { mapMutations, mapState } from "vuex";
import Login from "../../components/Login";

export default {
  name: "ThirtyFuse",
  computed: {},
  components: {
    SwiperSlide,
    Swiper,
    Login,
  },
  data() {
    return {
      isShowLogin: false, //登录判断
      isShowSynthesis: false, //合成成功
      isShowConfirm: false, //确认
      // isShowsignOut: false, //退出确认
      materialList: [], //原料数组
      increase: 3,
      option: {
        slidesPerView: 1,
        initialSlide: 0,
        centeredSlides: true,
        touchRatio: 1, //  触摸距离与slide滑动距离的比率。
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
      },
      poster: [
        // {
        //   grade: "青铜胸针",
        //   need: "所需幸运值：1-99",
        //   img: require("../../assets/img/thirty/qt.png"),
        // },
        {
          grade: "白银胸针",
          need: "所需幸运值：100-999",
          img: require("../../assets/img/thirty/qt.png"),
        },
        {
          grade: "黄金胸针",
          need: "所需幸运值：1000-29999",
          img: require("../../assets/img/thirty/qt.png"),
        },
        {
          grade: "铂金胸针",
          need: "所需幸运值：30000-99999",
          img: require("../../assets/img/thirty/qt.png"),
        },
        {
          grade: "钻石胸针",
          need: "所需幸运值：>100000",
          img: require("../../assets/img/thirty/qt.png"),
        },
      ],
      initialSlide: 0,
      grade: "白银胸针",
      material: "青铜胸针",
      type: "synthesisSilver", //需要多少个材料的接口参数
      successData: {},
    };
  },
  computed: {
    ...mapState({
      Silverfuse: (state) => state.Silverfuse,
      Goldfuse: (state) => state.Goldfuse,
      Platinumfuse: (state) => state.Platinumfuse,
      Diamondfuse: (state) => state.Diamondfuse,
      synthesieType: (state) => state.synthesieType,
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    this.wacthList();
    // this.synthesisNum();
    console.log(this.synthesieType);
    if (this.synthesieType == "synthesisSilver") {
      this.materialList = this.Silverfuse;
      this.swiper.activeIndex = 0;
      this.type = "synthesisSilver";
      this.material = "青铜胸针"; //需要的材料等级
      this.grade = "白银胸针";
    } else if (this.synthesieType == "synthesisGold") {
      this.materialList = this.Goldfuse;
      this.swiper.activeIndex = 1;
      this.type = "synthesisGold";
      this.material = "白银胸针"; //需要的材料等级
      this.grade = "黄金胸针";
    } else if (this.synthesieType == "synthesisPlatinum") {
      this.materialList = this.Platinumfuse;
      this.swiper.activeIndex = 2;
      this.type = "synthesisPlatinum";
      this.material = "黄金胸针"; //需要的材料等级
      this.grade = "铂金胸针";
    } else if (this.synthesieType == "synthesisDiamond") {
      this.materialList = this.Diamondfuse;
      this.swiper.activeIndex = 3;
      this.type = "synthesisDiamond";
      this.grade = "砖石胸针";
      this.material = "铂金胸针"; //需要的材料等级
    }
    this.initialSlide = this.swiper.activeIndex;
  },
  destroyed() {},
  created() {},
  watch: {
    materialList() {
      this.wacthList();
    },
  },
  methods: {
    ...mapMutations({
      setSilverfuse: "setSilverfuse",
      setGoldfuse: "setGoldfuse",
      setPlatinumfuse: "setPlatinumfuse",
      setDiamondfuse: "setDiamondfuse",
      setSynthesieType: "setSynthesieType",
    }),
    //
    toNext() {
      if (localStorage.getItem("token")) {
        this.toPage("FuseStuff", this.type);
      } else {
        this.isShowLogin = true;
      }
    },
    record() {
      console.log("Asda");
      if (localStorage.getItem("token")) {
        this.toPage("CompoRecord");
      } else {
        this.isShowLogin = true;
      }
    },
    // 合成
    async tosynthesis() {
      let list = [];
      console.log(this.type);
      this.materialList.forEach((item, i) => {
        list.push(item.id);
      });
      try {
        const res = await synthesis({
          type: this.type,
          nft: list,
        });

        this.successData = res.data;
        this.isShowConfirm = false;
        this.isShowSynthesis = true;
        console.log("adadadass", this.synthesieType);
        if (this.type == "synthesisSilver") {
          this.setSilverfuse([]);
          this.materialList = this.Silverfuse;
        } else if (this.type == "synthesisGold") {
          this.setGoldfuse([]);
          this.materialList = this.Goldfuse;
        } else if (this.type == "synthesisPlatinum") {
          this.setPlatinumfuse([]);
          this.materialList = this.Platinumfuse;
        } else if (this.type == "synthesisDiamond") {
          this.setDiamondfuse([]);
          this.materialList = this.Diamondfuse;
        }
      } catch (e) {
        console.log(e);
        if (e.code == 10054) {
          this.$toast(e.message);
        } else {
          this.$toast(e.data);
        }
      }
    },
    //判断有几个材料
    wacthList() {
      if (this.materialList.length == 2) {
        this.increase = 1;
      } else if (this.materialList.length == 1) {
        this.increase = 2;
      } else if (this.materialList.length == 0) {
        this.increase = 3;
      }
    },
    // 删除数组中对应的数据
    closeBtn(item) {
      this.materialList.forEach((jtem, j) => {
        if (jtem.id == item.id) {
          this.materialList.splice(j, 1);
          if (this.synthesieType == "synthesisSilver") {
            this.setSilverfuse(this.materialList);
          } else if (this.synthesieType == "synthesisGold") {
            this.setGoldfuse(this.materialList);
          } else if (this.synthesieType == "synthesisPlatinum") {
            this.setPlatinumfuse(this.materialList);
          } else if (this.synthesieType == "synthesisDiamond") {
            this.setDiamondfuse(this.materialList);
          }
        }
      });
    },
    toPage(name, type) {
      if (type) {
        this.setSynthesieType(type);
      }
      this.$router.push({
        name: name,
        params: { name: type },
      });
    },
    // async synthesisNum() {
    //   // synthesisSilver=>白银,synthesisGold=>黄金,synthesisPlatinum=>铂金,synthesisDiamond=>钻石
    //   try {
    //     const res = await synthesisConfig({
    //       type: this.type,
    //     });
    //     console.log(res);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    hasLogin() {
      this.isShowLogin = false;
      // this.getIndex();
    },
    slideChange() {
      // this.setfuse([]);
      this.initialSlide = this.swiper.activeIndex;
      if (this.initialSlide == 0) {
        this.type = "synthesisSilver";
        this.grade = "白银胸针";
        this.material = "青铜胸针"; //需要的材料等级
        this.materialList = this.Silverfuse;
        console.log(this.Silverfuse);
      } else if (this.initialSlide == 1) {
        this.type = "synthesisGold";
        this.grade = "黄金胸针";
        this.material = "白银胸针"; //需要的材料等级
        this.materialList = this.Goldfuse;
      } else if (this.initialSlide == 2) {
        this.type = "synthesisPlatinum";
        this.grade = "铂金胸针";
        this.material = "黄金胸针"; //需要的材料等级
        this.materialList = this.Platinumfuse;
      } else if (this.initialSlide == 3) {
        this.type = "synthesisDiamond";
        this.grade = "砖石胸针";
        this.material = "铂金胸针"; //需要的材料等级
        this.materialList = this.Diamondfuse;
      }
      console.log(this.materialList);
    },
  },
};
</script>
<style lang="scss">
.thirtyfuse {
  .thirtyfuse_top {
    height: 400px;
    width: 100%;
    background-image: url("../../assets/img/thirty/fusebg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .record {
      width: 85px;
      height: 27px;
      border: 1px solid #ffffff;
      border-radius: 13px 0 0 13px;
      position: absolute;
      right: 0;
      top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 400;
      color: #000000;
      background: rgba($color: #fff, $alpha: 0.7);
      z-index: 20;
      img {
        height: 10px;
        width: 10px;
        margin-left: 5px;
      }
    }
    .swp {
      height: 435px;
      width: 100%;
      .swiper-container1 {
        height: 421px;
        width: 100%;
        overflow: hidden;
        .swiper-wrapper {
          display: flex;
        }
        .swiper-button-prev {
          width: 60px;
          height: 60px;

          border-radius: 50%;
          text-align: center;
          line-height: 50px;
          font-size: 30px;
          font-weight: 500;
          position: absolute;
          box-shadow: none;
          background-image: url("../../assets/img/thirty/left.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          left: 0px;
          top: 40%;

          outline: none;
        }
        .swiper-button-next {
          width: 60px;
          height: 60px;
          // background: #ffffff;

          border-radius: 50%;
          text-align: center;
          line-height: 50px;
          font-size: 30px;
          font-weight: 500;
          position: absolute;
          right: 0px;
          top: 40%;
          background-image: url("../../assets/img/thirty/right.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          outline: none;
        }

        .swiper-slide {
          width: 100%;
          height: 100%;
          flex-shrink: 0;
        }
        .swp_text1 {
          width: 130px;
          height: 40px;
          border: 1px solid #ffffff;
          border-radius: 20px;
          font-size: 18px;
          font-weight: bold;
          color: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 25px auto 0;
          background: rgba($color: #fff, $alpha: 0.7);
        }

        .swp_img {
          width: 130px;
          height: 178px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          text-align: center;
          margin-top: 100px;
        }
      }
    }
  }
  .thirtyfuse_btm {
    height: 315px;
    position: relative;
    top: -35px;
    background-image: url("../../assets/img/thirty/thirtyfuse_btm.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 25px 20px 0;
    .title {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      margin-bottom: 25px;
    }
    .fuse_material {
      display: flex;
      //   align-items: center;
      justify-content: space-between;
      align-items: flex-start;
      min-height: 150px;
      .addlist {
        width: 105px;
        height: 105px;
        border: 1px dashed #171717;
        opacity: 0.7;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .list {
        .box {
          width: 105px;
          height: 105px;
          background: #f5f9f9;
          border-radius: 5px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .closeBtn {
            height: 18px;
            width: 18px;
            position: absolute;
            top: 5px;
            right: 5px;
          }
          .gradeImg {
            width: 55px;
            height: 80px;
          }
        }
        .name {
          font-size: 12px;
          font-weight: 500;
          color: #000000;
          text-align: center;
          margin: 10px 0 5px;
        }
        .number {
          font-size: 12px;
          font-weight: 400;
          color: #000000;
          text-align: center;
        }
      }
    }
    .okbtn {
      width: 335px;
      height: 49px;
      background: linear-gradient(36deg, #00e899 0%, #25c9ff 100%);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .nobtn {
      width: 335px;
      height: 49px;
      background: rgba($color: #000, $alpha: 0.1);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($color: #171717, $alpha: 0.65);
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Confirm {
      width: 295px;
      min-height: 358px;
      background: #ffffff;
      border-radius: 10px;
      padding: 25px 20px 20px;
      .title {
        span {
          background: linear-gradient(237deg, #0ae0b6 0%, #19d3de 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .required {
        height: 180px;
        background: rgba($color: #171717, $alpha: 0.03);
        border-radius: 5px;
        margin-top: 25px;
        padding: 15px 12px 0;
        .required_title {
          font-weight: 400;
          color: rgba($color: #171717, $alpha: 0.7);
        }
        .required_listBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .list {
            .img_box {
              width: 72px;
              height: 72px;
              background: #ffffff;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 40px;
                height: 57px;
              }
            }
          }
        }
      }
      .tips {
        font-size: 12px;
        font-weight: 400;
        color: rgba($color: #171717, $alpha: 0.7);
        text-align: center;
        margin: 10px 0 0 0;
      }
      .btn_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .no_btn {
          width: 118px;
          height: 37px;
          background: #eeeff2;
          border-radius: 19px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          color: #171717;
        }
        .yes_btn {
          width: 118px;
          height: 37px;
          background: linear-gradient(36deg, #00e899 0%, #25c9ff 100%);
          border-radius: 19px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .Synthesis {
      .Synthesis_bg {
        height: 436px;
        width: 375px;
        margin: 0 auto;
        background: url("../../assets/img/thirty/synthesisBG.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        .title {
          color: #ffffff;
          margin: 0px 0 15px 0;
        }
        .title1 {
          font-size: 26px;
          font-weight: 800;
          background: linear-gradient(0deg, #ffffff 0%, #c9fffe 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
        }
        .rank_icon {
          height: 218px;
          width: 150px;
          position: absolute;
          top: 62%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .tips {
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-top: 20px;
      }
      .accept {
        width: 271px;
        height: 49px;
        background: linear-gradient(36deg, #00e899 0%, #25c9ff 100%);
        border-radius: 24px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-top: 15px;
      }
    }
  }
}
</style>
