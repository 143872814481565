<template>
  <div>
    <van-pull-refresh
      v-model="isLoading"
      loosing-text="下拉即可刷新"
      pulling-text="释放即可刷新"
      loading-text="加载中"
      @refresh="onRefresh"
    >
      <div class="cont">
        <div class="headerBgk">
          <div class="icon">
            <div class="backIcon icons" @click="onBack()">
              <img src="../../assets/img/sleepActivity/back.png" alt="" />
            </div>
            <div
              class="kefuIcon icons"
              @click="gotoPage('sleepActivityHdgz', 'kefu')"
            >
              <img src="../../assets/img/sleepActivity/kefu.png" alt="" />
            </div>
          </div>
          <div class="guizePage" @click="gotoPage('sleepActivityrecord')">
            邀请记录 >
          </div>
        </div>
        <div class="bottomBgk">
          <div
            class="switchClass"
            :class="switchs == 'week' ? 'bottomBgkWeek' : 'bottomBgkTotal'"
          >
            <div
              class="switchWeek"
              :class="switchs == 'week' ? 'activity' : ''"
              @click="onSwitch('week')"
            >
              周排行榜
            </div>
            <div
              class="switchTotal"
              :class="switchs == 'all' ? 'activity' : ''"
              @click="onSwitch('all')"
            >
              总排行榜
            </div>
          </div>
          <div class="switchBottom" >
            <div class="" v-show="!switchType">
              <div class="activityBgk" v-show="switchs == 'week'">
                <div class="activityTime" v-if="configure.time">
                  活动时间：{{ configure.week_start_time | dateFormat("day") }} -
                  {{ configure.week_end_time | dateFormat("day") }}
                </div>
              </div>
              <div class="switchRankingTop">
                <div v-if="list.length > 1" class="rankingTop silver">
                  <div class="portrait">
                    <img :src="list[1].avatar" alt="" />
                  </div>
                  <div class="userGrowing">
                    <div class="userName">{{ list[1].username }}</div>
                    <div class="integral">{{ list[1].growing }}</div>
                  </div>
                </div>
                <div v-if="list.length" class="rankingTop gold">
                  <div class="portrait">
                    <img :src="list[0].avatar" alt="" />
                  </div>
                  <div class="userGrowing">
                    <div class="userName">{{ list[0].username }}</div>
                    <div class="integral">{{ list[0].growing }}</div>
                  </div>
                </div>
                <div v-if="list.length > 2" class="rankingTop copper">
                  <div class="portrait">
                    <img :src="list[2].avatar" alt="" />
                  </div>
                  <div class="userGrowing">
                    <div class="userName">{{ list[2].username }}</div>
                    <div class="integral">{{ list[2].growing }}</div>
                  </div>
                </div>
              </div>
              <div v-if="list.length > 3">
                <div class="rank_list">
                  <div
                    class="list-item"
                    v-for="(item, index) in list.slice(3)"
                    :key="index"
                  >
                    <div class="ranking items">{{ item.ranking }}</div>
                    <div class="user items">
                      <div class="avatars">
                        <img :src="item.avatar" alt="" />
                      </div>
                      <div class="userName">{{ item.username }}</div>
                    </div>
                    <div class="value items">{{ item.growing }}</div>
                  </div>
                  <div class="notList">没有更多了~</div>
                </div>
              </div>
            </div>
            <div v-show="switchType">
              <van-loading type="circular" color="#2c2bff" />
            </div>
          </div>
        </div>
        <!-- 悬浮按钮 -->
        <div class="btnBgk">
          <div class="btnWh">
            <div class="top">
              <div>距离上一名还差：{{ rankingInfo.difference }}</div>
              <div @click="onShare()">继续邀请好友 ></div>
            </div>
            <div class="bottom">
              <div class="rankingUser bottoms">{{ rankingInfo.ranking }}</div>
              <div class="userInfo bottoms">
                <div class="userAvatar">
                  <img v-if="user.avatar != '0'" :src="user.avatar" alt="" />
                  <img v-else src="../../assets/img/user.png" alt="" />
                </div>
                <div class="userName">
                  {{ user.nickname }}
                </div>
              </div>
              <div class="rankingUsers bottoms">{{ rankingInfo.growing }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>

    <Share
      ref="Share"
      v-if="showShare"
      @close="onColse()"
      :show="showShare"
    ></Share>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="loginSuccess"
    ></login>
    <van-overlay :show="show" :custom-style="{ background: 'rgba(0,0,0,1)' }">
      <div class="loading">
        <img src="../../assets/img/sleepActivity/loading.gif" alt="" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Share from "./components/shares";
import { leaderboard, info } from "../../api/sleep";
import { mapMutations, mapState } from "vuex";
import { getWxConfig } from "../../../tool";
import Login from "../../components/Login";
export default {
  components: {
    Share,
    Login,
  },
  data() {
    return {
      btnData: {
        btnType: "", //按钮状态 1未参与 2已参与 3未开始 4已结束
        btnFont: "参与预约", //按钮文案
      },
      switchs: "week", //当前选中的是周还是总 默认周排行榜
      list: [], //排名数组
      rankingInfo: {}, //排名信息
      configure: {}, //info信息
      switchType: false, //周总排行榜切换状态
      showShare: false, //海报是否显示
      isLoading: false, //下拉刷新
      show: false, //loading显示
      isShowLogin:false, //登录
    };
  },
  created() {
    document.title = "118早睡行动预约开启";
    getWxConfig("无聊猿#1365向你发起号召啦", "“118早睡行动”有奖预约等你来");
  },
  mounted() {
    this.onLeaderboard();
  },
  filters: {
    //时间戳转化
    dateFormat(value, type) {
      if (!value) return "";
      let time = new Date(value * 1000);

      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      if (type == "day") {
        return timeAdd0(y) + "-" + timeAdd0(m) + "-" + timeAdd0(d);
      }
    },
  },
  destroyed() {},
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      invited_code: (state) => state.invited_code,
      invited_ids: (state) => state.invited_ids,
    }),
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),

    //登录成功
    loginSuccess() {
      this.isShowLogin = false;
      this.onLeaderboard();
    },
    //获取详情
    async onConfigure() {
      try {
        const res = await info({
          acctoken: this.isLogin,
        });
        let data = res.data.data;
        this.configure = data;
        this.show = false;
      } catch (e) {
        this.onError(e);
      }
    },
    /**
     * name  跳转路径
     * type  跳转参数
     */
    gotoPage(name, type) {
      this.$router.push({
        path: name,
        query: {
          type: type,
        },
      });
    },
    onBack() {
      this.$router.go(-1);
    },
    //排行榜切换
    onSwitch(e) {
      this.switchs = e;
      this.switchType = true;
      setTimeout(() => {
        this.switchType = false;
      }, 2000);
      this.onLeaderboard();
      this.$forceUpdate();
    },
    //获取排行榜
    async onLeaderboard() {
      try {
        const res = await leaderboard({ type: this.switchs });
        this.rankingInfo = res.data;
        if (res.data.leaderboard instanceof Array) {
          this.list = res.data.leaderboard;
        } else {
          this.list = Object.values(res.data.leaderboard);
        }
        await this.onConfigure();
      } catch (e) {
        this.onError(e);
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.onLeaderboard();

        this.isLoading = false;
        clearInterval(this.timer);
      }, 1000);
    },
    //GIF加载完毕
    onImgSuccess() {
      setTimeout(() => {
        this.show = false;
      }, 1000);
      return (this.imgSrc = require("../../assets/img/sleepActivity/activity.gif"));
    },

    //关闭海报
    onColse() {
      this.showShare = false;
    },
    //海报生成
    onShare() {
      this.showShare = true;
    },

    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
      this.loadings = false;
    },
  },
};
</script>
<style>
</style>
<style scoped lang="scss">
.cont{
      min-height: 100vh;
    padding-bottom: 135px;
}
/deep/ .van-loading {
  text-align: center;
}
/deep/ .van-overlay {
  z-index: 5 !important;
}
/deep/ .van-pull-refresh{
  background: #0b051d!important;
}
/deep/ .van-pull-refresh__head {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

@mixin btn_Fun($url) {
  .btnFont {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      color: #fff;
      font-size: 20px;
      /* font-weight: 700; */
      letter-spacing: 2px;
      margin-top: -8px;
    }
  }
}
// 超出显示...单文本
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
//设置背景图
@mixin bgk_Fun($url) {
  background-image: url($url);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.headerBgk {
  height: 450px;
  position: relative;
  @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingHerder.png");

  .icon {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    .icons {
      width: 30px;
      height: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .guizePage {
    position: absolute;
    color: #fff;
    bottom: 180px;
    right: 25px;
    font-size: 13px;
  }
}

.btnBgk {
  position: fixed;
  bottom: 0;
  width: 100%;
  @include bgk_Fun($url: "../../assets/img/sleepActivity/bottom.png");
  z-index: 3;

  .btnWh {
    width: 100%;
    height: 90px;
    padding: 7px 30px 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      div {
        color: #979999;
        font-size: 11px;
      }

      div:last-child {
        color: #979999;
        font-size: 11px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 10px 0;
      @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingInfo.png");
      height: 43px;
      box-shadow: 0px 0px 15px #0008ff;
      border-radius: 25px;
      .bottoms {
        width: 33%;
        text-align: center;
      }

      .userInfo {
        display: flex;
        align-items: center;

        .userName {
          color: #ffffff;
          font-size: 11px;
          width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .userAvatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          background: #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .rankingUser {
        color: #ffffff;
      }

      .rankingUsers {
        color: #ffffff;
      }
    }
  }
}

.bottomBgk {
  width: 90%;
  margin: -165px auto 0;
  position: relative;
  z-index: 2;

  .switchClass {
    height: 40px;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 8px 0;
    height: 100%;

    .switchWeek {
      font-size: 15px;
    }

    .switchTotal {
      font-size: 15px;
    }
  }

  .switchBottom {
    background: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 15px;
    width: 100%;
    margin: -1px auto 0;
  }

  .activity {
    color: #2c2bff !important;
  }

  .switchRankingTop {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    margin-bottom: 15px;
    .rankingTop {
      width: 30%;
      position: relative;

      .portrait {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin: auto;
        margin-top: 21px;
        margin-bottom: 4px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .userGrowing {
        color: #ffffff;

        .userName {
          @include ellipsis();
          font-weight: 700;
          font-size: 17px;
          text-align: center;
          width: 80%;
          margin: 0 auto 0px;
        }

        .integral {
          font-weight: 700;
          font-size: 16px;
          text-align: center;
        }
      }
    }

    .silver {
      height: 120px;

      .integral {
        color: #8eb0fe;
      }

      @include bgk_Fun(
        $url: "../../assets/img/sleepActivity/rankingSilver.png"
      );
    }

    .gold {
      height: 120px;

      .integral {
        color: #f0b292;
      }

      @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingGold.png");
    }

    .copper {
      height: 120px;

      .integral {
        color: #4cd6b5;
      }

      @include bgk_Fun(
        $url: "../../assets/img/sleepActivity/rankingCopper.png"
      );
    }
  }

  .rank_list {
    height: 200px;
    overflow-y: scroll;
    .notList {
      text-align: center;
      margin-top: 20px;
      color: #3c3c3c;
      font-size: 12px;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #c5c5c5;
      padding: 20px 0;

      .items {
        width: 33%;
        text-align: center;
      }

      .ranking {
        font-size: 16px;
        color: #0037d4;
        font-weight: 700;
      }

      .user {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        .avatars {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          overflow: hidden;
          background: #979999;
          position: absolute;
          left: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          color: #000000;
          font-size: 13px;
          width: 80px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-weight: 700;
          position: absolute;
          right: -12px;
        }
      }

      .value {
        color: #000;
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
  .activityBgk {
    padding: 5px 0;

    .activityTime {
      background: #dcdcdc;
      text-align: center;
      border-radius: 20px;
      width: 75%;
      margin: auto;
      padding: 10px 0;
      font-size: 11px;
      padding: 3px;
    }
  }
}

.bottomBgkWeek {
  @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingWeekly.png");
}

.bottomBgkTotal {
  @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingTotal.png");
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}
</style>
