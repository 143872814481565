<template>
  <div class="back" @click="back">
    <img :src="require('../assets/img/back.png')" width="60%" height="50%" />
  </div>
</template>

<script>
export default {
  name: "Back",
  methods: {
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/Thirty" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.back {
  position: absolute;
  left: 17px;
  top: 17px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: rgba(236, 236, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
</style>
