<template>
  <div class="Thirtyrule">
    <div class="bg">
      <div class="back">
        <van-icon class="icon" @click="back()" name="arrow-left" />
      </div>
      <div class="title1">「艾草胸针」</div>
      <div class="title2">
        非遗文化数字藏品<br />
        共创活动规则
      </div>
      <div class="rule_box">
        <div class="head m-b-15">藏品介绍</div>
        <div class="wd justify m-b-30">
          轻松小镇「艾草胸针」非遗数字藏品不仅仅是简单的数字藏品，还是李时珍数字艺术头像中的重要配饰，也是轻松小镇元宇宙中的NFT数字藏品可穿戴道具，在轻松小镇东方美学元宇宙中，你可以戴上一枚精美的艾草胸针非遗数字藏品彰显独特身份，「艾草胸针」非遗数字藏品是与「蕲春手工石臼艾绒艾条制作技艺」非物质文化传承人（蕲春李时珍蕲艾研究所所长）王曦战略合作的一款具有纪念及收藏意义的数字藏品，于李时珍504年诞辰正式发布并开启预约，总量151852枚（该数值取自李时珍诞辰1518.5.26）；李时珍在本草纲目中记载【自成化以来，则以蕲州者为胜，用充方物，天下重之，谓之蕲艾】，蕲艾是李时珍故乡蕲春的医药特产，轻松小镇联合【蕲春李时珍蕲艾研究所】以蕲艾中的皇家供艾“九尖蕲艾“为原型，创作了【艾草胸针非遗数字藏品系列】，以纪念伟大医学家李时珍对后世的卓越贡献。
        </div>
        <div class="head m-b-20">活动时间</div>
        <div class="wd justify m-b-5">
          「艾草胸针」非遗文化数字藏品共设3期抽签空投活动，每一期将会开启限定抽签名额。
        </div>
        <div class="wd justify m-b-5">第三期总中签数量：18888</div>

        <div class="wd justify m-b-5">
          第三期预约抽签时间：6月15号18:00—6月20号12:00
        </div>
        <div class="wd justify m-b-5">排名快照时间：6月20号12:00</div>
        <div class="wd justify m-b-30">
          第三期抽签规则：用户参与「艾草胸针」第三期预约活动后即可实时抽签，完成任务获得幸运值累计越高，抽中藏品的概率越大，可获得的抽签次数越多。
        </div>
        <!-- <div class="wd justify m-b-30">
          第二期和第三期活动将会在第一期结束后公布详细规则。
        </div> -->
        <div class="head m-b-20">藏品发行数量</div>
        <div class="wd justify m-b-30">
          艾草胸针非遗数字藏品分为【青铜款，白银款，黄金款，铂金款，钻石款】5款<br />
          青铜款艾草胸针非遗数字藏品总量151852枚，在李时珍诞辰日（2022.5.26）正式开启预约，全部免费发放。<br />
          其中白银款，黄金款，铂金款，钻石款艾草胸针非遗数字藏品仅支持通过青铜款胸针合成获得，合成款数量将由用户合成数据决定。
        </div>
        <div class="head m-b-20">藏品获得方式</div>
        <div class="wd justify m-b-10">
          目前有四种方式可以获得「艾草胸针」非遗文化数字藏品。
        </div>
        <div class="wd justify m-b-10">
          （1）公众号参与活动获得：用户通过转发公众号指定文章参与活动，在预约结束成功参与公众号活动的前16666名实名认证用户将会直接获得1个青铜款「艾草胸针」非遗文化数字藏品空投。
        </div>
        <div class="wd justify m-b-10">
          （2）完成任务获得：用户在App活动页面参与预约后，通过完成实名认证、发送公众号口令、发送社群口令、以及邀请好友获得更高的幸运值，预约活动结束以后可参与藏品抽签，累计的幸运值越高，可获得的抽签次数越高，抽中藏品的概率越大，抽中的藏品数量越多，每个用户可抽中多个藏品并参与后续的藏品合成玩法。
        </div>
        <div class="wd justify m-b-10">
          （3）幸运值排行榜空投：用户在App活动页面参与预约后，通过完成实名认证、发送公众号口令、发送社群口令、以及邀请好友等任务获得更高的幸运值，用户在「艾草胸针」非遗文化数字藏品共创活动「幸运值排行榜」中排名前200，将在活动结束后直接获得5个获得「艾草胸针」非遗文化数字藏品空投奖励。
        </div>
        <div class="wd justify m-b-10">
          注：排名先后依据为【用户在活动中获得的幸运值数量】，若在200名时若出现幸运值相同的情况，则先获得该数量幸运值的用户为先。
        </div>
        <div class="wd justify m-b-10">
          （4）持有藏品空投：本次我们将会对所有持有轻松小镇部分藏品和创世艾草种子的用户开启分批免费空投。
        </div>
        <div class="wd justify m-b-10">第一批空投对象：</div>
        <div class="wd justify m-b-10">
          获得创始种植者勋章的用户将会获得9个「艾草胸针」非遗文化数字藏品空投；
        </div>
        <div class="wd justify m-b-10">
          持有姜小竹数字藏品的用户将会获得5个「艾草胸针」非遗文化数字藏品空投；
        </div>
        <div class="wd justify m-b-10">
          持有SSR级李时珍头像数字藏品、《本草纲目》明·金陵版终极典藏款数字藏品的用户将会获得4个「艾草胸针」非遗文化数字藏品空投；
        </div>
        <div class="wd justify m-b-10">第二批空投对象：</div>
        <div class="wd justify m-b-10">
          持有SR级李时珍头像数字藏品的用户将会获得3个「艾草胸针」非遗文化数字藏品空投；
        </div>
        <div class="wd justify m-b-10">第二批空投时间：6月15日 18:00</div>
        <div class="wd justify m-b-10">
          持有《本草纲目》明·金陵版、R级李时珍头像数字藏品的用户将获得2个「艾草胸针」非遗文化数字藏品空投；
        </div>
        <div class="wd justify m-b-10">第三批空投对象：</div>
        <div class="wd justify m-b-10">
          所有持有创世艾草种子的用户将会获得1个「艾草胸针」非遗文化数字藏品空投；
        </div>
        <div class="wd justify m-b-10">
          【本次特殊赋能用户】：持有Luck
          Quark金勋章藏品的用户将会获得2个「艾草胸针」非遗文化数字藏品空投；持有Luck
          Quark银勋章藏品的用户将会获得1个「艾草胸针」非遗文化数字藏品空投。
        </div>
        <div class="wd justify m-b-20">
          注：除【本草纲目】未合成款单个账号不叠加外，其他符合空投要求的藏品单个账号按持有数量叠加空投。
        </div>
      </div>
    </div>
    <div class="rule_box2">
      <div class="head m-b-20">合成升级玩法</div>
      <div class="wd justify m-b-5">
        「艾草胸针」非遗文化数字藏品将在抽签活动结束后，开启该系列藏品合成。<br />
        「艾草胸针」非遗数字藏品品级共分为【青铜款、白银款、黄金款、铂金款、钻石款】5种级别。青铜款总量151852枚，均由用户免费抽取或参与空投及活动获得。剩余白银款、黄金款、铂金款、钻石款「艾草胸针」非遗文化数字藏品用户仅可通过青铜款艾草胸针藏品合成获得。
      </div>
      <div class="wd justify m-b-30 bold">
        用户用于合成的藏品数量越多，对应合成的藏品品级将越高，具体的合成玩法将会后续在公众号公布。
      </div>
      <div class="head m-b-20">实体兑换玩法</div>
      <div class="wd justify m-b-10">
        本次最终合成的不同品级的「艾草胸针」非遗文化数字藏品不仅会拥有丰富的线上赋能，也会同时开启与李时珍故乡蕲春艾草实体产业及当地知名艾草企业的深度合作（目前已与「蕲春手工石臼艾绒艾条制作技艺」非物质文化传承人，蕲春李时珍蕲艾研究所所长王曦及所属企业完成合作签约）；本次轻松小镇艾草胸针旨在用数字藏品、数字化创新为推广中医文化、赋能实体经济带来新的玩法；合成款艾草胸针数字藏品可获得实体艾草产品的免费空投兑换，为数字化赋能实体产业探索创新玩法、创新路径，为实体经济、健康产业提供新可能。
      </div>
      <div class="wd justify m-b-30">
        注：本活动最终解释权归活动方「轻松小镇」所有。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Thirtyrule",
  components: {},
  data() {
    return {};
  },

  mounted() {},
  destroyed() {},
  created() {},
  watch: {},
  methods: {
    toUrl(url) {
      window.location.href = url;
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.Thirtyrule {
}
.bg {
  width: 100%;
  min-height: 2100px;
  background: url("../../assets/img/thirty/rule.png") no-repeat;
  background-size: 100% 100%;
}
.back {
  padding: 20px 20px 0;
  .icon {
    color: #fff;
    font-size: 25px;
  }
}
.rule_box2 {
  background: #fff;
  padding-bottom: 40px;
}
.title1 {
  font-size: 39px;
  font-weight: bold;
  color: #000000;
  margin: 20px 0 15px 0;
}
.title2 {
  padding: 0 20px;
  font-size: 27px;
  font-weight: 500;
  color: #000000;
}
.title3 {
  padding: 0 20px;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  margin-top: 15px;
}
.rule_box {
  margin-top: 200px;
  padding: 0 25px;
}
.rule_box2 {
  padding: 0 25px;
}
.head {
  font-size: 20px;
  font-weight: bold;
  color: #53bdac;
}
.head2 {
  width: 65px;
  height: 19px;
  background: #000000;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.wd {
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  line-height: 1.5em;
}
.mall_title {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  span {
    height: 4px;
    width: 4px;
    background: #000000;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.f8wd {
  font-size: 13px;
  font-weight: 400;
  color: #757575;
}
</style>
