<template>
  <div>
    <div class="tabbar">
      <div class="tabbarItem" @click="toPage('yqMallShopping')">
        <img :src="require('../../../assets/img/yqMall/shopping.png')" alt="" />
        <div class="tabbarText">购物车</div>
      </div>
      <div class="tabbarItem" @click="toPage('yqMallOrder')">
        <img style="width: 20px;" :src="require('../../../assets/img/yqMall/order.png')" alt="" />
        <div class="tabbarText">订单</div>
      </div>
      <div class="tabbarItem" @click="toPage('yqMallKf')">
        <img :src="require('../../../assets/img/yqMall/kef.png')" alt="" />
        <div class="tabbarText">客服</div>
      </div>
    </div>
  </div>
</template>

<script>
// import store from "../store";

export default {
  props: ["show", "collectionUrl"],

  components: {},
  data() {
    return {
      toappx: false //判断是否在APP内部
    };
  },

  computed: {},
  async mounted() {
  },
  methods: {
    toPage(name) {
      // this.$router.push({ name });
      // return false;
      this.toappx = this.openInWebview()
      if (name == 'yqMallShopping' || name == 'yqMallOrder') {
        if (this.toappx) {
          this.$router.push({ name });
        } else {
          var ua = navigator.userAgent.toLowerCase()
          if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            this.$toast('请右上角在默认浏览器浏览器打开喔~');
            // 微信浏览器判断
            return false
          }
        this.$toast("请打开APP，若未下载先下载轻松小镇APP喔~");
          let uri = "relax-town://pages/town/index";
          var _clickTime = new Date().getTime();
          window.location.href = uri;
          //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
          var _count = 0,
            intHandle;

          intHandle = setInterval(function () {
            _count++;
            var elsTime = new Date().getTime() - _clickTime;

            if (_count >= 100 || elsTime > 3000) {
              clearInterval(intHandle);
              //检查app是否打开
              if (document.hidden || document.webkitHidden) {
                // 打开了
              } else {
                setTimeout(() => {
                  window.location.href = 'http://app.relaverse.cn/m/download.html'
                }, 3000)
              }
            }
          }, 20);
        }
      } else {
        this.$router.push({ name });
      }
    },
    openInWebview() {
      /* 返回true或false; */
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        console.log(1)
        // 微信浏览器判断
        return false
      } else if (ua.match(/QQ/i) == 'qq') {
        // QQ浏览器判断
        return false
      } else if (ua.match(/WeiBo/i) == 'weibo') {
        return false
      } else {
        if (ua.match(/Android/i) != null) {
          return ua.match(/browser/i) == null
        } else if (ua.match(/iPhone/i) != null) {
          return ua.match(/safari/i) == null
        } else {
          return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tabbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px 20px;
  background: #fff;
  z-index: 1999;

  .tabbarItem {
    text-align: center;

    img {
      width: 22px;
      height: 22px;
      margin-bottom: 5px;
    }

    .tabbarText {
      font-size: 11px;
    }
  }
}
</style>
