<template>
  <van-overlay :show="show" @click="close">
    <div class="wrapper sign-in" @click.stop>
      <div class="block" v-if="proData.day < 7">
        <div class="close">
          <van-icon name="cross" size="15" color="#69686D" @click="close"/>
        </div>
        <div class="title bold m-b-10">连续签到第{{proData.day}}天</div>
        <div>获得{{proData.stone}}元石</div>
        <div class="box">
          <img :src="require('../assets/img/tone-dlg.png')" width="100%" height="100%"/>
        </div>
        <div class="btn f-18" @click="close">收下礼物</div>
      </div>
      <div class="block wrap" v-else>
        <div class="close">
          <van-icon name="cross" size="15" color="#69686D" @click="close"/>
        </div>
        <div class="title bold m-b-10">连续签到第{{proData.day}}天</div>
        <div>获得{{proData.box}}签到盲盒和{{proData.stone}}元石</div>
        <div class="box"></div>
        <div class="btn f-18" @click="close">收下礼物</div>
      </div>
    </div>
  </van-overlay>

</template>

<script>
export default {
  name: 'SignIn',
  props: {
    show: Boolean,
    proData: Object
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.sign-in{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block{
    width: 80%;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 10px;
    .title{
      font-size: 22px;
    }
    .box{
      margin-top: 30px;
      width: 141px;
      height: 165px;
    }
    .close{
      position: absolute;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: #ECECEC;
      right: 10px;
      top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn{
      margin-top: -10px ;
      width: 159px;
      height: 43px;
      line-height: 43px;
      background: #0A0A0A;
      border-radius: 21px;
      color: white;
      text-align: center;
    }
  }
  .wrap{
    height: 352px;
    background: url("../assets/img/sign-dlg.png") no-repeat;
    background-size: cover;
  }
}
</style>
