
<template>
  <div>
    <nav-bar title="设置" left-arrow @click-left="onClickLeft" />
    <div class="setCont">
      <div class="set" @click="sendMessage1('realPassword')">
        <div class="setLeft">密码安全</div>
        <div class="setRight">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="set" @click="sendMessage1('realAddress')">
        <div class="setLeft">地址管理</div>
        <div class="setRight">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="set" @click="toPage('yqMallPawnRules')">
        <div class="setLeft">典当规则</div>
        <div class="setRight">
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NavBar } from "vant";
export default {
  components: { NavBar },
  data() {
    return {
      yqImg: require("../../assets/img/yqMall/yq.png"),
      swiperOption: {
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
        loop: true, // 无限循环
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 9,
        loopAdditionalSlides: 400,
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  // created activated
  created() {
    console.log("created");
  },
  // 监听变量
  watch: {},
  // 在使用到的页面 添加如下代码
  mounted() {
  },

  // 页面离开 destroyed deactivated
  destroyed() {
    console.log("destroyed");
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    toPage(name) {
      this.$router.push({ name });
    },
    //向APP发送指令 realPassword 跳转密码  realAddress 跳转地址
    sendMessage1(e) {
      // document.addEventListener("UniAppJSBridgeReady", function () {
        uni.postMessage({
          data: {
            action: e,
          },
        });
      // });
    },
  },
};
</script>
<style  scoped lang="scss">
/deep/.van-nav-bar .van-icon {
  color: #000000;
}
.setCont {
  padding: 20px;
  .set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
</style>