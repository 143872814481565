<template>
  <div>
    <div class="head">
      <div>
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div>
      <div class="head-title">
        <div class="title">选择一个你想授权的藏品</div>
      </div>

      <div
        class="head-btn"
        @click="onClick()"
        :class="
          JSON.stringify(selects) != '{}'
            ? 'head-btnSelect'
            : 'head-btnDontSelect'
        "
      >
        <div>完成</div>
      </div>
    </div>
    <div>
      <div v-if="list.length" class="bottom">
        <div v-for="(item, index) in list" class="contBgk" :key="item.id">
          <div
            v-if="JSON.stringify(selects) != '{}' && item.id != selects.id"
            class="mask"
          ></div>
          <div class="listItem" @click="onSelect(item, index)">
            <div class="itemTop">
              <div class="itemImg">
                <img :src="item.nft_img_url" alt="" />
              </div>
              <div
                class="itemSelect"
                :class="item.select ? 'itemSelectSuccess' : ''"
              >
                <div v-show="item.select">
                  <img src="../../assets/img/lszSolicitation/tick.png" alt="" />
                </div>
              </div>
            </div>
            <div class="itemBtm">
              {{ item.nft_title }}
            </div>
          </div>
        </div>
      </div>
      <div class="notList" v-else>
        <img src="../../assets/img/lszSolicitation/notList.png" alt="" />
        <div class="listFont">
          <div>您还没有李时珍艺术俱乐部藏品</div>
          <div>快去找好友送你一个吧～</div>
        </div>
      </div>
    </div>
    <van-overlay
      z-index="100"
      :show="loading"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <van-loading
        type="circular"
        size="30"
        color="#42ce59"
        v-if="loading"
        class="loding"
        vertical
      >
        加载中...
      </van-loading>
    </van-overlay>
    <van-overlay
      z-index="100"
      :show="authorizationPopup"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="wrapperTitle">你是否选择以下藏品申请授权</div>
          <div class="wrapperCont">
            <div class="wrapperImg">
              <img :src="selects.nft_img_url" alt="" />
            </div>
            <div class="wrapperName">
              <div>{{ selects.nft_title }}</div>
              <div>#{{ selects.token_id }}</div>
            </div>
          </div>
          <div class="wrapperTime">
            <div>授权时长：<span>单次授权（15天）</span></div>
          </div>
          <div class="agreement">
            <div v-show="btnsState" class="agreementTitle">请先勾选协议</div>
            <div class="agreementFont">
              <div
                class="select"
                @click="onSelects()"
                :class="agreementSelectType ? 'selectSuccess' : ''"
              >
                <div v-show="agreementSelectType">
                  <img src="../../assets/img/lszSolicitation/tick.png" alt="" />
                </div>
              </div>
              <div class="text">请先阅读并勾选</div>
              <div class="xy" @click="gotoPage('SolicitationCpxq')">
                《藏品授权协议》
              </div>
            </div>
          </div>
          <div class="btn">
            <div class="btns btnN" @click="authorizationPopup = false">
              <div>再想想</div>
            </div>
            <div class="btns btnT" @click="onAuthorization()">
              <div>确认申请</div>
            </div>
          </div>
          <div class="tips">
            注：本次提交为申请授权申请，需要品牌方最终同意确认后才可完成授权，提交后还可更改选择。授权成功后藏品将冻结，在授权期不可转赠/授权等操作；若成功授权则本次授权自动失效。
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { choose, join } from "../../api/solicitation";
export default {
  name: "collection",
  computed: {
    ...mapState({
      isLogin: (state) => state.token,
      lszCollectionSelect: (state) => state.lszCollectionSelect,
    }),
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute(
        "style",
        "background:linear-gradient(90deg, #0C0C0D, #0F131B, #0C0C0D)"
      );
  },
  data() {
    return {
      list: [],
      // list:[],
      selects: {},
      authorizationPopup: false, //是否显示授权弹框
      agreementSelectType: false, //是否勾选协议
      btnsState: false, //点击确认申请判断是否勾选协议
      loading: true,
    };
  },
  mounted() {
    this.onClloectionList();
  },
  methods: {
    ...mapMutations({
      setLszCollectionSelect: "setLszCollectionSelect",
    }),
    //授权
    async onAuthorization() {
      if (!this.agreementSelectType) {
        this.btnsState = true;
        return false;
      }
      try {
        const res = await join({
          box_id: this.selects.id,
          acctoken: this.isLogin,
        });
        this.$toast("授权成功~");
        this.setLszCollectionSelect({});
        setTimeout(() => {
          this.$router.back();
        }, 2000);
      } catch (e) {
        this.onError(e);
      }
    },
    //用户藏品
    async onClloectionList() {
      try {
        const res = await choose({
          acctoken: this.isLogin,
        });
        let data = res.data.list;
        let list = [];
        for (let i = 0; i < data.length; i++) {
          data[i] = {
            ...data[i],
            select: false,
          };
          if (
            (this.lszCollectionSelect &&
              data[i].id == this.lszCollectionSelect.id) ||
            (res.data.selected && res.data.selected == data[i].id)
          ) {
            if (JSON.stringify(this.lszCollectionSelect) != "{}") {
              this.selects = this.lszCollectionSelect;
            } else {
              this.selects = data[i];
            }

            data[i].select = true;
          }
          list.push(data[i]);
        }
        this.list = list;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.onError(e);
      }
    },
    gotoPage(name) {
      this.$router.push({
        name,
      });
    },
    onClick() {
      if (JSON.stringify(this.selects) != "{}") {
        this.setLszCollectionSelect(this.selects);
        this.authorizationPopup = true;
      }
    },
    onSuccess() {
      this.authorizationPopup = false;
    },
    close() {
      this.authorizationPopup = false;
    },
    onSelects() {
      this.agreementSelectType = !this.agreementSelectType;
      if (this.agreementSelectType) {
        this.btnsState = false;
      }
    },
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
    },
    onSelect(item, index) {
      this.list[index].select = !this.list[index].select;
      this.selects = this.list[index];
      if (!this.list[index].select) {
        this.selects = {};
        this.setLszCollectionSelect({});
      }
    },
    back() {
      this.setLszCollectionSelect({});
      this.$router.back();
    },
  },
};
</script>
<style></style>
<style scoped lang="scss">
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    width: 80%;
    background: #000;
    padding: 18px;
    margin: auto;
    border-radius: 28px;

    img {
      pointer-events: unset;
    }

    .wrapperTitle {
      text-align: center;
      color: #7c7f83;
      margin-bottom: 20px;
    }

    .wrapperCont {
      background: #1f2627;
      border-radius: 13px;
      padding: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .wrapperImg {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        margin-right: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .wrapperName {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
      }

      .wrapperName div:last-child {
        margin-top: 5px;
      }
    }

    .wrapperTime {
      border: 1px solid #7c7f83;
      width: 100%;
      padding: 15px 20px;
      margin-bottom: 20px;
      border-radius: 5px;

      div {
        font-size: 12px;
        color: #928b9f;
        text-align: center;
        span {
          color: #ffffff !important;
        }
      }
    }

    .agreement {
      position: relative;

      .agreementTitle {
        background-image: url("../../assets/img/lszSolicitation/bubble2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 110px;
        height: 32px;
        position: absolute;
        top: -38px;
        left: -17px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 23px; */
        padding-bottom: 7px;
        font-size: 11px;
      }

      .agreementFont {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .select {
          border: 1px solid #ffffff;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        .selectSuccess {
          background: #ffffff;

          display: flex;
          align-items: center;
          justify-content: center;

          div {
            img {
              width: 10px;
              height: 10px;
            }
          }
        }

        .text {
          color: #918b9f;
          font-size: 12px;
        }

        .xy {
          color: #ffffff;
          font-size: 12px;
        }
      }
    }

    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45%;
        font-size: 14px;
        padding: 10px;
        border-radius: 30px;
      }

      .btnN {
        background: #212528;
        color: #9b9d9d;
      }

      .btnT {
        background: #22464b;
        color: #ffffff;
      }
    }

    .tips {
      color: #9b9d9d;
      font-size: 11px;
      line-height: 20px;
    }
  }
}

.notList {
  padding-top: 100px;
  text-align: center;

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
  }

  .listFont {
    div {
      text-align: center;
      font-size: 13px;
      color: #7c7f83;
      margin-bottom: 10px;
    }
  }
}

.bottom {
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  padding: 80px 10px 0;
}

.mask {
  width: 47%;
  height: 240px;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
}

.contBgk {
  background: #272b32;
  // margin: 0 40px 40px 0;
  border-radius: 10px;
  overflow: hidden;
  width: 47%;
  // width: px;
  margin-right: 18px;
  box-sizing: border-box;
  margin-bottom: 10px;

  &:nth-child(2n) {
    margin-right: 0;
  }

  .itemTop {
    width: 100%;
    position: relative;

    .itemImg {
      width: 100%;
      height: 200px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .itemSelect {
      border: 1px solid #ffffff;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 10px;
      top: 10px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      div {
        img {
          width: 10px;
          height: 10px;
        }
      }
    }

    .itemSelectSuccess {
      background: #ffffff;
    }
  }

  .itemBtm {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 10px 0;
    font-size: 12px;
  }
}

.head {
  background-size: 100% 100%;
  position: fixed;
  padding: 20px 10px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  width: 100%;
  z-index: 10;

  .head-title {
    color: #ffffff;
    text-align: center;
    font-size: 15px !important;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 40px;
  }

  .head-btn {
    transition: all 0.5;
    width: 100px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }

  .head-btnSelect {
    background: #ffffff;
    color: #000000;
  }

  .head-btnDontSelect {
    background: #1a1a1a;
    color: #5c5c5c;
  }
}
</style>
