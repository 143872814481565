import http from './http.js'


export const lottery = (data) => {
  return http.post('index/shizhen/lottery', data)
}
//领取单个
export const acceptOneApi = (data) => {
  return http.post('index/shizhen/acceptOne ', data)
}
//领取全部
export const acceptAllApi = (data) => {
  return http.post('index/shizhen/acceptAll ', data)
}
export const giveListapi = (data) => {
  return http.post('index/shizhen/giveList', data)
}

// 查询转赠用户
export const userQuery = (data) => {
  return http.post('index/Member/userQuery', data)
}

// 转正
export const giveApi = (data) => {
  return http.post('index/shizhen/give', data)
}

// 首页预约
export const reserve = (data) => {
  return http.post('index/shizhen/reserve', data)
}


// 合成百草
export const synthesis = (data) => {
  return http.post('index/shizhen/synthesis', data)
}

// 百草排名
export const ranking = (data) => {
  return http.post('index/Shizhen/ranking', data)
}

// 转正查看是否有安全密码
export const hasCode = (data) => {
  return http.post('/index/Security/hasCode', data)
}

// 转正查看是否有安全密码
export const useSecretCode = (data) => {
  return http.post('/index/security/useSecretCode', data)
}


// 转正查看是否有安全密码
export const newLottery = (data) => {
  return http.post('index/shizhen/newLottery', data)
}
// 分享海报数据
export const sharePoster = (data) => {
  return http.post('/index/Member/sharePoster', data)
}
