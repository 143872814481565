<template>
  <div class="page-box">
    <div class="heads">
      <div class="backs">
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div>
    </div>
    <div>
      <div class="bgk_Btm">
        <div class="bgk bgk1">
          <img
            class="img"
            src="../../assets/img/lszSolicitation/solictations/rink.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="bgk_Top">
        <div class="bgk bgk1">
          <img
            class="img"
            src="../../assets/img/lszSolicitation/title3.png"
            alt=""
          />
        </div>
        <div class="bgk_Times">
          <div class="timsIns" v-if="endTime">
            <img src="../../assets/img/lszSolicitation/naozhong.png" alt="" />
            <div>{{ endTime }}</div>
          </div>
          <van-loading
            v-else
            type="circular"
            size="20"
            color="#ffffff"
            class="loding"
          />
        </div>
        <div class="ranking">
          <div class="rankingTop">
            <div>排名</div>
            <div>用户</div>
            <div>助力值</div>
          </div>
          <div class="rank_list">
            <div class="list-item" v-for="(item, index) in list" :key="index">
              <div class="ranking items">{{ item.ranking }}</div>
              <div class="user items">
                <div class="avatars">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="userName">{{ item.username }}</div>
              </div>
              <div class="value items">{{ item.growing }}</div>
            </div>
            <div class="notList">没有更多了~</div>
          </div>
        </div>
        <div class="userRankings">
          <div class="top">
            <div>距上一名还差：{{ difference }}助力值</div>
            <div @click="onShare()">继续邀请好友 ></div>
          </div>
          <div class="bottom">
            <div class="rankingUser bottoms">
              {{ userRanking.ranking }}
            </div>
            <div class="userInfo bottoms">
              <div class="userAvatar">
                <img :src="user.avatar" alt="" />
              </div>
              <div class="userName">
                {{ user.nickname }}
              </div>
            </div>
            <div class="rankingUsers bottoms">
              {{ userRanking.growing }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Share ref="Share" @close="onColse()" :show="showShare"></Share>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { configure, leaderboard, ranking } from "../../api/solicitation";
import Login from "../../components/Login";
import Share from "./components/share";
import { getWxConfig } from "../../../tool";
const baseUrl = require("../../../site");

export default {
  name: "thirty",
  components: {
    Login,
    Share,
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#080808;");
  },
  data() {
    return {
      list: [],
      timer: "",
      endTime: "",
      userRanking: {}, //用户当前排名
      difference: "", //距上次还差多少助力值
      showShare: false, //分享海报展示
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  mounted() {
    this.onConfigure();
    this.onList();
    this.onRanking();
  },
  destroyed() {
    clearInterval(this.timer);
  },

  created() {
    // this.browserOpenApp();
    // window.location.href = "relax-town:///pages/user/realName";

    // this.getIndex();
    getWxConfig(
      "可穿戴非遗文化藏品艾草胸针火热预约中",
      "点击链接参与活动有机会免费获得"
    );
    // toApp("relax-town:///pages/user/realName");
  },
  watch: {},
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    //用户当前排名
    async onRanking() {
      try {
        const res = await ranking({
          acctoken: this.isLogin,
        });
        this.userRanking = res.data;
      } catch (e) {
        console.log(e);
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    //倒计时
    async onConfigure() {
      try {
        const res = await configure({
          acctoken: this.isLogin,
        });
        this.timer = setInterval(() => {
          const time = this.countDown(Number(res.data.end_time));
          if (!time) {
            clearInterval(this.timer);
            this.endTime = "活动已结束";
          } else {
            this.endTime = time;
          }
        }, 1000);
      } catch (e) {
        this.onError(e);
      }
    },
    //获取排行榜
    async onList() {
      try {
        const res = await leaderboard({
          acctoken: this.isLogin,
        });
        this.list = res.data.leaderboard;
        this.difference = res.data.difference;
      } catch (e) {
        this.onError(e);
      }
    },
    back() {
      this.$router.back();
    },
    onShare() {
      this.showShare = true;
    },
    onColse() {
      this.showShare = false;
    },
    gotoPage(name) {
      this.$router.push({
        name,
      });
    },
    // 抽奖
    async getUser() {
      try {
        const res = await userApi({});
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        console.log(e);
        if (e.code === 201) {
          this.login = false;
        }
      }
    },

    //倒计时
    countDown(endTime) {
      var nowtime = new Date(); //获取当前时间
      var lefttime = endTime * 1000 - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      if (leftd <= 0 && lefth <= 0 && leftm <= 0 && lefts <= 0) {
        return false;
      }

      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      return `距活动结束还剩 ${leftd}d  ${timeAdd0(lefth)}:${timeAdd0(
        leftm
      )}:${timeAdd0(lefts)}`;
      // return `${leftd}天${lefth}时${leftm}分${lefts}秒`;
    },
  },
};
</script>
<style scoped lang="scss">
.page-box{
  background: #15121b;
  min-height: 100vh;
}
.heads {
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;

  .backs {
    width: 27px;
    height: 27px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    position: fixed;
    left: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.bgk_Btm {
  position: absolute;

  .bgk {
    margin-bottom: -10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bgkIns {
    background: #000000;
    width: 100%;
    height: 980px;
  }

  .bgk1 {
  }

  .bgk2 {
  }

  .bgk3 {
  }

  .bgk5 {
    margin-bottom: 80px;
  }
}

.bgk_Top {
  position: absolute;
  z-index: 2;
  width: 100%;
  text-align: center;
  top: 350px;

  .bgk1 {
    width: 80%;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .ranking {
    padding: 15px;

    .rankingTop {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      color: #979999;
      font-size: 12px;

      div {
        width: 33%;
        text-align: center;
      }
    }

    .rank_list {
      height: 400px;
      overflow-y: scroll;
      margin-bottom: 100px;
      .notList {
        text-align: center;
        margin-top: 20px;
        color: #3c3c3c;
        font-size: 12px;
      }

      .list-item {
        display: flex;
        align-items: center;
        justify-content: space-around;
        border-bottom: 1px solid #3c3c3c;
        padding: 15px 0;

        .items {
          width: 33%;
          text-align: center;
        }

        .ranking {
          font-size: 13px;
          color: #07b295;
          font-weight: 700;
        }

        .user {
          display: flex;
          align-items: center;

          .avatars {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            background: #979999;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .userName {
            color: #ffffff;
            font-size: 11px;
            width: 70px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .value {
          color: #ffffff;
          font-size: 11px;
        }
      }
    }
  }

  .userRankings {
    margin: 30px 0 0px;
    padding: 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #080808;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      div {
        color: #979999;
        font-size: 11px;
      }

      div:last-child {
        color: #ffffff;
        font-weight: 700;
        font-size: 12px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #07b295;
      border-radius: 5px;
      width: 100%;
      padding: 10px 0;

      .bottoms {
        width: 33%;
        text-align: center;
      }

      .userInfo {
        display: flex;
        align-items: center;

        .userName {
          color: #ffffff;
          font-size: 11px;
          width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .userAvatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          background: #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .rankingUser {
        color: #ffffff;
      }

      .rankingUsers {
        color: #ffffff;
      }
    }
  }

  .bgk_Times {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    background-image: url("../../assets/img/lszSolicitation/time.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 80%;
    margin: 10px auto 30px;
    color: #ffffff;
    .timsIns {
      display: flex;
      align-items: center;
    }
    img {
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }
  }
}
</style>
