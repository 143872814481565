<template>
  <div class="share">
    <back style="z-index: 1"></back>
    <div class="up">
      <div class="wrap">
        <div style="height: 100%; width: 100%; position: relative">
          <swiper class="swiper-container1" :options="option" ref="mySwiper">
            <swiper-slide v-for="(item, index) in poster" :key="index">
              <div class="poster">
                <img
                  :src="item"
                  width="100%"
                  height="100%"
                  v-if="item"
                  @touchstart="getTouchStart(item)"
                  @touchend="getTouchEnd"
                />

                <div v-else class="wait">
                  <van-loading size="24px">正在生成海报...</van-loading>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <swiper class="swiper-container2" :options="option" ref="mySwiper">
            <swiper-slide v-for="(item, index) in posterList" :key="index">
              <div class="create" id="create" ref="poster">
                <div class="create-poster">
                  <img :src="item" width="100%" height="102%"/>
                </div>
                <div class="info">
                  <div class="left">
                    <img
                      :src="
                        user.avatar !== '0'
                          ? user.avatar
                          : require('../../assets/img/user.png')
                      "
                      class="avatar"
                    />
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                      "
                    >
                      <div class="f-10 font-width">
                        {{ user.nickname }}
                      </div>
                      <div class="grey f-7">我的邀请码：{{ code }}</div>
                      <div class="grey f-7">{{ invite_desc }}</div>
                    </div>
                  </div>
                  <vue-qr
                    :text="url"
                    class="qr-code"
                    :margin="0"
                    :size="50"
                  ></vue-qr>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="f-1 text-center m-b-5 text1">长按图片转发或保存</div>
      <div class="iconBox" @click="copy()" ref="copy">
        <span class="box"></span>
        <div class="text2">复制邀请码</div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import Back from '../../components/thirtyBack.vue'
import { share, wxConfig } from '../../api/user'
import { ranking } from '../../api/cxbc'
import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import { mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getWxConfig } from '../../../tool'
import Clipboard from 'clipboard'

// let vm
// menuItem:share:appMessage
// menuItem:share:timeline
export default {
  name: 'Share',
  components: {
    SwiperSlide,
    Swiper,
    Back,
    vueQr
  },
  data () {
    return {
      rank: 0,
      option: {
        slidesPerView: 1.15,
        centeredSlides: true,
        touchRatio: 1, //  触摸距离与slide滑动距离的比率。
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
        },
        // on: {
        //   slideChange () {
        //     vm.index = this.activeIndex
        //   },
        //   init () {
        //     vm.index = this.activeIndex
        //   }
        // }
      },
      activityId: null,
      userId: null,
      url: '',
      code: '',
      act_id: '',
      poster: [''],
      posterList: [require('../../assets/img/thirty/share.png')],
      count: 0
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created () {
    // vm = this
    let img = this.getUrlKey('img')
    this.act_id = this.getUrlKey('act_id')
    this.code = this.getUrlKey('code')
    this.invite_desc = this.getUrlKey('invite')
    if (img) {
      this.posterList = [img]
    }
    console.log(this.posterList)
    getWxConfig(
      '伤寒论数字藏品火热预约中',
      '点击链接参与活动有机会免费获得'
    )
  },
  mounted () {
    this.clipBoard = new Clipboard(this.$refs.copy, {
      text: () => {
        return `${this.code}`
      }
    })
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.getTouchStart()
    })
    this.getShareConfig()
    this.getRanking()
  },
  methods: {
    getTouchStart (imgUrl) {
      this.timer = setTimeout(() => {
        uni.postMessage({
          data: {
            action: imgUrl,
            invitationCode: '',
          },
        })
      }, 1000)
    },
    copy () {
      this.clipBoard.on('success', (e) => {
        console.log(e)
        this.$toast('复制成功')
      })
      this.clipBoard.on('error', (err) => {
        console.log(err)
        this.$toast.fail('复制失败')
      })
    },
    getUrlKey (name) {
      let url = window.location.href
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(/\+/g, '%20')) || ''
    },
    getTouchEnd () {
      clearTimeout(this.timer)
    },
    async getRanking () {
      try {
        const res = await ranking({})
        console.log(res)
        this.rank = res.data.ranking
      } catch (e) {
        console.log(e)
      }
    },
    async getShareConfig () {
      try {
        const res = await share({})
        this.activityId = res.data.reserve_id
        this.userId = res.data.user_id
        this.count = res.data.count
        this.url = `${window.location.origin}/?invited_id=${this.userId}&activity_id=${this.activityId}#/activity?id=${this.act_id}&code=${this.code}`
        localStorage.setItem('invited_id', res.data.user_id)
        localStorage.setItem('activity_id', res.data.reserve_id)
        localStorage.setItem('count', res.data.count)
        this.createPoster()
      } catch (e) {
        console.log(e)
      }
    },
    createPoster () {
      this.$refs.poster.forEach((item, index) => {
        console.log('kuan', parseFloat(item.parentNode.style.width))
        setTimeout(() => {
          html2canvas(item, {
            width: parseFloat(item.parentNode.style.width),
            backgroundColor: null, // null 表示设置背景为透明色
            useCORS: true, // 是否尝试使用CORS从服务器加载图像
            allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
            taintTest: true, // 是否在渲染前测试图片
            scale: 3, // dpr比列
            scrollY: 0, // 截屏时页面滚动，造成截屏图片不全或空白
          })
            .then((canvas) => {
              const img = canvas.toDataURL('image/png')
              this.$set(this.poster, index, img)
            })
            .catch((e) => {
              console.log(e)
              this.$toast.fail('生成海报失败')
            })
        }, 100)
      })
    },
  },
}
</script>

<style scoped lang="scss">

.share {
  height: 100vh;
  overflow: hidden;

  .up {
    height: 90vh;
    background: url("../../assets/img/user-bg.png") no-repeat;
    background-size: cover;

    .wrap {
      height: 100%;
      width: 100%;
      padding: 42px 0 10px;
      background-color: rgba(0, 0, 0, 0.48);
      position: relative;

      .swiper-container1 {
        height: 100%;
        width: 100%;

        .swiper-slide {
          border-radius: 3px;
          overflow: hidden;
          height: 100%;
          background-color: black;

          .poster {
            background-color: white;
            border-radius: 3px;
            height: 100%;
            width: 100%;

            .wait {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              width: 100%;
            }
          }
        }

        .swiper-slide:not(.swiper-slide-active) {
          transition: 400ms;
          transform: scale(0.9);
        }
      }

      .swiper-container2 {
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
        top: -10000px;

        .swiper-slide {
          .create {
            background-color: white;
            height: 100%;
            width: 100%;

            .create-poster {
              height: 85%;
              width: 100%;
              position: relative;

              .tip {
                width: 178px;
                padding: 5px 5px 8px;
                background: #000000;
                border-radius: 18px;
                font-size: 15px;
                color: white;
                text-align: center;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 39%;
              }

              .poster2 {
                position: absolute;
                bottom: 30px;
                width: 100%;
                left: 20px;
                line-height: 18px;
              }
            }

            .info {
              height: 13%;
              display: flex;
              padding: 0 25px 0 10px;
              justify-content: space-between;
              align-items: center;
              margin-top: 10px;

              .left {
                display: flex;

                .avatar {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  margin-right: 10px;
                }
              }

              .qr-code {
              }
            }
          }
        }
      }

      .swiper-pagination {
        width: 100%;
        margin: 20px 0;
      }

      .pagination {
        .swiper-pagination-bullet {
          background-color: #000000;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }

        .swiper-pagination-bullet-active {
          opacity: 0.3;
          background-color: #000000;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #333;
    height: 80px;
    .text1 {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      margin: 8px 0;
    }

    .yqmicon {
      width: 25px;
      height: 25px;
    }

    .iconBox {
      width: 137px;
      height: 34px;
      background: #000000;
      border-radius: 17px;
      display: flex;
      align-items: center;
      justify-content: center;

      .box {
        width: 16px;
        height: 16px;
        background: #34363d;
        border-radius: 50%;
        margin-right: 5px;
      }

      .text2 {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
</style>
