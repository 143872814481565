<template>
  <div>
    <div class="head">
      <div class="back">
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div>
      <div class="head-title">
        <div>李时珍数字艺术俱乐部藏品授权协议</div>
      </div>
    </div>
    <div class="texts">
      轻松小镇（深圳市轻松元宇科技有限公司）及李时珍数字艺术俱乐部Lishizhen.art（被授权方）与李时珍头像数字艺术藏品被征用用户（授权方）在平等、自愿、信任、互利的基础上，根据《中华人民共和国民法典》等有关法律法规，就被授权方获得授权方李时珍头像数字艺术藏品版权事宜达成本协议，并共同遵照执行。
      <p></p>
      本协议包括一下内容：
      <p></p>
      <div>一、授权方与被授权方</div>
      <div>二、授权内容</div>
      <div>三、授权期限</div>
      <div>四、争议解决</div>
      <div>五、本协议声明</div>
      <p></p>
      <div>一、授权方与被授权方</div>
      授权方：轻松小镇平台李时珍头像数字艺术藏品被征用用户
      被授权方：轻松小镇、李时珍数字艺术俱乐部Lishizhen.art

      <p></p>
      <div>二、授权内容</div>
      <div>
        1、授权方同意将李时珍头像数字艺术藏品的版权（著作权）在全球范围内的、不可撤销的、有期限的、无限制的、可再许可的信息网络传播权授权给被授权方。
      </div>
      <div>
        2、允许被授权方享有包括《中华人民共和国著作权法》中第十条所规定的财产权，具体包括：复制权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他合法权利。
      </div>
      <div>
        3、允许被授权方基于授权内容利用区块链技术在被授权平台（轻松小镇）宣传、推广李时珍头像数字艺术藏品联名款产品、周边产品、相关衍生品及相关传播活动。
      </div>
      <div>
        4、允许被授权方在区块链及合作平台(“授权场景”)基于个人欣赏、学习、娱乐、交流、社交等目的而复制、公开展示、展览、表演、播放、发布、汇集或以其他非营利的形式合理使用李时珍头像数字艺术藏品(“授权目的”)。
      </div>
      <div>具体授权场景如下:</div>
      <div>(1)在社交媒体或数藏平台中复制和展示数字藏品;</div>
      <div>(2)在线上线下展馆、展览和社区中复制和展示数字藏品;</div>
      <div>
        (3)在应用程序、APP、线下线上媒介或其他平台公开展示、播放、演唱数字藏品;
      </div>
      <div>(4)数字藏品复制和印刷在有形载体上(如服饰);</div>
      <div>(5)将数字藏品用于姜小竹智能硬件产品包装及产品线上线下宣传物料</div>
      <div>(6)其他经授权方事先同意的、符合授权目的的非营利的场景;</div>
      <div>
        (7)授权方同意被授权方可为完成授权目的而使用、展示授权内容。包括数字藏品形象和授权方的姓名、昵称或名称等;
      </div>
      <div>
        (8)授权方同意被授权方有权在本授权协议约定的范围内，允许被授权合作方行使被授权方依据本授权协议取得的全部或部分权利，且无须另行取得授权方的同意。
      </div>
      <p></p>
      三、授权期限
      <div>2022年8月22日12:00-2022年9月6日00:00</div>
      <p></p>
      四、争议解决
      <div>
        因本授权协议的签订、解释、执行、终止等所产生的一切法律纠纷，被授权方应与授权方友好协商解决；协商不成的，被授权方同意争议由被授权方所在地人民法院管辖。
      </div>
      <p></p>
      五、本协议声明
      <div>
        授权方或被授权方违反此协议、不遵守此协议导致侵犯任何第三方合法权益、产生任何纠纷或受到国家有关部门查处或处罚，均由行为侵害方独自承担所有法律责任。因此遭受的一切损失，由行为侵害方承担全部赔偿责任。
      </div>
      <p></p>
      本授权协议经双方签署后生效。本授权协议的签订、解释、修改等均由被授权方提出，经授权方确认后生效。
    </div>
  </div>
</template>
<script>
export default {
  name: "lszSolicitationHdgz",
  created() {},
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#000000");
  },
  methods: {
    back() {
      console.log(111);
      this.$router.back();
    },
  },
};
</script>
<style scped lang="scss">
.texts {
  color: #878689;
  line-height: 20px;
  padding: 20px;
  div {
    font-size: 12px !important;
  }
}
.head {
  background-size: 100% 100%;
  position: relative;
  padding: 28px;
  display: flex;
  align-items: center;
  background: #000000;
  .back {
    width: 27px;
    height: 27px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    position: fixed;
    left: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .head-title {
    color: #ffffff;
    text-align: center;
    font-size: 15px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
