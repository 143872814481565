<template>
  <!-- 合成记录  -->
  <div class="CompoRecord">
    <div class="block" @click="back()">
      <img
        :src="require('../../assets/img/back.png')"
        width="60%"
        height="50%"
      />
    </div>
    <van-list
      class="recordBox"
      :finished="finished"
      offset="0"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="list" v-for="(item, i) in recordList" :key="i">
        <div class="list_one">
          <div class="name">{{ item.send }}</div>
          <div class="time">{{ (item.created_at * 1000) | dateFormat }}</div>
        </div>
        <div class="list_two">已消耗藏品：</div>
        <div class="list_three">{{ item.to_use }}</div>
      </div>
    </van-list>
    <div class="noMore" v-if="!noMore">
      <van-loading type="spinner" size="15" class="m-r-10" /> {{ lodingtext }}
    </div>
    <div class="noMore" v-else>
      {{ lodingtext }}
    </div>
  </div>
</template>
<script>
import { synthesisLog } from "../../api/thirty.js";
export default {
  name: "CompoRecord",
  computed: {},
  components: {},
  data() {
    return {
      page: 1,
      finished: false, //上拉加载完毕
      flag: false,
      lodingtext: "加载更多",
      recordList: [],
      total: 0,
      noMore: false,
    };
  },
  computed: {},
  mounted() {
    this.getList();
  },
  destroyed() {},
  created() {},
  watch: {},
  methods: {
    async getList() {
      this.flag = false;

      try {
        const res = await synthesisLog({
          page: this.page,
        });
        console.log(res);
        this.recordList = this.recordList.concat(res.data.data.data);
        this.total = res.data.data.total;
        this.flag = true;
        if (this.recordList.length >= res.data.data.total) {
          console.log(this.recordList.length, res.data.data.total);
          this.lodingtext = "没有更多";
          this.noMore = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    onLoad() {
      console.log(".....");
      let _this = this;
      if (_this.recordList && _this.flag) {
        _this.page++;
        if (_this.recordList.length < _this.total) {
          _this.getList();
        }
      }
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: "/ThirtyFuse" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss">
.CompoRecord {
  min-height: 100vh;
  width: 100%;
  background: #fff;
  padding-bottom: 50px;
  .block {
    left: 17px;
    top: 17px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 20px;
  }
  .recordBox {
    padding: 0 25px 0;
    .list {
      padding: 25px 0;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      .list_one {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .name {
          font-size: 16px;
          font-weight: 500;
          color: #000000;
        }
        .time {
          font-size: 12px;
          font-weight: 400;
          color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      .list_two {
        font-size: 12px;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: 0.5);
        margin: 15px 0 5px 0;
      }
      .list_three {
        font-size: 12px;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: 0.7);
      }
    }
  }
  .noMore {
    font-size: 14px;
    font-weight: 400;
    color: rgba($color: #000000, $alpha: 0.5);
    text-align: center;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
