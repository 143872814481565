<template>
  <div>
    <div class="go-back" @click="goBack()">
      <img
        :src="require('../assets/img/Activity/left.png')"
        class="icon"
        style="width: 20px;height: 20px"
      />
    </div>
    <div>
      <img :src="rule" style="width: 100%">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rule',
  return: {
    rule: ''
  },
  async created () {
    this.rule = window.localStorage.rule
    this.rule = this.rule.replace(/'<p'/g, '<div')
    this.rule = this.rule.replace(/'<\/p'/g, '</div')
  },
  methods: {
    goBack () {
      window.history.back()
    }
  }
}
</script>

<style scoped>
p , html , body{
  display: block;
  padding: 0;
  border: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.go-back {
  height: 40px;
  width: 40px;
  position: fixed;
  background: #000000;
  opacity: 0.5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 10px;
  top: 10px;
  z-index: 10;
}
</style>
