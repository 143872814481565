<template>
  <div>
    <van-pull-refresh
      v-model="isLoading"
      loosing-text="下拉即可刷新"
      pulling-text="释放即可刷新"
      loading-text="加载中"
      @refresh="onRefresh"
      :disabled="refDisabled"
    >
      <div class="cont">
        <div class="headerBgk">
          <div class="icon">
            <div class="backIcon icons" @click="onBack()">
              <img src="../../assets/img/sleepActivity/back.png" alt="" />
            </div>
          </div>
        </div>
        <div class="list" ref="scrollBox" @scroll="divScroll">
          <div v-if="list.length">
            <div class="listCont" v-for="(item, index) in list" :key="index">
              <div class="listleft">
                <div class="listImg"><img :src="item.avator" alt="" /></div>
                <div class="listName">
                  {{ item.username }}
                </div>
              </div>
              <div class="listPhone">
                {{ item.mobile | formatPhone(item.mobile) }}
              </div>
              <div class="listRight">
                <div
                  class="listTypeR"
                  :class="Number(item.authentication) ? 'listTypeT' : 'listTypeR'"
                ></div>
                <div class="listType">
                  {{ Number(item.authentication) ? "已认证" : "未认证" }}
                </div>
              </div>
            </div>
          </div>
          <div class="notList" v-if="!list.length">
            暂无邀请记录，快去邀请吧~
          </div>
        </div>            
      </div>
    </van-pull-refresh>
    <van-overlay :show="show" :custom-style="{ background: 'rgba(0,0,0,1)' }">
      <div class="loading">
        <img src="../../assets/img/sleepActivity/loading.gif" alt="" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { invitation_log } from "../../api/sleep";
import { mapMutations, mapState } from "vuex";
import { getWxConfig } from "../../../tool";
export default {
  data() {
    return {
      isLoading: false, //下拉刷新
      show: true, //loading显示
      list: [],
      refDisabled: false,
      scrollTop: 0,
    };
  },
  created() {
    document.title = "118早睡行动预约开启";
    getWxConfig("无聊猿#1365向你发起号召啦", "“118早睡行动”有奖预约等你来");
  },
  mounted() {
    // let tableScorll = this.$refs.Box;

    //   tableScorll.addEventListener('scroll',()=>{
    //     console.log(222, tableScorll.scrollTop)

    //     this.scrollTop = tableScorll.scrollTop//data中定义scrollTop为0
    //     console.log(222, this.scrollTop)

    //   });
    this.onLeaderboard();
  },
  destroyed() {},
  watch: {
    // scrollTop(newVal){
    //   console.log(newVal)
    //     if(newVal<=0){
    //       this.refreshDisabled = false
    //     }else{
    //       this.refreshDisabled = true
    //     }
    // }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      invited_code: (state) => state.invited_code,
      invited_ids: (state) => state.invited_ids,
    }),
  },
  filters: {
    //手机号隐藏中间四位
    formatPhone(value, type) {
      if (value) {
        if (type == "card") {
          return (
            value.substring(0, 4) +
            " **** **** " +
            value.substring(value.length - 3)
          );
        } else if (type == "addr") {
          return (
            value.substring(0, 10) +
            "********" +
            value.substring(value.length - 10)
          );
        } else {
          return (
            value.substring(0, 3) + "****" + value.substring(value.length - 4)
          );
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    handleBlScroll() {},
    divScroll(e) {
      let toTop = this.$refs.scrollBox.scrollTop;
      console.log(8888, this.$refs.scrollBox);
      console.log("距离顶部" + toTop);
      if (toTop == 0) {
        this.refDisabled = false;
      } else {
        this.refDisabled = true;
      }
    },
    //回到上一页
    onBack() {
      this.$router.go(-1);
    },
    //获取邀请记录
    async onLeaderboard() {
      try {
        const res = await invitation_log({ acctoken: this.isLogin });
        this.list = res.data.data;
        console.log(111, this.list);
        this.show = false;
      } catch (e) {
        this.onError(e);
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.onLeaderboard();

        this.isLoading = false;
        clearInterval(this.timer);
      }, 1000);
    },

    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
      this.loadings = false;
    },
  },
};
</script>
<style >
</style>
<style scoped lang="scss">
.cont {
  background: #000000;
  height: 100vh;
}
/deep/ .van-pull-refresh {
  background: #000000 !important;
}
/deep/ .van-loading {
  text-align: center;
}
/deep/ .van-overlay {
  z-index: 5 !important;
}
/deep/ .van-pull-refresh__head {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

@mixin btn_Fun($url) {
  .btnFont {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      color: #fff;
      font-size: 20px;
      /* font-weight: 700; */
      letter-spacing: 2px;
      margin-top: -8px;
    }
  }
}
// 超出显示...单文本
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
//设置背景图
@mixin bgk_Fun($url) {
  background-image: url($url);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.headerBgk {
  height: 270px;
  position: relative;
  @include bgk_Fun($url: "../../assets/img/sleepActivity/recordBgk.png");

  .icon {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;
    .icons {
      width: 30px;
      height: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .guizePage {
    position: absolute;
    color: #fff;
    bottom: 180px;
    right: 25px;
    font-size: 13px;
  }
}

.btnBgk {
  position: fixed;
  bottom: 0;
  width: 100%;
  @include bgk_Fun($url: "../../assets/img/sleepActivity/bottom.png");
  z-index: 3;

  .btnWh {
    width: 100%;
    height: 90px;
    padding: 7px 30px 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      div {
        color: #979999;
        font-size: 11px;
      }

      div:last-child {
        color: #979999;
        font-size: 11px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 10px 0;
      @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingInfo.png");
      height: 43px;
      box-shadow: 0px 0px 15px #0008ff;
      border-radius: 25px;
      .bottoms {
        width: 33%;
        text-align: center;
      }

      .userInfo {
        display: flex;
        align-items: center;

        .userName {
          color: #ffffff;
          font-size: 11px;
          width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .userAvatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          background: #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .rankingUser {
        color: #ffffff;
      }

      .rankingUsers {
        color: #ffffff;
      }
    }
  }
}
.list {
  padding: 25px;
  position: relative;
  width: 100%;
  top: -50px;
  height: 450px;
  overflow-y: scroll;
  .notList {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }
  .listCont {
    border-bottom: 1px solid #3c3c3c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    .listleft {
      display: flex;
      align-items: center;
      .listImg {
        width: 40px;
        height: 40px;
        border-radius: 30px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .listName {
        width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #ffffff;
        margin-left: 10px;
      }
    }
    .listPhone {
      font-size: 14px;
      color: #ffffff;
    }
    .listRight {
      display: flex;
      align-items: center;
      .listTypeR {
        width: 10px;
        height: 10px;
        border-radius: 30px;
        overflow: hidden;
        background: #979999;
      }
      .listTypeT {
        width: 10px;
        height: 10px;
        border-radius: 30px;
        overflow: hidden;
        background: #00d011;
      }
      .listType {
        color: #ffffff;
        margin-left: 5px;
      }
    }
  }
}
.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}
</style>
