
<template>
  <div class="page-box">
    <nav-bar title="联系客服" left-arrow @click-left="onClickLeft" />
    <div class="kefuCont">
      <div class="kefuImg" @touchstart="touchStart()" @touchend="touchEnd()">
        <!-- <img :src="require('../../assets/img/yqMall/yq.png')" alt="" /> -->
        <img :src="imgUrl" />
      </div>
      <div class="kefuText">
        <div>如果您在使用中遇到问题，</div>
        <div>请扫描二维码关注小镇官方公众号</div>
        <div>联系我们的客服</div>
      </div>
    </div>
  </div>
</template>
<script>
import { NavBar } from "vant";
export default {
  components: { NavBar },
  data() {
    return {
      qrcodeUrl64: '',
      timer: '',
      // imgUrl: require('../../assets/img/kefu.png'),
      imgUrl: 'https://cdn3.relaverse.cn/1687682092345251635',

    };
  },
  // created activated
  created() { },
  // 监听变量
  watch: {},
  // 在使用到的页面 添加如下代码
  mounted() { },

  // 页面离开 destroyed deactivated
  destroyed() { },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    touchEnd() {
      //手指离开
      clearTimeout(this.timer);
    },
    touchStart() {
      //手指触摸
      clearTimeout(this.timer); //再次清空定时器，防止重复注册定时器
      // alert('准备复制')
      this.timer = setTimeout(() => {
        this.downloadIamge();
      }, 1000);
    },
    downloadIamge() {
      uni.postMessage({
          data: {
            action: {
              name: 'downLoadImg',
              url: this.imgUrl
            },
          },
        });
      // this.$toast('保存图片成功~');
    }
  },
};
</script>
<style  scoped lang="scss">
/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.page-box {
  height: 100vh;
  background: #fff;
}

.kefuCont {
  margin-top: 10rem;

  .kefuImg {
    width: 100px;
    height: 100px;
    margin: 20px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .kefuText {
    div {
      text-align: center;
      font-size: 14px;
      color: #333333;
    }
  }
}</style>