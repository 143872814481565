<template>
  <div class="page-box">
    <nav-bar title="地址管理" left-arrow @click-left="onClickLeft" />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="item">
        <div v-if="listAddress.length">
          <div class="addressList" @click="onTopPage(item)" v-for="(item, index) in listAddress" :key="index">
            <div class="addressLefts">
              <div class="listUser">
                <div>{{ item.name }}</div>
                <div>{{ item.phone }}</div>
                <div class="checks" v-if="item.is_top">默认</div>
              </div>
              <div class="addressText">
                {{ item.sheng }}{{ item.shi }}{{ item.qu }}{{ item.address }}
              </div>
            </div>
            <div class="addressCheck">
              <div class="closeIcon" @click.stop="onCloseAdd(item.id)">
                <van-icon name="cross" />
              </div>

              <div class="font" @click.stop="toSetAddress(item.id)">编辑</div>
            </div>
          </div>
        </div>
        <div class="noList" v-else>暂无地址</div>
      </div>
    </van-pull-refresh>

    <div class="btm" @click="toSetAddress('')">
      <div class="btn">添加新地址</div>
    </div>
  </div>
</template>

<script>
import { getAll,delAddress } from "../../api/yqMallApi";
import { NavBar, Checkbox } from "vant";
export default {
  name: "detail",
  components: { NavBar, Checkbox },
  data() {
    return {
      checked: false,
      listAddress: [],
      isLoading: false,
    };
  },
  computed: {},
  created() { },
  mounted() {
    // localStorage.setItem(
    //   "token",
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjI4NTE2MiIsImlzcyI6IjI4NTE2MiJ9.mImmdcrEtI092ha4iuASb2SBVwoxJ0gYgcoUYri2iUo"
    // );
    this.onGetAll();
  },
  methods: {
    onCloseAdd(e){
      this.$dialog
        .confirm({
          message: "确认要删除该地址吗？",
          confirmButtonColor: "black",
        })
        .then(() => {
          this.onDeleteAdd(e);
        })
        .catch(() => {
          console.log(22);
        });
    },
    async onDeleteAdd(e){
      try {
        const res = await delAddress({id: [e]});
      this.onGetAll();
        this.$toast("删除成功");
      } catch (e) {
        this.$toast(e.message||e.data);
        //TODO handle the exception
      }
    },
    async onTopPage(e) {
      localStorage.setItem('addressData', JSON.stringify(e))
      await this.$router.go(-1);
    },
    onRefresh() {
      this.onGetAll();
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    handleChange(e) {
      console.log(e);
    },
    //获取地址列表
    async onGetAll() {
      try {
        const res = await getAll({ acctoken: localStorage.getItem("token") });
        this.listAddress = res.data.data;
      } catch (e) {
        //TODO handle the exception
      }
    },
    toSetAddress(e) {
      uni.postMessage({
        data: {
          action: "realAddress",
          id: e,
        },
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>


<style scoped lang="scss">
/deep/.van-pull-refresh {
  height: 100%;
}

/deep/.van-loading {
  text-align: center;
}

/deep/.van-pull-refresh__head {
  display: flex;
  justify-content: center;
}

/deep/.van-nav-bar .van-icon {
  color: #000000;
}

.page-box {
  height: 100vh;
  background: #f1f1f1;
}

.noList {
  text-align: center;
  margin-top: 40px;
  color: #666666;
}

.item {
  padding: 6px 8px;

  .addressList {
    background: #fff;
    padding: 20px;
    margin-bottom: 6px;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .listUser {
      margin-bottom: 10px;
      font-size: 15px;
      display: flex;
      align-items: center;

      .checks {
        padding: 0px 7px;
        margin-left: 10px;
        color: #000;
        font-size: 11px;
        border-radius: 4px;
        border: 1px solid #000;
      }
    }

    .addressText {
      font-size: 13px;
      color: #666666;
      width: 200px;
    }

    .addressCheck {
      position: relative;
      .closeIcon {
        margin-bottom: 10px;
        position: absolute;
        right: 0;
        top: -26px;
      }

      .font {
        font-size: 12px;
        color: #000;
        border-radius: 50px;
        border: 1px solid #000;
        padding: 5px 10px;
      }
    }
  }
}

.btm {
  width: 100%;
  position: fixed;
  bottom: 0;
  margin: auto;

  .btn {
    width: 90%;
    margin: 0 auto 10px;
    background: #000;
    border-radius: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    padding: 10px 0;
  }
}</style>
