<template>
  <div class="share">
    <div class="back" @click="back" v-if="generateFg">
      <img
        :src="require('../../assets/img/back.png')"
        width="60%"
        height="50%"
      />
    </div>
    <div class="shareBG" v-if="generateFg">
      <div class="up">
        <div class="wrap">
          <div style="height: 100%; width: 100%; position: relative">
            <swiper class="swiper-container1" :options="option" ref="mySwiper">
              <swiper-slide v-for="(item, index) in poster" :key="index">
                <div class="poster">
                  <img
                    :src="item"
                    width="100%"
                    height="100%"
                    v-if="item"
                    @touchstart="getTouchStart(item)"
                    @touchend="getTouchEnd"
                  />
                  <div v-else class="wait">
                    <div class="wd1">LOADING</div>
                    <div class="wd2">海报生成中...</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <swiper class="swiper-container2" :options="option" ref="mySwiper">
              <swiper-slide v-for="(item, index) in posterList" :key="index">
                <div class="create" id="create" ref="poster">
                  <div class="create-poster">
                    <img :src="posterImg" width="100%" height="100%" />
                    <div class="name">{{ name }}</div>
                    <img :src="item" class="type_img" alt="" />
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="down share_btm p-30">
        <div class="right">长按海报保存分享</div>
        <div class="right" v-if="posterList.length > 1">
          左右滑动切换不同身份海报
        </div>
      </div>
    </div>
    <div class="write" v-else>
      <input
        type="text"
        class="write_input"
        maxlength="6"
        placeholder="请输入昵称生成海报"
        v-model="name"
      />
      <div class="sxbox"></div>
      <div class="write_btn" @click="generate()" v-if="token">生成海报</div>
      <div class="write_btn2" @click="generate2()" v-else>
        点击登录生成专属海报
      </div>
      <div class="wd3">生成海报邀请好友参与</div>
    </div>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="hasLogin"
    ></login>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import Back from "../../components/CxbcBack";
import { share, wxConfig } from "../../api/user";
import { sharePoster } from "../../api/cxbc";
import { ranking } from "../../api/cxbc";
import vueQr from "vue-qr";
import html2canvas from "html2canvas";
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { getWxConfig } from "../../../tool";
import Login from "../../components/Login";

// let vm
// menuItem:share:appMessage
// menuItem:share:timeline
export default {
  name: "Share",
  components: { SwiperSlide, Swiper, Back, vueQr, Login },
  data() {
    return {
      token: localStorage.getItem("token"),
      rank: 0,
      isShowLogin: false,
      name: "",
      generateFg: false,
      option: {
        slidesPerView: 1.5,
        centeredSlides: true,
        touchRatio: 1, //  触摸距离与slide滑动距离的比率。
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      activityId: null,
      userId: null,
      url: "",
      poster: [""],
      posterImg: require("../../assets/img/cxbc/LszShare.jpg"),
      posterList: [],
      count: 0,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    // vm = this
    getWxConfig(
      "李时珍头像数字藏品将正式发布 点击共同参与见证",
      "李时珍数字艺术俱乐部，中国中医科学院中医药信息研究所监制，中医数字创新实验室出品。"
    );
  },
  watch: {
    generateFg(e) {
      document.documentElement.scrollTop = 0;
    },
  },
  mounted() {
    // if (!localStorage.getItem("token")) {
    //   this.isShowLogin = true;
    // }
    document.addEventListener("UniAppJSBridgeReady", () => {
      this.getTouchStart();
    });
    this.getUserData();
  },
  methods: {
    generate() {
      if (this.name.length == 0) {
        this.$toast("请输入您的昵称");
      } else if (this.name.length > 6) {
        this.$toast("请输入6位以内的昵称");
      } else {
        this.generateFg = true;
        this.getShareConfig();
        this.getUserData();
        console.log(this.name);
      }
    },
    generate2() {
      this.isShowLogin = true;
    },
    back() {
      this.generateFg = false;
      this.poster = [""];
      // this.name = "";
    },
    getTouchStart(imgUrl) {
      this.timer = setTimeout(() => {
        uni.postMessage({
          data: {
            action: imgUrl,
          },
        });
      }, 1000);
    },
    getTouchEnd() {
      clearTimeout(this.timer);
    },
    async getUserData() {
      try {
        const res = await sharePoster({});
        this.posterList = res.data.data;
        console.log(this.posterList.length > 1);
        console.log(res);
      } catch (e) {
        console.log(e);
      }
    },
    async getShareConfig() {
      try {
        const res = await share({});
        this.activityId = res.data.reserve_id;
        this.userId = res.data.user_id;
        this.count = res.data.count;
        this.url = `${window.location.origin}/?invited_id=${this.userId}&activity_id=${this.activityId}#/cxbc`;
        // this.url = `${window.location.origin}/?subscried_id=${this.userId}#/`
        localStorage.setItem("invited_id", res.data.user_id);
        localStorage.setItem("activity_id", res.data.reserve_id);
        localStorage.setItem("count", res.data.count);
        this.createPoster();
      } catch (e) {
        console.log(e);
      }
    },
    hasLogin() {
      this.isShowLogin = false;
      this.getUserData();
      location.reload();
    },
    createPoster() {
      this.$refs.poster.forEach((item, index) => {
        setTimeout(() => {
          html2canvas(item, {
            width: parseFloat(item.parentNode.style.width),
            backgroundColor: null, // null 表示设置背景为透明色
            useCORS: true, // 是否尝试使用CORS从服务器加载图像
            allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
            taintTest: true, // 是否在渲染前测试图片
            scale: 3, // dpr比列
            scrollY: 0, // 截屏时页面滚动，造成截屏图片不全或空白
          })
            .then((canvas) => {
              const img = canvas.toDataURL("image/png");
              this.$set(this.poster, index, img);
            })
            .catch((e) => {
              console.log(e);
              this.$toast.fail("生成海报失败");
            });
        }, 100);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.share {
  height: 750px;
  // overflow: hidden;
  .back {
    position: absolute;
    left: 17px;
    top: 17px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: rgba(236, 236, 236, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 0;
  }
  .shareBG {
    height: 100%;
    background: url("../../assets/img/cxbc/LszBG.jpg") no-repeat center center
      fixed;
    background-size: cover;
    box-sizing: border-box;
    .up {
      height: 85vh;
      // background: url("../../assets/img/user-bg.png") no-repeat;
      background-size: cover;
      .wrap {
        height: 100%;
        width: 100%;
        padding: 63px 0 30px;
        position: relative;
        .swiper-container1 {
          height: 100%;
          .swiper-slide {
            border-radius: 3px;
            overflow: hidden;
            height: 100%;
            background-color: transparent;
            .poster {
              // background-color: white;
              border-radius: 3px;
              height: 100%;
              width: 100%;
              .wait {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 100%;
                width: 100%;
                .wd1 {
                  font-size: 25px;
                  color: #ffffff;
                  margin-bottom: 8px;
                }
                .wd2 {
                  color: #ffffff;
                }
              }
            }
          }
          .swiper-slide:not(.swiper-slide-active) {
            transition: 400ms;
            transform: scale(0.9);
          }
        }
        .swiper-container2 {
          position: absolute;
          height: 100%;
          left: 0;
          width: 100%;
          top: -10000px;
          .swiper-slide {
            margin-right: 30px;

            .create {
              background-color: white;
              height: 100%;
              width: 100%;
              .create-poster {
                height: 100%;
                width: 100%;
                position: relative;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                .name {
                  position: absolute;
                  color: #ffffff;
                  font-size: 14px;
                  top: 34%;
                  left: 20px;
                }
                .type_img {
                  height: auto;
                  position: absolute;
                  font-size: 18px;
                  top: 39%;
                  left: 11px;
                  width: 100px;
                  height: auto;
                }
                .tip {
                  width: 178px;
                  padding: 5px 5px 8px;
                  background: #000000;
                  border-radius: 18px;
                  font-size: 15px;
                  color: white;
                  text-align: center;
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 39%;
                }
                .poster2 {
                  position: absolute;
                  bottom: 30px;
                  width: 100%;
                  left: 20px;
                  line-height: 18px;
                }
              }
              .info {
                height: 15%;
                display: flex;
                padding: 10px;
                justify-content: space-between;
                align-items: center;
                .left {
                  display: flex;
                  .avatar {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-right: 10px;
                  }
                }
                .qr-code {
                }
              }
            }
          }
        }
        .swiper-pagination {
          width: 100%;
          margin: 20px 0;
        }
        .pagination {
          .swiper-pagination-bullet {
            background-color: #000000;
            margin-right: 20px;
            &:last-child {
              margin-right: 0;
            }
          }
          .swiper-pagination-bullet-active {
            opacity: 0.3;
            background-color: #000000;
          }
        }
      }
    }
    .share_btm {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 65px;
      // justify-content: space-between;
      .left {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
        display: flex;
        align-items: center;
        img {
          width: 29px;
          height: 25px;
          margin-right: 10px;
        }
      }
      .right {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.6;
      }
    }
  }
  .write {
    height: 100%;
    background: url("../../assets/img/cxbc/Lszfrom.jpg") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .write_input {
      background: transparent;
      position: absolute;
      left: 50%;
      top: 240px;
      transform: translate(-50%, -50%);
      width: 198px;
      border: none;
      color: #ffffff;
      font-size: 18px;
      font-family: SourceHanSansSC;
      font-weight: 300;
      // padding: 0 25px 0px 25px;
      text-align: center;
      box-sizing: border-box;
    }
    .sxbox {
      width: 198px;
      height: 2px;
      background: #ffffff;
      opacity: 0.7;
      position: absolute;
      left: 50%;
      top: 260px;
      transform: translate(-50%, -50%);
    }
    .write_input::-webkit-input-placeholder {
      color: #fff;
      opacity: 0.7;
      // position: absolute;
      // // top: 0.04rem;
      // left: 50%;
      // transform: translateX(-50%);
    }
    .write_btn {
      position: absolute;
      width: 193px;
      height: 42px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #ffffff;
      border-radius: 21px;
      left: 50%;
      bottom: 30px;
      transform: translate(-50%, -50%);
      font-size: 21px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .write_btn2 {
      position: absolute;
      width: 253px;
      height: 42px;
      background: rgba(255, 255, 255, 0.5);
      border: 1px solid #ffffff;
      border-radius: 21px;
      left: 50%;
      bottom: 30px;
      transform: translate(-50%, -50%);
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .wd3 {
      position: absolute;
      border-radius: 21px;
      left: 50%;
      bottom: 10px;
      transform: translate(-50%, -50%);
      font-size: 13px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
