<template>
  <div>
    <van-pull-refresh
      v-model="isLoading"
      loosing-text="下拉即可刷新"
      pulling-text="释放即可刷新"
      loading-text="加载中"
      @refresh="onRefresh"
    >
      <div v-if="showType == 1">
        <!-- 头部 -->
        <div class="headerBgk pand-10">
          <div class="headerImg">
            <img :src="imgSrc" :onload="onImgSuccess()" alt="" />
          </div>
          <div class="headerbottom">
            <img src="../../assets/img/sleepActivity/2.png" alt="" />
            <div class="totalPeople">
              {{ configure.total_person }}人已参与预约
            </div>
          </div>
        </div>

        <!-- 活动参与 -->
        <div class="canyu">
          <img src="../../assets/img/sleepActivity/canyu.png" alt="" />
        </div>

        <!-- 活动简介 -->
        <div class="introduction pand-10">
          <img src="../../assets/img/sleepActivity/introduce.png" alt="" />
        </div>

        <!-- 活动详情 -->
        <div class="details">
          <img src="../../assets/img/sleepActivity/font.png" alt="" />
        </div>
      </div>
      <div class="showType--two" v-if="showType == 2 && configure.time">
        <div
          class="smCont"
          v-if="
            isLogin &&
            !user.isAttested &&
            nowTimeNumber < configure.time.end &&
            shareId
          "
        >
          <div class="smLeft">
            <div class="imgIcon">
              <img src="../../assets/img/sleepActivity/icon.png" alt="" />
            </div>
            <div class="textFont">下载APP并实名认证可完成好友助力</div>
          </div>
          <div class="smBtn" @click="onDownLoad()">实名认证</div>
        </div>
        <div class="headerGif" :style="isLogin &&
            !user.isAttested &&
            nowTimeNumber < configure.time.end &&
            shareId ? 'margin-top: 45px;' : ''">
          <img :src="imgSrcs" :onload="onImgSuccess()" alt="" />
          <div class="totalPeople">
            <div>参与人数</div>
            <div >{{ configure.total_person }}</div>
          </div>
        </div>
        <div class="contents">
          <div class="contents--one">
            <img src="../../assets/img/sleepActivity/introduction.png" alt="" />
          </div>
          <div class="contents--two">
            <img src="../../assets/img/sleepActivity/part2.png" alt="" />
          </div>
        </div>
        <div class="publicationTime">
          <div class="timeHeader">
            <div v-if="configure.time">
              持续时间：{{ dateFormat(configure.time.start, "i") }} --
              {{ dateFormat(configure.time.end, "i") }}
            </div>
            <div>邀新4大站点</div>
          </div>
          <div class="listCont">
            <div
              class="list"
              v-for="(item, index) in timeList"
              :key="index"
              :style="`background-image: url(${item.imgSrc}); background-size: 100% 100%; background-repeat: no-repeat;`"
            >
              <div class="listName">
                <span>{{ item.font.fontOne }}</span
                >{{ item.font.fontTwo }}
              </div>
              <div class="listStrat">
                <div>{{ item.startDate.dateOne }}</div>
                <div>{{ item.startDate.dateTwo }}</div>
              </div>
              <div class="timeLine">
                <img src="../../assets/img/sleepActivity/timeLine.png" alt="" />
              </div>
              <div class="listEnd">
                <div>{{ item.endDate.dateOne }}</div>
                <div>{{ item.endDate.dateTwo }}</div>
              </div>
            </div>
          </div>
          <div class="publicationEndTime">
            <div>周排行榜公布时间:</div>
            <div>{{ timeContinued }}</div>
          </div>
          <div class="timeTotal">
            <div v-if="configure.time">
              总排行榜公布时间：{{ dateFormat(configure.time.end, "i") }}
            </div>
            <div>排行奖励均可叠加</div>
          </div>
        </div>
        <div class="explain">
          <img src="../../assets/img/sleepActivity/explain.png" alt="" />
        </div>
      </div>

      <!-- 底部 -->
      <div class="btm">
        <!-- <div class="btmQr">
          <img
            class="btmQrImg"
            src="../../assets/img/sleepActivity/qr.png"
            alt=""
          />
          <div class="btmFont">
            <div class="text">加入轻松小镇社群</div>
            <div class="text">了解更多活动信息</div>
          </div>
        </div>
        <div class="btmBgk">
          <img v-if="showType == 1" src="../../assets/img/sleepActivity/btmFont.png" alt="" />
          <img v-if="showType == 2" src="../../assets/img/sleepActivity/btmFont2.png" alt="" />

        </div> -->
        <div class="btmQr">
          <img src="../../assets/img/sleepActivity/ttts.png" alt="" />
        </div>
      </div>
      <!-- 悬浮按钮 -->
      <div class="btnBgk" v-if="rankingInfo">
        <div class="btnWh">
          <div class="btnBottom" v-if="btnData.btnType == 2">
            <div class="btnLeft">
              <div class="ranking">本周排名：{{ rankingInfo.ranking }}</div>
              <div class="userPortrait">
                <div class="portrait">
                  <img v-if="user.avatar != '0'" :src="user.avatar" alt="" />
                  <img v-else src="../../assets/img/user.png" alt="" />
                </div>
                <div class="userIntegral">
                  <div class="name">{{ user.nickname }}</div>
                  <div class="integral">
                    本周积分：{{ rankingInfo.growing }}
                  </div>
                </div>
              </div>
            </div>
            <div class="btnRight">
              <div
                class="rankingFont"
                @click="gotoPage('sleepActivityRankingList')"
              >
                查看完整排行 >
              </div>
              <div class="btns">
                <div class="btnFont" @click="onTypeBtn()">
                  <div class="text">
                    {{ btnData.btnFont }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btnFont" v-else @click="onTypeBtn()">
            <div class="text">
              <!-- :style="
                btnData.btnType == 2 ? 'font-size: 15px;' : 'font-size: 20px;'
              " -->
              {{ btnData.btnFont }}
            </div>
          </div>
        </div>
        <div class="btnTime" v-if="!configure.hide_switch">{{ endTime }}</div>
      </div>
    </van-pull-refresh>

    <Share
      ref="Share"
      v-if="showShare"
      @close="onColse()"
      :show="showShare"
    ></Share>
    <WinaPrize
      ref="WinaPrize"
      v-if="isLogin && configure.tips"
      @close="onWinaPrizeColse()"
      :show="winaPrizeType"
      :rankingData="rankingInfo"
    ></WinaPrize>
    <Appointment
      ref="Appointment"
      v-if="appointmentType"
      @close="onAppointmentColse()"
      :show="appointmentType"
      :shareId="shareId"
      :isAttested="user.isAttested"
    ></Appointment>
    <login
      :show="isShowLogin"
      @close="isShowLogin = false"
      @success="loginSuccess"
    ></login>
    <van-overlay :show="show" :custom-style="{ background: 'rgba(0,0,0,1)' }">
      <div class="loading">
        <img src="../../assets/img/sleepActivity/loading.gif" alt="" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import Share from "./components/share";
import WinaPrize from "./components/winaPrize";
import Appointment from "./components/appointment";
import { info, reserve, leaderboard, tips } from "../../api/sleep";
import { mapMutations, mapState } from "vuex";
import Login from "../../components/Login";
import { getWxConfig } from "../../../tool";
export default {
  components: { Share, Login, WinaPrize, Appointment },
  data() {
    return {
      btnData: {
        btnType: "", //按钮状态 1未参与 2已参与 3未开始 4已结束
        btnFont: "参与预约", //按钮文案
      },
      time: "00:00:00", //时间
      showShare: false, //海报是否显示
      configure: {}, //详情数据
      rankingInfo: {}, //排行榜信息
      loadings: true,
      isShowLogin: false, //判断是否登录
      endTime: "",
      imgSrc: require("../../assets/img/sleepActivity/apo.png"), //gif图
      imgSrcs: require("../../assets/img/sleepActivity/part1.png"), //gif图
      isLoading: false, //下拉刷新
      show: true, //loading显示
      winaPrizeType: false, //中奖弹框
      imgSrcState: false, //判断头图是否加载完毕
      showType: 2, //样式判断
      timeList: [], //当月时间4周处理
      timeContinued: "", //当月持续时间
      appointmentType: false, //预约活动是否成功
      shareId: "", //上级ID
      nowTimeNumber: "", //当前时间
    };
  },
  created() {
    document.title = "118早睡行动预约开启";
    getWxConfig("无聊猿#1365向你发起号召啦", "“118早睡行动”有奖预约等你来");
  },
  mounted() {
    this.onShareID();
    this.onConfigure();
    // setTimeout(()=>{
    //   this.appointmentType = true
    // },3000)
  },
  destroyed() {},
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      invited_code: (state) => state.invited_code,
      invited_ids: (state) => state.invited_ids,
    }),
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    onAppointmentColse() {
      this.appointmentType = false;
      this.onConfigure();
    },
    onWinaPrizeColse() {
      this.winaPrizeType = false;
    },
    //跳转页面
    gotoPage(name) {
      this.$router.push({
        name,
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        this.onConfigure();
        clearInterval(this.timer);
      }, 1000);
    },
    //GIF加载完毕
    onImgSuccess() {
      // this.imgSrc = require("../../assets/img/sleepActivity/activity.gif")
      console.log(111)
      this.imgSrcState = true;
      this.imgSrcs = require("../../assets/img/sleepActivity/part1.gif");
      
    },
    //点击按钮
    onTypeBtn() {
      if (!this.isLogin) {
        this.isShowLogin = true;
        return false;
      }
      //1预约 2打开海报 3活动未开始
      console.log(this.btnData);

      switch (this.btnData.btnType) {
        case 1:
          this.onReserve();
          break;
        case 2:
          this.onShare();
          break;
        case 3:
          this.$toast("活动未开始");
          break;
        case 4:
          this.$toast("活动已结束");
          break;
      }
    },
    //获取链接值
    GetArgsFromHref(sHref, sArgName) {
      var args = sHref.split("?");
      var retval = "";

      if (args[0] == sHref) {
        /*参数为空*/
        return retval; /*无需做任何处理*/
      }
      var str = args[1];
      args = str.split("&");
      for (var i = 0; i < args.length; i++) {
        str = args[i];
        var arg = str.split("=");
        if (arg.length <= 1) continue;
        if (arg[0] == sArgName) retval = arg[1];
      }
      return retval;
    },
    //获取上级ID
    onShareID() {
      let url = window.location.hash;
      this.shareId = this.GetArgsFromHref(url, "invited_ids")
        ? this.GetArgsFromHref(url, "invited_ids")
        : "";
    },
    //加入活动
    async onReserve() {
      this.onShareID();
      try {
        const res = await reserve({
          acctoken: this.isLogin,
          from_user: this.shareId,
        });
        this.appointmentType = true;
        // this.$toast(res.data.info);
        // this.onConfigure();
      } catch (e) {
        this.onError(e);
      }
    },
    //获取详情
    async onConfigure() {
      try {
        const res = await info({
          acctoken: this.isLogin,
        });
        let data = res.data.data;
        this.configure = data;
        this.$nextTick(()=>{
          this.onTimeHandle();
          this.onTimeBtnType();
          //判断头部是否加载完毕
          if (this.imgSrcState) {
            setTimeout(() => {
              this.show = false;
            }, 1500);
          }
          //判断是否登录
          if (this.isLogin) {
            this.winaPrizeType = this.configure.tips;
            this.onLeaderboard();
          }
        })
        
      } catch (e) {
        this.onError(e);
      }
    },
    //4个周时间处理
    onTimeHandle() {
      this.$nextTick(() => {
        let dataList = this.configure.time_arr,
          list = [],
          totalTimeList = [];
        dataList.forEach((item, index) => {
          totalTimeList.push(this.dateFormat(item.end_time, "i"));
          list.push({
            startDate: {
              //当周开始时间
              dateOne: this.dateFormat(item.start_time, "s"), //月日
              dateTwo: this.dateFormat(item.start_time, "d"), //时分
            },
            endDate: {
              //当周结束时间
              dateOne: this.dateFormat(item.end_time, "s"), //月日
              dateTwo: this.dateFormat(item.end_time, "d"), //时分
            },
            font: {
              fontOne: item.title.substring(0, 1), //取第一个字
              fontTwo: item.title.slice(1), //截取第一个字
            },
            imgSrc: item.image_url,
          });
        });
        this.timeContinued = totalTimeList.join("/");
        this.timeList = list;
        this.$forceUpdate();
      });
    },
    onDownLoad(){
      window.location.href = 'https://appfile.relaverse.cn/m/download.html'
    },
    openInWebview () {
      /* 返回true或false; */
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 微信浏览器判断
        return false
      } else if (ua.match(/QQ/i) == 'qq') {
        // QQ浏览器判断
        return false
      } else if (ua.match(/WeiBo/i) == 'weibo') {
        return false
      } else {
        if (ua.match(/Android/i) != null) {
          return ua.match(/browser/i) == null
        } else if (ua.match(/iPhone/i) != null) {
          return ua.match(/safari/i) == null
        } else {
          return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null
        }
      }
    },
    //获取排行榜
    async onLeaderboard() {
      try {
        const res = await leaderboard({ type: "week" });
        if (res.data instanceof Object) {
          this.rankingInfo = res.data;
        } else {
          this.rankingInfo = {};
        }
      } catch (e) {
        this.onError(e);
      }
    },
    //按钮状态
    onTimeBtnType() {
      let i = 0;
      var nowtime = new Date(); //获取当前时间
      //当前时间小于开始时间活动未开始
      this.nowTimeNumber = Math.floor(nowtime.getTime() / 1000);
      if (this.nowTimeNumber < this.configure.time.start) {
        this.btnData.btnFont = "活动未开始";
        this.btnData.btnType = 3;
        return false;
      }
      //当前时间大于结束时间活动已结束
      if (this.nowTimeNumber > this.configure.time.end) {
        this.btnData.btnFont = "活动已结束";
        this.btnData.btnType = 4;
        return false;
      }
      // 未参与已参与状态
      if (!this.configure.is_join) {
        this.btnData.btnFont = "参与预约";
        this.btnData.btnType = 1;
        return false;
      } else {
        this.btnData.btnFont = "邀请好友";
        this.btnData.btnType = 2;
      }
      this.timer = setInterval(() => {
        const time = this.countDown(Number(this.configure.time.end), 1);
        if (!time) {
          clearInterval(this.timer);
          if (this.configure.is_join) {
            this.loadings = true;
          } else {
            this.btnData.btnFont = "活动已结束";
            this.btnData.btnType = 4;
            this.loadings = false;
          }
          // announceTime
        } else {
          this.endTime = time;
          if (!i) {
            i += 1;
            this.loadings = false;
          }
        }
      }, 1000);
    },
    //关闭海报
    onColse() {
      this.showShare = false;
    },
    //海报生成
    onShare() {
      this.showShare = true;
    },
    //登录成功
    loginSuccess() {
      this.isShowLogin = false;
      this.showGetMedal();
    },
    //没登陆先登录
    onLogins() {
      if (!this.isLogin) {
        this.isShowLogin = true;
        return false;
      }
    },
    showGetMedal() {
      this.onConfigure();
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
      this.loadings = false;
    },
    /**
     * 倒计时
     * @param {Number} endTime 结束时间
     * @param {Number} type 状态值
     */
    countDown(endTime, type) {
      var nowtime = new Date(); //获取当前时间
      var lefttime = endTime * 1000 - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数

      if (leftd <= 0 && lefth <= 0 && leftm <= 0 && lefts <= 0) {
        return false;
      }
      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      if (type == 1) {
        return ` ${leftd}d  ${timeAdd0(lefth)}:${timeAdd0(leftm)}:${timeAdd0(
          lefts
        )}后结束`;
      } else {
        return `结果将在 ${timeAdd0(lefth)}:${timeAdd0(leftm)}:${timeAdd0(
          lefts
        )} 后公布`;
      }
      // return `${leftd}天${lefth}时${leftm}分${lefts}秒`;
    },
    //时间戳转化
    dateFormat(value, type) {
      if (!value) return "";
      let time = new Date(Math.floor(value * 1000));
      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      if (type == "s") {
        return timeAdd0(m) + "月" + timeAdd0(d) + "日";
      } else if (type == "d") {
        return timeAdd0(h) + ":" + timeAdd0(mm);
      } else if (type == "i") {
        return (
          timeAdd0(m) +
          "月" +
          timeAdd0(d) +
          "日" +
          timeAdd0(h) +
          ":" +
          timeAdd0(mm)
        );
      }
    },
  },
};
</script>
<style scoped  lang="scss">
/deep/ .van-pull-refresh{
  background: #0f0f0f!important;
}
/deep/ .van-loading {
  text-align: center;
}
/deep/ .van-overlay{
  z-index: 5!important;
}
/deep/ .van-pull-refresh__head {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}
// 超出显示...单文本
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
@mixin btn_Fun($url, $height, $width, $fongSize) {
  .btnFont {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: $height;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $width;
    margin: auto;
    .text {
      color: #fff;
      font-size: $fongSize;
      /* font-weight: 700; */
      letter-spacing: 2px;
      margin-top: -8px;
    }
  }
}
@mixin bgk_Fun($url) {
  background-image: url($url);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.headerGif{
  position: relative;
  .totalPeople{
    position: absolute;
    bottom: 94px;
    right: 23px;
    color: #fff;
    div{
      font-size: 20px;
      line-height: 30px;
      text-align: right;
      font-weight: 700;
    }
    div:last-child{
      font-size: 25px!important;

    }
  }
}
.smCont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;z-index: 3;
  .smLeft {
    display: flex;
    align-items: center;
    .imgIcon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .textFont {
      font-size: 11px;
      color:#fff;
    }
  }
  .smBtn {
    background: #2a1ccc;
    padding: 5px 15px;
    border-radius: 20px;
      color:#fff;
    font-size: 11px;
  }
}
.showType--two {
  .contents {
    position: relative;
    top: -7px;
    .contents--one {
      position: absolute;
      top: -87px;
      z-index: 2;
    }
  }
  .publicationTime {
    .timeHeader {
      color: #fff;
      font-size: 16px;
      margin-bottom: 20px;
      div {
        text-align: center;
      }
      div:first-child {
        margin-bottom: 10px;
      }
    }
    .listCont {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 30px;
      .list {
        color: #ffffff;
        width: 85px;
        height: 150px;
        padding: 10px 0px 0px 10px;
        .listName {
          margin-bottom: 5px;
          font-size: 11px;
          span {
            font-size: 16px !important;
            font-weight: 700;
            margin-right: 5px;
          }
        }
        .listStrat {
          margin-bottom: 4px;
          div:first-child {
            font-size: 15px;
            font-weight: 700;
          }
        }
        .timeLine{
          width: 1px;
          height: 15px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .listEnd {
          div:first-child {
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
    }
    .publicationEndTime {
      color: #ffffff;
      width: 70%;
      margin: auto;
      line-height: 25px;
      div {
        text-align: center;
      }
    }
    .timeTotal {
      color: #ffffff;
      margin: 20px 0px;
      div {
        text-align: center;
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 80px;
    height: 80px;
  }
}
.canyu {
  padding-top: 10px;
}
.introduction {
  padding-top: 5px !important;
}
.details {
  padding: 20px 0;
}
.btm {
  .btmQr {
    text-align: center;
    // margin-bottom: -170px;
    position: relative;
    background: #0f0f0f;
    .btmQrImg {
      margin-top: 10px;
      width: 130px;
      height: 130px;
      border-radius: 5px;
      overflow: hidden;
    }
    .btmFont {
      margin-top: 10px;
      .text {
        font-size: 13px;
        color: #fff;
        text-align: center;
      }
    }
  }
}

.pand-10 {
  padding: 20px 20px 0;
}
img {
  width: 100%;
  height: 100%;
}
.btnBgk {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  @include bgk_Fun($url: "../../assets/img/sleepActivity/bottom.png");
  .btnWh {
    width: 100%;
    height: 100px;
    .btnBottom {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      color: #fff;

      .btnLeft {
        width: 50%;
        .ranking {
          background-image: url("../../assets/img/sleepActivity/rankingBar.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding: 5px 10px;
          margin-bottom: 10px;
          font-size: 11px;
          width: 85%;
          text-align: center;
        }
        .userPortrait {
          display: flex;
          align-items: center;
          .portrait {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 5px;
          }
          .userIntegral {
            .name {
              width: 100px;
              @include ellipsis();
              font-size: 15px;
              margin-bottom: 5px;
            }
            .integral {
              font-size: 13px;
            }
          }
        }
      }
      .btnRight {
        width: 50%;
        .rankingFont {
          padding: 10px 0;
          text-align: center;
        }
        .btns {
          margin-top: 10px;
          @include btn_Fun(
            $url: "../../assets/img/sleepActivity/btm.png",
            $height: 36px,
            $width: 90%,
            $fongSize: 17px
          );
          .btnFont {
            .text {
              padding-top: 5px;
            }
          }
        }
      }
    }
    @include btn_Fun(
      $url: "../../assets/img/sleepActivity/button.png",
      $height: 100%,
      $width: 100%,
      $fongSize: 20px
    );
  }
  .btnTime {
    text-align: center;
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    color: #b6b6b6;
    font-size: 14px;
  }
}
.headerBgk {
  background-image: url("../../assets/img/sleepActivity/bg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .headerImg {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    display: flex;
  }
  .headerbottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    display: flex;
    position: relative;
    .totalPeople {
      position: absolute;
      bottom: 120px;
      left: 0;
      right: 0;
      color: #fff;
      text-align: center;
      font-size: 19px;
      font-weight: 700;
    }
  }
}
</style>
