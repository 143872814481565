<template>
  <div>
    <div class="head">
      <div class="back">
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div>
    </div>
    <div v-if="configure.time">
      <div v-if="type == 'hdgz'" class="hdgz" v-html="configure.time.text"></div>
    </div>
    <div class="qrs" v-if="type == 'kefu'">
      <div class="qr">
        <img :src="configure.time.image_url" alt="" />
      </div>
      <div class="qrFont">
        <div>微信扫描上方二维码联系客服</div>
        <div>我们将在看到消息后第一时间内给你回复</div>
      </div>
    </div>
    <van-overlay :show="show" :custom-style="{ background: 'rgba(0,0,0,1)' }">
      <div class="loading">
        <img src="../../assets/img/sleepActivity/loading.gif" alt="" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { info } from "../../api/sleep";
export default {
  name: "sleepActivityHdgz",
  data() {
    return {
      type: "", //判断是否是二维码 还是活动规则
      show: false,
      configure: {},
    };
  },
  created() {
    this.getParams();
  },
  mounted() {
    this.onConfigure();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#000000");
  },
  methods: {
    back() {
      this.$router.back();
    },
    
    //上级参数
    getParams() {
      this.type = this.$route.query.type;
      console.log(this.type);
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      console.log(e);
      this.$toast(e.data);
    },
    //获取详情
    async onConfigure() {
      try {
        const res = await info({
          acctoken: this.isLogin,
        });
        let data = res.data.data;
        this.configure = data;
      } catch (e) {
        this.onError(e);
      }
    },
  },
};
</script>
<style></style>
<style scoped lang="scss">
/deep/ .van-pull-refresh__head {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}
 ::v-deep img{
     max-width:100%;
 }  
.qrs {
  margin: 50px 0 0;
  .qr {
    width: 100px;
    height: 100px;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .qrFont {
    margin-top: 40px;
    font-size: 16px;
    color: #ffffff;
    div {
      text-align: center;
    }
  }
}
.texts {
  color: #878689;
  line-height: 20px;
  padding: 20px;
  div {
    font-size: 12px !important;
  }
}
.head {
  background-size: 100% 100%;
  position: relative;
  padding: 28px;
  display: flex;
  align-items: center;
  background: #000000;
  .back {
    width: 27px;
    height: 27px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    position: fixed;
    left: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}
.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}
/deep/ .van-overlay {
  z-index: 11 !important;
}
</style>
