// 本草纲目预约情况
import http from './http'

export const reserve = (data) => {
  return http.post('/index/Herb/lightMugwort', data)
}

// 本草合成藏品列表
export const checknft = (data) => {
  return http.post('index//Herb/CheckNft', data)
}

// 本草合成
export const synthesisNft = (data) => {
  return http.post('index//Herb/synthesisNft', data)
}
