<template>
  <div>
     <van-overlay
      z-index="100"
      :show="helpSuccess"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="blockBgk">
            <div class="successImg">
              <img
                src="../../../assets/img/lszSolicitation/helpSuccess.png"
                alt=""
              />
            </div>
            <div class="successText">
              <div>助力成功</div>
              <div>感谢您对好友的支持</div>
            </div>
            <div class="successBtn" @click="onSuccess">
              <div>确定</div>
            </div>
          </div>
          <div class="tipss">点击将自动返回活动首页</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay
      z-index="100"
      :show="successful"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <div class="wrapper">
        <div class="fail" @click.stop>
          <div class="failIcon" @click="close">
            <img
              src="../../../assets/img/lszSolicitation/closePopup.png"
              alt=""
            />
          </div>
          <div class="failTop">
            <div>
              您的助力榜排名：
              <span>{{ ranking }}</span>
              名
            </div>
          </div>
          <div class="failFont">
            <div>恭喜您</div>
            <div>已获得本次授权资格</div>
            <div>奖励将在服贸会结束后空投</div>
          </div>
          <div
            class="failBtn"
            :class="!btnState ? 'showBtn' : 'hideBtn'"
            @click="onFailReceive()"
          >
            <div>确定</div>
            <!-- <div>{{ toappx ? "确定" : "前往APP查看" }}</div> -->
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay
      z-index="100"
      :show="fail"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <div class="wrapper">
        <div class="fail" @click.stop>
          <div class="failIcon" @click="close">
            <img
              src="../../../assets/img/lszSolicitation/closePopup.png"
              alt=""
            />
          </div>
          <div class="failTop">
            <div>
              您的助力榜排名：
              <span>{{ ranking }}</span>
              名
            </div>
          </div>
          <div class="failFont">
            <div>很抱歉</div>
            <div>您未获得本次授权资格</div>
          </div>
          <div
            class="failBtn"
            :class="!btnState ? 'showBtn' : 'hideBtn'"
            @click="onFailReceive()"
          >
            <!-- <div>{{ toappx ? "确定" : "前往APP查看" }}</div>
             -->
            <div>确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay
      z-index="100"
      :show="receiveSuccess"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <div class="wrapper">
        <div class="receiveSuccess" @click.stop>
          <div class="failIcon" @click="close">
            <img
              src="../../../assets/img/lszSolicitation/closePopup.png"
              alt=""
            />
          </div>
          <div class="failTop">
            <div>
              您的助力榜排名：
              <span>{{ ranking }}</span>
              名
            </div>
          </div>
          <div class="failFont">
            <div>很抱歉</div>
            <div>您未获得本次授权资格</div>
          </div>
          <div class="failPng">
            <!-- <img src="../../../assets/img/lszSolicitation/rBox.png" alt=""> -->
            <div>已领取</div>
          </div>
          <div class="failBtn">
            <div>参与奖励已领取</div>
          </div>
          <div class="tips">
            <div>可打开 <span>APP - 我的</span> 查看获得奖励</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay
      z-index="100"
      :show="failrReward"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <div class="wrapper">
        <div class="failReceive" @click.stop>
          <div class="failIcon" @click="close">
            <img
              src="../../../assets/img/lszSolicitation/closePopup.png"
              alt=""
            />
          </div>
          <div class="failFont">
            <div class="fontTitle">恭喜您获得了</div>
            <div class="reward">
              <div>青铜胸针 <span>*1</span></div>
              <div>转赠符 <span>*2</span></div>
            </div>
          </div>
          <div class="failPng">
            <img src="../../../assets/img/lszSolicitation/reward.png" alt="" />
          </div>
          <div class="failBtn" @click="onFailReceives()">
            <div>确认</div>
          </div>
          <div class="tips">
            <div>可打开 <span>APP - 我的</span> 查看获得奖励</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { user_receive } from "../../../api/solicitation";
export default {
  name: "TipsPopup",
  props: {
    //排名
    ranking: {
      type: [Number, String],
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      helpSuccess: false, //助力成功
      successful: false, //授权成功
      fail: false, //授权失败
      failrReward: false, //授权奖励
      btnState: false, //是否领取成功
      receiveSuccess: false, //领取完毕
      toappx: false, //判断是否在APP内领取
    };
  },
  mounted() {
    this.toappx = this.openInWebview();
  },
  methods: {
    onFailReceives() {
      this.onSwitch(4, "close");
    },
    //未中奖用户领取奖励
    async onFailReceive() {
      // if (this.toappx) {
      // try {
      //   const res = await user_receive({
      //     acctoken: this.isLogin,
      //   });
      //   this.btnState = true;
      //   setTimeout(() => {
      //     this.onSwitch(3, "close");
      //     this.onSwitch(4, "open");
      //   }, 3000);
      // } catch (e) {
      //   this.onError(e);
      // }
      this.onSwitch(2, "close");
      this.onSwitch(3, "close");
      // } else {
      //   window.location.href = "https://appfile.relaverse.cn/m/download.html";
      // }
    },
    onError(e) {
      this.$toast(e.data);
    },
    close() {
      this.onClose();
    },
    onSuccess() {
      this.$emit("onSuccess");
      this.onClose();
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({
          path: "/",
        });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
    /**
     * @param {Object} status
     * @param {Object} switchTypes	open 开启  close 关闭
     */
    onSwitch(status, switchTypes) {
      const actions = this.onListMap();
      let action = [...actions].filter(
        ([key, value]) => key.switchTypes == switchTypes && key.status == status
      );
      action.forEach(([key, value]) => value.call(this));
    },
    //关闭所有弹框
    onClose() {
      this.helpSuccess = false;
      this.successful = false;
      this.fail = false;
      this.failrReward = false;
      this.receiveSuccess = false; //领取完毕
    },
    openInWebview() {
      /* 返回true或false; */
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // 微信浏览器判断
        return false;
      } else if (ua.match(/QQ/i) == "qq") {
        // QQ浏览器判断
        return false;
      } else if (ua.match(/WeiBo/i) == "weibo") {
        return false;
      } else {
        if (ua.match(/Android/i) != null) {
          return ua.match(/browser/i) == null;
        } else if (ua.match(/iPhone/i) != null) {
          return ua.match(/safari/i) == null;
        } else {
          return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null;
        }
      }
    },
    onListMap() {
      const actions = new Map([
        [
          {
            switchTypes: "close",
            status: 1,
          },
          () => {
            this.helpSuccess = false;
          },
        ],
        [
          {
            switchTypes: "open",
            status: 1,
          },
          () => {
            this.helpSuccess = true;
          },
        ],
        [
          {
            switchTypes: "close",
            status: 2,
          },
          () => {
            this.successful = false;
          },
        ],
        [
          {
            switchTypes: "open",
            status: 2,
          },
          () => {
            this.successful = true;
          },
        ],
        [
          {
            switchTypes: "close",
            status: 3,
          },
          () => {
            this.fail = false;
          },
        ],
        [
          {
            switchTypes: "open",
            status: 3,
          },
          () => {
            this.fail = true;
          },
        ],
        [
          {
            switchTypes: "close",
            status: 4,
          },
          () => {
            this.failrReward = false;
          },
        ],
        [
          {
            switchTypes: "open",
            status: 4,
          },
          () => {
            this.failrReward = true;
          },
        ],
        [
          {
            switchTypes: "close",
            status: 5,
          },
          () => {
            this.receiveSuccess = false;
          },
        ],
        [
          {
            switchTypes: "open",
            status: 5,
          },
          () => {
            this.receiveSuccess = true;
          },
        ],
      ]);
      return actions;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .van-overlay {
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .receiveSuccess {
    background: #000 !important;
    border-radius: 10px;
    padding: 10px 0 20px;
    width: 80%;
    position: relative;
    .failIcon {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      justify-content: flex-end;
      position: absolute;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .failTop {
      background-image: url("../../../assets/img/lszSolicitation/gradient.png");
      background-size: 100% 100%;
      padding: 10px 0;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 20px;
      div {
        color: #e3e3e3;
        font-size: 12px;

        span {
          color: #24b658 !important;
        }
      }
    }

    .failFont {
      margin-bottom: 30px;

      div {
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
      }
    }

    .failText {
      color: #b6b6b6;
      font-size: 12px;
      margin-bottom: 35px;
      text-align: center;
    }

    .failPng {
      width: 210px;
      height: 180px;
      background-image: url("../../../assets/img/lszSolicitation/receiveSuccessBgk.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;

      div {
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;
        padding: 14px 0;
        color: #464949;
        // background: rgb(red, green, blue);
      }
    }

    .failPngAnimation {
      animation: triangleAnimation 3s;
      animation-fill-mode: forwards;
    }

    .failBtn {
      transition: all 0.5s;
      padding: 15px 0px;
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      background: #1a1a1a;
      border-radius: 30px;

      div {
        color: #959595;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .tips {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        color: #848484;
        font-size: 12px;
        font-weight: 700;

        span {
          color: #c9c9c9 !important;
        }
      }
    }
  }

  .failReceive {
    background: #000 !important;
    border-radius: 10px;
    padding: 10px 0 20px;
    width: 80%;
    position: relative;
    .failIcon {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      justify-content: flex-end;
      position: absolute;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .failFont {
      margin-bottom: 20px;
      margin-top: 20px;
      .fontTitle {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        margin-bottom: 20px;
      }

      .reward {
        display: flex;
        align-items: center;
        justify-content: space-around;

        div {
          background-image: url("../../../assets/img/lszSolicitation/polygonBgk.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding: 5px 30px;
          width: 120px;
          font-size: 11px;
          color: #fff;
          font-weight: 700;
          line-height: 20px;
          text-align: center;

          span {
            color: #ffeb9c !important;
          }
        }
      }
    }

    .failPng {
      width: 200px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .failPngAnimation {
      animation: triangleAnimation 4s;
      animation-fill-mode: forwards;
    }

    .failBtn {
      padding: 15px 0px;
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
      background: #22464b;
      border-radius: 30px;

      div {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .tips {
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        color: #848484;
        font-size: 12px;
        font-weight: 700;

        span {
          color: #c9c9c9 !important;
        }
      }
    }
  }

  .fail {
    background: #000 !important;
    border-radius: 10px;
    padding: 10px 0 20px;
    width: 80%;
    position: relative;
    .failIcon {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
      justify-content: flex-end;
      position: absolute;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .failTop {
      background-image: url("../../../assets/img/lszSolicitation/gradient.png");
      background-size: 100% 100%;
      padding: 10px 0;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 30px;

      div {
        color: #e3e3e3;
        font-size: 12px;

        span {
          color: #24b658 !important;
        }
      }
    }

    .failFont {
      margin-bottom: 30px;

      div {
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
      }
      div:first-child {
        margin-bottom: 10px;
      }
    }

    .failText {
      color: #b6b6b6;
      font-size: 12px;
      margin-bottom: 35px;
      text-align: center;
    }

    .failPng {
      width: 200px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .failPngAnimation {
      animation: triangleAnimation 3s;
      animation-fill-mode: forwards;
    }

    .showBtn {
      opacity: 1;
    }

    .hideBtn {
      opacity: 0;
    }

    .failBtn {
      transition: all 0.5s;
      padding: 15px 0px;
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      background: #22464b;
      border-radius: 30px;

      div {
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .successful {
    .successfuls {
      margin-bottom: 10px;

      div {
        background: linear-gradient(-20deg, #fdffeb 0%, #ffebbb 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 25px;
        font-weight: 700;
        text-align: center;
      }
    }

    .successfulsRanking {
      font-size: 13px;
      color: #7c7b80;
      margin-bottom: 20px;

      text-align: center;

      span {
        color: #fff;
        font-size: 12px;
      }
    }

    .successBgk {
      background-image: url("../../../assets/img/lszSolicitation/successBgk.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 40px 10px;
      width: 300px;
      margin: auto;

      .qrCode {
        width: 180px;
        height: 180px;
        background: #fff;
        border-radius: 10px;
        margin: 0 auto 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 90%;
          height: 90%;
        }
      }

      .successFont {
        div {
          text-align: center;
          font-size: 14px;
          color: #fff;
          line-height: 20px;
        }
      }
    }

    .btns {
      color: #000;
      font-size: 13px;
      background: #ffffff;
      border-radius: 30px;
      width: 70%;
      padding: 15px 0;
      display: flex;
      font-weight: 700;
      align-items: center;
      justify-content: center;
      margin: 20px auto 0;
    }
  }

  .block {
    .blockBgk {
      width: 260px;
      background: #000;
      padding: 18px;
      margin: auto;
      border-radius: 28px;

      img {
        pointer-events: unset;
      }
    }

    .tipss {
      text-align: center;
      margin-top: 20px;
      font-size: 12px;
      color: #ffffff;
    }

    .successImg {
      width: 100px;
      height: 100px;
      margin: 0 auto 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .successText {
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      font-weight: 700;

      div {
        text-align: center;
        margin-bottom: 10px;
      }
    }

    .successBtn {
      margin: 20px auto 0;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      background: #22464b;
      width: 80%;
      color: #fff;
    }
  }
}

.back {
  position: absolute;
  left: 17px;
  top: 17px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: rgba(236, 236, 236, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

forwards {
  @keyframes triangleAnimation {
    0% {
      transform: scale(1);
    }

    60% {
      transform: scale(1.1);
    }

    70%,
    80% {
      transform: scale(1.1) rotate(14deg);
    }

    75% {
      transform: scale(1.1) rotate(-14deg);
    }

    65%,
    85% {
      transform: scale(1.1) rotate(0);
    }

    100% {
      transform: scale(1.1);
    }
  }
}
</style>
