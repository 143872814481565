<template>
  <div class="home" @touchstart='getTouchStart($event)' @touchend='getTouchEnd($event)'>
    <!--    <div class="go-back" @click="goBack()">
          <img
            :src="require('../assets/img/Activity/left.png')"
            class="icon"
            style="width: 20px;height: 20px"
          />
        </div>-->
    <div class="info">
      <div class="goods-img">
        <div style="height: 390px" v-if="!detail.detail_img"></div>
        <img style="width: 100%" :src="detail.detail_img"
             @click="addTouch({'event':2,'height':0,'action':3,'create_time':new Date().getTime()})">
        <div class="goods-rule" @click="toRule()">规 则</div>
        <div class="goods-time">
          <div class="goods-lock">
            <img style="width: 20px;height: 20px" @load="getHeaderHeight()"
                 :src="require('../assets/img/Activity/time.png')">
            <div>{{ actendtime }}</div>
          </div>
        </div>
      </div>
      <div class="goods-info" id="bannerHeight"
           @click="addTouch({'event':2,'height':0,'action':2,'create_time':new Date().getTime()})">
        <div class="goods-title">{{ detail.name }}</div>
        <div class="goods-tips-all">
          <div class="goods-tips" v-for="i in nft_info.creator">
            <div class="tips-title">{{i.name}}</div>
            <div class="tips-info">{{ i.value }}</div>
          </div>
        </div>
        <div class="line"></div>
        <div class="info-title">
          <div class="info-title-name">藏品故事</div>
          <div class="info-title-open" @click="openIntroduction()" v-show="!openInfo">展开</div>
          <div class="info-title-open" @click="openIntroduction()" v-show="openInfo">收起</div>
          <img :class="!openInfo || 'rx180'" :src="require('../assets/img/Activity/down.png')"
               style="width: 20px;height: 20px"/>
        </div>
        <div class="info-introduce">{{ showIntroduction }}</div>
      </div>
      <div class="goods-info">
        <div class="line"></div>
        <div class="act" :style="'background-image: url('+ detail.sacrifice_background_img+')'">
          <div class="limit-time">限时</div>
          <div class="act-info">
            <div class="act-info-title">{{ nft_info.nft_name }}</div>
            <div class="act-info-user">
              <div class="act-info-header">
                <div class="act-info-header-1">
                  <img class="yuan-img" v-if="subscribeList[0]" :src="subscribeList[0].avatar">
                  <img class="yuan-img" v-else :src="require('../assets/img/user.png')">
                </div>
                <div class="act-info-header-2">
                  <img class="yuan-img" v-if="subscribeList[1]" :src="subscribeList[1].avatar">
                  <img class="yuan-img" v-else :src="require('../assets/img/user.png')">
                </div>
                <div class="act-info-header-3">
                  <img class="yuan-img" v-if="subscribeList[2]" :src="subscribeList[2].avatar">
                  <img class="yuan-img" v-else :src="require('../assets/img/user.png')">
                </div>
              </div>
              <div class="act-info-num">已有 {{ detail.total_mission_count }} 人参加</div>
            </div>
          </div>
          <div class="act-btn">
            <div @click="openActInfo(10,{'event':2,'height':0,'action':4,'create_time':new Date().getTime()})">去抽签</div>
          </div>
        </div>
        <div class="info-title">
          <div class="info-title-name">藏品介绍</div>
        </div>
      </div>
      <div class="goods-info">
        <div class="info-content" :style="'background-image: url('+ detail.background_img+')'">
          <div v-html="detail.detail"></div>
        </div>
      </div>
      <div style="height: 68px"></div>
    </div>
    <div class="footer">
      <div class="footer-info">
        <div class="footer-tips">
          <div class="footer-tips-title">限量</div>
          <div class="footer-tips-info">{{ nft_info.first_number }}份</div>
        </div>
        <div class="footer-prize" @click="clean()"><span>￥</span>{{ nft_info.price }}</div>
      </div>
      <div class="footer-btn">
        <div class="footer-btn-name footer-btn-end" v-show="status == 0">等待开始</div>
        <div class="footer-btn-name" @click="subscribe()" v-show="status == 1">立即预约</div>
        <div class="footer-btn-name" @click="toShare()" v-show="status == 1.5">已预约,邀好友</div>
        <div class="footer-btn-name footer-btn-end" v-show="status == 2">预约结束</div>
        <div class="footer-btn-name" @click="goodLuck()" v-show="status == 3">立即抽奖</div>
        <div class="footer-btn-name footer-btn-end" v-show="status == 4">等待发售</div>
        <div class="footer-btn-name" @click="toBuy()" v-show="status == 5">优先购买</div>
        <div class="footer-btn-name" @click="toBuy()" v-show="status == 6">立即购买</div>
        <div class="footer-btn-name footer-btn-end" v-show="status == 7">已售罄</div>
        <div class="footer-btn-name footer-btn-end" v-show="status == 8">已结束</div>
        <div class="footer-btn-num">已预约人数：{{ detail.total_subscribed_count }}</div>
      </div>
    </div>
    <div class="eject" v-if="showBg != 0">
      <div class="eject-center" v-if="showBg < 10">
        <div v-show="showBg == 1">
          <div class="eject-icon">
            <img :src="require('../assets/img/Activity/true.png')" style="width: 100%;height: 100%">
          </div>
          <div class="eject-title">预约成功</div>
          <div class="eject-info">你已获得{{ prop.first_subscribe_reward }}个{{ prop.prop_name ? prop.prop_name : '元石' }}
          </div>
          <div class="eject-btn" @click="toShare()">邀好友获得更多{{ prop.prop_name ? prop.prop_name : '元石' }}
          </div>
          <div class="eject-tips">
            <div>{{
                prop.prop_name ? prop.prop_name : '元石'
              }}可用于抽签活动内抽取{{ nft_info.nft_name ? nft_info.nft_name : '优先购资格' }}
            </div>
            <div>{{ prop.prop_name ? prop.prop_name : '元石' }}越多抽签中签概率越大</div>
          </div>
        </div>
        <div v-show="showBg == 2 ">
          <div class="eject-title">您尚未实名认证无法抽签</div>
          <div class="eject-info">请先完成实名认证</div>
          <div class="eject-btn" @click="down()">前往实名</div>
          <div class="eject-tips">
            <div>根据国家相关法律要求</div>
            <div>未完成实名认证不可获得藏品</div>
          </div>
        </div>
        <div v-show="showBg == 3">
          <div class="eject-info">很遗憾</div>
          <div class="eject-title">你这次没有抽中</div>
          <div class="eject-btn" @click="goodLuck()">再抽一次</div>
          <div class="eject-tips">
          </div>
        </div>
        <div v-show="showBg == 4">
          <div class="eject-title">您尚未预约无法参与抽签</div>
          <div class="eject-info">请先预约参与活动</div>
          <div class="eject-btn" @click="subscribe()">立即预约</div>
          <div class="eject-tips">
          </div>
        </div>
        <div v-show="showBg == 5 ">
          <div class="eject-title">您尚未实名认证</div>
          <div class="eject-info">请先完成实名认证</div>
          <div class="eject-btn" @click="down()">前往APP实名</div>
          <div class="eject-tips">
            <div>根据国家相关法律要求</div>
            <div>未完成实名认证不可获得藏品</div>
          </div>
        </div>
        <div class="eject-close" @click="showBg = 0">
          <img :src="require('../assets/img/Activity/close2.png')" style="width: 12px;height: 12px;">
        </div>
      </div>
    </div>
    <div class="eject" v-if="showBottom != 0">
      <div class="eject-bottom">
        <div v-show="showBottom == 10">
          <div>
            <div class="eject-bottom-close"
                 @click="openActInfo(0,{'event':2,'height':0,'action':18,'create_time':new Date().getTime()})">
              <img :src="require('../assets/img/Activity/close.png')" style="width: 100%;height: 100%">
            </div>
            <div class="eject-main-title">
              参与抽签得优先购资格
              <div class="eject-main-rule"
                   @click="openActInfo(12,{'event':2,'height':0,'action':7,'create_time':new Date().getTime()})">规则
              </div>
            </div>
            <div class="eject-main-time">{{ luckendtime }}</div>
            <div class="eject-main-info">
              <div class="eject-main-prop"
                   @click="addTouch({'event':2,'height':0,'action':8,'create_time':new Date().getTime()})">
                <div class="prop-icon">
                  <img :src="prop.prop_logo">
                </div>
                <div class="prop-name">
                  {{ prop.prop_name ? prop.prop_name : '元石' }}：
                </div>
                <div class="prop-num">
                  {{ userprop.num }}
                </div>
              </div>
              <div class="eject-main-goods">
                <div class="eject-main-icon">
                  <img :src="nft_info.avatar">
                </div>
                <div>
                  <div class="eject-main-goods-tips">有机会获得</div>
                  <div class="eject-main-goods-title">本次数字藏品优先购资格</div>
                </div>
              </div>
              <div class="eject-main-btn">
                <div class="eject-main-null" @click="myPropLog()">
                  <div>我的奖品</div>
                </div>
                <div v-show="!loading" class="eject-main-full" :style="luckstatus != 2?'background:#cccccc':''"
                     @click="goodLuck()">
                  <div class="eject-main-btn-tips">立即抽奖（剩余{{ canClick }}次）</div>
                  <div class="eject-main-btn-num">
                    消耗{{ prop.sacrifice_del }}{{ prop.prop_name ? prop.prop_name : '元石' }}可抽奖一次
                  </div>
                </div>
                <div v-show="loading" class="eject-main-full eject-main-loading">
                  <img style="width: 30px;height: 30px;display: block"
                       :src="require('../assets/img/Activity/loading.gif')">
                  <div>抽奖中</div>
                </div>
              </div>
            </div>
            <div class="eject-main-info-title">
              <div class="info-title-name">任务中心</div>
              <div class="info-title-open" @click="myInviteLog(0)">邀请记录</div>
              <img :src="require('../assets/img/Activity/right.png')" style="width: 18px;height: 18px;display: block"/>
            </div>
            <div class="eject-main-task">
              <div class="eject-main-task-list">
                <div class="task-icon">
                  <img :src="require('../assets/img/Activity/tips.png')">
                </div>
                <div>
                  <div class="task-name">实名认证</div>
                  <div class="task-tips">参与抢购用户需完成实名认证 <br>
                    {{ prop.cert_reward ? prop.prop_name + '+' + prop.cert_reward : '' }}
                  </div>
                </div>
                <div class="task-btn" @click="showBg = 5" v-if="!my_status.certification">去完成</div>
                <div class="task-btn-ok" v-else>已完成</div>
              </div>
              <div class="eject-main-task-list">
                <div class="task-icon">
                  <img :src="require('../assets/img/Activity/tips.png')">
                </div>
                <div>
                  <div class="task-name">加入社群发送口令</div>
                  <div class="task-tips">扫码加入社群发送对应口令 <br>
                    {{ prop.prop_name ? prop.prop_name : '元石' }}+{{
                      prop.join_group_reward ? prop.join_group_reward : 1
                    }}
                  </div>
                </div>
                <div class="task-btn" v-if="!my_status.join_group" @click="openShowGroup()">去完成</div>
                <div class="task-btn-ok" v-else>已完成</div>
              </div>
              <div class="eject-main-task-list">
                <div class="task-icon">
                  <img :src="require('../assets/img/Activity/tips.png')">
                </div>
                <div>
                  <div class="task-name">加入公众号发送口令</div>
                  <div class="task-tips">扫码加入公众号发送对应口令 <br>
                    {{
                      prop.prop_name ? prop.prop_name : '元石'
                    }}+{{ prop.join_official_account_reward ? prop.join_official_account_reward : 1 }}
                  </div>
                </div>
                <div class="task-btn" v-if="!my_status.join_official_account" @click="openShowOfficial()">去完成</div>
                <div class="task-btn-ok" v-else>已完成</div>
              </div>
              <div class="eject-main-task-list">
                <div class="task-icon">
                  <img :src="require('../assets/img/Activity/tips.png')">
                </div>
                <div>
                  <div class="task-name">邀请用户预约藏品抢购</div>
                  <div class="task-tips">每成功邀请1名用户完成预约 <br>
                    {{ prop.prop_name ? prop.prop_name : '元石' }}+{{ prop.invite_reward ? prop.invite_reward : 1 }}
                  </div>
                </div>
                <div class="task-btn" @click="toShare()">去邀请</div>
              </div>
            </div>
            <div style="height: 20px"></div>
          </div>
        </div>
        <div v-show="showBottom == 11">
          <div>
            <div class="eject-bottom-title">
              <div @click="showBottom = 10">
                <img :src="require('../assets/img/Activity/left2.png')"
                     style="width: 15px;height: 15px;display: block"></div>
              <div>我的奖品</div>
              <div></div>
            </div>
            <div class="eject-goods" v-if="myPropList.length > 0">
              <div class="eject-list" v-for="(i,index) in myPropList" :key="index">
                <div class="eject-list-icon">
                  <img :src="i.avatar">
                </div>
                <div class="eject-list-info">
                  <div class="eject-list-title">{{ i.name }}</div>
                  <div class="eject-list-time">{{ i.time }}</div>
                </div>
                <div class="eject-list-num">*1</div>
              </div>
              <div class="eject-list-null">没有更多了</div>
            </div>
            <div class="eject-nogoods" v-else>
              <div class="eject-nogoods-img">
                <img :src="require('../assets/img/Activity/null-goods.png')" style="width: 100%; height: 100%">
              </div>
              <div class="eject-nogoods-title">您还未抽中藏品，再试试看吧~</div>
            </div>
          </div>
        </div>
        <div v-show="showBottom == 12">
          <div class="eject-bottom-title">
            <div @click="openActInfo(10,{'event':2,'height':0,'action':19,'create_time':new Date().getTime()})">
              <img :src="require('../assets/img/Activity/left2.png')"
                   style="width: 15px;height: 15px;display: block"></div>
            <div>抽签规则</div>
            <div></div>
          </div>
          <div class="eject-rule">
            <div>
              <img :src="detail.sacrifice_rule" style="width: 100%">
            </div>
          </div>
        </div>
        <div v-show="showBottom == 13">
          <div>
            <div class="eject-bottom-title">
              <div @click="showBottom = 10">
                <img :src="require('../assets/img/Activity/left2.png')"
                     style="width: 15px;height: 15px;display: block"></div>
              <div>邀请记录</div>
              <div></div>
            </div>
            <div class="invite-title">
              <div :style="cert == 0 ?'color:#00D0C1':''" @click="myInviteLog(0)">已实名</div>
              <div :style="cert == 1 ?'color:#00D0C1':''" @click="myInviteLog(1)">未实名</div>
            </div>
            <div class="eject-goods">
              <div class="invite-list" v-for="(i,index) in myInviteList" :key="index">
                <div class="invite-info">
                  <div class="invite-user">
                    <div class="invite-status" :style="i.subscribe || 'background: #cccccc'">
                      {{ i.subscribe ? '已预约' : '未预约' }}
                    </div>
                    <div class="invite-name">{{ i.username }}</div>
                  </div>
                  <div class="invite-time">{{ i.invite_time }}</div>
                </div>
                <div class="invite-phone">{{ i.mobile }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="luck" v-if="showWin">
      <div class="luck-info">
        <div class="luck-title">恭喜你，你已抽中</div>
        <div class="luck-name">{{ nft_info.nft_name }}</div>
        <div class="luck-img">
          <img :src="nft_info.avatar">
        </div>
        <div class="luck-btn" @click="showWin = false">立即收下</div>
      </div>
    </div>
    <div class="eject-bg" @touchmove.prevent @click="showBg = 0" v-if="showBg != 0"></div>
    <div class="show-bg" @touchmove.prevent v-if="showWin || showBottom != 0"></div>
    <div class="show-open-gif" @touchmove.prevent v-show="openGif">
      <div class="show-open-info">
        <img :src="sacrifice_success_img" style="width: 200px">
      </div>
    </div>
    <she-group-dlg :isShow="isShowGroup" @close="isShowGroup = false"></she-group-dlg>
    <login :show="isShowLogin" @close="LoginClose()" @success="loginSuccess"></login>
    <ThirtyWx :isShow="isShowGroup" :qrcode="qrcode" :actCode="actCode" @close="isShowGroup = false"></ThirtyWx>
    <ThirtyWx2 :isShow="isShowOfficial" :qrcode="qrcode2" :actCode="actCode"
               @close="isShowOfficial = false"></ThirtyWx2>
  </div>
</template>

<script>
import Login from '../components/Login'
import SignIn from '../components/SignIn'
import SheGroupDlg from '../components/SheGroupDlg'
import { mapMutations, mapState } from 'vuex'
import { userApi, signInApi, configApi } from '../api/user'
import { getWxConfig } from '../../tool'
import {
  isGetActivity,
  subscribe,
  goodLuck,
  subscribeLog,
  myPropLog,
  myInviteLog,
  getGlobal,
  userCreate
} from '../api/thirty'
import ThirtyWx from '../components/ThirtyWx.vue'
import ThirtyWx2 from '../components/ThirtyWx2.vue'
import { Toast } from 'vant'

let vm = ''

export default {
  name: 'Home',
  components: {
    Login,
    SignIn,
    SheGroupDlg,
    ThirtyWx,
    ThirtyWx2,
  },
  data () {
    return {
      act_id: '',
      actendtime: '',//活动倒计时时间
      luckendtime: '',//抽签倒计时时间
      openInfo: true,
      invitedNum: 0,
      infoNum: 0,
      signNum: 0,
      loading: false,
      seedInvitedNum: 0,
      isShowLogin: false,
      showWin: false,
      isShowGroup: false,
      isShowOfficial: false,
      openGif: false,
      showBg: 0, //  背景层显示 1预约成功 2未实名 3未抽中 4未预约
      showBottom: 0, //  底部背景层显示 1预约成功 2未实名 3未抽中 4未预约
      status: 0,  // 0未开始 1预约中 2预约结束 3抽签开始 4等待发售 5优先购开始 6购买开始 7已售罄 8已结束
      luckstatus: 0,//抽签状态 0没有活动 1未开始 2已开始 3已结束
      qrcode: '',// 社群二维码
      qrcode2: '',// 公众号二维码
      actCode: '',//活动口令
      signData: {},
      detail: {},
      nft_info: {},
      my_status: {},
      canClick: 0,
      cert: 0,
      sacrifice_success_img: '',//抽奖的动画
      myPropList: [],// 用户已获得道具列表
      subscribeList: [],// 参与用户列表
      myInviteList: [],// 邀请记录
      userprop: {},// 用户道具信息
      prop: {},
      introduction: '',
      showIntroduction: '',
      isShowGetMedal: false,
      timer: 0,
      timer1: 0,
      timerC: 0,
      userlog: [],
      startHeight: 0,
      endHeight: 0,
      invited_code: '',//上级邀请码
      toappx: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      isGetActivity: (state) => state.token
    }),
  },
  async created () {
    vm = this
    this.act_id = this.getUrlKey('id')
    localStorage.setItem('act_id', this.act_id)
    this.invited_code = this.getUrlKey('code'),
      localStorage.setItem('invited_code', this.invited_code)
    await this.getConfig()
    await this.getUser()
    await this.GetActivity()
    await this.subscribeLog()
    this.toappx = this.openInWebview()
    this.showGetMedal()
    if (window.localStorage.token == '') {
      this.isShowLogin = true
    }
    getWxConfig('轻松小镇', '个人中心')
  },
  mounted () {
    window.addEventListener('scroll', this.scrollBottom) //监听页面是否滚动到底部
    window.addEventListener('scroll', this.scrollStart)
    let _this = this
    _this.timerC = setInterval(function () {
      _this.subUserCreate()
    }, 10000)
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState == 'hidden') {
        //切离该页面时执行
        _this.userlog.push({
          'event': 4,
          'height': 0,
          'action': 23,
          'create_time': new Date().getTime()
        })
        //clearInterval(_this.timerC)
        localStorage.setItem('userlog', JSON.stringify(_this.userlog))
      } else if (document.visibilityState == 'visible') {
        //切换到该页面时执行
        if (JSON.parse(localStorage.getItem('userlog'))) {
          _this.userlog = JSON.parse(localStorage.getItem('userlog'))
        }
        _this.userlog.push({
          'event': 1,
          'height': 0,
          'action': 1,
          'create_time': new Date().getTime()
        })
        localStorage.removeItem('userlog')
      }
    })
  },
  methods: {
    ...mapMutations({
      vxSetUser: 'setUser',
      vxSetSignIn: 'setSignIn',
    }),
    getTouchStart (e) {
      //滑动事件开始
      this.startHeight = this.endHeight
      window.addEventListener('scroll', this.scrollStart)
    },
    clean () {
      localStorage.clear()
    },
    addTouch (data) {
      this.userlog.push(data)
    },
    getTouchEnd (e) {
      //滑动事件结束
      console.log('离开', this.startHeight, this.endHeight)
      if (this.startHeight != this.endHeight) {
        let h = this.startHeight - this.endHeight
        let event = 4
        let action = 20
        if (h > 0) {
          //上划
          event = 3
        }
        if (this.endHeight > 400) {
          action = 20
        } else if (this.endHeight > 800) {
          action = 21
        } else if (this.endHeight < 400) {
          action = 26
        }
        this.userlog.push({
          'event': event,
          'height': h,
          'action': action,
          'create_time': new Date().getTime()
        })
      }
    },
    scrollStart (e) {
      let top = e.srcElement.scrollingElement.scrollTop
      this.endHeight = top
    },
    scrollBottom () {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      if (scrollTop + clientHeight >= scrollHeight) {
        let add = true
        this.userlog.forEach(function (e) {
          if (new Date().getTime() - e.create_time < 500 && e.action == 22) {
            add = false
          }
        })
        if (add) {
          this.userlog.push({
            'event': 4,
            'height': 0,
            'action': 22,
            'create_time': new Date().getTime()
          })
          console.log('记录到底啦')
        }
      }
    },
    //获取头图高度
    getHeaderHeight () {
      let chart = document.getElementById('bannerHeight')
      this.bannerHeight = chart.getBoundingClientRect().top
    },
    //提交行为记录
    subUserCreate () {
      if (this.userlog.length == 0) {
        return
      }
      try {
        const res = userCreate({
          logs: this.userlog,
          act_id: this.act_id
        })
        this.userlog = []
      } catch (e) {
        this.$toast(e.data)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },

    down () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 14,
        'create_time': new Date().getTime()
      })
      if (this.toappx) {
        window.location = 'relax-town:///pages/user/realName'
      } else {
        window.location.href = 'http://app.relaverse.cn/m/download.html'
      }
      let that = this
      that.opening = true
      that.timer = setTimeout(function () {
        //  未安装的情况
        that.opening = false
        //  跳转app store
        let r = confirm('未安装APP? 是否去App store查看')
        if (r) {
          window.location = 'https://appfile.relaverse.cn/m/download.html'
        }
      }, 5000)
    },
    toBuy () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 13,
        'create_time': new Date().getTime()
      })
      window.location.href = this.detail.buy_url
    },
    toUrl (url) {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 23,
        'create_time': new Date().getTime()
      })
      window.location.href = url
    },
    toRule () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 7,
        'create_time': new Date().getTime()
      })
      window.localStorage.rule = this.detail.rule
      window.location.href = '/#/ActivityRule'
    },
    openActInfo (id, data) {
      this.userlog.push(data)
      if (!window.localStorage.token) {
        this.isShowLogin = true
      } else if (!this.my_status.subscribe) {
        //未预约
        this.showBg = 4
      } else {
        this.showBottom = id
      }
    },
    goBack () {
      window.history.back()
    },
    loginSuccess () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 24,
        'create_time': new Date().getTime()
      })
      this.isShowLogin = false
      this.showGetMedal()
      this.GetActivity()
    },
    LoginClose () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 25,
        'create_time': new Date().getTime()
      })
      this.isShowLogin = false
    },
    showGetMedal () {
      if (this.user.medal && !localStorage.getItem('hasMedal')) {
        this.isShowGetMedal = true
      }
    },
    toShare () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 15,
        'create_time': new Date().getTime()
      })
      window.location.href = `/#/ThirtyActShare?img= ${this.detail.share_img}&act_id=${this.act_id}&code=${this.user.code}&invite=${this.detail.invite_desc}`
    },
    openShowGroup () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 16,
        'create_time': new Date().getTime()
      })
      // this.showBg = 0
      this.isShowGroup = true
    },
    openShowOfficial () {
      // this.showBg = 0
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 17,
        'create_time': new Date().getTime()
      })
      this.isShowOfficial = true
    },
    getUrlKey (name) {
      let url = window.location.href
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [, ''])[1].replace(/\+/g, '%20')) || ''
    },
    async getConfig () {
      try {
        const res = await getGlobal({
          act_id: this.act_id
        })
        this.qrcode = res.data.group_img
        this.qrcode2 = res.data.official_account_img
      } catch (e) {
        // console.log(e)
      }
    },
    async getUser () {
      try {
        const res = await userApi({})
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        })
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 获取活动信息
    async GetActivity () {
      clearTimeout(this.timer)
      try {
        const res = await isGetActivity({
          act_id: this.act_id
        })
        this.detail = res.data
        if (this.detail.detail) {
          this.detail.detail = this.detail.detail.replace(/\<img/g, '<img style=\'width: 100%;\'')
        }
        if (this.detail.rule) {
          this.detail.rule = this.detail.rule.replace(/\<img/g, '<img style=\'width: 100%;\'')
        }
        if (res.data.sacrifice_info) {
          this.sacrifice_success_img = res.data.sacrifice_info.sacrifice_success_img
        }
        this.actCode = res.data.command + ' ' + this.user.code
        this.nft_info = res.data.nft_info
        this.introduction = res.data.introduction
        this.my_status = res.data.my_status
        if (res.data.prop[0]) {
          this.prop = res.data.prop[0]
        } else {
          this.prop = { show: true }
        }
        // 用户道具信息
        if (res.data.my_status.prop[0]) {
          this.userprop = res.data.my_status.prop[0]
        } else {
          this.userprop = { show: true }
        }
        let s = this.prop.sacrifice_del
        if (s != 0 && this.userprop.num) {
          this.canClick = Math.floor(this.userprop.num / s)
        }
        this.getActTime()
        this.getLuckTime()
        this.openInfo = true
        this.openIntroduction()
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 获取参与者列表
    async subscribeLog () {
      try {
        const res = await subscribeLog({
          act_id: this.act_id,
        })
        this.subscribeList = res.data.data
      } catch (e) {
        this.$toast(e.data)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 获取邀请记录
    async myInviteLog (cert) {
      let _this = this
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 5,
        'create_time': new Date().getTime()
      })
      this.cert = cert
      this.myInviteList = []
      this.$toast.loading({
        message: '',
        forbidClick: true,
      })
      try {
        const res = await myInviteLog({
          act_id: this.act_id,
          cert: this.cert
        })
        this.myInviteList = res.data.data
        this.showBottom = 13
        setTimeout(function () {
          _this.$toast.clear()
        }, 500)
      } catch (e) {
        this.$toast(e.data)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 我的奖品
    async myPropLog () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 11,
        'create_time': new Date().getTime()
      })
      try {
        const res = await myPropLog({
          act_id: this.act_id,
          prop_id: this.prop.prop_id
        })
        this.showBottom = 11
        this.myPropList = res.data.data
      } catch (e) {
        this.$toast(e.data)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 预约
    async subscribe () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 4,
        'create_time': new Date().getTime()
      })
      try {
        const res = await subscribe({
          act_id: this.act_id,
          from_uid: localStorage.getItem('invited_id'),
        })
        this.showBg = 1
        this.GetActivity()
      } catch (e) {
        this.$toast(e.data)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 抽奖
    async goodLuck () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 10,
        'create_time': new Date().getTime()
      })
      let _this = this
      _this.showBg = 0
      if (this.luckstatus != 2) {
        this.$toast('不在抽奖时间内!')
        return
      }
      // 未预约弹窗
      if (!this.my_status.subscribe) {
        this.showBg = 4
        return
      }
      this.showBg = 0
      this.loading = true
      try {
        const res = await goodLuck({
          act_id: this.act_id,
        })
        if (this.sacrifice_success_img) {
          this.openGif = true
        }
        _this.loading = false
        setTimeout(function () {
          _this.openGif = false
          if (res.data.result == 1) {
            _this.showWin = true
          } else {
            _this.showBg = 3
          }
          _this.GetActivity()
        }, 2500)
      } catch (e) {
        _this.openGif = false
        _this.loading = false
        this.$toast(e.data)
        if (e.code === 201) {
          this.isShowLogin = true
        }
      }
    },
    // 遍历时间 , 倒计时
    /*getActTime () {
      const _this = this
      let time = 0
      let now_time = new Date().getTime() / 1000
      if (now_time > this.detail.activity_end_time) {
        this.actendtime = '活动已结束'
        this.status = 8
        return
      }
      if (now_time > this.detail.buy_end_time) {
        this.actendtime = '已售罄'
        this.status = 7
        return
      }

      // 等待抽签 是否有抽签活动 且预约时间<预约开始时间
      if (this.detail.sacrifice == 1 && now_time < this.detail.sacrifice_info.sacrifice_start_time) {
        time = this.detail.sacrifice_info.sacrifice_start_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离抽签开始' + endtime
        this.status = 2
        // console.log(22222)
      }

      // 预约开始 判断当前时间是否在预约时间内
      if (now_time < this.detail.subscribe_end_time && now_time > this.detail.subscribe_start_time) {
        time = this.detail.subscribe_end_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离预约结束' + endtime
        if (this.my_status.subscribe) {
          this.status = 1.5
        } else {
          this.status = 1
        }
      }

      // 抽签开始 是否有抽签活动 且抽签时间时间<抽签结束时间
      if (this.detail.sacrifice == 1 && now_time < this.detail.sacrifice_info.sacrifice_end_time && now_time > this.detail.sacrifice_info.sacrifice_start_time) {
        time = this.detail.sacrifice_info.sacrifice_end_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离抽签结束' + endtime
        this.status = 3
        if (!this.my_status.subscribe) {
          this.status = 2
        }
      }

      // 抽签结束,等待发售 是否有抽签活动 且抽签时间时间<抽签结束时间
      if (now_time > this.detail.sacrifice_info.sacrifice_end_time && now_time < this.detail.buy_start_time) {
        // console.log(4444)
        time = this.detail.buy_start_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离正式发售开始' + endtime
        this.status = 4
        if (!this.my_status.subscribe) {
          this.status = 2
        }
      }

      // 是否可以优先购
      if (this.detail.advance_buy == 1) {
        //  首先有优先购活动
        if (now_time > this.detail.advance_buy_info.advance_buy_start_time && now_time < this.detail.advance_buy_info.advance_buy_end_time && this.my_status.advance_buy) {
          // 并且有优先购资格且在优先购时间段内
          this.advance_buy = true
          this.status = 5
        } else {
          this.advance_buy = false
        }
        if (!this.my_status.subscribe) {
          this.status = 2
        }
      }

      // 抢购开始 且当前时间在 抢购开始时间和抢购结束时间内
      if (this.detail.sacrifice == 1 && now_time < this.detail.buy_end_time && now_time > this.detail.buy_start_time) {
        // console.log(6666)
        time = this.detail.buy_end_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离正式发售结束' + endtime
        this.status = 6
        if (!this.my_status.subscribe) {
          this.status = 2
        }
      }

      if (now_time < this.detail.activity_start_time) {
        time = this.detail.activity_start_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离预约开始' + endtime
        this.status = 0
      }

      this.timer = setTimeout(function () {
        _this.getActTime()
      }, 1000)
    },*/
    getActTime () {
      const _this = this
      let time = 0
      let now_time = new Date().getTime() / 1000
      if (now_time > this.detail.activity_end_time) {
        this.actendtime = '活动已结束'
        this.status = 8
        return
      }
      if (now_time > this.detail.buy_end_time) {
        this.actendtime = '已售罄'
        this.status = 7
        return
      }

      // 抽签结束,等待发售 是否有抽签活动 且抽签时间时间<抽签结束时间
      if (now_time < this.detail.buy_start_time) {
        time = this.detail.buy_start_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离正式发售' + endtime
        this.status = 2
      }

      // 预约开始 判断当前时间是否在预约时间内
      if (now_time < this.detail.subscribe_end_time && now_time > this.detail.subscribe_start_time) {
        time = this.detail.subscribe_end_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离预约结束' + endtime
        if (this.my_status.subscribe) {
          this.status = 1.5
        } else {
          this.status = 1
        }
      }

      // 是否可以优先购
      if (this.detail.advance_buy == 1) {
        //  首先有优先购活动
        if (now_time > this.detail.advance_buy_info.advance_buy_start_time && now_time < this.detail.advance_buy_info.advance_buy_end_time && this.my_status.advance_buy) {
          // 并且有优先购资格且在优先购时间段内
          this.advance_buy = true
          this.status = 5
        } else {
          this.advance_buy = false
        }
      }

      // 抢购开始 且当前时间在 抢购开始时间和抢购结束时间内
      if (this.detail.sacrifice == 1 && now_time < this.detail.buy_end_time && now_time > this.detail.buy_start_time) {
        // console.log(6666)
        time = this.detail.buy_end_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离正式发售结束' + endtime
        this.status = 6
        if (!this.my_status.subscribe) {
          this.status = 2
        }
      }

      if (now_time < this.detail.activity_start_time) {
        time = this.detail.activity_start_time - now_time
        let endtime = _this.showtime(time)
        this.actendtime = '距离预约开始' + endtime
        this.status = 0
      }

      this.timer = setTimeout(function () {
        _this.getActTime()
      }, 1000)
    },
    getLuckTime () {
      const _this = this
      let time = 0
      let now_time = new Date().getTime() / 1000
      // 等待抽签 是否有抽签活动 且预约时间<预约开始时间
      if (this.detail.sacrifice == 1 && now_time < this.detail.sacrifice_info.sacrifice_start_time) {
        time = this.detail.sacrifice_info.sacrifice_start_time - now_time
        let endtime = _this.showtime(time)
        this.luckendtime = '距离抽签开始' + endtime
        this.luckstatus = 1
      }

      // 抽签开始 是否有抽签活动 且抽签时间时间<抽签结束时间
      if (this.detail.sacrifice == 1 && now_time < this.detail.sacrifice_info.sacrifice_end_time && now_time > this.detail.sacrifice_info.sacrifice_start_time) {
        time = this.detail.sacrifice_info.sacrifice_end_time - now_time
        let endtime = _this.showtime(time)
        this.luckendtime = '距离抽签结束' + endtime
        this.luckstatus = 2
      }

      // 抽签结束,等待发售 是否有抽签活动 且抽签时间时间<抽签结束时间
      if (now_time > this.detail.sacrifice_info.sacrifice_end_time) {
        this.luckendtime = '抽签已结束'
        this.luckstatus = 3
        return
      }
      // 没有抽签活动
      if (this.detail.sacrifice == 0) {
        this.luckendtime = '没有抽签活动'
        this.luckstatus = 0
        return
      }

      this.timer1 = setTimeout(function () {
        _this.getLuckTime()
      }, 1000)
    },
    //  是否显示或影藏藏品故事
    openIntroduction () {
      this.userlog.push({
        'event': 2,
        'height': 0,
        'action': 6,
        'create_time': new Date().getTime()
      })
      if (this.openInfo) {
        let i = this.introduction
        let str = i.slice(0, 40)
        this.showIntroduction = str + '...'
      } else {
        this.showIntroduction = this.introduction
      }
      this.openInfo = !this.openInfo
    },
    //  计算时间
    showtime (lefttime) {
      if (lefttime < 1) {
        this.GetActivity()
        return
      }
      lefttime = lefttime * 1000
      let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24))
      let lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24)
      let leftm = Math.floor(lefttime / (1000 * 60) % 60)
      let lefts = Math.floor(lefttime / 1000 % 60)
      return leftd + '天' + lefth + '时' + leftm + '分' + lefts + '秒'
    },

    async SignIn () {
      if (!this.isLogin) {
        this.isShowLogin = true
        return
      }
      try {
        const res = await signInApi({})
        this.signData = {
          day: res.data.day,
          box: res.data.box,
          stone: res.data.store,
        }
        this.vxSetSignIn(true)
        this.getUser()
        this.GetActivity()
      } catch (e) {
        this.$toast(e.data)
      }
    },
    openInWebview () {
      /* 返回true或false; */
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 微信浏览器判断
        return false
      } else if (ua.match(/QQ/i) == 'qq') {
        // QQ浏览器判断
        return false
      } else if (ua.match(/WeiBo/i) == 'weibo') {
        return false
      } else {
        if (ua.match(/Android/i) != null) {
          return ua.match(/browser/i) == null
        } else if (ua.match(/iPhone/i) != null) {
          return ua.match(/safari/i) == null
        } else {
          return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null
        }
      }
    },
    toPage (name) {
      if (this.isLogin) {
        this.$router.push({ name })
      } else {
        this.isShowLogin = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.show-open-gif {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 95;
  overflow-y: hidden;

  .show-open-info {
    width: 200px;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 13px;
    transform: translate(-50%, -50%);
    z-index: 90;
  }
}

.eject-bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 85;
  overflow-y: hidden;
}

.show-bg {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 60;
  overflow-y: hidden;
}

.eject {
  width: 100vw;

  .eject-center {
    width: 310px;
    background: #FFFFFF;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 13px;
    transform: translate(-50%, -50%);
    z-index: 90;

    .eject-icon {
      width: 46px;
      height: 46px;
      margin: 10px auto;
    }

    .eject-title {
      font-size: 21px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #171717;
      padding: 10px;
      text-align: center;
    }

    .eject-info {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #171717;
      text-align: center;
    }

    .eject-btn {
      width: 280px;
      height: 74px;
      background-image: url("../assets/img/Activity/btn.png");
      background-size: 100% 100%;
      margin: 20px auto 0 auto;
      text-align: center;
      color: #ffffff;
      line-height: 50px;
      font-size: 17px;
      font-weight: 600;
    }

    .eject-tips, .eject-tips div {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #171717;
      text-align: center;
      line-height: 19px;
    }

    .eject-close {
      position: absolute;
      width: 35px;
      height: 35px;
      background: rgba(76, 82, 90, 0.7);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -50px;
      left: 50%;
      right: 0;
      left: 0;
      margin: auto;
    }
  }

  .eject-bottom {
    padding: 13px;
    width: 100%;
    height: 605px;
    left: 0;
    right: 0;
    background: #F2F5FA;
    border-radius: 21px 21px 0 0;
    position: fixed;
    bottom: 0;
    z-index: 80;

    .eject-bottom-title {
      display: flex;
      height: 40px;
      align-items: center;
      font-family: PingFang SC;
      font-weight: 800;
      color: #171717;
      justify-content: space-between;

      div {
        font-size: 19px;
      }
    }

    .eject-rule {
      overflow: scroll;
      height: 530px;
      margin-top: 10px;
    }

    .invite-title {
      display: flex;
      height: 50px;
      justify-content: space-around;
      line-height: 50px;

      div {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .eject-goods {
      height: 530px;
      overflow: scroll;

      .eject-list {
        display: flex;
        padding: 10px;
        width: 348px;
        height: 82px;
        background: #FFFFFF;
        align-items: center;
        border-radius: 10px;
        margin-top: 10px;

        .eject-list-icon {
          width: 62px;
          height: 62px;
          border-radius: 5px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }

        .eject-list-info {
          margin-left: 10px;

          .eject-list-title {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 20px;
            color: #000000;
          }

          .eject-list-time {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #1F2734;
            opacity: 0.6;
          }
        }

        .eject-list-num {
          margin-left: auto;
          margin-right: 8px;

          div {
            line-height: 20px;
          }
        }
      }

      .eject-list-null {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1F2734;
        line-height: 50px;
        text-align: center;
        opacity: 0.6;
      }

      .invite-list {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 15px auto;
        height: 55px;
        border-bottom: 1px solid #dddddd;

        .invite-info {
          .invite-user {
            display: flex;

            .invite-status {
              width: 50px;
              height: 20px;
              background: linear-gradient(225deg, #0BB7FF 0%, #00D0C1 100%);
              border-radius: 16px 16px 16px 0px;
              font-size: 12px;
              color: #ffffff;
              text-align: center;
              line-height: 20px;
            }

            .invite-name {
              font-size: 14px;
              padding-left: 10px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #000000;
            }
          }

          .invite-time {
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #000000;
            opacity: 0.7;
          }
        }

        .invite-phone {
          font-size: 16px;
          font-family: DINPro;
          font-weight: 500;
          color: #000000;
          line-height: 50px;
        }
      }

      .invite-list:last-child {
        border: none
      }
    }

    .eject-nogoods {
      .eject-nogoods-img {
        width: 173px;
        height: 127px;
        margin: 20px auto;
      }

      .eject-nogoods-title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #171717;
        text-align: center;
        line-height: 31px;
      }
    }

    .eject-bottom-close {
      height: 24px;
      width: 24px;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .eject-main-title {
      font-size: 23px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #171717;
      line-height: 31px;
      display: flex;
      align-items: center;
      padding-top: 10px;

      .eject-main-rule {
        width: 35px;
        height: 22px;
        background: #171717;
        border-radius: 2px;
        margin-left: 10px;
        color: #ffffff;
        text-align: center;
        line-height: 22px;
      }
    }

    .eject-main-time {
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #171717;
      line-height: 21px;
      padding-top: 5px;
    }

    .eject-main-info {
      position: relative;
      height: 191px;
      background: #FFFFFF;
      border-radius: 10px;
      margin-top: 15px;
      padding: 21px 16px;

      .eject-main-prop {
        right: 20px;
        top: 10px;
        position: absolute;
        height: 23px;
        background: #F2F5FA;
        border-radius: 10px;
        display: flex;
        align-items: center;

        .prop-icon {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .prop-name {
          font-size: 10px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #171717;
        }

        .prop-num {
          font-size: 12px;
          font-family: DIN;
          font-weight: 500;
          color: #171717;
          margin-right: 5px;
        }
      }

      .eject-main-goods {
        display: flex;

        .eject-main-icon {
          width: 83px;
          height: 83px;
          border-radius: 3px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 3px;
          }
        }

        .eject-main-goods-tips {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #1F2734;
          line-height: 21px;
          opacity: 0.8;
          padding: 10px 14px;
        }

        .eject-main-goods-title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #171717;
          padding-left: 14px;
        }
      }

      .eject-main-btn {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;

        .eject-main-null {
          width: 106px;
          height: 46px;
          border: 2px solid #00E098;
          border-radius: 23px;

          div {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #171717;
            text-align: center;
            line-height: 42px;
            background: linear-gradient(49deg, #00B8AF 0%, #00BE7F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .eject-main-full {
          width: 196px;
          height: 46px;
          background: linear-gradient(235deg, #00D5D2 0%, #00E290 100%);
          border-radius: 23px;
          text-align: center;
          color: #FFFFFF;

          .eject-main-btn-tips {
            text-align: center;
            font-family: PingFang SC;
            font-weight: bold;
            padding-top: 4px;
          }

          .eject-main-btn-num {
            text-align: center;
            font-family: PingFang SC;
            padding-top: 2px;
            font-size: 10px;
          }
        }

        .eject-main-loading {
          width: 196px;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;

          div {
            font-size: 18px;
            padding-left: 10px;
          }
        }
      }
    }

    .eject-main-info-title {
      display: flex;
      font-weight: bold;
      color: #171717;
      align-items: center;
      height: 50px;

      .info-title-name {
        font-size: 17px;
      }

      .info-title-open {
        font-size: 14px;
        margin-left: auto;
      }
    }

    .eject-main-task {
      overflow: scroll;
      height: 260px;

      .eject-main-task-list {
        height: 68px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .task-icon {
          width: 42px;
          height: 42px;
          background: #EDF1F7;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 19px;
            height: 21px;
          }
        }

        .task-name {
          padding-left: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
        }

        .task-tips {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #1F2734;
          opacity: 0.6;
          padding-left: 10px;
        }

        .task-btn {
          width: 71px;
          height: 29px;
          background: #FFFFFF;
          border: 1px solid #171717;
          border-radius: 15px;
          text-align: center;
          line-height: 29px;
          margin-left: auto;
        }

        .task-btn-ok {
          width: 71px;
          height: 29px;
          color: #ffffff;
          background: #cccccc;
          border-radius: 15px;
          text-align: center;
          line-height: 29px;
          margin-left: auto;
        }
      }
    }
  }
}

.luck {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);

  .luck-info {
    width: 100vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    background: url("../assets/img/Activity/open-bg.png");
    background-size: 100% 100%;
    z-index: 100;

    .luck-title {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      opacity: 0.7;
    }

    .luck-name {
      width: 70%;
      margin: auto;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 800;
      text-align: center;
      color: #FFFFFF;
      padding-top: 10px;
    }

    .luck-img {
      width: 265px;
      height: 266px;
      background: #030303;
      border-radius: 5px;
      margin: 20px auto;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .luck-btn {
      width: 265px;
      height: 51px;
      background: linear-gradient(235deg, #00BCBA 0%, #00C980 100%);
      border-radius: 25px;
      margin: auto;
      text-align: center;
      line-height: 51px;
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #FFFFFF;
    }
  }
}

.home {
  background: #FDFDFE;
  font-family: PingFang SC;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .go-back {
    height: 40px;
    width: 40px;
    position: fixed;
    background: #000000;
    opacity: 0.5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10px;
    top: 10px;
    z-index: 10;
  }

  .rx180 {
    transform: rotateX(180deg);
  }

  .info {
    width: 100vw;

    .goods-info {
      padding: 0 20px;
      background-size: 100% 100%;

      .line {
        height: 1px;
        background: rgba(0, 2, 16, 0.1);
        margin: 20px 0;
      }

      .goods-title {
        font-size: 21px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #171717;
        padding-top: 16px;
      }

      .goods-tips-all {
        display: flex;
        flex-wrap: wrap;

        .goods-tips {
          display: flex;
          height: 26px;
          line-height: 26px;
          border-radius: 4px;
          margin-right: 10px;
          margin-top: 10px;

          .tips-title {
            font-size: 12px;
            background: #171717;
            color: #fff;
            padding: 0 8px;
            border-radius: 4px 0 0 4px;
          }

          .tips-info {
            font-size: 12px;
            background: #ECF1F3;
            padding: 0 8px;
            border-radius: 0 4px 4px 0;
          }
        }
      }

      .info-title {
        display: flex;
        font-weight: bold;
        color: #171717;
        align-items: center;

        .info-title-name {
          font-size: 20px;
        }

        .info-title-open {
          margin-right: 3px;
          margin-left: auto;
        }
      }

      .info-introduce {
        font-family: PingFang-SC-Medium;
        padding-top: 10px;
        font-size: 15px;
        font-weight: 500;
        color: #171717;
        line-height: 24px;
        opacity: 0.7;
      }

      .act {
        height: 119px;
        border-radius: 5px;
        position: relative;
        display: flex;
        padding: 34px 19px 0 19px;
        justify-content: space-between;
        margin-bottom: 20px;
        background-size: 100% 100%;

        .limit-time {
          width: 48px;
          height: 24px;
          background: linear-gradient(235deg, #00D5D2 0%, #00E290 100%);
          border-radius: 0 0 3px 3px;
          position: absolute;
          top: 0;
          left: 26px;
          text-align: center;
          line-height: 24px;
          color: #ffffff;
          font-size: 15px;
          font-weight: bold;
        }

        .act-info {
          height: 80px;

          .act-info-title {
            width: 182px;
            font-size: 19px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 38px;
            height: 38px;
          }

          .act-info-user {
            display: flex;

            .act-info-header {
              position: relative;
              width: 60px;

              div {
                width: 20px;
                height: 20px;
                position: absolute;
                border-radius: 50%;
              }

              .act-info-header-1 {
                left: 0;
                z-index: 10;
              }

              .act-info-header-2 {
                left: 18px;
                z-index: 9;
              }

              .act-info-header-3 {
                left: 36px;
              }
            }

            .act-info-num {
              width: 96px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              margin-left: 10px;
            }
          }
        }

        .act-btn {
          width: 114px;
          height: 42px;
          background: #FFFFFF;
          border-radius: 26px;
          font-family: PingFang SC;
          font-weight: bold;
          margin-top: 10px;
          color: #17A29C;
          line-height: 42px;

          div {
            font-size: 15px;
            font-weight: bold;
            text-align: center;
            background: linear-gradient(49deg, #00B8AF 0%, #00BE7F 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    .goods-img {
      width: 100%;
      position: relative;
      color: #fff;
      font-weight: bold;
      font-size: 14px;

      .goods-rule {
        position: absolute;
        right: 0;
        height: 40px;
        text-align: center;
        line-height: 40px;
        width: 60px;
        border-radius: 40px 0 0 40px;
        opacity: 0.8;
        background: linear-gradient(235deg, #00D5D2 0%, #00E290 100%);
        bottom: 20px;
      }

      .goods-lock {
        position: absolute;
        left: 0;
        display: flex;
        padding: 0 20px;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 0 40px 40px 0;
        text-align: center;
        line-height: 40px;
        background: rgba(100, 100, 100, 0.5);
        bottom: 20px;

        div {
          padding: 0 5px;
        }
      }
    }

    .info-content {
      width: 100%;
      background-size: 100% 100%;
      border-radius: 5px;
    }
  }

  .footer {
    width: 100vw;
    height: 68px;
    background: #FFFFFF;
    box-shadow: 0 0 41px 0 rgba(13, 58, 108, 0.09);
    position: fixed;
    bottom: 0;
    display: flex;
    z-index: 50;
    padding: 8px 20px;
    justify-content: space-between;

    .footer-info {
      padding-right: 20px;

      .footer-tips {
        display: flex;
        flex-wrap: nowrap;
        height: 18px;
        line-height: 18px;
        border-radius: 2px;

        .footer-tips-title {
          white-space: nowrap;
          font-size: 10px;
          background: #171717;
          color: #fff;
          padding: 0 4px;
          border-radius: 2px 0 0 2px;
        }

        .footer-tips-info {
          white-space: nowrap;
          font-size: 10px;
          background: #ECF1F3;
          padding: 0 4px;
          border-radius: 0 2px 2px 0;
        }
      }

      .footer-prize {
        font-size: 28px;
        font-family: DIN;
        font-weight: bold;
        color: #FF2424;

        span {
          font-size: 11px;
        }
      }
    }

    .footer-btn {
      height: 51px;
      position: relative;
      border-radius: 25px;
      color: #fff;
      width: 100%;

      .footer-btn-name {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 25px;
        background: linear-gradient(235deg, #00D5D2 0%, #00E290 100%);
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;
        padding: 4px;
      }

      .footer-btn-num {
        position: absolute;
        bottom: 6px;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        margin: auto;
        width: 100%;
        text-align: center;
      }
    }

    .footer-btn-end {
      background: #cccccc !important;
    }
  }
}

.yuan-img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}
</style>
