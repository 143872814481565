<template>
  <!--  -->
  <div class="fusestuff">
    <div class="block" @click="back()">
      <img
        :src="require('../../assets/img/back.png')"
        width="60%"
        height="50%"
      />
    </div>
    <van-list
      v-if="!loding && stuffList.length > 0"
      class="listbox"
      :finished="finished"
      offset="0"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div
        class="list"
        :class="[item.state ? 'gl' : '']"
        v-for="(item, i) in stuffList"
        :key="i"
        @click="selected(i, item)"
      >
        <div
          class="up_chain"
          :class="
            SelectedList.length >= 3 && !item.state && item.nft_hash
              ? 'Mantle'
              : ''
          "
        ></div>
        <div class="Mantle" v-if="!item.nft_hash">上链中</div>
        <img class="list_img" :src="item.photo" alt="" />
        <div class="list_name f-14 bold">{{ item.nft_name }}</div>
        <div class="list_number f-12">#{{ item.token_id }}</div>
        <div class="right_bottom" :class="[item.state ? 'right_bottom1' : '']">
          <img src="../../assets/img/thirty/du.png" alt="" />
        </div>
      </div>

      <div class="btnBox">
        <!-- 我选好了哦 -->
        <div
          class="okbtn f-13 bold"
          @click="confirm()"
          v-if="SelectedList.length == 3"
        >
          确认选择
        </div>
        <!-- 还没有 -->
        <div class="nobtn f-13 bold" v-else>确认选择</div>
      </div>
    </van-list>
    <div class="" v-if="!loding && stuffList.length > 0">
      <div class="noMore" v-if="!noMore">
        <van-loading size="20" class="m-r-5" /> {{ lodingtext }}
      </div>
      <div class="noMore" v-if="noMore">
        {{ lodingtext }}
      </div>
    </div>
    <div class="moreBox" v-if="!loding && stuffList.length <= 0">
      <img class="more" src="../../assets/img/thirty/nomore.png" alt="" />
      <div class="moretext">您还未获得{{ material }}<br />先去获得藏品吧～</div>
    </div>
    <van-loading v-if="loding" size="25" class="m-r-5 loding" />
    <!-- 您是否确定合成 -->
    <van-overlay
      :show="isShowsignOut"
      @click="isShowsignOut = false"
      z-index="100"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }"
    >
      <div class="wrapper">
        <div class="signOut">
          <div class="f-16 bold text-center m-t-30">您已填入藏品合成材料</div>
          <div class="f-16 bold text-center m-t-5">是否确认退出</div>
          <div class="tips">退出后已选择藏品将被清空</div>
          <div class="btn_box">
            <div class="no_box" @click="ConfirmExit()">确认取消</div>
            <div class="ok_box m-t-20" @click="isShowsignOut = false">
              我再想想
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { synthesisList } from "../../api/thirty.js";
import { mapMutations, mapState } from "vuex";

export default {
  name: "FuseStuff",
  computed: {},
  components: {},
  data() {
    return {
      isShowsignOut: false, //退出确认
      glFG: -1, //选中了那个
      stuffList: [], //列表数据
      SelectedList: [], // 选中的数组
      synthesisSilver: "", //需要多少个材料的接口参数
      finished: false, //上拉加载完毕
      flag: false,
      lodingtext: "加载更多",
      material: "青铜胸针",
      total: 0,
      page: 1,
      noMore: false,
      loding: true,
    };
  },
  computed: {
    ...mapState({
      Silverfuse: (state) => state.Silverfuse,
      Goldfuse: (state) => state.Goldfuse,
      Platinumfuse: (state) => state.Platinumfuse,
      Diamondfuse: (state) => state.Diamondfuse,
    }),
  },
  async mounted() {
    this.synthesisSilver = this.$route.params.name;
    console.log(this.synthesisSilver);
    await this.synthesisList();
    this.backfill();
    if (this.synthesisSilver == "synthesisSilver") {
      this.SelectedList = this.Silverfuse;
      this.material = "青铜胸针";
    } else if (this.synthesisSilver == "synthesisGold") {
      this.SelectedList = this.Goldfuse;
      this.material = "白银胸针";
    } else if (this.synthesisSilver == "synthesisPlatinum") {
      this.SelectedList = this.Platinumfuse;
      this.material = "黄金胸针";
    } else if (this.synthesisSilver == "synthesisDiamond") {
      this.SelectedList = this.Diamondfuse;
      this.material = "铂金胸针";
    }
  },
  destroyed() {},
  created() {},
  watch: {},
  methods: {
    ...mapMutations({
      setSilverfuse: "setSilverfuse",
      setGoldfuse: "setGoldfuse",
      setPlatinumfuse: "setPlatinumfuse",
      setDiamondfuse: "setDiamondfuse",
    }),
    // 选中3个之后删除1个后 数据回填
    backfill() {
      this.stuffList.forEach((item, i) => {
        // console.log(item);
        // item.state = false;
        if (this.synthesisSilver == "synthesisSilver") {
          this.Silverfuse.forEach((jtem, j) => {
            if (item.id == jtem.id) {
              this.stuffList[i].state = true;
            }
          });
        } else if (this.synthesisSilver == "synthesisGold") {
          this.Goldfuse.forEach((jtem, j) => {
            if (item.id == jtem.id) {
              this.stuffList[i].state = true;
            }
          });
        } else if (this.synthesisSilver == "synthesisPlatinum") {
          this.Platinumfuse.forEach((jtem, j) => {
            if (item.id == jtem.id) {
              this.stuffList[i].state = true;
            }
          });
        } else if (this.synthesisSilver == "synthesisDiamond") {
          this.Diamondfuse.forEach((jtem, j) => {
            if (item.id == jtem.id) {
              this.stuffList[i].state = true;
            }
          });
        }
      });
      this.$forceUpdate();
    },
    //选中那个
    selected(i, item) {
      if (this.stuffList[i].state == true) {
        this.stuffList[i].state = false;
        this.SelectedList.forEach((jtem, j) => {
          if (jtem.token_id == item.token_id) {
            this.SelectedList.splice(j, 1);
            if (this.synthesisSilver == "synthesisSilver") {
              this.setSilverfuse(this.SelectedList);
            } else if (this.synthesisSilver == "synthesisGold") {
              this.setGoldfuse(this.SelectedList);
            } else if (this.synthesisSilver == "synthesisPlatinum") {
              this.setPlatinumfuse(this.SelectedList);
            } else if (this.synthesisSilver == "synthesisDiamond") {
              this.setDiamondfuse(this.SelectedList);
            }
            // this.setfuse(this.SelectedList);
          }
        });
      } else if (this.stuffList[i].state == false) {
        if (this.SelectedList.length < 3 && item.nft_hash) {
          this.stuffList[i].state = true;
          this.SelectedList.push(item);
          if (this.synthesisSilver == "synthesisSilver") {
            this.setSilverfuse(this.SelectedList);
          } else if (this.synthesisSilver == "synthesisGold") {
            this.setGoldfuse(this.SelectedList);
          } else if (this.synthesisSilver == "synthesisPlatinum") {
            this.setPlatinumfuse(this.SelectedList);
          } else if (this.synthesisSilver == "synthesisDiamond") {
            this.setDiamondfuse(this.SelectedList);
          }
          // this.setfuse(this.SelectedList);
        }
      }
      // console.log(i);
    },
    confirm() {
      if (this.synthesisSilver == "synthesisSilver") {
        this.setSilverfuse(this.SelectedList);
      } else if (this.synthesisSilver == "synthesisGold") {
        this.setGoldfuse(this.SelectedList);
      } else if (this.synthesisSilver == "synthesisPlatinum") {
        this.setPlatinumfuse(this.SelectedList);
      } else if (this.synthesisSilver == "synthesisDiamond") {
        this.setDiamondfuse(this.SelectedList);
      }
      // 选好了返回合成首页
      if (window.history.length <= 1) {
        this.$router.push({ path: "/ThirtyFuse" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
    // 获取页面数据
    async synthesisList() {
      // synthesisSilver=>白银,synthesisGold=>黄金,synthesisPlatinum=>铂金,synthesisDiamond=>钻石
      this.flag = false;
      try {
        const res = await synthesisList({
          type: this.synthesisSilver,
          page: this.page,
        });
        this.stuffList = this.stuffList.concat(res.data.data.data);
        this.total = res.data.data.total;
        this.stuffList.forEach((item, i) => {
          item.state = false;
          if (this.synthesisSilver == "synthesisSilver") {
            this.Silverfuse.forEach((jtme, j) => {
              if (item.id == jtme.id) {
                item.state = true;
              }
            });
          } else if (this.synthesisSilver == "synthesisGold") {
            this.Goldfuse.forEach((jtme, j) => {
              if (item.id == jtme.id) {
                item.state = true;
              }
            });
          } else if (this.synthesisSilver == "synthesisPlatinum") {
            this.Platinumfuse.forEach((jtme, j) => {
              if (item.id == jtme.id) {
                item.state = true;
              }
            });
          } else if (this.synthesisSilver == "synthesisDiamond") {
            this.Diamondfuse.forEach((jtme, j) => {
              if (item.id == jtme.id) {
                item.state = true;
              }
            });
          }
        });
        if (this.stuffList.length >= res.data.data.total) {
          this.lodingtext = "没有更多";
          this.noMore = true;
        }
        this.flag = true;
        this.loding = false;
        console.log();
      } catch (e) {
        console.log(e);
      }
    },
    back() {
      console.log(this.SelectedList);
      if (this.SelectedList.length > 0) {
        this.isShowsignOut = true;
      } else {
        if (this.synthesisSilver == "synthesisSilver") {
          this.setSilverfuse([]);
        } else if (this.synthesisSilver == "synthesisGold") {
          this.setGoldfuse([]);
        } else if (this.synthesisSilver == "synthesisPlatinum") {
          this.setPlatinumfuse([]);
        } else if (this.synthesisSilver == "synthesisDiamond") {
          this.setDiamondfuse([]);
        }
        if (window.history.length <= 1) {
          this.$router.push({ path: "/ThirtyFuse" });
          return false;
        } else {
          this.$router.go(-1);
        }
      }
    },
    ConfirmExit() {
      if (this.synthesisSilver == "synthesisSilver") {
        this.setSilverfuse([]);
      } else if (this.synthesisSilver == "synthesisGold") {
        this.setGoldfuse([]);
      } else if (this.synthesisSilver == "synthesisPlatinum") {
        this.setPlatinumfuse([]);
      } else if (this.synthesisSilver == "synthesisDiamond") {
        this.setDiamondfuse([]);
      }
      if (window.history.length <= 1) {
        this.$router.push({ path: "/ThirtyFuse" });
        return false;
      } else {
        this.$router.go(-1);
      }
    },
    onLoad() {
      let _this = this;
      if (_this.stuffList && _this.flag) {
        _this.page++;
        if (_this.stuffList.length < _this.total) {
          _this.synthesisList();
          this.backfill();
        }
      }
    },
    toPage(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>
<style lang="scss">
.fusestuff {
  min-height: 100vh;
  background: #f4f4f4;
  padding-top: 10px;
  padding-bottom: 110px;
  position: relative;
  .block {
    left: 17px;
    top: 17px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 20px;
  }
  .listbox {
    padding: 15px 20px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .gl {
      background: url("../../assets/img/thirty/rightbg.png") no-repeat !important;
      background-size: 100% 100% !important;
      overflow: hidden !important;
    }
    .list {
      width: 160px;
      height: 210px;
      background: #fff;
      // border-image: linear-gradient(0deg, #00ae97, #00b0ad) 10 10;
      // background: linear-gradient(237deg, #0ae0b6, #19d3de);
      border-radius: 5px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .Mantle {
        height: 100%;
        width: 100%;
        display: flex;
        border-radius: 5px;
        border: 1px solid transparent;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba($color: #000000, $alpha: 0.7);
        position: absolute;
        z-index: 10;
        color: #fff;
      }
      .up_chain {
      }
      .list_img {
        width: 74px;
        height: 107px;
      }
      .list_name {
        color: #171717;
        margin: 20px 0 5px;
        text-align: center;
      }
      .list_number {
        text-align: center;

        color: #171717;
      }
      .right_bottom {
        width: 18px;
        height: 18px;
        background: #ffffff;
        border: 1px solid #171717;
        opacity: 0.5;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 11px;
          width: 11px;
        }
      }
      .right_bottom1 {
        width: 18px;
        height: 18px;
        border: 1px solid;
        border: 1px solid #25c9ff !important;
        background: linear-gradient(36deg, #00e899 0%, #25c9ff 100%);
        border-radius: 50%;
      }
    }

    .btnBox {
      width: 375px;
      height: 90px;
      background: rgba($color: #f4f4f4, $alpha: 0.9);
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 12;
      .okbtn {
        width: 335px;
        height: 49px;
        background: linear-gradient(36deg, #00e899 0%, #25c9ff 100%);
        border-radius: 24px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .nobtn {
        width: 335px;
        height: 49px;
        background: #d3d3d3;
        border-radius: 24px;
        color: rgba($color: #171717, $alpha: 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .noMore {
    margin: 0 auto;
    color: rgba($color: #000000, $alpha: 0.7);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .moreBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .more {
      width: 170px;
      height: 125px;
    }
    .moretext {
      text-align: center;
      line-height: 20px;
      margin-top: 15px;
      font-size: 12px;
      font-weight: 400;
      color: #171717;
    }
  }
  .loding {
    margin: 30px auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .signOut {
      width: 295px;
      min-height: 191px;
      background: #ffffff;
      border-radius: 5px;
      padding-bottom: 20px;
      .tips {
        color: rgba($color: #000, $alpha: 0.7);
        text-align: center;
        margin-top: 15px;
      }
      .btn_box {
        display: flex;
        align-items: center;
        justify-content: center;
        .no_box {
          width: 118px;
          height: 37px;
          background: #eeeff2;
          border-radius: 19px;
          font-size: 12px;
          font-weight: 500;
          color: #171717;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px 20px 0 0;
        }
        .ok_box {
          width: 118px;
          height: 37px;
          background: linear-gradient(36deg, #00e899 0%, #25c9ff 100%);
          border-radius: 19px;
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
