<template>
  <div class="page-box">
    <div class="head">
      <div>
        <van-icon name="arrow-left" class="white" size="18" @click="back()" />
      </div>
      <div class="head-title">
        <div class="title">邀请记录</div>
      </div>
    </div>
    <div class="concat">
      <div class="selectTop">
        <div
          class="select"
          :class="item.select ? 'selects' : ''"
          v-for="(item, index) in listType"
          @click="onSelect(item)"
          :key="index"
        >
          {{ item.name }}
          <div v-show="item.select" class="selectLine"></div>
        </div>
      </div>
      <div class="lines"></div>
      <div class="listBtm">
        <div class="listItem" v-for="(item, index) in list" :key="index">
          <div class="users">
            <div class="list_name">{{ item.username }}</div>
            <div class="list_time">{{ item.time | dateFormat("day") }}</div>
          </div>
          <div class="list_phone">
            {{ item.mobile }}
          </div>
        </div>
        <div class="loadmores" v-if="listType.length">
          <van-loading
            v-show="loadings.status == 'loading'"
            type="circular"
            size="20"
            color="#908f93"
            class="loding"
          />
          {{
            loadings.status == "loading"
              ? "加载中"
              : loadings.status == "nomore"
              ? "暂无邀请好友"
              : "轻轻上拉"
          }}
        </div>
      </div>
    </div>
    <van-overlay
      z-index="100"
      :show="loading"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.5)' }"
    >
      <van-loading
        type="circular"
        size="30"
        color="#42ce59"
        v-if="loading"
        class="loding"
        vertical
      >
        加载中...
      </van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { help_log, enter_log } from "../../api/solicitation";
export default {
  name: "collection",
  filters: {
    dateFormat(value, type) {
      if (!value) return "";
      let time = new Date(value * 1000);

      function timeAdd0(str) {
        if (str < 10) {
          switch (type) {
            case "min":
              str = str;
              break;
            case "day":
              str = "0" + str;
              break;
          }
        }
        return str;
      }
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      switch (type) {
        case "min":
          return `${timeAdd0(m)}`;
          break;
        case "day":
          return `${timeAdd0(y)}-${timeAdd0(m)}-${timeAdd0(d)} ${timeAdd0(
            h
          )}:${timeAdd0(mm)}`;
          break;
      }
    },
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#15121b;");
  },
  data() {
    return {
      loadings: {
        status: "loadmore",
        loadingText: "努力加载中",
        loadmoreText: "轻轻上拉",
        nomoreText: "实在没有了",
      },
      size: 15,
      page: 1,
      listType: [
        {
          url: "https://cdn3.relaverse.cn/uploads/images/0601/16540594950008af0.png",
          id: 1,
          select: true,
          name: "邀请已助力",
        },
        {
          url: "https://cdn3.relaverse.cn/uploads/images/0601/16540594950008af0.png",
          id: 2,
          select: false,
          name: "邀请未助力",
        },
      ],
      list: [],
      selects: {},
      loading: true,
    };
  },
  mounted() {
    this.onListData();
    this.handleScroll();
  },
  methods: {
    handleScroll() {
      let _this = this;
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight == scrollHeight) {
          _this.page++;
          _this.onListData();
        }
      };
    },
    //获取记录
    async onListData() {
      try {
        var res;
        if (this.listType[0].select) {
          res = await help_log({
            acctoken: this.isLogin,
            page: this.page,
          });
        } else if (this.listType[1].select) {
          res = await enter_log({
            acctoken: this.isLogin,
            page: this.page,
          });
        }
        this.massagerList = res.data.data;
        this.page == 1
          ? (this.list = this.massagerList.data)
          : (this.list = this.list.concat(this.massagerList.data));
        (this.massagerList.data.length == 0 && this.page != 1) ||
        this.list.length < this.size
          ? (this.loadings.status = "nomore")
          : (this.loadings.status = "loading");
        this.loading = false;
      } catch (e) {
        this.onError(e);
      }
    },
    onError(e) {
      console.log(e);
      this.$toast(e.data);
    },
    onSelect(item) {
      this.loadings.status = "loading";
      for (let i = 0; i < this.listType.length; i++) {
        this.listType[i].select = false;
      }
      this.selects = item;
      this.listType[item.id - 1].select = true;
      this.page = 1;
      this.list = [];
      this.onListData();
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="scss">
.page-box{
  background: #15121b;
  min-height: 100vh;
}
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .van-icon {
  z-index: 2;
}

.concat {
  padding-top: 80px;
}

.selectTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 64%;
  margin: auto;

  .select {
    color: #35323a;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .selects {
    color: #908f93 !important;
    font-size: 16px;

    .selectLine {
      width: 10px;
      height: 2px;
      margin: 5px auto 0;
      background: #908f93;
    }
  }
}

.lines {
  width: 100%;
  height: 3px;
  background: #000000;
}

.listBtm {
  padding: 20px 30px;

  .loadmores {
    color: #908f93;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    .loding {
      margin-right: 10px;
    }
  }

  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 20px;
    border-bottom: 1px solid #2e2d32;

    .users {
      .list_name {
        color: #a8a7aa;
        font-size: 13px;
        margin-bottom: 5px;
      }

      .list_time {
        color: #424047;
        font-size: 11px;
      }
    }

    .list_phone {
      font-size: 13px;
      color: #a8a7aa;
    }
  }
}

.head {
  background-size: 100% 100%;
  position: fixed;
  padding: 20px 10px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #15121b;
  width: 100%;
  z-index: 10;

  .head-title {
    color: #ffffff;
    text-align: center;
    font-size: 15px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
  }
}
</style>
