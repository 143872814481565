<template>
  <div class="page-box">
    <img class="imgBgk" src="../../assets/img/lszSe/lszSeIndex.png" alt="" />
    <div class="btns">
      <div class="btnsIns loadingIns" v-if="loadings">
        <div>
          <van-loading type="circular" size="30" color="#ffffff" v-if="loadings" class="loding" />
        </div>
      </div>
      <div v-else>
        <div class="btnsTime" v-if="!endActivity">
          {{ endTime }}
        </div>
        <div class="btnsIns" v-else-if="endActivity && configure.is_join && !announceActivity">   
          <div class="btnsT btnsInT">
            <div>
              {{ announceTime }}
            </div>
          </div>
        </div>
        <div class="btnsIns" v-else-if="endActivity && configure.is_join && announceActivity">
          <div class="btnsS">
              <div>
                活动已结束
              </div>
            </div>
            <div class="btnsT btnsIn" @click="gotoPage('lszSeRankingList')">
              <div>
                查看中奖榜单
              </div>
            </div>
        </div>
        <div class="btnsIns" v-else>
          <div class="btnsS">
              <div>
                活动结束
              </div>
            </div>
            <div class="btnsT btnsIn" @click="gotoPage('lszSeRankingList')">
              <div>
                查看中奖榜单
              </div>
            </div>
        </div>
        <div v-if="!endActivity">
          <div class="btnsIns" v-if="!configure.is_join">
            <div class="btnsT btnsInT" @click="gotoPage('lszSeCollection')">
              <div>
                参与征集
              </div>
            </div>
          </div>
          <div class="btnsIns" v-else>
            <div class="btnsS" @click="gotoPage('lszSeCollection')">
              <div>
                重新选择藏品
              </div>
            </div>
            <div class="btnsT btnsIn" @click="gotoPage('lszSeRankingList')">
              <div>
                查看参与排行
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <login :show="isShowLogin" @close="isShowLogin = false" @success="loginSuccess"></login>
    <TipsPopup ref="TipsPopup" :ranking="userRanking.ranking" @onSuccess="onSuccessIn()"></TipsPopup>
  </div>
</template>
<script>
import {
  userApi,
} from "../../api/user";
import {
  enter,
  configure,
  ranking,
  winning
} from "../../api/lszSolicitation";
import {
  mapMutations,
  mapState
} from "vuex";
import Login from "../../components/Login";
import Share from "./components/share";
import TipsPopup from "./components/TipsPopup";

const baseUrl = require("../../../site");

export default {
  name: "thirty",
  components: {
    Login,
    Share,
    TipsPopup
  },
  data() {
    return {
      isShowLogin: false, //判断是否登录
      configure: {}, //用户是否参与
      userRanking: {}, //用户当前排行榜
      timer: '', //活动结束定时器
      announceTimer: '', //公布结果定时器
      endTime: '',//活动结束倒计时
      endActivity: false,//活动是否结束
      loadings: true,
      announceTime: '',//公布倒计时
      announceActivity: false//是否公布结果
    };
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background-color:#05060a')
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      invited_code: (state) => state.invited_code,
      invited_ids: (state) => state.invited_ids
    }),
  },
  mounted() {
    // document.addEventListener("UniAppJSBridgeReady", () => {
    //   console.log("yyyyy");
    //   // this.getTouchStart();
    // });
    //没参与活动进入

    // this.$refs.TipsPopup.onSwitch(2, 'open')
    console.log(1122,this.invited_ids)
    this.onLogoin()
  },
  destroyed() {
    clearInterval(this.timer);
  },

  created() {
    // this.browserOpenApp();
    // window.location.href = "relax-town:///pages/user/realName";
    // this.getIndex();
    // getWxConfig(
    //   "可穿戴非遗文化藏品艾草胸针火热预约中",
    //   "点击链接参与活动有机会免费获得"
    // );
    // toApp("relax-town:///pages/user/realName");
  },
  watch: {},
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    async onLogoin() {
      this.loadings = true
      await this.onConfigure();
      if (this.isLogin) {
        if (!this.configure.is_enter) {
          await this.onParticipate();
        }
        await this.getUser();
        await this.onRanking();
      }
    },
    //用户当前排名
    async onRanking() {
      try {
        const res = await ranking({
          acctoken: this.isLogin
        });
        this.userRanking = res.data
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true;
        }
      }
    },
    //是否报名
    async onConfigure() {
      let i = 0;
      try {
        const res = await configure({
          acctoken: this.isLogin
        });
        this.timer = setInterval(() => {
          const time = this.countDown(Number(res.data.end_time), 1)
          if (!time) {
            clearInterval(this.timer)
            if (this.configure.is_join) {
              this.loadings = true
              this.onPublish();
            } else {
              this.endTime = '活动已结束'
              this.endActivity = true
              this.loadings = false
            }
            // announceTime
          } else {
            this.endTime = time
            if(!i){
              i += 1
              this.loadings = false
            }
          }
        }, 1000)
        this.configure = res.data
      } catch (e) {
        this.onError(e)
      }
    },
    //是否开始公布结果
    onPublish() {
      this.endActivity = true
      this.announceActivity = false
      this.announceTimer = setInterval(() => {
        const time = this.countDown(Number(1659960000), 2)
        if (!time) {
          clearInterval(this.announceTimer)
          // announceTime
          this.announceActivity = true
        } else {
          this.announceTime = time
        }
      }, 1000)
      setTimeout(()=>{
        this.loadings = false
      },1500)
    },
    //用户是否中奖
    async onPublishData() {
      try {
        const res = await winning({
          acctoken: this.isLogin
        });
          if (res.data.code == 200) {
            this.$refs.TipsPopup.onSwitch(2, 'open')
          } else if (res.data.code == 222 && !res.data.receive) {
            this.$refs.TipsPopup.onSwitch(3, 'open')
          } else {
            this.$refs.TipsPopup.onSwitch(5, 'open')
          }
        
      } catch (e) {
        this.onError(e)
      }
    },
    //参加活动
    async onParticipate() {
      try {
        const res = await enter({
          invite: this.invited_code ? this.invited_code : this.invited_ids ? this.invited_ids : '',
          acctoken: this.isLogin
        });
        this.onConfigure();
      } catch (e) {
        this.onError(e)
      }
    },
    //登录成功
    loginSuccess() {
      this.isShowLogin = false;
      this.showGetMedal();
    },
    //没登陆先登录
    onLogins(){
      if (!this.isLogin) {
          this.isShowLogin = true
        return false
      }
    },
    showGetMedal() {
      this.onLogoin();
    },
    /**
     * @param {*} name 跳转页面路由的name
     */
    gotoPage(name) {
      if (!this.isLogin && name == 'lszSeCollection') {
        this.$toast('您还未登录，请先登录账号');
        setTimeout(() => {
          this.isShowLogin = true
        }, 2000)
        return false
      }
      this.$router.push({
        name,
      });
    },
    //获取用户信息
    async getUser() {
      try {
        const res = await userApi({});
        this.vxSetUser({
          day: res.data.day,
          join_reserve: res.data.join_reserve,
          isSign: res.data.signs,
          ...res.data.user,
        });
      } catch (e) {
        this.onError(e);
      }
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息 
     */
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
      this.loadings = false
    },
    /**
     * 倒计时
     * @param {Number} endTime 结束时间
     * @param {Number} type 状态值
     */
    countDown(endTime, type) {
      var nowtime = new Date() //获取当前时间
      var lefttime = (endTime * 1000) - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24), //计算小时数
        leftm = Math.floor(lefttime / (1000 * 60) % 60), //计算分钟数
        lefts = Math.floor(lefttime / 1000 % 60); //计算秒数

      if (leftd <= 0 && lefth <= 0 && leftm <= 0 && lefts <= 0) {
        return false
      }

      function timeAdd0(str) {
        if (str < 10) {
          str = '0' + str;
        }
        return str;
      }
      if (type == 1) {
        return `距征集结束还剩 ${leftd}day  ${timeAdd0(lefth)}:${timeAdd0(leftm)}:${timeAdd0(lefts)}`
      } else {
        return `结果将在 ${timeAdd0(lefth)}:${timeAdd0(leftm)}:${timeAdd0(lefts)} 后公布`
      }
      // return `${leftd}天${lefth}时${leftm}分${lefts}秒`;
    }
  },
};
</script>
<style scoped lang="scss">
.imgBgk{
  width: 100%;
  margin-bottom: 70px;
}

.btns {
  position: fixed;
  width: 100%;
  z-index: 3;
  padding: 10px 0 20px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  .btnsTime {
    text-align: center;
    font-size: 13px;
    color: #fff;
    margin: 10px 0 20px;
  }

  .loadingIns {
    justify-content: center;
  }

  .btnsIns {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 12px;

    .bubbles {
      position: absolute;
      background-image: url('../../assets/img/lszSolicitation/bubble.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      font-size: 11px;
      color: #22464B;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 38px;
      top: -28px;
      width: 145px;
      height: 30px;
      animation: whirling 6s linear;
      animation-fill-mode: forwards;

      div {
        margin-bottom: 7px;
        color: #22464B;
        font-size: 11px;
      }
    }

    .btnsS {
      border-radius: 50px;
      background: #2a2e30;
      border: 1px solid #2a2e30;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36%;

      div {
        padding: 10px 0;
        color: #fff;
      }
    }

    .btnsInT {
      width: 80%;
    }

    .btnsIn {
      width: 54%;
    }

    .btnsT {
      border-radius: 50px;
      background: #22464B;
      border: 1px solid #F2CD8F;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        padding: 10px 0;
        color: #fff;
      }
    }
  }
}

@keyframes whirling {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
