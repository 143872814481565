<template>
  <div class="share">
    <van-overlay
      z-index="100"
      :show="show"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.7)' }"
    >
      <div class="contPopup">
        <div class="headerTopBgk"></div>
        <div class="conts">
          <div class="contFonts">
            <div>你在周排行中排名<span>{{prize.ranking}}</span></div>
            <div>恭喜获得以下奖励空投</div>

          </div>
          <div class="contReward">
            <div class="contRewardCont" v-for="(item, index) in prize.content" :key="index">{{item.name}}</div>
          </div>
          <div class="contTips">
            <div style="font-size: 11px;">数字道具奖励将直接到账</div>
            <div style="font-size: 11px;">实体产品请等待客服联系发放</div>
          </div>
          <div class="contBtn">
            <div class="contBtnBgk" @click="close()">
              立即收下
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import store from "../store";
import { tips } from "../../../api/sleep";
export default {
  props: ["show", "rankingData"],
  data() {
    return {
      prize: [],//奖励清单
    };
  },
  computed: {
  },
  async mounted() {
    this.onTips();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //获取中奖参数
    async onTips() {
      try {
        const res = await tips({
          acctoken: this.isLogin,
        });
        this.prize = res.data.data
      } catch (e) {
        this.onError(e);
      }
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      this.$toast(e.data);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
  //设置背景图
  @mixin bgk_Fun($url) {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .contPopup{
    background: #000000;
    box-shadow: 0 5px 20px #2d32bb;
    width: 65%;
    border-radius: 10px;
    .headerTopBgk{
      width: 80%;
      height: 280px;
      margin: auto;
      position: relative;
      top: -50px;
      @include bgk_Fun($url: '../../../assets/img/sleepActivity/popup.png');
    }
    .conts{
      margin-top: -116px;
      .contFonts{
        margin-bottom: 8px;
        div{
          color: #fff;
          text-align: center;
          margin-bottom: 0px;
          span{
            font-weight: 700;
            color: #005aff;
          }
        }
      }
      .contReward{
        .contRewardCont{
          padding: 10px 0;
          background: #1f26ea;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80%;
          margin: auto;
          border-radius: 5px;
          margin-bottom: 13px;
        }
      }
      .contTips{
        margin: 20px 0;
        color: #c5c5c5;
        div{
          text-align: center;
        }
      }
      .contBtn{
        margin: 20px 0;
        .contBtnBgk{
          @include bgk_Fun($url: '../../../assets/img/sleepActivity/btm.png');
          width: 60%;
          height: 35px;
          display: flex;
          align-items: center;
          margin: auto;
          text-align: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
  // @include bgk_Fun($url: '../../assets/img/sleepActivity/rankingHerder.png');
</style>
