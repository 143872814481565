import http from './http.js'

//点击参与
export const enter = (data) => {
  return http.post('index/solicitation/enter', data)
}

//是否报名
export const configure = (data) => {
  return http.post('index/solicitation/configure', data)
}

//当前用户排名
export const ranking = (data) => {
  return http.post('index/solicitation/ranking', data)
}

//用户藏品
export const choose = (data) => {
  return http.post('index/solicitation/choose', data)
}

//用户藏品授权
export const join = (data) => {
  return http.post('index/solicitation/join', data)
}

//助力记录 已助力
export const help_log = (data) => {
  return http.post('index/solicitation/help_log', data)
}

//助力记录 未助力
export const enter_log = (data) => {
  return http.post('index/solicitation/enter_log', data)
}

//排行榜
export const leaderboard = (data) => {
  return http.post('index/solicitation/leaderboard', data)
}

//帮别人助力
export const help = (data) => {
  return http.post('index/solicitation/help', data)
}

//扫码接口
export const scan_code = (data) => {
  return http.post('index/solicitation/scan_code', data)
}

//用户查看自己是否中奖
export const winning = (data) => {
  return http.post('index/solicitation/winning', data)
}

//未中奖用户领取胸针和转增符
export const user_receive = (data) => {
  return http.post('index/solicitation/user_receive', data)
}
