<template>
  <div class="page-box">
    <div class="head">
      <!-- <div class="back">
        <van-icon name="arrow-left" class="white" size="18" @click="back" />
      </div> -->
      <div class="head-title">
        <div>帮好友助力</div>
      </div>
    </div>
    <div v-if="datas.data" class="bottoms">
      <div class="avatars">
        <img :src="datas.data.avatar" alt="">
      </div>
      <div class="contents">
        <div class="userName">
          {{datas.data.username}}
        </div>
        <div class="textFont">
          正在申请成为
        </div>
        <div class="bottomSizeFont">
          <div>李时珍数字艺术俱乐部</div>
          <div>共创合作伙伴</div>
        </div>
        <div class="bottomImg">
          <div class="bottomImgs">
            <img :src="datas.data.image" alt="">
          </div>
          <div class="imgSkeletons">
            <div class="imgLIne imgLIne1"></div>
            <div class="imgLIne imgLIne2"></div>
            <div class="imgLIne imgLIne3"></div>
          </div>
          <div class="imgSkeleton"></div>
        </div>
      </div>
    </div>
    <div class="helpNumberText">当前好友排名<span>{{datas.ranking}}</span></div>
    <div class="helpNumberText mTp">好友距离前1名仅差 <span>{{datas.difference}}</span> 个助力！</div>
    <div class="helpBtn" @click="onBtnData()" :class="helpType == 1 ? '' : 'helpBtns'">
      <div>{{helpText}}</div>
    </div>
    <div class="gotoInde" @click="onGoToPage('lszSolicitationIndex')" v-if="isLogin && helpType != 1">
      返回活动首页 <van-icon name="arrow" color="#ffffff" size="11"/>
    </div>
    <login :show="isShowLogin" @close="isShowLogin = false" @success="loginSuccess"></login>
    <TipsPopup ref="TipsPopup" @onSuccess="onSuccessIn()"></TipsPopup>
  </div>
</template>
<script>
  import {
    enter,
    configure,
    ranking,
    help,
    scan_code
  } from "../../api/lszSolicitation";
  import {
    mapMutations,
    mapState
  } from "vuex";
  import {
    userApi
  } from "../../api/user";
  import Login from "../../components/Login";
  import TipsPopup from "./components/TipsPopup";
  export default {
    components: {
      TipsPopup,
      Login
    },
    computed: {
      ...mapState({
        user: (state) => state.user,
        isLogin: (state) => state.token,
        invited_code: (state) => state.invited_code,
        invited_ids: (state) => state.invited_ids
      }),
    },
    data() {
      return {
        helpText: '立即帮好友助力',
        helpType: 1, //1 暂未助力  2 今日已助力  3活动结束
        isShowLogin: false, //判断是否登录
        invited_id: '',
        datas:{},
        configure: {}
      }
    },
    name: "lszSolicitationHdgz",
    created() {},
    beforeCreate () {
      document.querySelector('body').setAttribute('style', 'background-color:#000000;')
    },
    mounted() {
      this.invited_id = this.$route.query.invited_id || localStorage.getItem("invited_id");
      this.onData();
      console.log(444, this.invited_id)
      if(this.isLogin){
        this.onParticipate();
        this.onConfigure();
      }
    },
    methods: {
      ...mapMutations({
        vxSetUser: "setUser"
      }),
      async onData() {
        try {
          const res = await scan_code({
            invite: this.invited_id
          });
          this.datas = res.data
        } catch (e) {
          this.onError(e);
        }
      },
      //参加活动
      async onParticipate(){
        try {
          const res = await enter({
            invite: this.invited_id,
            acctoken: this.isLogin
          });
        } catch (e) {
          this.onError(e)
        }
      },
      //用户助力
      async onBtnData() {
        if(this.helpType != 1){
          return false
        }
        if (this.isLogin) {
          try {
            const res = await help({
              invite: this.invited_id
            });
            this.$refs.TipsPopup.onSwitch(1, 'open')
          } catch (e) {
            this.onError(e);
          }
        } else {
          this.getUser();
        }
      },
      //判断活动时间是否过期
      async onConfigure(){
        try {
          const res = await configure(
          {
            acctoken: this.isLogin
          }
          );
          let data = res.data
          this.timer = setInterval(() => {
            const time = this.countDown(Number(data.end_time))
            if (!time) {
              clearInterval(this.timer)
              this.helpType = 3
              this.helpText = '活动结束'
            } else if(data.is_help) {
              this.helpType = 2
              this.helpText = '今日已助力'
            }
          }, 1000)
          this.configure = res.data
        } catch (e) {
          this.onError(e)
        }
      },
      //点击关闭
      onSuccessIn() {
        this.$router.push('lszSolicitationIndex');
      },
      async getUser() {
        try {
          const res = await userApi({});
          this.vxSetUser({
            day: res.data.day,
            join_reserve: res.data.join_reserve,
            isSign: res.data.signs,
            ...res.data.user,
          });
        } catch (e) {
          this.onError(e);
        }
      },
      onError(e) {
        console.log(e);
        if (e.code === 201) {
          this.$toast('您暂未登录，请先登录~');
          setTimeout(() => {
            this.isShowLogin = true;
          }, 2000)
        } else {
          this.$toast(e.data);
        }
      },
      //登录成功
      loginSuccess() {
        this.isShowLogin = false;
      },
      back() {
        this.$router.back();
      },
      //页面跳转
      onGoToPage(name){
        this.$router.push({
          name,
        });
      },
      //倒计时
      countDown(endTime) {
      	var nowtime = new Date() //获取当前时间
      	var lefttime = (endTime * 1000) - nowtime.getTime(),//距离结束时间的毫秒数
      		leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
      		lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24), //计算小时数
      		leftm = Math.floor(lefttime / (1000 * 60) % 60), //计算分钟数
      		lefts = Math.floor(lefttime / 1000 % 60); //计算秒数
      	if(leftd <= 0 && lefth <= 0 && leftm <= 0 && lefts <= 0){
      		return false
      	} else {
          return true
        }
      	// return `${leftd}天${lefth}时${leftm}分${lefts}秒`;
      }
    },
  };
</script>
<style scoped lang="scss">
.page-box{
  background: #000000;
  min-height: 100vh;
}
  .mTp{
    margin: 0 0 20px!important;
  }
  .helpNumberText {
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    margin: 35px 0 10px;

    span {
      color: #07B395;
      font-weight: 700;
    }
  }
  .gotoInde{
    color:#ffffff;
    font-size: 12px;
    margin: 20px 0;
    text-align: center;
  }
  .helpBtn {
    width: 70%;
    margin: auto;
    background: #22464B;
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    border-radius: 50px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: all .5s;
  }

  .helpBtns {
    background: #282733;
    color: #53525c;
  }

  .bottoms {
    width: 90%;
    margin: auto;
    height: 460px;
    background-image: url('../../assets/img/lszSolicitation/helpBgk.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 50px;

    .avatars {
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #ffffff;
      margin: 0 auto 0px;
      width: 70px;
      height: 70px;
      position: relative;
      bottom: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .contents {
      position: relative;
      bottom: 20px;

      .userName {
        color: #07B395;
        font-size: 15px;
        margin-bottom: 15px;
        text-align: center;
        font-weight: 700;
      }

      .textFont {
        font-size: 12px;
        margin-bottom: 20px;
        text-align: center;
        color: #a8a8a8;
      }

      .bottomSizeFont {
        margin-bottom: 40px;

        div {
          text-align: center;
          font-size: 18px;
          font-weight: 700;
          color: #ffffff;
        }
      }

      .bottomImg {
        width: 100%;
        height: 100%;
        position: relative;

        .bottomImgs {
          position: absolute;
          width: 200px;
          height: 200px;
          border-radius: 10px;
          overflow: hidden;
          margin: auto;
          right: 0;
          left: 0;
          z-index: 2;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .imgLIne {
          width: 1px;
          height: 20px;
          transform: rotate(325deg);
          background: #D3FEFF;
          position: absolute;
        }

        .imgLIne1 {
          right: -1px;
        }

        .imgLIne2 {
          right: 0;
          bottom: -10px;
        }

        .imgLIne3 {
          left: 7px;
          bottom: -13px;
        }

        .imgSkeletons {
          position: relative;
          width: 200px;
          height: 200px;
          margin: auto;
        }

        .imgSkeleton {
          position: absolute;
          right: 0px;
          left: 15px;
          top: 12px;
          width: 200px;
          height: 200px;
          border: 1px solid #D3FEFF;
          border-radius: 10px;
          margin: auto;
          z-index: 1;
        }
      }
    }

  }

  .texts {
    color: #878689;
    line-height: 20px;
    padding: 20px;

    div {
      font-size: 12px !important;
    }
  }

  .head {
    background-size: 100% 100%;
    position: relative;
    padding: 28px;
    display: flex;
    align-items: center;
    background: #000000;

    .back {
      width: 27px;
      height: 27px;
      background: rgba(0, 0, 0, 0.48);
      border-radius: 50%;
      position: fixed;
      left: 13px;
      top: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }

    .head-title {
      color: #ffffff;
      text-align: center;
      font-size: 15px !important;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
