<template>
  <div class="share">
    <van-overlay
      z-index="100"
      :show="show"
      @click="close"
      :custom-style="{ background: 'rgba(0, 0, 0, 0.7)' }"
    >
      <div class="contPopup">
        <div class="headerTopBgk"></div>
        <div class="conts">
          <div class="contFonts" >
            <div>预约成功</div>
            <div>{{font.fontName}}</div>
          </div>
          <div class="contReward" @click.stop="onType(type)">
            <div class="contRewardCont">{{font.fontBtn}}</div>
          </div>
          <div class="contTips" v-if="type == 2">
            <div style="font-size: 11px;">操作步骤:</div>
            <div style="font-size: 11px;">1.点击下载APP</div>
            <div style="font-size: 11px;">2.使用此账号手机号登录</div>
            <div style="font-size: 11px;">3.点击【设置-账户与安全】</div>
            <div style="font-size: 11px;">4.完成实名认证</div>
          </div>
          <div class="contTips" v-if="type == 3">
            <div style="font-size: 11px;">操作步骤:</div>
            <div style="font-size: 11px;">1.点击按钮生成并保存海报</div>
            <div style="font-size: 11px;">2.发送给好友</div>
            <div style="font-size: 11px;">3.好友预约并完成实名认证</div>
          </div>
          <!-- <div class="contBtn">
            <div class="contBtnBgk" @click="close()">
              立即收下
            </div>
          </div> -->
        </div>
      </div>
      <div class="backs" @click="close()">
          <img
            class="bckgsImg"
            src="../../../assets/img/sleepActivity/close.png"
            alt=""
          />
        </div>
    </van-overlay>
  </div>
</template>

<script>
// import store from "../store";
import { tips } from "../../../api/sleep";
export default {
  props: ["show", "shareId", "isAttested"],
  data() {
    return {
      prize: [],//奖励清单
      type: '0', // 判断当前值
      font:{
        fontName: '',
        fontBtn:'',
      }
    };
  },
  computed: {
  },
  async mounted() {
    console.log(11,this.shareId)
    console.log(22,this.isAttested)

    // 没有上级
    if(!this.shareId){
      this.type = 1
      this.font.fontName = '邀请好友获得更多积分'
      this.font.fontBtn = '邀请好友预约'
    //若未实名并且有上级y
    } else if(!this.isAttested && this.shareId){
      this.type = 2
      this.font.fontName = '实名认证为好友成功助力'
      this.font.fontBtn = '下载APP完成实名'
    //若实名并且有上级
    } else if(this.isAttested && this.shareId){
      this.type = 3
      this.font.fontName = '邀请好友获得更多积分'
      this.font.fontBtn = '邀请好友参与预约'
    }
  },
  methods: {
    openInWebview () {
      /* 返回true或false; */
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 微信浏览器判断
        return false
      } else if (ua.match(/QQ/i) == 'qq') {
        // QQ浏览器判断
        return false
      } else if (ua.match(/WeiBo/i) == 'weibo') {
        return false
      } else {
        if (ua.match(/Android/i) != null) {
          return ua.match(/browser/i) == null
        } else if (ua.match(/iPhone/i) != null) {
          return ua.match(/safari/i) == null
        } else {
          return ua.match(/macintosh/i) == null && ua.match(/windows/i) == null
        }
      }
    },
    close() {
      this.$emit("close");
    },
    onType(e){
      let a = this.openInWebview()
      if(a){
        this.close()
      } else {
        window.location.href = 'https://appfile.relaverse.cn/m/download.html'
      }
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      this.$toast(e.data);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
  //设置背景图
  @mixin bgk_Fun($url) {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .backs {
    width: 27px;
    height: 27px;
    background: rgba(0, 0, 0, 0.48);
    border-radius: 50%;
    position: fixed;
    bottom: 19%;
    left: 47%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .bckgsImg {
      width: 100%;
      height: 100%;
    }
  }
  .contPopup{
    background: #000000;
    box-shadow: 0 5px 20px #2d32bb;
    width: 65%;
    border-radius: 10px;
    .headerTopBgk{
      width: 80%;
      height: 280px;
      margin: auto;
      position: relative;
      top: -50px;
      @include bgk_Fun($url: '../../../assets/img/sleepActivity/popup.png');
    }
    .conts{
      margin-top: -116px;
      .contFonts{
        margin-bottom: 8px;
        line-height: 26px;
        position: relative;
        div{
          color: #fff;
          text-align: center;
          margin-bottom: 0px;
          font-size: 13px;
          span{
            font-weight: 700;
            color: #005aff;
          }
        }
        div:first-child{
          font-size: 17px!important;
          font-weight: 700;
        }
      }
      .contReward{
        .contRewardCont{
          padding: 10px 0;
          background: #1f26ea;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80%;
          margin: auto;
          border-radius: 5px;
          margin-bottom: 13px;
        }
      }
      .contTips{
        margin: 20px 0;
        color: #c5c5c5;
        line-height: 28px;
        div{
          text-align: center;
        }
      }
      .contBtn{
        margin: 20px 0;
        .contBtnBgk{
          @include bgk_Fun($url: '../../../assets/img/sleepActivity/btm.png');
          width: 60%;
          height: 35px;
          display: flex;
          align-items: center;
          margin: auto;
          text-align: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
  // @include bgk_Fun($url: '../../assets/img/sleepActivity/rankingHerder.png');
</style>
